import { Backdrop, CircularProgress, Popover, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../styles';
import { usePO } from '../../utils/POContext';
import { makeStyles } from '@mui/styles';

interface Props {
	zIndex?: number;
	smallBackdrop?: boolean;
	width?: number;
	height?: number;
}

const BackDropLoading: React.FC<Props> = ({
	zIndex,
	smallBackdrop,
	width,
	height,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const useStyles = makeStyles(() => ({
		backdrop: {
			backdropFilter: `blur(5px)`,
			backgroundColor:
				selectedTheme.id === 'dark' ? 'rgba(18, 18, 18, 0.7)' : '#edededb8',
			boxShadow: '0px 0px 250px 0px #FFFFFF80',
		},
		popoverRoot: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: zIndex || 1,
		},
	}));

	const classes = useStyles();

	return smallBackdrop ? (
		<Popover
			data-testid="Popover"
			open
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			anchorReference={'none'}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'center',
			}}
			classes={{
				root: classes.popoverRoot,
			}}
			slotProps={{
				paper: {
					style: {
						width: width,
						height: height,
						padding: 30,
						backdropFilter: 'blur(5px)',
						backgroundColor:
							selectedTheme.id === 'dark'
								? 'rgba(18, 18, 18, 0.7)'
								: '#EDEDEDB2',
						boxShadow: '0px 0px 250px 0px #FFFFFF80',
						borderRadius: 10,
						border: selectedTheme.id === 'dark' ? '' : '1px solid #c0c0c0',
					},
				},
			}}
		>
			<Line style={{ height: '100%' }}>
				<Column>
					<CircularProgress
						color="inherit"
						sx={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
					<Typography
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							marginTop: 10,
						}}
					>
						{`${t('Carregando...')}`}
					</Typography>
				</Column>
			</Line>
		</Popover>
	) : (
		<Backdrop
			data-testid="Backdrop"
			open={true}
			invisible={true}
			style={{
				zIndex: zIndex || 1,
			}}
			className={classes.backdrop}
		>
			<Column>
				<CircularProgress
					color="inherit"
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
				<Typography
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						marginTop: 10,
					}}
				>
					{`${t('Carregando...')}`}
				</Typography>
			</Column>
		</Backdrop>
	);
};

export default BackDropLoading;
