import React from 'react';
import { Typography } from '@mui/material';
import { Column, Line } from '../../styles';

const UserTerms: React.FC = () => {
	return (
		<Line
			style={{
				width: '94%',
				justifyContent: 'center',
				paddingBottom: 20,
			}}
		>
			<Column
				style={{
					marginTop: 40,
					rowGap: 30,
					padding: '50px 60px 60px 80px',
					width: '60%',
					borderStyle: 'solid',
					borderWidth: 1,
					borderColor: 'gray',
					background: 'white',
					boxShadow: '5px 5px 5px rgba(200,200,200, 0.5)',
				}}
			>
				<Typography
					align="center"
					variant="h5"
					style={{ fontWeight: 'bold', width: '100%' }}
				>
					TERMOS DE USO: Aplicativo e Extensão
				</Typography>
				<Typography
					align="center"
					style={{ fontWeight: 'bold', width: '100%', marginTop: -10 }}
				>
					Versão atualizada em 03/06/2024.
				</Typography>
				<Typography align="left" style={{ width: '100%', marginTop: 30 }}>
					Prezado(a) Usuário(a),
				</Typography>
				<Typography align="left" style={{ width: '100%' }}>
					Para que você possa utilizar a extensão é necessário ler atentamente
					os termos de uso a seguir.
				</Typography>
				<Typography align="justify">
					A Offer Technologies é criadora e titular da ferramenta Plataforma
					Offer sediada em Florianópolis, Santa Catarina, Brasil, na Rua Santa
					Luzia, 100, Sala 801, CEP 88.036-540. A Plataforma Offer possibilita
					que o usuário possa de forma rápida e segura manifestar sua opinião
					sobre uma determinada oferta, conteúdo ou produto caso suspeite de que
					os mesmos estejam ofertando/divulgando a venda de produto(s) que, por
					sua vez, infrinja(m) direito(s) de propriedade intelectual alheios,
					tais como, produtos piratas ou falsificados. As opiniões do usuário
					serão chamadas de &quot;denúncia&quot;. A(s) denúncia(s) do(s)
					usuário(s) será(ão) objeto de análise por profissionais
					especializados, que confirmarão ou não a mesma. Em caso positivo, o
					titular do direito de propriedade intelectual infringido adotará as
					providências cabíveis para denúncia, exclusão da referida oferta ou
					conteúdo e você poderá acompanhar o status da providência. Para que
					você possa utilizar a ferramenta é necessário ler atentamente os
					termos de uso a seguir, bem como as permissões e, somente utilizar o
					sistema se você estiver de acordo com as mesmas. O usuário poderá
					denunciar URLs veiculados ou não por meio de redes sociais,
					e-commerces e sites através da ferramenta de denúncia digital. O
					usuário poderá também denunciar estabelecimentos comerciais, por meio
					da ferramenta de denúncia física, onde indicará a localização do
					estabelecimento através do preenchimento de um formulário ou concessão
					da permissão de acesso a sua localização. A Offer Tecnhologies, por
					sua vez, compromete-se a manter estrito sigilo em relação à informação
					que o usuário lhe compartilhar, bem como, a garantir total privacidade
					em relação a outras informações compartilhadas pelo usuário, bem como,
					comprometendo-se a utilizar as informações da URL denunciada
					exclusivamente para o fim de monitoramento de legalidade do referido
					conteúdo. A Offer Technologies ficará isenta de responsabilidade
					perante o usuário caso tenha de fornecer informações por força de
					ordem judicial ou imposição legal. Após a obtenção da URL denunciada,
					a Offer Technologies Ltda passará a monitorar a mesma a partir de
					servidor próprio. O usuário que atender as condições comerciais
					estabelecidas pela Offer Technologies Ltda terá uma licença temporária
					de uso do software &quot;Plataforma Offer&quot;, na modalidade
					personal computer. O usuário compromete-se em manter sigilo de sua
					senha de acesso, não podendo compartilhá-la com outro(s),
					comprometendo-se também a utilizar a &quot;Plataforma Offer&quot;
					apenas em um computador de sua propriedade. Os direitos de propriedade
					intelectual referentes ao software, layouts, marcas, conteúdos, que
					caracterizam a Plataforma Offer pertencem exclusivamente à OFFER
					TECHNOLOGIES LTDA. A Offer Technologies poderá a qualquer momento, por
					sua mera liberalidade, realizar atualizações e modificações no sistema
					Plataforma Offer, independentemente de qualquer autorização prévia do
					usuário. A Offer Technologies poderá a seu critério descontinuar os
					serviços Plataforma Offer, bastando avisar o usuário, através do email
					de seu cadastro, com 10 dias de antecedência. O Usuário que infringir
					os termos de uso, ou que proceder de forma ilegal, terá seu acesso
					imediatamente bloqueado pela Offer Technologies, sem prejuízo de
					sofrer outras sanções de cunho civil ou penal.
				</Typography>
				<Typography align="left" style={{ width: '100%' }}>
					Aplicam-se ao presente termo as leis brasileiras.
				</Typography>
				<Typography align="left" style={{ width: '100%' }}>
					Em caso de dúvidas ou sugestões, contate o email a seguir:
				</Typography>
				<Typography
					align="right"
					variant="h6"
					style={{ fontWeight: 'bold', width: '100%' }}
				>
					contato@offertech.com.br
				</Typography>
			</Column>
		</Line>
	);
};

export default UserTerms;
