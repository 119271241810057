import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { api, isAxiosError } from '../../../../utils/api';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { useSnackbar } from 'notistack';
import { ErrorResponse } from '../../../../interfaces';

interface Props {
	open: boolean;
	setOpen: any;
	rowData: any;
	refresh: () => void;
}

const DisableSearchDialog: React.FC<Props> = ({
	rowData,
	refresh,
	open,
	setOpen,
}: Props) => {
	const { selectedTheme, cursorMode } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClientStatusChange = async () => {
		setLoading(true);
		try {
			const searchPayload = {
				ISactive: !rowData.ISactive,
			};
			const searchResponse = await api.patch(
				`/searches/${rowData.searchID}/${rowData.clientID}`,
				searchPayload
			);
			enqueueSnackbar(searchResponse.data.detail, { variant: 'success' });
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		} finally {
			setLoading(false);
			handleClose();
			refresh();
		}
	};

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '20px 30px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay6dp
						: selectedTheme.foreground,
				color:
					selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
			}}
		>
			<Typography variant="h6">
				{rowData?.ISactive === true
					? `${t('Buscas.Tabs.Buscas Salvas.Deseja desativar a busca')}: ${
							rowData?.title
						}?`
					: `${t('Buscas.Tabs.Buscas Salvas.Deseja ativar a busca')}: ${
							rowData?.title
						}?`}
			</Typography>
		</DialogContent>
	);

	const loadingButton = () => {
		if (loading) {
			return 'gray';
		} else {
			return selectedTheme === 'dark'
				? selectedTheme.overlay8dp
				: selectedTheme.error;
		}
	};

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loadingButton() }}
				>
					{t('Buscas.Tabs.Plataformas.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						onClick={handleClientStatusChange}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{rowData?.ISactive === true
							? t('Buscas.Tabs.Plataformas.Desativar')
							: t('Buscas.Tabs.Plataformas.Ativar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{ cursor: cursorMode }}
			>
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default DisableSearchDialog;
