import React, { useEffect, useState } from 'react';
import SnackbarMessage from '../../../../components/SnackbarMessage';

interface Props {
	tableData: any;
}
const CheckProcessigItems: React.FC<Props> = ({ tableData }: Props) => {
	const [openProcessingMessage, setOpenProcessingMessage] = useState(false);
	const [processingMessage, setProcessingMessage] = useState('');
	const [processingItems, setProcessingItems] = useState<any[]>([]);

	useEffect(() => {
		const tempTableData: any = [];
		const tempProcessingData: any = new Set([]);
		/** Checking items being processed, reporting those items and building table data without them */
		tableData.map((el) => {
			if (el.ISprocessing === true) {
				tempProcessingData.add(el);
			}
			if (el.ISprocessing === false || !el.ISprocessing) {
				tempTableData.push(el);
			}
			return null;
		});
		setProcessingItems(Array.from(tempProcessingData));
		if (processingItems.length > 0) {
			setTimeout(() => {
				setProcessingMessage(
					`Há ${processingItems.length} item(s) em processamento.`
				);
				setOpenProcessingMessage(true);
			}, 2000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData]);

	return (
		<SnackbarMessage
			open={openProcessingMessage}
			setOpen={setOpenProcessingMessage}
			message={processingMessage}
			type={'info'}
			position={{ vertical: 'bottom', horizontal: 'center' }}
			time={10000}
		/>
	);
};

export default CheckProcessigItems;
