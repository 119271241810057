import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/styles';
import './index.css';
import theme from './ui/theme';

import App from './App';
import { Auth0Provider } from './react-auth0-spa';
import history from './utils/history';
import * as serviceWorker from './serviceWorker';
import DayjsUtils from '@date-io/dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as Sentry from '@sentry/react';
import config from './utils/config';

Sentry.init({
	dsn: 'https://68d5d1897c5398d03f3aeeaaeecd8290@sentry.offertech.com.br/7',
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	beforeSend(event) {
		if (event.logger === 'console') {
			return null;
		}
		return event;
	},
});

const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<div>
		<Auth0Provider
			domain={config.domain}
			client_id={config.clientId}
			audience={config.audience}
			redirect_uri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
		>
			<ThemeProvider theme={theme}>
				<LocalizationProvider utils={DayjsUtils}>
					<App />
				</LocalizationProvider>
			</ThemeProvider>
		</Auth0Provider>
	</div>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
