import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { Line } from '../../../../../styles';
import { usePO } from '../../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../../components/LoadingDots';

interface Props {
	setConfirm: (param) => void;
	closeDialog: () => void;
	platformName: string;
	open: boolean;
	addType: string;
}

const ConfirmPlatformDialog: React.FC<Props> = ({
	setConfirm,
	closeDialog,
	platformName,
	open,
	addType,
}: Props) => {
	const { selectedTheme, cursorMode } = usePO();
	const { t } = useTranslation();
	const [loading] = React.useState(false);

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '20px 30px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
			}}
		>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
				}}
			>
				{addType === 'branch'
					? `${t(
							'Buscas.Tabs.Plataformas.Adicionar um novo pais para a plataforma'
						)}: ${platformName}?`
					: `${t(
							'Buscas.Tabs.Plataformas.Adicionar uma plataforma'
						)}: ${platformName}?`}
			</Typography>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={closeDialog}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Buscas.Tabs.Plataformas.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						size="small"
						onClick={() => setConfirm(addType)}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{t('Buscas.Tabs.Plataformas.Adicionar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{ cursor: cursorMode }}
			>
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default ConfirmPlatformDialog;
