import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	Zoom,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { TagType, apiResponse } from '../../../interfaces';
import { Line } from '../../../styles';
import Loading from '../../../components/LoadingDots';
import { OfferSwitch, OfferToolTip } from '../../../helpers';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePO } from '../../../utils/POContext';
import { api, setupRequestToken } from '../../../utils/api';
import { updateTagStatus } from '../../../services/tags';

interface props {
	rowData: TagType;
	refresh: () => void;
}

const TagStatusChangeDialog: React.FC<props> = ({
	rowData,
	refresh,
}: props) => {
	const { token } = useAuth0();
	const { selectedTheme } = usePO();
	setupRequestToken(api, token);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [switchState, setSwitchState] = useState(rowData.ISactive);
	const [tagSwitchState, setTagSwitchState] = useState(switchState);

	const switchOfferStyles = (switchState) => {
		if (switchState) {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.primary;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.primaryDark
				: selectedTheme.disabled;
		}
	};

	const handlePanelsSwitchChange = (event) => {
		setTagSwitchState(event.target.checked);
		setOpen(true);
	};

	const handleClose = () => {
		setTagSwitchState(switchState);
		setOpen(false);
	};

	const handleTagStatusChange = async () => {
		const payload: TagType = {
			description: rowData.description,
			textColor: rowData.textColor,
			badgeColor: rowData.badgeColor,
			ISactive: !rowData.ISactive,
			id: '',
			name: '',
		};
		setLoading(true);
		const response: apiResponse | undefined = await updateTagStatus(
			rowData.id,
			payload
		);
		if (response && response.success) {
			enqueueSnackbar(
				tagSwitchState
					? t('Etiqueta.Etiqueta ativada com sucesso')
					: t('Etiqueta.Etiqueta desativada com sucesso'),
				{
					variant: tagSwitchState ? 'success' : 'warning',
				}
			);
			setSwitchState(tagSwitchState);
			refresh();
		} else {
			enqueueSnackbar(t('Erro ao atualizar a etiqueta'), {
				variant: 'error',
			});
		}
		handleClose();
		setLoading(false);
	};

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '5px 10px',
				}}
			>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Etiqueta.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={100} height={30} loop />
				) : (
					<Button
						onClick={handleTagStatusChange}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{switchState ? t('Etiqueta.Desativar') : t('Etiqueta.Ativar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<div style={{ marginLeft: 10 }}>
				<OfferToolTip
					title={
						switchState
							? `${t('Etiqueta.Desativar etiqueta')}`
							: `${t('Etiqueta.Ativar etiqueta')}`
					}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<OfferSwitch
						size="small"
						checked={switchState}
						onChange={handlePanelsSwitchChange}
						style={{
							color: switchOfferStyles(tagSwitchState),
						}}
					/>
				</OfferToolTip>
			</div>

			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				<DialogContent
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					<Line
						style={{
							justifyContent: 'space-between',
							padding: '2px 10px 10px 10px',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						<Typography data-testid="textDisable" variant="h6">
							{`${
								switchState
									? t('Etiqueta.Desativar etiqueta')
									: t('Etiqueta.Ativar etiqueta')
							}:  `}
						</Typography>

						<Typography variant="h6" style={{ marginLeft: 5 }}>
							{`${rowData.name}`}
						</Typography>
						<Typography variant="h6">?</Typography>
					</Line>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default TagStatusChangeDialog;
