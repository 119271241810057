import React from 'react';
import {
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../../../../styles';
import { usePO } from '../../../../../../utils/POContext';

interface Props {
	setConfirm: (param) => void;
	closeDialog: () => void;
	clientID: string;
	open: boolean;
	addType: string;
}

const AddInfoDialog: React.FC<Props> = ({
	setConfirm,
	closeDialog,
	clientID,
	open,
	addType,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '0px 5px',
				}}
			>
				<Button
					onClick={closeDialog}
					size="small"
					style={{ color: selectedTheme.error }}
				>
					{t('Clientes.Cancelar')}
				</Button>
				<Button
					onClick={() => setConfirm(addType)}
					size="small"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					{t('Clientes.Adicionar')}
				</Button>
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<Dialog
				open={open}
				onClose={(_, reason) => {
					if (reason === 'escapeKeyDown') {
						closeDialog();
					}
				}}
				// onBackdropClick={(_) => {
				// 	return null;
				// }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				<DialogContent
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					<Line
						style={{
							justifyContent: 'space-between',
							padding: '2px 10px 10px 10px',
						}}
					>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
							}}
						>
							{addType === 'branch'
								? `${t(
										'Clientes.Adicionar uma flial ao cliente'
									)}: ${clientID}?`
								: `${t('Clientes.Adicionar novo cliente')}: ${clientID}?`}
						</Typography>
					</Line>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default AddInfoDialog;
