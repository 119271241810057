import React from 'react';
import { IconButton, Zoom } from '@mui/material';
import { LaunchRounded } from '@mui/icons-material/';
import { Link } from 'react-router-dom';
import { OfferToolTip } from '../../../helpers';
import { usePanelControls } from '../../../hooks/PanelControls';
import { useViewAuthor } from '../../../hooks/ViewAuthor';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';

interface Props {
	rowData: any;
}

const OpenCurrentSearch: React.FC<Props> = ({ rowData }: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const { setViewAuthorByName } = useViewAuthor();
	const { setFilterBarStates } = usePanelControls();

	const handleOpenAuthor = () => {
		setViewAuthorByName(`${rowData.name}`);
		setFilterBarStates(true);
	};

	return (
		<OfferToolTip
			title={`${t('Entidades.Visualizar autor')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				component={Link}
				disabled
				size="small"
				to="/autores"
				aria-label="open-author"
				style={{ marginRight: 10 }}
				onClick={handleOpenAuthor}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<LaunchRounded
					sx={{
						transform: 'rotateY(180deg)',
						fontSize: '16px',
						color:
							selectedTheme.id === 'dark' && selectedTheme.textColorDisable,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);
};

export default OpenCurrentSearch;
