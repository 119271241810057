import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { apiResponse } from '../../../interfaces';
import { useSnackbar } from 'notistack';
import { toggleRule } from '../../../services/rules';
import { Line } from '../../../styles';
import LoadingDots from '../../../components/LoadingDots';
interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	refresh: () => void;
	data: any;
}

const RuleToggleDialog: React.FC<Props> = ({
	open,
	setOpen,
	refresh,
	data,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, updateCursorMode, selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		const ruleID = data.id;
		const switchRuleTo = !data.ISactive;
		setLoading(true);
		updateCursorMode('wait');
		const response: apiResponse | undefined = await toggleRule(
			ruleID,
			selectedClient.id,
			switchRuleTo
		);
		if (response && response.status !== undefined) {
			if (response.status < 300) {
				enqueueSnackbar(t(`${response.message}`), { variant: 'success' });
			} else if (response.status >= 400) {
				const message = response.message;
				enqueueSnackbar(t(`${message}`), { variant: 'error' });
			}
		}
		updateCursorMode('default');
		setOpen(false);
		setLoading(false);
		refresh();
	};

	return (
		<Dialog open={open}>
			<DialogContent
				style={{
					padding: 22,
					width: '100%',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Typography
					align="center"
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{data?.ISactive
						? `${t('Regras.Desativar a regra')}: ${data?.name}`
						: `${t('Regras.Ativar a regra')}: ${data?.name}`}
					?
				</Typography>
			</DialogContent>
			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				<Line
					style={{
						justifyContent: 'space-between',
						padding: 5,
					}}
				>
					<Button
						disabled={loading}
						onClick={() => setOpen(false)}
						style={{ color: selectedTheme.error }}
					>
						{t('Regras.Cancelar')}
					</Button>
					<Button
						disabled={loading}
						onClick={handleConfirm}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{loading ? (
							<LoadingDots height={25} width={60} loop />
						) : (
							t('Regras.Confirmar')
						)}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};
export default RuleToggleDialog;
