import React from 'react';
import { makeStyles } from '@mui/styles';
import {
	Card,
	Divider,
	Paper,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { Header, Line } from '../../styles';
import { usePO } from '../../utils/POContext';

interface Props {
	tab1Component: any;
	tab2Component: any;
	tab3Component?: any;
	tab4Component?: any;
	tab1Title: string;
	tab2Title: string;
	tab3Title?: string;
	tab4Title?: string;
	windowTitle?: any;
	useModal?: boolean;
	handleCloseModal?: any;
	openModal?: boolean;
	modalFooter?: any;
	modalSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	modalProps?: any;
}

/**
 * Window Tabs: Add tabs to switch contents of a window
 * @requredProps !!!!
 * @tab1Component React component
 * @tab2Component React component
 * @tab1Title string
 * @tab2Title string
 * @windowTitle String or React component
 * @useModal boolean - if true, the window will be displayed in a modal
 * @modalProps object (optional) - props to be passed to the Dialog Mui component
 */
const WindowTabs: React.FC<Props> = ({
	tab1Component,
	tab2Component,
	tab3Component,
	tab4Component,
	tab1Title,
	tab2Title,
	tab3Title,
	tab4Title,
	windowTitle,
	useModal,
	handleCloseModal,
	openModal,
	modalFooter,
	modalSize,
	modalProps,
}: Props) => {
	const { selectedTheme } = usePO();
	const [clickedTab, setClickedTab] = React.useState('tab01');

	const useStyles = makeStyles(() => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 5,
			boxShadow: useModal ? 'unset' : '4px 4px 10px rgba(0, 0, 0, 0.1)',
		},
		header: {
			justifyContent: 'space-between',
			width: '100%',
			height: 64,
			fontSize: 18,
			fontWeight: 'bold',
			padding: 20,
		},
		title: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			fontWeight: 'bold',
		},
		buttonTab: {
			position: 'relative',
			height: 30,
			textTransform: 'none',
			fontWeight: 'bold',
			opacity: 0.8,
			borderWidth: 0,
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay8dp
					: selectedTheme.foreground,
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.textColorDisable
					: selectedTheme.primaryLight,
			'&:hover': {
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay4dp
						: selectedTheme.disabled,
				border: 'none',
			},
		},
		buttonTabActive: {
			position: 'relative',
			height: 30,
			textTransform: 'none',
			borderWidth: 0,
			backgroundColor: useModal
				? selectedTheme.primaryDark
				: selectedTheme.id === 'dark'
					? selectedTheme.currentPageButton
					: selectedTheme.background,
			fontWeight: 'bold',
			zIndex: 2,
			pointerEvents: 'none',
			opacity: useModal ? 0.9 : 1,
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.primary,
		},
	}));

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'main':
				return '';
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};
	const classes = useStyles();

	const handleTabClick = (tab: any) => {
		setClickedTab(tab);
	};

	const renderHeader = () => {
		return (
			<Header
				style={{
					padding: useModal ? '10px 15px' : 20,
					height: useModal ? 50 : 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.id === 'main'
								? selectedTheme.primary
								: selectedTheme.background,
					justifyContent: 'space-between',
					minWidth: 'max-content',
					display: 'flex',
				}}
			>
				<Line
					data-testid="headerText"
					style={{
						marginRight: useModal ? 10 : 0,
					}}
				>
					{windowTitle}
				</Line>
				<Line
					style={{
						justifyContent: 'flex-end',
						marginRight: -5,
					}}
				>
					<ButtonGroup
						disableElevation={useModal}
						style={{
							border: handleBorderButton(),
						}}
					>
						<Button
							data-testid={tab1Title}
							id="tab01"
							className={
								clickedTab === 'tab01'
									? classes.buttonTabActive
									: classes.buttonTab
							}
							style={{
								padding: '0 20px',
								minWidth: 'max-content',
							}}
							onClick={() => handleTabClick('tab01')}
						>
							{tab1Title}
						</Button>

						<Button
							data-testid={tab2Title}
							id="tab02"
							className={
								clickedTab === 'tab02'
									? classes.buttonTabActive
									: classes.buttonTab
							}
							style={{
								padding: '0 20px',
								minWidth: 'max-content',
							}}
							onClick={() => handleTabClick('tab02')}
						>
							{tab2Title}
						</Button>
						{tab3Component ? (
							<Button
								data-testid={tab3Title}
								id="tab03"
								className={
									clickedTab === 'tab03'
										? classes.buttonTabActive
										: classes.buttonTab
								}
								style={{
									padding: '0 20px',
									minWidth: 'max-content',
								}}
								onClick={() => handleTabClick('tab03')}
							>
								{tab3Title}
							</Button>
						) : null}
						{tab4Component ? (
							<Button
								data-testid={tab4Title}
								id="tab04"
								className={
									clickedTab === 'tab04'
										? classes.buttonTabActive
										: classes.buttonTab
								}
								style={{
									padding: '0 20px',
									minWidth: 'max-content',
								}}
								onClick={() => handleTabClick('tab04')}
							>
								{tab4Title}
							</Button>
						) : null}
					</ButtonGroup>
				</Line>
			</Header>
		);
	};

	const renderContent = () => {
		switch (clickedTab) {
			case 'tab01':
				return tab1Component;
			case 'tab02':
				return tab2Component;
			case 'tab03':
				return tab3Component;
			case 'tab04':
				return tab4Component;
			default:
				break;
		}
		return null;
	};

	return useModal ? (
		<Dialog
			maxWidth={modalSize || 'sm'}
			onClose={handleCloseModal}
			fullWidth
			scroll="paper"
			open={openModal || false}
			{...modalProps}
		>
			<DialogTitle style={{ padding: 0 }}>
				{renderHeader()}
				<Divider />
			</DialogTitle>
			<DialogContent style={{ padding: 0 }}>{renderContent()}</DialogContent>
			<DialogActions
				style={{
					height: 50,
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.overlay3dp
							: selectedTheme.foreground,
				}}
			>
				{modalFooter}
			</DialogActions>
		</Dialog>
	) : (
		<Card className={classes.root}>
			{renderHeader()}
			<Paper style={{ width: '100%' }}>
				<Divider
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
					}}
				/>
				<Divider
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
					}}
				/>
				{renderContent()}
			</Paper>
		</Card>
	);
};

export default WindowTabs;
