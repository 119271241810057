import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'rowPerPage',
	initialState: {
		selectedRowPerPage:
			window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1,
	},
	reducers: {
		changeRowPerPage(state, { payload }) {
			return { ...state, selectedRowPerPage: payload };
		},
	},
});

export const { changeRowPerPage } = slice.actions;

export const selectedRowPerPageItem = (state) => state;

export default slice.reducer;
