import React from 'react';
import { Typography } from '@mui/material';
import { usePO } from '../../../utils/POContext';

interface Props {
	keyText: string;
}

const ShortCutIcon: React.FC<Props> = ({ keyText }: Props) => {
	const { selectedTheme } = usePO();

	return (
		<div
			style={{
				display: 'flex',
				width: 'auto',
				padding: 2,
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 4,
				background:
					selectedTheme.id === 'dark'
						? `linear-gradient(224deg, #2C2C2C -5.09%, #8C8C8C 49.26%, #393939 102.54%)`
						: `linear-gradient(224deg, #B3B3B3 -6.73%, #E6E6E6 59.35%, #737373 101.41%)`,
				boxShadow: `0px 2px 2.7px 0px rgba(77, 77, 77, 0.25)`,
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: 'auto',
					minWidth: 26,
					height: 26,
					borderRadius: 3,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '#EAEAEA',
				}}
			>
				<Typography
					style={{
						textAlign: 'center',
						fontWeight: 600,
						padding: '0 5px',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					{keyText}
				</Typography>
			</div>
		</div>
	);
};

export default ShortCutIcon;
