import React from 'react';
import { Zoom, IconButton } from '@mui/material';
import { LaunchOutlined } from '@mui/icons-material/';
import { OfferToolTip } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
interface Props {
	rowData: any;
}

const OpenExternalLink: React.FC<Props> = ({ rowData }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	return (
		<OfferToolTip
			aria-label="inativo"
			arrow
			enterDelay={700}
			enterNextDelay={700}
			title={`${t('Notificações.Tabs.Removidas.Ver anúncio na Plataforma')}`}
			TransitionComponent={Zoom}
		>
			<IconButton
				href={rowData?.link}
				target="_blank"
				style={{ marginBottom: -1 }}
			>
				<LaunchOutlined
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: '#4F4F4F',
						fontSize: 24,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);
};
export default OpenExternalLink;
