import React, { useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Select,
	TextField,
	MenuItem,
	ListSubheader,
	IconButton,
} from '@mui/material';
import { HelpOutlined, Error, CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../utils/api';
import Loading from '../../../components/LoadingDots';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';
import { TagObjectType } from '../../../interfaces';
import SelectClient from '../../../components/SelectClient';
import SubmitedResumeDialog from '../SubmitedResumeDialog';
import TagSelector from '../../../components/TagSelector';
import { OfferToolTip } from '../../../helpers';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateNotGoodCards,
	updateGoodCards,
} from '../../../redux/slices/cardFeedbackSlice';
import {
	submitClassifications,
	submitTags,
} from '../../../services/classifications/bulkActions';

interface Props {
	setSelectedAll: any;
}

const SubmitQueueDialog: React.FC<Props> = ({ setSelectedAll }) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const {
		selectedTheme,
		selectedClient,
		classificationTypes,
		tagsActiveClient,
		userData,
	} = usePO();
	const { selectedCards, handleSelectCard } = useCardSelector();
	const { enqueueSnackbar } = useSnackbar();

	setupRequestToken(api, token);

	/** Geather all selected card tags */
	const tagsSet = new Set();
	selectedCards?.map((card) => card.tags?.map((tag) => tagsSet.add(tag)));
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [tagsINcard, setTagsINcard] = useState(true);
	const [loading, setLoading] = useState(false);
	const [denounceObs, setDenounceObs] = useState('');
	const [denounceValue, setDenounceValue] = useState('NOT_SET');
	const [openDialog, setOpenDialog] = useState(false);
	const [submitDataResults, setSubmitDataResults] = useState<any>();
	const [tagsToSubmit, setTagsToSubmit] = useState<any>([]);
	const [lastTags, setLastTags] = useState<TagObjectType[]>([]);
	const [operation, setOperation] = useState<string>('');

	const notGoodCardsList = useSelector(
		(state: any) => state.cardFeedback.notGoodCardsList
	);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	const handleDenounceReasonChange = (event: any) => {
		setDenounceValue(event.target.value);
	};

	const handleClickOpen = (operation) => {
		setOperation(operation);
		setOpen(true);
		setTagsINcard(true);
		selectedCards.map((card) => {
			if (card.tags?.length === 0) {
				setTagsINcard(false);
			}
			return null;
		});
	};

	const handleClose = () => {
		setLoading(false);
		setSelectedAll([]);
		setOpen(false);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleClickDenounce = () => {
		// check if role is admin
		if (userData.roles.includes('Analista de posts')) {
			if (tagsINcard || tagsToSubmit.length > 0) {
				handleSubmit();
			} else {
				enqueueSnackbar(t('Algum card selecionado não tem etiqueta.'), {
					variant: 'warning',
				});
			}
		} else {
			handleSubmit();
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		/** Checks if there are tags and submit them */
		if (tagsToSubmit.length > 0) {
			const tagsPayload = {
				offerIDs: selectedCards.map((el) => el.offerID),
				tagIDs: tagsToSubmit.map((el: TagObjectType) => el.tagID),
			};
			const tagsResponse: any = await submitTags(
				tagsPayload,
				selectedClient.id
			);
			if (tagsResponse.success === true) {
				setLastTags(tagsToSubmit);
				enqueueSnackbar(t(`Classificações.${tagsResponse.data[0].detail}`), {
					variant: 'success',
				});
				if (operation === 'tag') {
					handleClose();
				}
			} else {
				setLoading(false);
				setTagsToSubmit(lastTags);
				enqueueSnackbar('Erro ao processar Etiquetas', { variant: 'error' });
			}
		}
		if (operation === 'classify') {
			const goodItems: any = [];
			const notGoodItems: any = [];
			const payload = {
				offerID: selectedCards.map((el) => el.offerID),
				classificationModel: {
					classificationTypeID: denounceValue,
					denounceStatusID: 'CLASSIFICADA',
					clientID: selectedClient.id,
					observation: denounceObs,
				},
			};
			/** Submits Cassifications */

			const response: any = await submitClassifications(payload);
			if (response.success === true) {
				response.data.map((item) => {
					if (item.status < 300) {
						goodItems.push(item.id);
					}
					if (item.status >= 400) {
						notGoodItems.push(item);
					}
					return null;
				});
				if (notGoodItems.length === 0) {
					enqueueSnackbar(
						t('Classificações.Classificações enviadas com sucesso'),
						{ variant: 'success' }
					);
					handleSelectCard(null, 'clear');
					setSelectedAll(false);
				} else {
					setSubmitDataResults({ good: goodItems, notGood: notGoodItems });
					setOpenDialog(true);
				}
				setLoading(false);
				if (denounceValue === 'PSTLG') {
					dispatch(updateGoodCards(goodCardsList.concat(goodItems)));
				} else {
					dispatch(updateNotGoodCards(notGoodCardsList.concat(goodItems)));
				}
				handleClose();
			} else {
				setLoading(false);
				setTagsToSubmit(lastTags);
				enqueueSnackbar(t(response?.message), { variant: 'error' });
			}
		}
		return null;
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const renderDenounceReasons = () => {
		const legalClassificationType = classificationTypes.find(
			(item) => item?.id === 'PSTLG'
		);
		if (selectedClient !== undefined && selectedClient !== null) {
			if (classificationTypes?.length) {
				return (
					<>
						<Typography
							align="left"
							variant="subtitle2"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								width: '100%',
								opacity: 0.8,
							}}
						>
							{t('Classificações.Escolha um motivo')}:
						</Typography>
						<Select
							variant="outlined"
							value={denounceValue}
							onChange={handleDenounceReasonChange}
							style={{
								border:
									selectedTheme.id === 'dark'
										? `1px solid ${selectedTheme.footerLine}`
										: `1px solid #dedede`,
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
							}}
							sx={{
								width: '100%',
								height: '40px',
								'.MuiSelect-select': {
									padding: '5px 10px',
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay3dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
								'.MuiSelect-icon': {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
								'& [aria-expanded=true]': {
									background:
										selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
								},
							}}
							inputProps={{
								MenuProps: {
									PaperProps: {
										sx: {
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.overlay3dp
													: selectedTheme.foreground,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										},
									},
								},
							}}
							MenuProps={{
								sx: {
									'.MuiMenu-list': {
										pt: 0,
										pb: 0,
									},
								},
							}}
						>
							<ListSubheader
								style={{
									backgroundColor:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay4dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorDisable
											: '',
									fontWeight: 'bold',
								}}
							>
								{t('Classificações.Permitir anúncio')}
							</ListSubheader>
							<MenuItem
								value={legalClassificationType.id}
								key={legalClassificationType.name}
								sx={{
									backgroundColor:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay4dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
									},
								}}
							>
								{t('ClassificationType.' + legalClassificationType.id)}
							</MenuItem>
							<ListSubheader
								style={{
									backgroundColor:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay4dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorDisable
											: '',
									fontWeight: 'bold',
								}}
							>
								{t('Classificações.Classificar anúncio')}
							</ListSubheader>
							{classificationTypes.map((item) => {
								if (item.ISactive && !item.ISgood) {
									return (
										<MenuItem
											value={item.id}
											key={item.name}
											sx={{
												backgroundColor:
													selectedTheme.id === 'dark'
														? selectedTheme.overlay4dp
														: selectedTheme.foreground,
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '',
												'&:hover': {
													backgroundColor:
														selectedTheme.id === 'dark' &&
														selectedTheme.overlay6dp,
												},
											}}
										>
											{t('ClassificationType.' + item.id)}
										</MenuItem>
									);
								}
							})}
						</Select>
						{denounceValue !== 'NOT_SET' ? (
							<Line style={{ minHeight: 80, maxWidth: 500 }}>
								<Typography
									variant="subtitle2"
									sx={{
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
										opacity: 0.8,
										margin: '10px 0 -10px 5px',
									}}
								>
									{classificationTypes.map((item) =>
										item.id === denounceValue
											? t('ClassificationTypeDescription.' + item.description)
											: null
									)}
								</Typography>
							</Line>
						) : (
							<Line style={{ height: 80 }} />
						)}
						<TextField
							id="observation"
							name="observation"
							label={t('Classificações.Observações')}
							value={denounceObs}
							onChange={handleObservationChange}
							multiline
							rows={2}
							variant="outlined"
							inputProps={{
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
							InputLabelProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								'& .MuiOutlinedInput-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								width: '100%',
								marginTop: '35px',
							}}
						/>
					</>
				);
			}
		}
		return (
			<Column style={{ rowGap: 10 }}>
				<Typography
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Escolha um cliente para classificar o anúncio')}:
				</Typography>
				<SelectClient />
			</Column>
		);
	};

	const renderTags = () => (
		<Column>
			<Line style={{ justifyContent: 'space-between' }}>
				<Typography
					style={{
						marginTop: operation === 'classify' ? 20 : 0,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Etiquetas')}:
				</Typography>
				<OfferToolTip
					title={t(
						'Classificações.Cada anúncio receberá individualmente estas etiquetas após o envio da lista'
					)}
				>
					<HelpOutlined
						style={{
							fontSize: 14,
							marginBottom: operation === 'classify' ? -20 : 0,
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					/>
				</OfferToolTip>
			</Line>
			<TagSelector
				dataArray={tagsToSubmit}
				setDataArray={setTagsToSubmit}
				suggestions={tagsActiveClient}
				limitTags={3}
				autoSave={false}
				width={'540px'}
			/>
		</Column>
	);

	const renderWarning = () => (
		<Line
			style={{
				width: '100%',
				background: selectedTheme.background,
				justifyContent: 'center',
				opacity: 0.7,
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '10px 15px',
			}}
		>
			<Error style={{ fontSize: 30, color: 'orange' }} />
			<Typography
				variant="caption"
				style={{
					width: '70%',
					marginLeft: 20,
					color: selectedTheme.tone === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{t(
					`Classificações.Durante o processamento de múltiplos anúncios o sistema ficará em espera. Por favor, aguarde.`
				)}
			</Typography>
		</Line>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				height: 55,
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					margin: '2px 10px 0 10px',
				}}
			>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Classificações.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={60} height={30} loop />
				) : (
					<Button
						size="small"
						onClick={handleClickDenounce}
						data-testid="apllyTags"
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
						}}
					>
						{t('Classificações.Aplicar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				display: 'flex',
				height: 60,
				borderBottom: 'solid',
				borderBottomWidth: 1,
				borderBottomColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '12px 20px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				{selectedCards?.length > 1 ? (
					<Typography
						variant="h6"
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					>
						{`${t('Classificações.Classificar os')} ${selectedCards.length} ${t(
							'Classificações.anúncios selecionados'
						)}?`}
					</Typography>
				) : (
					<Typography
						variant="h6"
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					>
						{t('Classificações.Classificar o anúncio selecionado')}?
					</Typography>
				)}
				<IconButton
					onClick={handleClose}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						sx={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderClient = () => (
		<Column>
			<Line>
				<Typography
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						marginTop: '20px',
					}}
				>
					{t('Classificações.Cliente')}:
				</Typography>
			</Line>
			<TextField
				size="small"
				variant="outlined"
				name="selectedClient"
				value={selectedClient?.name}
				style={{ width: '100%', fontWeight: 'bold' }}
				InputProps={{
					readOnly: true,
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					},
				}}
				sx={{
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
					background: selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
				}}
			/>
		</Column>
	);

	return (
		<div>
			<Line>
				<Button
					size="small"
					onClick={() => handleClickOpen('classify')}
					data-testid="classifyButton"
					style={{
						margin: '2px 12px -1px 8px',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.success
								: selectedTheme.warning,
						fontWeight: 'bold',
					}}
				>
					{t('Classificações.Classificar')}
				</Button>
				<Button
					size="small"
					onClick={() => handleClickOpen('tag')}
					data-testid="tagButton"
					style={{
						margin: '2px 12px -1px 8px',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.success
								: selectedTheme.warning,
						fontWeight: 'bold',
					}}
				>
					{t('Classificações.Etiquetar')}
				</Button>
			</Line>
			<Dialog
				open={open}
				maxWidth="sm"
				fullWidth
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
			>
				{renderHeader()}
				<DialogContent
					style={{
						padding: 0,
						background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					}}
				>
					<Column style={{ padding: '20px 30px' }}>
						{operation === 'classify' && renderDenounceReasons()}
						{renderTags()}
						{renderClient()}
					</Column>
					{renderWarning()}
				</DialogContent>
				{renderFooter()}
			</Dialog>
			<SubmitedResumeDialog
				open={openDialog}
				close={handleCloseDialog}
				data={submitDataResults}
			/>
		</div>
	);
};

export default SubmitQueueDialog;
