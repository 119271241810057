import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const getNotificationsSpreadsheetFile = async (payload) => {
	try {
		const response = await api.post(
			'/reports/clients/export/offerclassification',
			payload,
			{
				timeout: 5 * 60 * 1000, // 5 minutos
			}
		);
		const downloadUrl = response.data;
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.click();
		return { success: true, message: 'Arquivo baixado com sucesso' };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getIsProcessing = async (clientID) => {
	try {
		const response = await api.get(
			`/offerClassification/clients/${clientID}/is-processing`
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const editOfferClassification = async (payload) => {
	try {
		const response = await api.put('/offerClassification', payload);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
