import { api, isAxiosError } from '../../utils/api';
import { ErrorResponse, ReportsClassificationData } from '../../interfaces';

export const fetchReportsPanel = async (requestBody) => {
	const preferenceRegex =
		/{"preference":"ReactiveListResult"}[\s\S]*?}(?=\n|$)/g;
	const matches = requestBody.match(preferenceRegex);

	let formattedQuery = null;

	if (matches) {
		const reactiveListResultQuery = matches[0];
		formattedQuery = await reactiveListResultQuery
			.replace(/"size":(?:20|50|100),/g, '')
			.replace(/{"preference":"[^"]*"}\n?/g, '');
	}

	const timeout = 60 * 10000; // 10 minutes

	const reportsData: ReportsClassificationData = {
		totalOffersResponse: null,
		priceAverageResponse: null,
		totalItemsSoldResponse: null,
		totalValueItemsSoldResponse: null,
		totalProductsInStockResponse: null,
		totalValueProductsInStockResponse: null,
		offersByPriceResponse: null,
		top100SellersResponse: null,
	};

	const endpoints = [
		'/reports/clients/metrics/elastic/total-offers',
		'/reports/clients/metrics/elastic/items-sold-price-average',
		'/reports/clients/metrics/elastic/total-items-sold',
		'/reports/clients/metrics/elastic/total-value-items-sold',
		'/reports/clients/metrics/elastic/total-products-in-stock',
		'/reports/clients/metrics/elastic/total-value-products-in-stock',
		'/reports/clients/metrics/elastic/offers-by-price-classification-area',
		'/reports/clients/metrics/elastic/top100-sellers-classification-area',
	];

	const promises = endpoints.map((endpoint) =>
		api.post(endpoint, formattedQuery, { timeout: timeout })
	);

	const results = await Promise.allSettled(promises);

	const keys = [
		'totalOffersResponse',
		'priceAverageResponse',
		'totalItemsSoldResponse',
		'totalValueItemsSoldResponse',
		'totalProductsInStockResponse',
		'totalValueProductsInStockResponse',
		'offersByPriceResponse',
		'top100SellersResponse',
	] as (keyof ReportsClassificationData)[];

	results.forEach((result: any, index) => {
		const key = keys[index];

		reportsData[key] = {
			data: result.status !== 'rejected' ? result.value?.data : null,
			status:
				result.status !== 'rejected'
					? result.value.status
					: result.reason.response?.status || result.status,
			detail:
				result.status !== 'rejected'
					? result.value.statusText
					: result.reason.response?.data.detail || result.reason.message,
		};
	});
	return reportsData;
};

export const verifyQueryParameters = async (requestBody) => {
	const preferenceRegex =
		/{"preference":"ReactiveListResult"}[\s\S]*?}(?=\n|$)/g;
	const matches = requestBody.match(preferenceRegex);

	let formattedQuery = null;

	if (matches) {
		const reactiveListResultQuery = matches[0];
		formattedQuery = await reactiveListResultQuery
			.replace(/"size":(?:20|50|100),/g, '')
			.replace(/{"preference":"[^"]*"}\n?/g, '');
	}

	const timeout = 60 * 10000; // 10 minutes

	try {
		const response = await api.post(
			'/reports/clients/metrics/elastic/test-max-data-elastic-scroll',
			formattedQuery,
			{ timeout }
		);
		return {
			success: true,
			data: response.data,
			message: response.data.detail,
			status: response.data.status_code,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
