import React from 'react';
import { Snackbar, IconButton, AlertProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material/';
import { Line } from '../../styles';
import { usePO } from '../../utils/POContext';

function Alert(props: AlertProps) {
	return <Alert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

interface Props {
	open: boolean;
	setOpen: any;
	message: string;
	type?: any;
	position?: any;
	/**
	 * Optional: Duration in milisseconds of snackbar message. Ex.: 8000 (8s)
	 * */
	time?: number;
	closeIcon?: boolean;
}

const SnackbarMessage: React.FC<Props> = ({
	open,
	setOpen,
	message,
	position,
	type,
	/** Duration of the message in milisseconds */
	time,
	closeIcon,
}: Props) => {
	const classes = useStyles();
	const { selectedTheme } = usePO();

	// eslint-disable-next-line consistent-return
	const checkCloseIcon = () => {
		if (closeIcon) {
			return true;
		}
	};

	const handleClose = (
		event: React.SyntheticEvent | React.MouseEvent,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const renderAlert = () => {
		if (type === 'warning') {
			return (
				<Alert severity={type || 'info'} icon={<Line />}>
					<Line
						style={{
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						{message}
						{checkCloseIcon() ? (
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={handleClose}
								style={{ marginLeft: 15 }}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<CloseRounded fontSize="small" />
							</IconButton>
						) : null}
					</Line>
				</Alert>
			);
		} else {
			return (
				<Alert severity={type || 'info'}>
					{message}
					{checkCloseIcon() ? (
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={handleClose}
							style={{ marginLeft: 15 }}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded fontSize="small" />
						</IconButton>
					) : null}
				</Alert>
			);
		}
	};

	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={position || { vertical: 'top', horizontal: 'center' }}
				autoHideDuration={time || 5000}
				data-testid="SnackBarComponent"
				onClose={() => handleClose}
				open={open}
				style={{ opacity: 1 }}
			>
				{renderAlert()}
			</Snackbar>
		</div>
	);
};

export default SnackbarMessage;
