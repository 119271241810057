import React, { useState } from 'react';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Select,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import {
	AddCircleRounded,
	CloseRounded,
	Brightness1,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import LoadingDots from '../../../components/LoadingDots';
import { ChromePicker } from 'react-color';
import { GithubPlacement } from '@uiw/react-color-github';
import { api } from '../../../utils/api';
import { OfferToolTip } from '../../../helpers';
import { enqueueSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import { TagType } from '../../../interfaces';

interface Props {
	refresh: () => void;
}

const AddTag: React.FC<Props> = ({ refresh }: Props) => {
	const { selectedTheme, cursorMode, selectedClient, userData, tagTypes } =
		usePO();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = React.useState(false);
	const [hexBackgroundColor, setHexBackgroundColor] = useState('#4d4b4b');
	const [openBackgroundPicker, setOpenBackgroundPicker] = useState(false);
	const [hexTextColor, setHexTextColor] = useState('#fff');
	const [openTextPicker, setOpenTextPicker] = useState(false);
	const [tagName, setTagName] = useState<any>();
	const [description, setDescription] = useState<any>('');
	const [formErrorTagName, setFormErrorTagName] = useState(false);
	const [selectedTagType, setSelectedTagType] = useState<string>('CLIENTE');

	const useStyles = makeStyles(() => ({
		menuItem: {
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.background,
			},
		},
	}));

	const classes = useStyles();

	const handleClose = () => {
		setTagName(null);
		setDescription('');
		setHexBackgroundColor('#4d4b4b');
		setHexTextColor('#fff');
		setOpen(false);
	};

	const handleSendData = async () => {
		try {
			setLoading(true);
			const newTagPayload = {
				name: tagName,
				description: description,
				tagTypeID: selectedTagType,
				clientID: selectedClient.id,
				userID: userData.id,
				textColor: hexTextColor,
				badgeColor: hexBackgroundColor,
			};
			const tagResponse = await api.post(`/tags`, newTagPayload);
			setLoading(false);
			enqueueSnackbar(t(`Etiqueta.${tagResponse.data.detail}`), {
				variant: 'success',
			});
			handleClose();
			refresh();
		} catch (error: any) {
			setLoading(false);
			enqueueSnackbar(
				t(`Etiqueta.${error.response.data.detail}`) ||
					t('Erro interno. Tente novamente mais tarde'),
				{
					variant: 'error',
				}
			);
		}
	};

	async function handleSubmit() {
		if (!tagName) {
			setFormErrorTagName(true);
		}
		if (tagName && selectedClient?.id) {
			setFormErrorTagName(false);
			handleSendData();
		}
	}

	const handleCloseBackgroundPicker = () => {
		setOpenBackgroundPicker(false);
	};

	const handleCloseTextPicker = () => {
		setOpenTextPicker(false);
	};

	const textColorHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const renderCustomHeaderComponent = () => (
		<IconButton
			style={{ marginRight: '-15px', borderRadius: 5 }}
			onClick={() => setOpen(true)}
			sx={{
				'&:hover': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.primaryLight,
				},
			}}
		>
			<Typography
				variant="subtitle1"
				style={{
					fontWeight: 'bold',
					color: textColorHeader(),
					marginRight: 15,
					marginTop: 2,
				}}
			>
				{t('Etiqueta.Adicionar Etiqueta')}
			</Typography>
			<AddCircleRounded
				style={{
					color: textColorHeader(),
				}}
			/>
		</IconButton>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				color: '#FFFFFF',
				display: 'flex',
			}}
			id="max-width-dialog-title"
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					noWrap
					style={{
						color: textColorHeader(),
						fontSize: 20,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{t('Etiqueta.Nova Etiqueta')}
				</Typography>
				<IconButton
					onClick={() => handleClose()}
					style={{ marginRight: '-16px' }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color: textColorHeader(),
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderTagTypesSelector = () => {
		const excludedTagTypes = ['BUSCA', 'USUARIO', 'IDE'];
		const filteredTagTypes: TagType[] = [];
		tagTypes &&
			tagTypes.map((el) => {
				if (!excludedTagTypes.includes(el.id)) {
					filteredTagTypes.push(el);
				}
				return null;
			});

		return (
			<Select
				name="tagType"
				variant="outlined"
				aria-label="tagType"
				value={selectedTagType}
				disabled={!filteredTagTypes}
				onChange={(e) => {
					setSelectedTagType(e.target.value);
				}}
				margin="dense"
				classes={{
					root: classes.menuItem,
				}}
				MenuProps={{
					MenuListProps: {
						disablePadding: true,
					},
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
				}}
				style={{
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
				}}
				sx={{
					width: '40%',
					height: '40px',
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor:
							selectedTheme.id === 'dark' && selectedTheme.textColorDisable,
					},
					'&.Mui-disabled': {
						'.MuiOutlinedInput-notchedOutline': {
							borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
						},
						'.MuiSelect-icon': {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorDisable,
						},
					},
					'.MuiOutlinedInput-notchedOutline': {
						borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
					},
					'.MuiSelect-select': {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					},
					'.MuiSelect-icon': {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					'& [aria-expanded=true]': {
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					},
				}}
				inputProps={{
					MenuProps: {
						MenuListProps: {
							disablePadding: true,
						},
						PaperProps: {
							sx: {
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
						},
					},
				}}
			>
				{filteredTagTypes.length > 0 &&
					filteredTagTypes.map((el, index) => (
						<MenuItem key={index} value={el.id}>
							{t(`Etiqueta.${el.name}`)}
						</MenuItem>
					))}
			</Select>
		);
	};

	const renderBackgroundColorPicker = () => (
		<>
			<OfferToolTip
				title={`${t('Etiqueta.Cor de Fundo')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Chip
					variant="outlined"
					label={t('Etiqueta.Cor de Fundo')}
					clickable
					onClick={() => setOpenBackgroundPicker(!openBackgroundPicker)}
					style={{
						width: '28%',
						height: 40,
						fontSize: 15,
						backgroundColor:
							selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						border:
							selectedTheme.id === 'dark'
								? `1px solid #575757`
								: '1.5px solid rgb(189 189 189)',
						borderRadius: 6,
					}}
					onDelete={() => setOpenBackgroundPicker(!openBackgroundPicker)}
					deleteIcon={
						<Brightness1
							style={{
								fontSize: 22,
								color: hexBackgroundColor,
								borderRadius: 16,
								border:
									selectedTheme.tone === 'light'
										? '2px solid #67676754'
										: '2px solid #bdbdbd54',
							}}
						/>
					}
				/>
			</OfferToolTip>
			{openBackgroundPicker ? (
				<div
					style={{
						position: 'absolute',
						zIndex: 2,
						marginTop: -200,
						marginLeft: 190,
					}}
				>
					<div
						style={{
							position: 'fixed',
							top: '0px',
							right: '0px',
							bottom: '0px',
							left: '0px',
						}}
						onClick={() => handleCloseBackgroundPicker()}
					/>
					<ChromePicker
						color={hexBackgroundColor}
						placement={GithubPlacement.Bottom}
						onChange={(color) => {
							setHexBackgroundColor(color.hex);
						}}
					/>
				</div>
			) : null}
		</>
	);

	const renderTextColorPicker = () => (
		<>
			<OfferToolTip
				title={`${t('Etiqueta.Cor de Texto')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Chip
					variant="outlined"
					label={t('Etiqueta.Cor de Texto')}
					clickable
					onClick={() => setOpenTextPicker(!openTextPicker)}
					style={{
						width: '28%',
						height: 40,
						fontSize: 15,
						backgroundColor:
							selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						border:
							selectedTheme.id === 'dark'
								? `1px solid #575757`
								: '1.5px solid rgb(189 189 189)',
						borderRadius: 6,
					}}
					onDelete={() => setOpenTextPicker(!openTextPicker)}
					deleteIcon={
						<Brightness1
							style={{
								fontSize: 22,
								color: hexTextColor,
								borderRadius: 16,
								border:
									selectedTheme.tone === 'light'
										? '2px solid #67676754'
										: '2px solid #bdbdbd54',
							}}
						/>
					}
				/>
			</OfferToolTip>
			{openTextPicker ? (
				<div
					style={{
						position: 'absolute',
						zIndex: 2,
						marginTop: -200,
						marginLeft: 460,
					}}
				>
					<div
						style={{
							position: 'fixed',
							top: '0px',
							right: '0px',
							bottom: '0px',
							left: '0px',
						}}
						onClick={() => handleCloseTextPicker()}
					/>
					<ChromePicker
						color={hexTextColor}
						placement={GithubPlacement.Bottom}
						onChange={(color) => {
							setHexTextColor(color.hex);
						}}
					/>
				</div>
			) : null}
		</>
	);

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '30px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			<Column
				style={{
					height: '100%',
					alignItems: 'start',
					justifyContent: 'space-between',
					rowGap: 20,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				}}
			>
				<TextField
					name="tagName"
					value={tagName}
					error={formErrorTagName}
					variant="outlined"
					size="small"
					label={t('Etiqueta.Nome da Etiqueta')}
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						},
					}}
					onChange={(event) => {
						setTagName(event.target.value);
					}}
					sx={{
						width: '100%',
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						'.MuiSvgIcon-root ': {
							fill:
								selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
					}}
				/>
				<TextField
					name="description"
					value={description}
					variant="outlined"
					multiline
					rows="2"
					label={t('Etiqueta.Descrição')}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						},
					}}
					onChange={(event) => {
						setDescription(event.target.value);
					}}
					sx={{
						width: '100%',
						'& .MuiOutlinedInput-root': {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						'.MuiSvgIcon-root ': {
							fill:
								selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						},
					}}
				/>
				<Typography
					style={{
						marginBottom: -13,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{t('Etiqueta.Tipo de Etiqueta')}:
				</Typography>
				<Line style={{ justifyContent: 'space-between' }}>
					{renderTagTypesSelector()}
					{renderBackgroundColorPicker()}
					{renderTextColorPicker()}
				</Line>
			</Column>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Etiqueta.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						type="button"
						size="small"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
							background:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: selectedTheme.foreground
							}`,
						}}
					>
						{t('Etiqueta.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			{renderCustomHeaderComponent()}
			<Dialog
				open={open}
				aria-label="confirm-edit-dialog"
				fullWidth
				maxWidth="md"
				disableEscapeKeyDown
				scroll="body"
				style={{ cursor: cursorMode }}
			>
				{renderHeader()}
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default AddTag;
