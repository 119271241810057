import React, { useEffect, useState } from 'react';
import { Card, Chip, Link, Popover, Typography, Zoom } from '@mui/material';
import '../../Card/Front/image-galery-card.css';
import { WallpaperRounded } from '@mui/icons-material/';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import ImageNotFound from '../../../../assets/images/image_not_found.jpg';
import { Column, Line } from '../../../../styles';
import FormDivider from '../../../../components/FormDivider';
import { OfferToolTip } from '../../../../helpers';
import FullSizeImageDialog from '../../FullSizeImageModal';
import PlatformIcon from '../../../../components/PlatformIcon';
import { usePO } from '../../../../utils/POContext';
import {
	ErrorResponse,
	MessageType,
	TagObjectType,
} from '../../../../interfaces';
import { api, isAxiosError } from '../../../../utils/api';
import TagSelector from '../../../../components/TagSelector';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import LoadingDots from '../../../../components/LoadingDots';
import { enqueueSnackbar } from 'notistack';

interface Props {
	offerData: any;
	images: any;
	tagFieldError: boolean;
	handleLoadingTags: (value) => void;
	handleSetCardTags: (cardTags) => void;
}

const ClassificationModalLeft: React.FC<Props> = ({
	offerData,
	images,
	tagFieldError,
	handleLoadingTags,
	handleSetCardTags,
}) => {
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, tagsActiveClient } = usePO();

	const [imageIndex, setImageIndex] = React.useState(0);
	const [openImageModal, setOpenImageModal] = React.useState(false);
	const [cardTags, setCardTags] = useState<any[]>([]);
	const [lastTags, setLastTags] = useState<TagObjectType[]>([]);
	const [loadingTags, setLoadingTags] = useState(false);

	useEffect(() => {
		if (offerData?.tags) {
			setTimeout(() => {
				setCardTags(filteredClientTags);
			}, 100);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient?.id]);

	const filteredClientTags = offerData?.tags?.filter((tag: any) => {
		return tag.clientID === selectedClient.id;
	});

	const handleShowImage = () => {
		setOpenImageModal(true);
	};

	const renderImageCarousel = () => {
		let imagesArray = [];
		if (images) {
			if (images.map) {
				imagesArray = images.map((el: string) => {
					return {
						thumbnail: el,
						original: el,
					};
				});
			}
			return (
				<Column
					style={{
						cursor: 'pointer',
						height: 215,
						marginLeft: 20,
					}}
				>
					<ImageGallery
						key="modal"
						items={imagesArray}
						showThumbnails
						onErrorImageURL={ImageNotFound}
						showPlayButton={false}
						showFullscreenButton={false}
						thumbnailPosition="left"
						onSlide={(e) => setImageIndex(e)}
						onClick={handleShowImage}
					/>
				</Column>
			);
		}
		return (
			<Column style={{ justifyContent: 'center', height: 215 }}>
				<WallpaperRounded fontSize="large" color="disabled" />
				<Typography
					variant="subtitle2"
					style={{ marginTop: 10, color: '#A0A0A0', fontWeight: 'bold' }}
				>
					{t('Classificações.Imagem não disponível')}
				</Typography>
			</Column>
		);
	};

	const handleSnackbarMessage = (message: string, type: MessageType) => {
		enqueueSnackbar(message, { variant: type });
	};

	const handleSaveTags = async () => {
		const success: any = [];
		const error: any = [];

		const payload = {
			offerIDs: [offerData.offerID],
			tagIDs: cardTags.map((el: any) => el.tagID),
		};

		try {
			setLoadingTags(true);
			handleLoadingTags(true);
			const tagsResponse = await api.patch(
				`/tagINoffer/elasticsearch?clientID=${selectedClient.id}`,
				payload,
				{
					timeout: 300 * 1000,
				}
			);
			tagsResponse.data.map((el: any) => {
				if (el.status >= 400) {
					error.push(el.detail);
				} else {
					success.push(el.detail);
				}
				return null;
			});
			handleSetCardTags(cardTags);
			setLoadingTags(false);
			handleLoadingTags(false);
			setLastTags(cardTags);
			handleSnackbarMessage(success[0], 'success');
			if (error.length > 0) {
				setTimeout(() => {
					handleSnackbarMessage(error[0], 'error');
				}, 3000);
			}
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		} finally {
			setCardTags(lastTags);
			setLoadingTags(false);
			handleLoadingTags(false);
		}
	};

	const renderOfferInformation = () => (
		<Column
			style={{
				padding: '0 10px',
				height: cardTags.length > 0 ? 155 : 163,
				marginTop: -10,
			}}
		>
			<Typography
				variant="caption"
				style={{
					width: '105%',
					padding: 10,
					marginBlock: 5,
					overflowY: 'auto',
					overflowX: 'hidden',
					whiteSpace: 'pre-line',
					borderRadius: 2,
				}}
			>
				{offerData.description}
			</Typography>
		</Column>
	);

	const renderTagSelector = () => (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<Column style={{ alignItems: 'start', marginTop: 5, rowGap: 5 }}>
					<Typography variant="subtitle2">
						{`${t('Classificações.Etiquetas')}`}:
					</Typography>
					<Line
						{...bindTrigger(popupState)}
						style={{
							maxHeight: 40,
							marginTop: -3,
							width: '100%',
							flexWrap: 'wrap',
							alignItems: 'start',
							borderStyle: 'solid',
							borderWidth: 1,
							borderColor: tagFieldError
								? 'red'
								: selectedTheme.tone === 'dark'
									? '#707070'
									: '#CACACA',
							borderRadius: 4,
							overflow: 'hidden',
						}}
					>
						<div style={{ height: 30, width: 1, top: 0, left: 0 }}></div>
						{cardTags.length > 0 ? (
							<>
								<Chip
									key={cardTags[0]?.name}
									variant="outlined"
									label={cardTags[0]?.name}
									style={{
										height: '30px',
										margin: '4px',
										backgroundColor: cardTags[0]?.badgeColor,
										borderColor: selectedTheme.id === 'dark' ? '' : '#E8E8E8',
										color: cardTags[0]?.textColor,
										borderRadius: 6,
										fontSize: 15,
										maxWidth: '75%',
									}}
								/>
								{cardTags.length > 1 ? (
									<Typography style={{ padding: 8 }}>{`+ ${
										cardTags.length - 1
									} `}</Typography>
								) : null}
								<div
									style={{
										position: 'absolute',
										left: 348,
										bottom: 83,
										display: loadingTags ? 'block' : 'none',
									}}
								>
									<LoadingDots width={50} loop />
								</div>
							</>
						) : null}
					</Line>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Column
							style={{
								padding: '20px 30px',
								height: '100%',
								alignItems: 'start',
								justifyContent: 'space-between',
								rowGap: 10,
							}}
						>
							<Line>
								<Typography>
									{`${t('Classificações.Adicionar Etiquetas')}`}:
								</Typography>
								<div
									style={{
										position: 'absolute',
										right: 16,
										display: loadingTags ? 'block' : 'none',
									}}
								>
									<LoadingDots width={50} loop />
								</div>
							</Line>
							<TagSelector
								error={tagFieldError}
								setDataArray={setCardTags}
								dataArray={cardTags}
								handleSaveTags={() => handleSaveTags()}
								suggestions={tagsActiveClient}
								loading={loadingTags}
								autoSave
								rows={1}
								maxItems={6}
								width={'310px'}
							/>
						</Column>
					</Popover>
				</Column>
			)}
		</PopupState>
	);

	return (
		<Card
			style={{
				height: 500,
				width: 400,
				padding: 15,
			}}
		>
			<Line
				style={{
					justifyContent: 'flex-end',
					height: 1,
				}}
			>
				<OfferToolTip
					title={
						<Typography variant="subtitle2" align="center">{`${t(
							'Classificações.Abrir anúncio na plataforma de origem'
						)} (${offerData.platformINcountryID.replace(
							'|',
							' - '
						)})`}</Typography>
					}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<Link
						href={offerData.permalink}
						target="blank"
						style={{ margin: '13px -10px 0 0', zIndex: 200 }}
					>
						<PlatformIcon
							data={offerData.platformINcountryID}
							dataType="platform"
							size={32}
							cursor="pointer"
						/>
					</Link>
				</OfferToolTip>
			</Line>
			{renderImageCarousel()}
			<FormDivider
				width="100%"
				name={t('Classificações.Descrição')}
				background={selectedTheme.id === 'dark' && selectedTheme.primaryDark}
			/>
			{renderOfferInformation()}
			{renderTagSelector()}
			<FullSizeImageDialog
				open={openImageModal}
				setOpen={setOpenImageModal}
				imageIndexToShow={imageIndex}
				images={offerData.images}
			/>
		</Card>
	);
};

export default ClassificationModalLeft;
