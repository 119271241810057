import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Link, Typography, Zoom } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material/';

import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../../styles';
import FormDivider from '../../../../../components/FormDivider';
import { OfferToolTip } from '../../../../../helpers';
import FullSizeImageDialog from '../../../../../components/FullSizeImageModal';
import PlatformIcon from '../../../../../components/PlatformIcon';
import ImageNotFound from '../../../../../assets/images/image_not_found.jpg';
import { usePO } from '../../../../../utils/POContext';
import TagSelector from '../../../../../components/TagSelector';
import Gallery from 'react-image-gallery';
import classNames from 'classnames';
import styles from './image-gallery-offer.module.css';
import dateFormat from 'dateformat';

interface Props {
	rowData: any;
	item: any;
	images: any;
}

const NotificationModalLeft: React.FC<Props> = ({ item, rowData }) => {
	const { t } = useTranslation();
	const { selectedTheme, selectedClient } = usePO();

	const [imageIndex, setImageIndex] = React.useState(0);
	const [openImageModal, setOpenImageModal] = React.useState(false);
	const [cardTags, setCardTags] = useState<any[]>([]);
	const [loadingTags] = useState(false);

	const getTagsFromOffer = () => {
		if (rowData.offer?.tags) {
			setCardTags(filteredClientTags);
		} else setCardTags([]);
	};

	useEffect(() => {
		if (rowData.offer?.tags) {
			getTagsFromOffer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData]);

	const filteredClientTags = rowData.offer?.tags?.filter((tag: any) => {
		return tag.clientID === selectedClient.id;
	});

	const handleShowImage = () => {
		setOpenImageModal(true);
	};

	const renderTagSelector = () => (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				rowGap: 5,
				justifyContent: 'center',
			}}
		>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{`${t('Notificações.Tabs.Classificadas.Etiquetas')}`}:
			</Typography>

			<TagSelector
				dataArray={cardTags}
				setDataArray={setCardTags}
				loading={loadingTags}
				autoSave
				size="small"
				tagSize="small"
				rows={1}
				maxItems={6}
				limitTags={2}
				width={'100%'}
				disabled
			/>
		</Column>
	);

	const renderClassificationDetails = () => (
		<Column
			style={{
				height: '100%',
				rowGap: 5,
				justifyContent: 'center',
				alignItems: 'flex-start',
			}}
		>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{`${t('Notificações.Tabs.Classificadas.Detalhes da classificação')}`}:
			</Typography>

			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{ width: '50%', justifyContent: 'flex-start', columnGap: 10 }}
				>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Denunciante')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{rowData.denouncer?.denouncerName}
					</Typography>
				</Line>
				<Line
					style={{ width: '50%', justifyContent: 'flex-end', columnGap: 10 }}
				>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.E-mail')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{rowData.denouncer?.denouncerEmail}
					</Typography>
				</Line>
			</Line>

			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{ width: '50%', justifyContent: 'flex-start', columnGap: 10 }}
				>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Criado em')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{dateFormat(item?.created, 'dd/mm/yyyy')}
					</Typography>
				</Line>
				<Line
					style={{ width: '50%', justifyContent: 'flex-end', columnGap: 10 }}
				>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Status')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{rowData.offerClassification.denounceStatusID}
					</Typography>
				</Line>
			</Line>
		</Column>
	);

	const renderOfferDescription = () => (
		<Column
			style={{
				display: 'flex',
				flexDirection: 'column',
				paddingInline: 10,
				justifyContent: 'flex-start',
				height: item?.description ? '40%' : '0',
			}}
		>
			<FormDivider
				name={t('Notificações.Tabs.Classificadas.Descrição')}
				background={
					selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : 'white'
				}
				color={selectedTheme.id === 'dark' && selectedTheme.textColorMedium}
			/>
			<Typography
				variant="body2"
				style={{
					height: '25vh',
					overflowY: 'auto',
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{item?.description}
			</Typography>
		</Column>
	);

	const renderPlatformIcon = () => (
		<Line
			style={{
				justifyContent: 'flex-end',
				height: 1,
				position: 'relative',
				top: 15,
				right: -3,
			}}
		>
			<OfferToolTip
				title={
					<Typography variant="subtitle2" align="center">{`${t(
						'Notificações.Tabs.Classificadas.Abrir anúncio na plataforma de origem'
					)} (${item.platformINcountryID.replace('|', ' - ')})`}</Typography>
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Link href={item.permalink} target="blank" style={{ zIndex: 200 }}>
					<PlatformIcon
						data={item.platformINcountryID}
						dataType="platform"
						size={50}
						cursor="pointer"
					/>
				</Link>
			</OfferToolTip>
		</Line>
	);

	const renderLeftNav = (onClick: any) => {
		return (
			<ChevronLeft
				style={{
					position: 'absolute',
					left: '-8vw',
					top: 'calc(50% - 30px)',
					zIndex: 200,
					cursor: 'pointer',
					fontSize: 50,
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.background,
					filter:
						selectedTheme.tone === 'dark'
							? 'drop-shadow(0 1px 1px rgba(255,255,255,0.5))'
							: 'drop-shadow(0 1px 1px rgba(0,0,0,0.5))',
				}}
				onClick={onClick}
			/>
		);
	};

	const renderRightNav = (onClick: any) => {
		return (
			<ChevronRight
				style={{
					position: 'absolute',
					right: '-8vw',
					top: 'calc(50% - 30px)',
					zIndex: 200,
					cursor: 'pointer',
					fontSize: 50,
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.background,
					filter:
						selectedTheme.tone === 'dark'
							? 'drop-shadow(0 1px 1px rgba(255,255,255,0.5))'
							: 'drop-shadow(0 1px 1px rgba(0,0,0,0.5))',
				}}
				onClick={onClick}
			/>
		);
	};

	const renderImageCarousel = (item, rowData) => {
		const itemImagesToUse: string[] = [];

		if (
			item.images &&
			Array.isArray(item.images) &&
			item.images.length > 0 &&
			item.images.some((img) => img !== '')
		) {
			itemImagesToUse.push(...item.images.filter((img) => img !== ''));
		}

		if (item.thumb && item.thumb !== '') {
			itemImagesToUse.push(item.thumb);
		}

		if (rowData.offer.thumb && rowData.offer.thumb !== '') {
			itemImagesToUse.push(rowData.offer.thumb);
		}

		const imageContainerHeight = item?.description ? '60%' : '100%';
		const imageHeight = item?.description ? '36vh' : '80vh';

		return (
			<Column
				style={{
					justifyContent: 'center',
					height: imageContainerHeight,
				}}
			>
				<div className={classNames(styles.modalGallery)}>
					<Gallery
						renderLeftNav={renderLeftNav}
						renderRightNav={renderRightNav}
						renderItem={(item) => {
							return (
								<div className="modal-gallery">
									<img
										style={{
											height: imageHeight,
											width: '80%',
											objectFit: 'contain',
											cursor: !item ? 'default' : 'pointer',
										}}
										src={item}
										alt=""
										onError={(e) => {
											e.currentTarget.src = ImageNotFound;
										}}
									/>
								</div>
							);
						}}
						key="modal"
						items={itemImagesToUse}
						showThumbnails={false}
						showPlayButton={false}
						showFullscreenButton={false}
						onSlide={(e) => setImageIndex(e)}
						onClick={handleShowImage}
					/>
					{item && (
						<FullSizeImageDialog
							open={openImageModal}
							setOpen={setOpenImageModal}
							imageIndexToShow={imageIndex}
							images={itemImagesToUse}
						/>
					)}
				</div>
			</Column>
		);
	};

	return (
		<>
			<Grid item xs={5} style={{ rowGap: '15px', display: 'grid' }}>
				{/* cardEsqSup */}
				<Card
					style={{
						height:
							(item.description && cardTags.length > 0) ||
							rowData.denouncer.denouncerName
								? 'calc(100vh - 100px - 100px - 15px - 60px)' /* 100vh - header - cardEsqInf - padding - footer */
								: 'auto',
						padding: '20px 15px',
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
					}}
				>
					{renderPlatformIcon()}
					{renderImageCarousel(item, rowData)}
					{item.description &&
						item.description !== '' &&
						renderOfferDescription()}
				</Card>
				{/* cardEsqInf */}
				<Card
					style={{
						height: '100px',
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
					}}
				>
					<CardContent>
						{cardTags.length > 0 ? renderTagSelector() : null}
						{(!item.description || item.description) &&
							cardTags.length === 0 &&
							renderClassificationDetails()}
					</CardContent>
				</Card>
			</Grid>
		</>
	);
};

export default NotificationModalLeft;
