import React, { useEffect, useState } from 'react';
import OfferTable from '../../../components/OfferTable';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import {
	Typography,
	IconButton,
	Dialog,
	DialogTitle,
	Button,
	DialogActions,
	DialogContent,
	TextField,
	Zoom,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
} from '@mui/material';
import { Column, Line } from '../../../styles';
import FormDivider from '../../../components/FormDivider';
import { CloseRounded, Refresh } from '@mui/icons-material';
import { EntityRowData } from '..';

import ImagePreview from './ImagePreview';
import {
	OfferToolTip,
	TextMaskDynamicRegNumber,
	convertDate,
	handlePlatformINCountry,
} from '../../../helpers';
import PlatformIcon from '../../../components/PlatformIcon';
import FullSizeImageDialog from '../../../components/FullSizeImageModal';

import OpenOfferInPlatform from './OpenOfferInPlatform';
import { enqueueSnackbar } from 'notistack';
import { getOfferByAuthor } from '../../../services/authors/offersByAuthorID';
import { AuthorData } from '../EntityFormModal';
import {
	createNewAppraisals,
	updateAppraisal,
} from '../../../services/entities';
import { getClientData } from '../../../services/entities';

interface ColumnData {
	columnTitle: string;
	fieldName: string;
	translateColumnData: boolean;
	alignTitle: string;
	marginTitle?: string;
	cellWidth: number;
	dataType?: string;
}

interface ClientDataRegistration {
	id: string;
	clientID: string;
	countryID: string;
	registrationTypeID: string;
	BIID: string;
	clientRegNumber: string;
	zipCode: string;
	streetAddress: string;
	numberAddress: number;
	cityName: string;
	stateName: string;
	complement: string;
	contactName: string;
	phone: string;
	email: string;
	ISactive: boolean;
	created: string;
}

interface Props {
	rowData: EntityRowData;
	open: boolean;
	setOpen: (value: boolean) => void;
	tableData: AuthorData[] | null;
	colsData: ColumnData[];
	tableActions: any;
	singleAppraisalData: any;
	handleParentClose: () => void;
}

const AppraisalModal: React.FC<Props> = ({
	open,
	setOpen,
	rowData,
	tableData,
	colsData,
	tableActions,
	singleAppraisalData,
	handleParentClose,
}: Props) => {
	const { selectedClient, selectedTheme, userData } = usePO();
	const { t } = useTranslation();
	const imageIndex = 0;

	const [loading, setLoading] = useState(false);
	const [tableDataReport, setTableDataReport] = useState<any[]>([]);
	const [clientData, setClientData] = useState<ClientDataRegistration[]>([]);
	const [isClientDataLoaded, setIsClientDataLoaded] = useState(false);
	const [openImageModal, setOpenImageModal] = useState<boolean>(false);
	const [rowImage, setRowImage] = useState<string>('');
	const [authorOffers, setAuthorOffers] = useState<any[]>([]);
	const [selectedTableItems, setSelectedTableItems] = useState<any[]>([]);
	const [selectedCNPJ, setSelectedCNPJ] = useState<string>('');

	const fetchClientData = async (clientID) => {
		const responseClient: any = await getClientData(clientID);
		if (responseClient && responseClient.status < 300) {
			setClientData(responseClient.data);
			setIsClientDataLoaded(true);
			if (responseClient.data && responseClient.data.length > 0) {
				setSelectedCNPJ(responseClient.data[0].clientRegNumber);
			}
		} else {
			if (responseClient && responseClient.status >= 400) {
				const message = responseClient.message;
				enqueueSnackbar(t(`${message}`), { variant: 'error' });
			}
		}
	};

	useEffect(() => {
		if (selectedClient.id) {
			fetchClientData(selectedClient.id);
		}
	}, [selectedClient.id]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const authorIDs = tableData?.map((item) => item.id);
				const query = `{"preference":"ReactiveListResult"}\n{"query":{"bool":{"must":[{"terms":{"author.authorID": ${JSON.stringify(authorIDs)}}},{"match":{"client.clientID": "${selectedClient.id}"}}]}},"size": 10000}\n`;
				const response: any = await getOfferByAuthor(query);
				if (response && response.status < 300) {
					const offers = response.data;
					setAuthorOffers(offers);
					const data = buildTableData(offers);
					setTableDataReport(data);
				} else {
					if (response && response.status >= 400) {
						const message = response.message;
						enqueueSnackbar(t(`${message}`), { variant: 'error' });
					}
				}
			} catch (error) {
				console.log('Dados não disponíveis para construir a tabela');
			} finally {
				setLoading(false);
			}
		};
		if (open) fetchData();
		// eslint-disable-next-line
	}, [open, selectedClient.id, tableData]);

	useEffect(() => {
		if (!tableDataReport || !singleAppraisalData) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const appraisalOfferClassificationIDs =
			singleAppraisalData?.offerClassificationIDs || [];
		const selectedItems = tableDataReport.filter((item) =>
			appraisalOfferClassificationIDs.includes(
				item._source?.offerClassification?.offerClassificationID.toString()
			)
		);
		if (selectedItems.length > 0) {
			setSelectedTableItems(selectedItems);
		}
		setLoading(false);
	}, [tableDataReport, singleAppraisalData]);

	const refreshTableData = async () => {
		setLoading(true);
		try {
			const data = buildTableData(authorOffers);
			setTableDataReport(data);
		} catch (error) {
			console.log('Dados não disponíveis para construir a tabela');
		}
		setLoading(false);
	};

	const handleOpenFullSizeImage = (urlRef: string) => {
		setRowImage(urlRef);
		setOpenImageModal(true);
	};

	const renderPlatformLogo = (offers: string) => (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() => handleOpenFullSizeImage(offers)}
		>
			<PlatformIcon
				data={offers}
				dataType="image"
				size={40}
				borderRadius={4}
				cursor="pointer"
			/>
		</div>
	);

	const buildTableData = (offers) => {
		const tempData: any[] = [];
		const auxDataResponse = offers.responses[0].hits;
		auxDataResponse.hits.forEach((el) => {
			const newPlatform = {
				...el,
				date: convertDate(el._source.created, userData.i18nID, 'default'),
				permalink: el._source.offer.permalink,
				platform: handlePlatformINCountry(
					el._source.offer.platformINcountryID,
					userData.i18nID
				),
				client: el._source.client.clientID,
				denounceStatus: el._source.denounceStatus.denounceStatusID,
				denounceReason: t(
					'ClassificationType.' +
						el._source.classificationType.classificationTypeID
				),
				thumb: renderPlatformLogo(el._source.offer.thumb),
				title: el._source.offer.title,
			};
			tempData.push(newPlatform);
		});
		return tempData;
	};

	const handleClose = () => {
		setOpen(false);
	};

	const colsDataReport = [
		{
			columnTitle: '',
			fieldName: 'thumb',
			translateColumnData: false,
			cellWidth: 90,
			align: 'center',
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Autores.Anúncio'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 150,
		},
		{
			columnTitle: t('Autores.Plataforma'),
			fieldName: 'platform',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 150,
		},
		{
			columnTitle: t('Status'),
			fieldName: 'denounceStatus',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 150,
		},
		{
			columnTitle: t('FilterBar.Fonte da Denúncia'),
			fieldName: 'denounceReason',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 50,
		},
		{
			columnTitle: t('Autores.Data'),
			fieldName: 'date',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			marginTitle: '0 0 0 -5px',
		},
	];

	const tableActionsAprraisal = [
		{
			component: OpenOfferInPlatform,
			props: { tableData },
			action: () => {},
		},
		{
			component: ImagePreview,
			props: { tableData },
			action: () => {},
		},
	];

	const handleCommitInfo = async () => {
		setLoading(true);
		try {
			if (singleAppraisalData) {
				const appraisalPayload = {
					offerClassificationIDs: selectedTableItems.map(
						(el) => el._source.offerClassification.offerClassificationID
					),
				};

				const response = await updateAppraisal(
					singleAppraisalData.id,
					appraisalPayload.offerClassificationIDs
				);
				if (response && response.success) {
					enqueueSnackbar(t('Entidades.Laudo atualizado com sucesso.'), {
						variant: 'success',
					});
					refreshTableData();
					handleParentClose();
				} else {
					enqueueSnackbar(t(`Entidades.Laudo não encontrado`), {
						variant: 'warning',
					});
				}
			} else {
				const appraisalPayload = {
					clientID: selectedClient.id,
					entityID: rowData.id,
					offerClassificationIDs: selectedTableItems.map(
						(el) => el._source.offerClassification.offerClassificationID
					),
				};

				const response = await createNewAppraisals(appraisalPayload);
				if (response && response.success) {
					enqueueSnackbar(t('Entidades.Laudo adicionado com sucesso.'), {
						variant: 'success',
					});
					refreshTableData();
					handleParentClose();
				} else {
					enqueueSnackbar(
						t(`Entidades.Já existe um laudo cadastrado para essa entidade.`),
						{
							variant: 'warning',
						}
					);
				}
			}
		} catch (error) {
			enqueueSnackbar(t('Ocorreu um erro ao processar a operação.'), {
				variant: 'error',
			});
		}

		setLoading(false);
		handleClose();
	};

	const renderLeftFooterComponents = () => (
		<Line>
			<OfferToolTip
				title={`${t('Buscas.Tabs.Plataformas.Atualizar dados da tabela')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={refreshTableData}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Refresh
						style={{
							fontSize: 23,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</Line>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				color: '#FFFFFF',
				display: 'flex',
			}}
			id="max-width-dialog-title"
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					noWrap
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						fontSize: 20,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{singleAppraisalData
						? t('Entidades.Editar Laudo')
						: t('Entidades.Adicionar Laudo')}
				</Typography>
				<IconButton
					onClick={handleClose}
					style={{ marginRight: '-16px' }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderDialogContent = () => (
		<DialogContent
			key={clientData[0]?.id}
			style={{
				width: 1050,
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				padding: '20px 30px',
			}}
		>
			<FormDivider
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground
				}
				name={t('Entidades.Cliente')}
				margin="10px 0px 15px 0px"
			/>
			<Column
				style={{
					rowGap: 15,
					marginBottom: 10,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				}}
			>
				<Line style={{ justifyContent: 'space-between', columnGap: 15 }}>
					{isClientDataLoaded && clientData.length > 1 ? (
						<FormControl
							variant="outlined"
							size="small"
							fullWidth
							sx={{
								width: '100%',
								'.MuiOutlinedInput-root': {
									fontSize: 15,
									border:
										selectedTheme.id === 'dark'
											? `1px solid ${selectedTheme.footerLine}`
											: `1px solid #dedede`,
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay3dp
											: selectedTheme.foreground,
								},
								'.MuiOutlinedInput-notchedOutline': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
								'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
									{
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								'.MuiSelect-select': {
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay3dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
								'.MuiSelect-icon': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
								'& [aria-expanded=true]': {
									background:
										selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
								},
							}}
						>
							<InputLabel
								sx={{
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{t('Entidades.Nome')}
							</InputLabel>
							<Select
								name="nameClient"
								data-testid="nameClient"
								value={selectedCNPJ}
								onChange={(event) => {
									const selectedRegNumber = event.target.value;
									setSelectedCNPJ(selectedRegNumber);
								}}
								inputProps={{
									MenuProps: {
										PaperProps: {
											sx: {
												background:
													selectedTheme.id === 'dark'
														? selectedTheme.overlay3dp
														: selectedTheme.foreground,
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '',
											},
										},
									},
								}}
							>
								{clientData.map((client) => (
									<MenuItem
										key={client.clientRegNumber}
										value={client.clientRegNumber}
									>
										{`${client.clientID}|${client.cityName}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					) : (
						<TextField
							name="nameClient"
							label={t('Entidades.Nome')}
							data-testid="name"
							variant="outlined"
							size="small"
							fullWidth
							value={selectedClient.name}
							sx={{
								width: '100%',
								'& .MuiFormLabel-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
							inputProps={{
								readOnly: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
						/>
					)}
					<TextField
						name="registrationClient"
						label={t('Entidades.Número de registro')}
						data-testid="registrationClient"
						variant="outlined"
						size="small"
						value={selectedCNPJ}
						fullWidth
						sx={{
							width: '40%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						InputProps={{
							inputComponent: TextMaskDynamicRegNumber as any,
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>

					{rowData?.reportID ? (
						<TextField
							name="report"
							label={t('Entidades.Laudo')}
							data-testid="report"
							variant="outlined"
							size="small"
							fullWidth
							sx={{
								width: '30%',
								'& .MuiFormLabel-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
							inputProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
						/>
					) : null}
				</Line>
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name={t('Entidades.Entidade')}
					margin="5px"
				/>
				<Line style={{ justifyContent: 'space-between', columnGap: 15 }}>
					<TextField
						name="nameEntity"
						label={t('Entidades.Nome')}
						data-testid="nameEntity"
						variant="outlined"
						size="small"
						value={rowData?.name}
						fullWidth
						sx={{
							width: '100%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
					<TextField
						name="registrationEntity"
						label={t('Entidades.Número de registro')}
						data-testid="registrationEntity"
						variant="outlined"
						size="small"
						value={rowData?.registration}
						fullWidth
						sx={{
							width: '40%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
				</Line>
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name={t('Entidades.Plataformas')}
					margin="5px"
				/>
				{tableData && tableData.length > 0 ? (
					<OfferTable
						denseText
						tableData={tableData}
						colsData={colsData}
						loading={loading}
						tableActions={tableActions}
					/>
				) : (
					<Line
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							height: 40,
							width: '100%',
							border: `1px solid ${selectedTheme.borderLight}`,
							borderRadius: 4,
						}}
					>
						<Typography
							variant="subtitle2"
							style={{
								opacity: 0.6,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Entidades.Nenhuma plataforma vinculada')}
						</Typography>
					</Line>
				)}
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name={t('Entidades.Ofertas')}
					margin="5px"
				/>
				{tableDataReport && tableDataReport.length > 0 ? (
					<OfferTable
						denseText
						tableData={tableDataReport}
						colsData={colsDataReport}
						loading={loading}
						tableFooter={tableDataReport.length > 0}
						tableActions={tableActionsAprraisal}
						customRowsPerPage={5}
						customRowsPerPageOptions={[]}
						selectedTableItems={selectedTableItems}
						setSelectedTableItems={setSelectedTableItems}
						checkboxes
						ternaryCheckbox
						selectAll
						customLeftFooterComponent={renderLeftFooterComponents()}
					/>
				) : (
					<Line
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							height: 40,
							width: '100%',
							border: `1px solid ${selectedTheme.borderLight}`,
							borderRadius: 4,
						}}
					>
						<Typography
							variant="subtitle2"
							style={{
								opacity: 0.6,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Entidades.Nenhuma oferta vinculada')}
						</Typography>
					</Line>
				)}
			</Column>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Entidades.Cancelar')}
				</Button>

				<Button
					type="submit"
					data-testid="submitButton"
					onClick={handleCommitInfo}
					disabled={loading}
					style={{
						width: 90,
						height: 30,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: selectedTheme.foreground
						}`,
					}}
				>
					{t('Entidades.Salvar')}
				</Button>
			</Line>
		</DialogActions>
	);

	return (
		<>
			<FullSizeImageDialog
				open={openImageModal}
				setOpen={setOpenImageModal}
				imageIndexToShow={imageIndex}
				images={rowImage}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-label="confirm-edit-dialog"
				scroll="body"
				maxWidth="xl"
			>
				{renderHeader()}
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default AppraisalModal;
