import React, { useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	DialogTitle,
	Box,
	Divider,
	Zoom,
	TextField,
} from '@mui/material';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { CloseRounded, BorderColorRounded } from '@mui/icons-material/';
import convertCountryName from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/LoadingDots';
import { Line } from '../../../../styles';
import Panel from '../../../../components/Panel';
import { RegularPanel } from '../../../../components/Panel/styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import { apiResponse, ClientType } from '../../../../interfaces';
import { updateClientInfo } from '../../../../services/client';
import { useSnackbar } from 'notistack';

convertCountryName.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);

interface Props {
	rowData: ClientType;
	refresh: () => void;
}

const ClientForm: React.FC<Props> = ({ rowData, refresh }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = React.useState(false);

	const [formErrors, setFormErrors] = useState<any>({});
	const [formData, setFormData] = useState<ClientType>({
		name: rowData?.name || '',
		clientID: rowData?.clientID || '',
		description: rowData?.description || '',
		ISactive: rowData?.ISactive || false,
		created: rowData?.created || '',
		id: rowData?.id || '',
		logo: rowData?.logo || '',
		email: rowData?.email || '',
		referenceCode: rowData?.referenceCode || '',
		BIID: rowData?.BIID || '',
	});

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleCommitInfo = async () => {
		const clientPayload = {
			name: formData.name,
			description: formData.description,
			BIID: formData.BIID,
		};
		setLoading(true);
		const response: apiResponse | undefined = await updateClientInfo(
			rowData.id,
			clientPayload
		);
		if (response && response.status) {
			enqueueSnackbar(t('Clientes.Cliente atualizado com sucesso'), {
				variant: 'success',
			});
			refresh();
		} else {
			enqueueSnackbar(t('Clientes.ERRO'), {
				variant: 'warning',
			});
		}
		setLoading(false);
		handleClose();
	};

	async function handleSubmit(formData) {
		try {
			const schema = Yup.object().shape({
				name: Yup.string()
					.min(3, t('Clientes.O nome precisa ter no mínimo 3 caracteres'))
					.required(t('Clientes.O nome é obrigatório')),
			});
			await schema.validate(formData, {
				abortEarly: false,
			});
			// eslint-disable-next-line no-unused-expressions
			setFormErrors({});
			handleCommitInfo();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const handleFormStyle = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handleFormTitle = () => (
		<Typography
			noWrap
			style={{
				color: handleFormStyle(),
				fontSize: 20,
				fontWeight: 'bold',
				maxWidth: '90%',
			}}
		>
			{`${t('Clientes.Editar Cliente')}: ${rowData.name}`}
		</Typography>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: 5,
				}}
			>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Clientes.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={90} height={35} loop />
				) : (
					<Button
						onClick={handleCommitInfo}
						variant="contained"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Clientes.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<IconButton
				onClick={handleOpen}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<OfferToolTip
					title={`${t('Clientes.Editar Cliente')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<BorderColorRounded
						style={{
							fontSize: 20,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</OfferToolTip>
			</IconButton>
			<Dialog
				open={open}
				scroll="body"
				disableEscapeKeyDown
				maxWidth="md"
				fullWidth
			>
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color: '#FFFFFF',
						display: 'flex',
					}}
					id="draggable-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					>
						{handleFormTitle()}

						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							disabled={loading}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent
					style={{
						padding: 0,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : '',
					}}
				>
					<Divider />
					<Divider />
					<RegularPanel>
						<Panel open title={t('Clientes.Informações Básicas')}>
							<Form
								onPointerEnterCapture={undefined}
								onPointerLeaveCapture={undefined}
								placeholder=""
								onSubmit={handleSubmit}
								style={{
									width: '100%',
									padding: '5px 0px',
								}}
							>
								<TextField
									variant="outlined"
									size="small"
									name="name"
									value={formData?.name}
									error={!!formErrors?.name}
									helperText={formErrors?.name}
									onChange={(e) => {
										setFormData({ ...formData, name: e.target.value });
									}}
									label={t('Clientes.Nome')}
									autoComplete="off"
									InputLabelProps={{
										style: {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorMedium,
										},
									}}
									InputProps={{
										style: {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorMedium,
										},
									}}
									sx={{
										width: '100%',
										'& .MuiInputBase-root': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
								/>
								<TextField
									variant="outlined"
									size="small"
									name="BIID"
									value={formData?.BIID}
									error={formErrors?.description}
									helperText={formErrors?.description}
									onChange={(e) => {
										setFormData({ ...formData, BIID: e.target.value });
									}}
									label={t('Clientes.Relatório Superset')}
									autoComplete="off"
									InputLabelProps={{
										style: {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorMedium,
										},
									}}
									InputProps={{
										style: {
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorMedium
													: '',
										},
									}}
									sx={{
										width: '100%',
										marginTop: '25px',
										'& .MuiInputBase-root': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
								/>
								<TextField
									variant="outlined"
									multiline
									rows="2"
									name="description"
									value={formData?.description}
									error={formErrors?.description}
									helperText={formErrors?.description}
									onChange={(e) => {
										setFormData({ ...formData, description: e.target.value });
									}}
									label={t('Clientes.Descrição')}
									autoComplete="off"
									InputLabelProps={{
										style: {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorMedium,
										},
									}}
									InputProps={{
										style: {
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorMedium
													: '',
										},
									}}
									sx={{
										width: '100%',
										marginTop: '25px',
										'& .MuiInputBase-root': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
								/>
							</Form>
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel title={t('Clientes.Planos')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel title={t('Clientes.Direitos Protegidos')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel title={t('Clientes.Documentos e Imagens')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel noMargin title={t('Clientes.Dashboard e Estatísticas')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default ClientForm;
