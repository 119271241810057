import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const runSearchNow = async (searchId: string, clientID: string) => {
	try {
		const response = await api.post(
			`/searches/${searchId}/clients/${clientID}/auto`
		);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
