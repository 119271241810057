import React from 'react';
import { IconButton, Typography, Zoom } from '@mui/material';
import { ResponsiveCalendar } from '@nivo/calendar';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import {
	ArrowForwardIosOutlined,
	ArrowBackIosOutlined,
} from '@mui/icons-material/';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../helpers';
import BackdropLoading from '../../../components/BackdropLoading';

interface Props {
	calendarChartData: any;
	filteredYear: number;
	leftBarChartData: any;
	leftCardData?: any;
	loading: boolean;
	rightBarChartData: any;
	setFilteredYear: any;
	darkThemeChart: any;
	themeColors: any;
}

const AnalystDashboard: React.FC<Props> = ({
	calendarChartData,
	filteredYear,
	leftBarChartData,
	loading,
	rightBarChartData,
	setFilteredYear,
	darkThemeChart,
	themeColors,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const today = new Date();
	const platformOfferFirstDate = '2022-03-01T00:00:00';

	const LegendFormated = (value) => {
		switch (value) {
			case 0:
				return `${t('Inicio.Algumas Classificações')}`;
			default:
				return `${value}+ ${t('Inicio.Classificações')}`;
		}
	};

	const renderCalendarChart = () => (
		<>
			<Line
				style={{
					height: '30%',
				}}
			>
				<OfferToolTip
					placement="right"
					title={`${t('Inicio.Ano Anterior')}`}
					arrow
					enterDelay={200}
					enterNextDelay={200}
					TransitionComponent={Zoom}
				>
					<div>
						<IconButton
							size="small"
							key={filteredYear}
							aria-label="close"
							color="inherit"
							disabled={
								filteredYear === Number(platformOfferFirstDate.slice(0, 4))
									? true
									: false
							}
							onClick={() => setFilteredYear(filteredYear - 1)}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<ArrowBackIosOutlined
								sx={{
									fontSize: '50px',
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							/>
						</IconButton>
					</div>
				</OfferToolTip>
				<ResponsiveCalendar
					data={calendarChartData}
					key={filteredYear}
					from={`${filteredYear}-01-02`}
					to={`${filteredYear}-12-31`}
					emptyColor={
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#eeeeee'
					}
					colors={themeColors()}
					margin={{ top: 40, right: 20, bottom: 30, left: 40 }}
					monthBorderColor={selectedTheme.id === 'dark' ? '#444444' : '#ffffff'}
					monthLegendPosition="after"
					dayBorderWidth={2}
					dayBorderColor={selectedTheme.id === 'dark' ? '#444444' : '#ffffff'}
					legendFormat={(value) => LegendFormated(value)}
					legends={[
						{
							anchor: 'top',
							direction: 'row',
							translateY: -40,
							itemCount: 4,
							itemWidth: 150,
							itemHeight: 36,
							itemsSpacing: 10,
							itemDirection: 'left-to-right',
						},
					]}
					theme={darkThemeChart}
				/>
				<OfferToolTip
					placement="right"
					title={`${t('Inicio.Ano Posterior')}`}
					arrow
					enterDelay={200}
					enterNextDelay={200}
					TransitionComponent={Zoom}
				>
					<div>
						<IconButton
							size="small"
							key={filteredYear}
							aria-label="close"
							color="inherit"
							disabled={filteredYear === today.getFullYear() ? true : false}
							onClick={() => setFilteredYear(filteredYear + 1)}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<ArrowForwardIosOutlined
								sx={{
									fontSize: '50px',
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							/>
						</IconButton>
					</div>
				</OfferToolTip>
			</Line>
			<Typography
				variant="subtitle2"
				style={{
					textAlign: 'center',
					marginTop: -25,
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Inicio.Distribuição das classificações do usuário por dia')}`}
			</Typography>
		</>
	);

	const RenderLeftBarChart = (data) => (
		<Column style={{ height: '100%', width: '90%' }}>
			<ResponsiveBar
				data={data}
				margin={{ top: 50, right: 150, bottom: 50, left: 50 }}
				colors={themeColors()}
				axisBottom={{ tickRotation: data.length > 4 ? 20 : 0 }}
				indexBy="client"
				labelTextColor={selectedTheme.id === 'main' ? '#000000' : '#fff'}
				enableGridY={false}
				labelSkipHeight={10}
				keys={handleFormattedKeys(data)}
				tooltip={({ id, value, color }) => (
					<div
						style={{
							backgroundColor: selectedTheme.id === 'dark' ? '#595959' : '#fff',
							fontSize: 13,
							borderRadius: 4,
							padding: '5px 9px',
							boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
						}}
					>
						<Line>
							<div
								style={{
									width: 13,
									height: 13,
									backgroundColor: color,
									marginRight: 7,
								}}
							/>
							<Typography
								variant="subtitle2"
								style={{
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{`${id}`}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									fontWeight: 'bold',
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							>
								{`: ${value}`}
							</Typography>
						</Line>
					</div>
				)}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 160,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 150,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
				theme={darkThemeChart}
			/>
			<Typography
				variant="subtitle2"
				align="left"
				style={{
					marginTop: -10,
					paddingLeft: 40,
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t(
					'Inicio.Distribuição de classificações do usuário por cliente e por plataforma'
				)}`}
			</Typography>
		</Column>
	);

	const handleFormattedKeys = (data) => {
		const response = new Map();
		data.forEach((el: any) => {
			Object.keys(el).forEach((sub) => {
				if (sub !== 'client') {
					response.set(sub, sub);
				}
			});
		});
		return Array.from(response.values());
	};

	const RenderRightBarChart = (data) => (
		<Column style={{ height: '100%', width: '90%' }}>
			<ResponsiveBar
				data={data}
				margin={{ top: 50, right: 150, bottom: 50, left: 50 }}
				colors={themeColors()}
				axisBottom={{ tickRotation: data.length > 4 ? 20 : 0 }}
				indexBy="client"
				labelTextColor={selectedTheme.id === 'main' ? '#000000' : '#fff'}
				enableGridY={false}
				labelSkipHeight={10}
				keys={handleFormattedKeys(data)}
				tooltip={({ id, value, color }) => (
					<div
						style={{
							backgroundColor: selectedTheme.id === 'dark' ? '#595959' : '#fff',
							fontSize: 13,
							borderRadius: 4,
							padding: '5px 9px',
							boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
						}}
					>
						<Line>
							<div
								style={{
									width: 13,
									height: 13,
									backgroundColor: color,
									marginRight: 7,
								}}
							/>
							<Typography
								variant="subtitle2"
								style={{
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{`${id}`}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									fontWeight: 'bold',
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							>
								{`: ${value}`}
							</Typography>
						</Line>
					</div>
				)}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 160,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 150,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
				theme={darkThemeChart}
			/>
			<Typography
				variant="subtitle2"
				align="left"
				style={{
					marginTop: -10,
					paddingLeft: 40,
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t(
					'Inicio.Distribuição de classificações do usuário por cliente e por etiqueta'
				)}`}
			</Typography>
		</Column>
	);

	return (
		<>
			{renderCalendarChart()}
			<Line
				style={{
					height: '60%',
					marginTop: -45,
					justifyContent: 'space-between',
				}}
			>
				{RenderLeftBarChart(leftBarChartData)}
				{RenderRightBarChart(rightBarChartData)}
			</Line>
			{loading ? <BackdropLoading /> : null}
		</>
	);
};

export default AnalystDashboard;
