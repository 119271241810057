import React, { useState } from 'react';
import CardFlip from 'react-card-flip';
import Front from './Front';
import Back from './Back';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';
import { makeStyles } from '@mui/styles';
import { getEntityOnAuthor } from '../../../services/entities';

const useStyles = makeStyles(() => ({
	card: {
		'&:hover': {
			boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
		},
	},
	disabledCards: {
		transitionDelay: '1s',
		opacity: 0.3,
		transition: 'opacity 1.5s ease-in',
		pointerEvents: 'none',
	},
}));

interface Props {
	authorData: any;
	margin?: number;
}

const FlippingCard: React.FC<Props> = ({ authorData, margin }) => {
	const { selectedCards, handleSelectCard } = useCardSelector();
	const classes = useStyles();
	const [flip, setFlip] = useState(false);
	const [newKey, setNewKey] = useState(0);
	const [boundEntity, setBoundEntity] = useState<any>(null);
	const [loading, setLoading] = useState(false);

	const handleFlip = async () => {
		setFlip(!flip);
		if (boundEntity === null) {
			setLoading(true);
			const response: any = await getEntityOnAuthor(authorData.authorID);
			if (response.success === true) {
				setBoundEntity(response.data);
			}
			if (response.success === false) {
				setBoundEntity(null);
			}
			setLoading(false);
		}
	};

	const handleSetTags = (tagsData) => {
		authorData.tags = tagsData;
		setNewKey(newKey + 1);
		if (selectedCards.length > 0) {
			handleSelectCard(
				{ offerID: authorData.authorID, tags: authorData.tags },
				'remove'
			);
			handleSelectCard(
				{ offerID: authorData.authorID, tags: authorData.tags },
				'add'
			);
		}
	};

	const transformFree = {
		front: {
			transformStyle: 'flat',
		},
		back: { transformStyle: 'flat' },
	};

	return (
		<div style={{ margin }} key={authorData.authorID}>
			<CardFlip
				isFlipped={flip}
				flipDirection="horizontal"
				cardStyles={transformFree}
			>
				<Front
					author={authorData}
					handleFlip={handleFlip}
					className={classes.card}
				/>
				<Back
					author={authorData}
					handleFlip={handleFlip}
					className={classes.card}
					handleSetTags={handleSetTags}
					boundEntity={boundEntity}
					setBoundEntity={setBoundEntity}
					loading={loading}
				/>
			</CardFlip>
		</div>
	);
};

export default FlippingCard;
