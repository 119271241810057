import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import { Line, Column } from '../../styles';
import { useAuth0 } from '../../react-auth0-spa';
import { useTranslation } from 'react-i18next';
import newLandpage from '../../assets/images/newLandpage.png';
import OfferGoals from '../../assets/images/OfferGoals.png';
import BrFlag from '../../assets/images/flags/brazil.png';
import UsFlag from '../../assets/images/flags/usa.png';
import EsFlag from '../../assets/images/flags/spain.png';
import i18n from '../../i18n';
import styled from 'styled-components';

const Landpage: React.FC = () => {
	const { loginWithRedirect } = useAuth0();
	const { t } = useTranslation();

	useEffect(() => {
		const lastLanguage = localStorage.getItem('language') || 'pt-BR';
		i18n.changeLanguage(lastLanguage);
	}, []);

	const handleChangeLanguage = (language: string) => {
		i18n.changeLanguage(language);
		localStorage.setItem('language', language);
	};

	const CustomButton = styled.button`
		color: #f1f1f1;
		position: absolute;
		top: 52%;
		right: 41%;
		background: #000000;
		width: 180px;
		height: 37px;
		font-size: 16px;
		padding: 0px 7px;
		border: 0px;
		transform: translate(51%, -49%);
		cursor: pointer;

		@media (max-width: 1280px) {
			top: 51%;
			width: 160px;
			height: 30px;
			font-size: 14px;
		}
	`;

	const renderBackground = () => (
		<Line style={{ position: 'fixed' }}>
			<Column
				style={{
					width: '65vw',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'row',
				}}
			>
				<div style={{ height: '372px', width: '328px' }}>
					<div
						style={{
							backgroundImage: `url(${OfferGoals})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: '14em',
							backgroundPosition: '50% 25%',
							width: '100%',
							height: '100%',
						}}
					></div>
				</div>
			</Column>

			<Column
				style={{
					height: '100vh',
					backgroundImage: `url(${newLandpage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'right ',
					backgroundRepeat: 'no-repeat',
					backgroundAttachment: 'fixed',
				}}
			>
				<CustomButton onClick={loginWithRedirect}>
					{t('ACESSE AGORA')}
				</CustomButton>
			</Column>
		</Line>
	);

	const renderLanguageTopBar = () => (
		<Line
			style={{
				width: '20%',
				columnGap: '1vh',
				justifyContent: 'flex-start',
				marginLeft: '1.2vh',
			}}
		>
			<IconButton onClick={() => handleChangeLanguage('pt-br')}>
				<img alt="landpage-icon" src={BrFlag} style={{ height: '1.5vh' }} />
			</IconButton>
			<IconButton
				onClick={() => handleChangeLanguage('es-es')}
				style={{ marginLeft: '-1.5vh' }}
			>
				<img alt="landpage-icon" src={EsFlag} style={{ height: '1.5vh' }} />
			</IconButton>
			<IconButton
				onClick={() => handleChangeLanguage('en-us')}
				style={{ marginLeft: '-1.5vh' }}
			>
				<img alt="landpage-icon" src={UsFlag} style={{ height: '1.5vh' }} />
			</IconButton>
		</Line>
	);

	const renderTopBar = () => (
		<Line
			style={{
				width: '100vw',
				height: '6vh',
				justifyContent: 'end',
				alignItems: 'space-between',
				position: 'fixed',
				left: 0,
				top: 0,
				zIndex: 3,
			}}
		>
			{renderLanguageTopBar()}
			<Line
				style={{
					width: '100%',
					justifyContent: 'flex-end',
					columnGap: '2vh',
					marginRight: 10,
				}}
			></Line>
		</Line>
	);

	return (
		<>
			{renderBackground()}
			{renderTopBar()}
		</>
	);
};

export default Landpage;
