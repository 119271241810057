import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ViewSearchData {
	saveTagID: string;
	setSaveTagID: (saveTagID: string) => void;
	saveDenouncementID: string;
	setSaveDenouncementID: (saveUrl: string) => void;
	saveINcountryID: any;
	setSaveINcountryID: (saveINcountryID: any) => void;
}

export const ViewSearchContext = createContext<ViewSearchData>(
	{} as ViewSearchData
);

interface Props {
	children: ReactNode;
}

export const ViewSearch: React.FC<Props> = ({ children }: Props) => {
	const [saveTagID, setSaveTagID] = useState('');
	const [saveDenouncementID, setSaveDenouncementID] = useState('');

	const [saveINcountryID, setSaveINcountryID] = useState([]);

	return (
		<ViewSearchContext.Provider
			value={{
				setSaveTagID,
				saveTagID,
				saveINcountryID,
				setSaveINcountryID,
				saveDenouncementID,
				setSaveDenouncementID,
			}}
		>
			{children}
		</ViewSearchContext.Provider>
	);
};

export function useViewSearch(): ViewSearchData {
	return useContext(ViewSearchContext);
}
