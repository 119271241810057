import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';

const Configurações: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(changeActiveMenu('configuracoes'));
	}, [dispatch]);

	return <div />;
};

export default Configurações;
