import React from 'react';
import {
	Content,
	Title,
	WelcomeMessage,
	LoginButton,
	DisconectAccount,
} from './styles';
import { useAuth0 } from '../../react-auth0-spa';

const Page404: React.FC = () => {
	const { logout } = useAuth0();

	const logoutWithRedirect = () =>
		logout({
			returnTo: window.location.origin,
		});

	return (
		<div
			style={{
				position: 'fixed',
				right: 0,
				bottom: 0,
				minWidth: '100%',
				minHeight: '100%',
				background: '#dadada',
			}}
		>
			<Content>
				<Title>Ops..</Title>
				<WelcomeMessage>
					Esta página apenas não foi encontrada em nossos servidores.
				</WelcomeMessage>
				<LoginButton>Voltar ao inicio</LoginButton>
				<DisconectAccount onClick={() => logoutWithRedirect()}>
					Desconectar da plataforma
				</DisconectAccount>
			</Content>
		</div>
	);
};

export default Page404;
