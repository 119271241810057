import { api, isAxiosError } from '../../utils/api';
import { ErrorResponse } from '../../interfaces';

export const fetchDenouncementsSearchByAPI = async (searchPayload: any) => {
	// Filter out null or undefined values from queryParams
	const filteredQueryParams: any = Object.fromEntries(
		Object.entries(searchPayload).filter(([value]) => value != null)
	);
	try {
		const response = await api.post(
			`/denouncements/filters`,
			filteredQueryParams,
			{
				timeout: 2 * 60 * 1000,
			}
		);
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchDenouncementSources = async () => {
	try {
		const response = await api.get('/sources');
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchDenouncementStatus = async () => {
	try {
		const response = await api.get('/denounceStatus');
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchAvailablePlatforms = async () => {
	try {
		const response = await api.get('/platformINcountry/allplatforms/countries');
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchFields = async () => {
	try {
		const response = await api.get('/denouncements/fields');
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchUsers = async (clientID) => {
	try {
		const response = await api.get(`/userINclient/client/${clientID}`);
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getDenouncementSpreadSheet = async (payload) => {
	payload.itemsPerPage = 10000;
	// Filter out null or undefined values and exclude the 'page' key from queryParams
	const filteredQueryParams: any = Object.fromEntries(
		Object.entries(payload).filter(
			([key, value]) => value != null && key !== 'page'
		)
	);
	try {
		const response = await api.post(
			'/denouncements/download',
			filteredQueryParams,
			{
				timeout: 5 * 60 * 500, // 5 minutes
			}
		);
		const downloadUrl = response.data;
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.click();
		return { success: true, message: 'Arquivo baixado com sucesso' };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
