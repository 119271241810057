import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Typography,
	Zoom,
} from '@mui/material';
import { usePO } from '../../utils/POContext';
import { OfferToolTip } from '../../helpers';
import { Help, CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../styles';
import ShortCutIcon from './ShortCutIcon';
import { ShortCutsType } from '../../interfaces';
interface Props {
	headerTitle: string;
	data: ShortCutsType[];
}

const ShortCutModal: React.FC<Props> = ({ headerTitle, data }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [openShortCutModal, setOpenShortCutModal] = useState(false);

	const handleClose = () => {
		setOpenShortCutModal(false);
	};

	const ShortCutInfoButton = () => (
		<>
			<div style={{ marginRight: -5 }}>
				<OfferToolTip
					title={t('Atalhos de teclado')}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						size="small"
						onClick={() => setOpenShortCutModal(true)}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<Help
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primary,
								fontSize: 20,
							}}
						/>
					</IconButton>
				</OfferToolTip>
			</div>
		</>
	);

	const handleHeaderColor = () => {
		if (selectedTheme.id === 'main') {
			return selectedTheme.primary;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.foreground;
		}
	};

	const RenderHeader = () => (
		<Line
			style={{
				justifyContent: 'space-between',
			}}
		>
			<Typography
				noWrap
				style={{
					color: handleHeaderColor(),
					fontSize: 20,
					fontWeight: 'bold',
					maxWidth: '90%',
				}}
			>
				{`${t('Atalhos de teclado')}: ${headerTitle}`}
			</Typography>
			<IconButton
				onClick={handleClose}
				style={{ marginRight: '-16px' }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<CloseRounded
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				/>
			</IconButton>
		</Line>
	);

	const RenderDialogContent = () => (
		<Column style={{ gap: 25, width: 600 }}>
			{data?.map((el: ShortCutsType, index: number) => (
				<>
					<Line style={{ justifyContent: 'space-between', width: '100%' }}>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>
							{el.description}
						</Typography>
						<Grid
							container
							direction="row"
							wrap="nowrap"
							style={{ gap: 10, width: 'auto' }}
						>
							{el?.shortcut.map((shortCut: string) => (
								<Grid key={shortCut} item>
									<ShortCutIcon keyText={shortCut} />
								</Grid>
							))}
						</Grid>
					</Line>
					{data?.length - 1 !== index ? (
						<Divider
							style={{
								width: '100%',
								background:
									selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
							}}
						/>
					) : null}
				</>
			))}
		</Column>
	);

	const RenderFooter = () => (
		<Line
			style={{
				justifyContent: 'flex-end',
			}}
		>
			<Button
				size="small"
				onClick={handleClose}
				style={{
					color: selectedTheme.error,
				}}
				data-testid="closeModal"
			>
				{t('Fechar')}
			</Button>
		</Line>
	);

	return (
		<>
			{ShortCutInfoButton()}
			<Dialog open={openShortCutModal} disableEscapeKeyDown maxWidth="xl">
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						display: 'flex',
					}}
					id="max-width-dialog-title"
				>
					<RenderHeader />
				</DialogTitle>
				<DialogContent
					style={{
						padding: '25px 30px',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
					}}
				>
					<RenderDialogContent />
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						padding: '10px 15px',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					<RenderFooter />
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ShortCutModal;
