import React from 'react';
import { Zoom, IconButton } from '@mui/material';
import { LaunchOutlined } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../../../helpers';
import { usePO } from '../../../../../utils/POContext';

interface Props {
	rowData: any;
}

const OpenOfferInPlatform: React.FC<Props> = ({ rowData }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	return (
		<OfferToolTip
			aria-label="inativo"
			arrow
			enterDelay={700}
			enterNextDelay={700}
			title={`${t('Abrir link na plataforma')}`}
			TransitionComponent={Zoom}
		>
			<IconButton
				href={rowData?.permalink}
				target="_blank"
				style={{ marginBottom: -1 }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<LaunchOutlined
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: '#4F4F4F',
						fontSize: 22,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);
};
export default OpenOfferInPlatform;
