import React, { useState } from 'react';
import SingleOfferWindow from '../SingleOfferWindow';
import { Dialog } from '@mui/material';

interface Props {
	index: number;
	offersDataPackage: object[];
	open: boolean;
	setOpen: (open: boolean) => void;
}

const MultiItemCarousel: React.FC<Props> = ({
	index,
	offersDataPackage,
	open,
	setOpen,
}: Props) => {
	const [currentOfferIndex, setCurrentOfferIndex] = useState<number>(index);
	const [loading, setLoading] = useState(false);
	const [currentOfferData, setCurrentOfferData] = useState(
		offersDataPackage[index]
	);
	const [carouselPosition, setCarouselPosition] = useState(
		`${index + 1}/${offersDataPackage.length}`
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCycleOffer = (direction: string) => {
		setLoading(true);
		let newIndex = currentOfferIndex;
		if (
			direction === 'forward' &&
			currentOfferIndex < offersDataPackage.length - 1
		) {
			newIndex = currentOfferIndex + 1;
		} else if (direction === 'backward' && currentOfferIndex > 0) {
			newIndex = currentOfferIndex - 1;
		}
		setCurrentOfferIndex(newIndex);
		setCurrentOfferData(offersDataPackage[newIndex]);
		setCarouselPosition(`${newIndex + 1}/${offersDataPackage.length}`);
		setLoading(false);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="offer-carousel-modal"
				fullScreen
				PaperProps={{
					style: { background: 'rgba(0,0,0,0.3)' },
				}}
			>
				<SingleOfferWindow
					offerData={currentOfferData}
					loading={loading}
					useCarousel
					setOpen={setOpen}
					handleCycleOffer={handleCycleOffer}
					carouselPosition={carouselPosition}
				/>
			</Dialog>
		</>
	);
};

export default MultiItemCarousel;
