import {
	Chip,
	Typography,
	Button,
	IconButton,
	Popover,
	Paper,
	Table,
	TableRow,
	TableCell,
	TextField,
	Select,
	MenuItem,
} from '@mui/material';
import {
	BackspaceRounded,
	ListAltRounded,
	CloseRounded,
} from '@mui/icons-material/';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../../helpers';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';

interface Props {
	formattedItems: string[];
	setFormattedItems: (items: any) => void;
	formError: string[];
}

const InsertListPopover: React.FC<Props> = ({
	formattedItems,
	setFormattedItems,
	formError,
}) => {
	const { selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const [listAnchorEl, setListAnchorEl] =
		React.useState<HTMLButtonElement | null>(null);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const [splitElement, setSplitElement] = useState('1');
	const openPopover = Boolean(anchorEl);
	const openListPopover = Boolean(listAnchorEl);

	const [unFormattedItems, setUnFormattedItems] = useState<string>('');
	const handleKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			handleFormatItems();
		}
	};

	// function to slice string elements and add to array
	const handleFormatItems = () => {
		const newSet = new Set();
		const tempItems = unFormattedItems?.split(
			splitElement === '1' ? '\n' : ','
		);

		tempItems.map((el: string) => {
			if (newSet.size < 100 && el !== '') {
				newSet.add(
					el
						.replace(splitElement === '1' ? '\n' : ',', '')
						.replace('"', '')
						.replace('"', '')
						.replace('[', '')
						.replace(']', '')
				);
			}
			formError.filter((item) => item !== 'IDlist');
			return null;
		});
		if (newSet.size >= 100) {
			enqueueSnackbar(
				t(
					'Classificações.Limite de itens alcançado Eliminando duplicados e mantendo os 100 primeiros'
				),
				{ variant: 'warning', autoHideDuration: 6000 }
			);
		}
		setFormattedItems(Array.from(newSet));
		handleClosePopover();
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	const handleCloseListPopover = () => {
		setListAnchorEl(null);
	};

	const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClearList = () => {
		setFormattedItems([]);
		setUnFormattedItems('');
	};

	const handleOpenList = (event: React.MouseEvent<HTMLButtonElement>) => {
		setListAnchorEl(event.currentTarget);
	};

	const handleChangeSplitElement = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setSplitElement(event.target.value as string);
	};

	const renderBorderColor = () => {
		return selectedTheme.tone === 'dark' ? '#707070' : '#cacaca';
	};

	const renderOfferIDList = () => (
		<Line style={{ width: '100%', justifyContent: 'space-between' }}>
			<Line
				key={formattedItems.length}
				style={{
					height: 40,
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: 'nowrap',
					border: `1px solid ${
						formattedItems.length === 0 && formError.includes('IDlist')
							? 'red'
							: renderBorderColor()
					}`,
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
					borderRadius: 4,
					padding: 4,
					overflow: 'hidden',
				}}
			>
				{formattedItems?.length > 0 ? (
					<Line
						style={{
							maxWidth: 450,
							justifyContent: 'flex-start',
							overflow: 'hidden',
							flexWrap: 'nowrap',
						}}
					>
						{formattedItems?.map((el, index) => {
							if (index < 2) {
								return (
									<OfferToolTip key={el} title={el}>
										<Chip
											key={index}
											label={el}
											style={{
												height: '25px',
												margin: 2,
												maxWidth: 200,
												backgroundColor:
													selectedTheme.id === 'dark'
														? selectedTheme?.foreground
														: selectedTheme.primaryDark,
												borderColor:
													selectedTheme.id === 'dark' ? '' : '#eaeaea',
												borderRadius: '4px',
												color:
													selectedTheme.id === 'dark'
														? 'white'
														: selectedTheme?.foreground,
												fontSize: 12,
											}}
										/>
									</OfferToolTip>
								);
							}
						})}
						{formattedItems.length > 2 ? (
							<Typography
								variant="subtitle2"
								onClick={handleOpenList}
								style={{
									color:
										selectedTheme.tone === 'light'
											? selectedTheme.primaryDark
											: 'white',
									marginLeft: 3,
									cursor: 'pointer',
								}}
							>{`+${formattedItems?.length - 2}`}</Typography>
						) : null}
					</Line>
				) : null}
				{formattedItems?.length === 0 || formattedItems === null ? (
					<Button
						size="small"
						onClick={handleOpenPopover}
						sx={{
							color:
								selectedTheme.tone === 'light'
									? selectedTheme.primaryDark
									: 'white',
							width: ' 100%',
							display: 'flex',
							justifyContent: 'flex-start',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						{t('Classificações.Adicionar lista de IDs')}
					</Button>
				) : (
					<Line style={{ justifyContent: 'flex-end', width: 60, columnGap: 5 }}>
						<OfferToolTip title={`${t('Classificações.Limpar lista de IDs')}`}>
							<IconButton
								onClick={handleClearList}
								size="small"
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<BackspaceRounded
									style={{ color: selectedTheme.error, fontSize: 20 }}
								/>
							</IconButton>
						</OfferToolTip>
						<OfferToolTip title={`${t('Classificações.Exibir lista de IDs')}`}>
							<IconButton
								onClick={handleOpenList}
								size="small"
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<ListAltRounded
									style={{
										color:
											selectedTheme.tone === 'dark'
												? 'white'
												: selectedTheme.primaryDark,
										fontSize: 20,
									}}
								/>
							</IconButton>
						</OfferToolTip>
					</Line>
				)}
			</Line>
		</Line>
	);

	const renderInsertListFooter = () => (
		<Line style={{ justifyContent: 'space-between', padding: '10px 20px' }}>
			<Button
				size="small"
				onClick={handleClosePopover}
				style={{ color: selectedTheme.error }}
			>
				{t('Classificações.Cancelar')}
			</Button>
			<Line style={{ justifyContent: 'center', columnGap: 10, width: 'auto' }}>
				<Typography
					variant="subtitle2"
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Separar elementos por')}:
				</Typography>
				<Select
					variant="standard"
					margin="dense"
					value={splitElement}
					onChange={(event: any) => handleChangeSplitElement(event)} //TODO Arrumar Tipagem
					style={{ width: 50, height: 20 }}
					sx={{
						'.MuiSelect-select': {
							padding: '5px 10px',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
						'.MuiSelect-icon': {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
						'& [aria-expanded=true]': {
							background:
								selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
						},
					}}
					inputProps={{
						MenuProps: {
							PaperProps: {
								sx: {
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay3dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
							},
						},
					}}
				>
					<MenuItem
						value="1"
						sx={{
							background:
								selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
							'&.Mui-selected': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.foreground,
								},
							},
						}}
					>
						\n
					</MenuItem>
					<MenuItem
						value="2"
						sx={{
							background:
								selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
							},
							'&.Mui-selected': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.foreground,
								},
							},
						}}
					>
						,
					</MenuItem>
				</Select>
			</Line>
			<Button
				onClick={handleFormatItems}
				size="small"
				style={{
					color:
						selectedTheme.tone === 'light'
							? selectedTheme.primaryDark
							: 'white',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.primaryDark
							: selectedTheme.primaryDark,
					border: `1px solid ${
						selectedTheme.id === 'dark'
							? selectedTheme.footerLine
							: 'transparent'
					}`,
				}}
			>
				{'Ok'}
			</Button>
		</Line>
	);

	const renderInsertListPopover = () => (
		<Popover
			open={openPopover}
			onClose={handleClosePopover}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			sx={{
				'.MuiPopover-paper': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme?.overlay6dp,
					border: `1px solid ${
						selectedTheme.id === 'dark'
							? selectedTheme.footerLine
							: 'transparent'
					}`,
				},
			}}
		>
			<TextField
				variant="outlined"
				multiline
				size="small"
				placeholder={`${t(
					'Classificações.Cole ou digite os IDs separados por '
				)} ${splitElement === '1' ? '\\n' : ','}`}
				rows={4}
				value={unFormattedItems}
				onChange={(e) => setUnFormattedItems(e.target.value)}
				onKeyDown={handleKeyPress}
				inputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					},
				}}
				InputLabelProps={{
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					},
				}}
				sx={{
					margin: '20px 20px 0 20px',
					width: '420px',
					maxHeight: '500px',
					'& .MuiOutlinedInput-root': {
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
					},
					'& .MuiInputBase-root': {
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
			/>
			{renderInsertListFooter()}
		</Popover>
	);

	const renderReviewListPopover = () => (
		<Popover
			open={openListPopover}
			onClose={handleCloseListPopover}
			anchorEl={listAnchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
		>
			<Paper
				style={{
					padding: '5px 15px',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme?.overlay6dp,
				}}
			>
				<Line style={{ justifyContent: 'space-between', gap: 5 }}>
					<Typography
						sx={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							fontWeight: 'bold',
						}}
					>{`${t(
						'Classificações.Listando'
					)} ${formattedItems?.length} IDs`}</Typography>
					<IconButton
						onClick={handleCloseListPopover}
						size="small"
						sx={{
							marginRight: '-10px',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							sx={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primary,
							}}
						/>
					</IconButton>
				</Line>
				<Table size="small">
					{formattedItems?.map((el) => {
						return (
							<TableRow key={el}>
								<TableCell
									sx={{
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									}}
								>
									{el}
								</TableCell>
							</TableRow>
						);
					})}
				</Table>
			</Paper>
		</Popover>
	);

	return (
		<>
			{renderOfferIDList()}
			{renderInsertListPopover()}
			{renderReviewListPopover()}
		</>
	);
};
export default InsertListPopover;
