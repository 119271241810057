import React, { useEffect } from 'react';
import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material/';
import { Autocomplete, IconButton, TextField, Zoom } from '@mui/material';
import { OfferToolTip } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import TagSelector from '../../../../components/TagSelector';
import { makeStyles } from '@mui/styles';

interface Props {
	procedure: any;
	index: any;
	procedureTypeList: any;
	ruleEmpty: boolean;
	handleUpdateProcedures: (index, field, value) => void;
	handleAddProcedures: () => void;
	handleRemoveProcedures: (index) => void;
}
const SelectProcedures: React.FC<Props> = ({
	procedure,
	index,
	procedureTypeList,
	ruleEmpty,
	handleUpdateProcedures,
	handleAddProcedures,
	handleRemoveProcedures,
}) => {
	const { t } = useTranslation();
	const { selectedTheme, tagsActiveClient, classificationTypes } = usePO();

	const [selectedTags, setSelectedTags] = React.useState<any>([]);

	useEffect(() => {
		if (procedure?.action === 'etiquetar') {
			const tempTags: any = [];
			procedure.properties?.forEach((option) => {
				tagsActiveClient?.forEach((tag) => {
					if (tag.tagID === option) {
						tempTags.push(tag);
					}
				});
			});
			addTagToProcedures(tempTags);
			setSelectedTags(tempTags);
		}
		if (procedure?.action === 'classificar') {
			if (Array.isArray(procedure.properties)) {
				procedure.properties.forEach((option) => {
					const selectedClassification = classificationTypes?.find(
						(el) => el.id === option
					);
					if (selectedClassification) {
						updateProcedures('properties', selectedClassification);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [procedure]);

	const addTagToProcedures = (tag) => {
		setSelectedTags(tag);
		updateProcedures('properties', tag);
	};

	const updateProcedures = (field, value) => {
		switch (field) {
			case 'action':
				handleUpdateProcedures(index, 'properties', []);
				handleUpdateProcedures(index, 'action', value);
				break;
			case 'properties':
				handleUpdateProcedures(index, 'properties', value);
				break;
		}
	};

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		autoCompleteStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const textIconStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const renderProcedures = () => (
		<>
			<Autocomplete
				id="action-select"
				data-testid="actionProcedure"
				style={{
					width: '40%',
				}}
				options={['classificar', 'etiquetar']}
				value={procedure?.action}
				onChange={(_, newValue: any) => {
					updateProcedures('action', newValue);
				}}
				autoHighlight
				autoComplete
				autoSelect
				selectOnFocus
				disableClearable
				getOptionLabel={(option) => (option ? t(`Regras.${option}`) : '')}
				renderOption={(props, option) => (
					<li {...props}>{t(`Regras.${option}`)}</li>
				)}
				classes={{
					option: classes.option,
					root: classes.autoCompleteStyle,
					paper: classes.autoCompleteStyle,
				}}
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
					'.MuiSvgIcon-root ': {
						fill:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
					background:
						selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant={'outlined'}
						name="selectedAction"
						data-testid="selectedActionInput"
						size="small"
						error={ruleEmpty && !procedure?.action}
						placeholder={t('Regras.Ação')}
						inputProps={{
							...params.inputProps,
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
							},
						}}
						sx={{
							width: '100%',
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
					/>
				)}
			/>

			{procedure?.action === 'etiquetar' ? (
				<TagSelector
					setDataArray={addTagToProcedures}
					limitTags={2}
					dataArray={selectedTags}
					suggestions={tagsActiveClient}
					width={'100%'}
					error={ruleEmpty && procedure?.properties.length === 0}
				/>
			) : (
				<Autocomplete
					data-testid="selectedFieldInput"
					id="field-select"
					style={{
						width: '100%',
						cursor: 'pointer',
					}}
					disabled={!procedure?.action}
					options={classificationTypes}
					value={procedure?.properties}
					onChange={(_, newValue: any) => {
						updateProcedures('properties', newValue);
					}}
					autoHighlight
					autoComplete
					autoSelect
					multiple={false}
					limitTags={2}
					disableClearable
					loadingText={t('Carregando...')}
					selectOnFocus
					getOptionLabel={(option) =>
						procedure?.properties.length !== 0
							? t('ClassificationType.' + option?.id)
							: ''
					}
					classes={{
						option: classes.option,
						root: classes.autoCompleteStyle,
						paper: classes.autoCompleteStyle,
					}}
					sx={{
						color:
							selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,

						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorDisable
									: '',
						},
						'& .MuiInputBase-root.Mui-disabled': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
								},
							},
						},
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						'& .Mui-disabled .MuiSvgIcon-root': {
							fill:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorDisable
									: '',
						},
						'.MuiSvgIcon-root ': {
							fill:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorMedium
									: '',
						},

						background:
							selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
					}}
					renderOption={(props, option) => (
						<li {...props}>
							{procedure?.action !== 'etiquetar'
								? t('ClassificationType.' + option?.id)
								: option?.name}
						</li>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant={'outlined'}
							name="selectedField"
							data-testid="selectedFieldInput"
							size="small"
							error={ruleEmpty && procedure?.properties.length === 0}
							placeholder={
								procedure?.action === 'etiquetar'
									? t('Regras.Etiquetas')
									: t('Regras.Tipo de classificação')
							}
							inputProps={{
								...params.inputProps,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								width: '100%',
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'& .MuiInputBase-input::placeholder': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: '#fff',
							}}
						/>
					)}
				/>
			)}
		</>
	);
	const renderAddButton = () => (
		<IconButton style={{ marginRight: '-15px' }}>
			{procedureTypeList.length > 1 ? (
				<OfferToolTip
					title={t('Regras.Remover conjunto de ações')}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<RemoveCircleRounded
						style={{
							color: textIconStyleHeader(),
						}}
						onClick={() => handleRemoveProcedures(index)}
					/>
				</OfferToolTip>
			) : (
				<OfferToolTip
					title={t('Regras.Adicionar novo conjunto de ações')}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<AddCircleRounded
						style={{
							color: textIconStyleHeader(),
						}}
						onClick={handleAddProcedures}
					/>
				</OfferToolTip>
			)}
		</IconButton>
	);

	return (
		<Line
			style={{
				justifyContent: 'space-between',
				columnGap: 10,
				paddingRight: 12,
			}}
		>
			{renderProcedures()}
			{renderAddButton()}
		</Line>
	);
};
export default SelectProcedures;
