import styled from 'styled-components';

export const PanelBar = styled.div`
	width: 100%;
	height: 25;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
	border-radius: 0px 0px 6px 6px;
	/* border-style: solid;
  border-color: #eeeeee;
  border-width: 1px; */
	padding: 5px 25px;
	cursor: pointer;
`;

export const PanelBarTitle = styled.div`
	width: 95%;
	display: flex;
	flex-direction: row;
	font-style: bold;
	font-weight: 500;
	font-size: 12px;
	align-items: center;
	letter-spacing: 0.1em;
	/* color: #303030; */
`;

export const PanelBarRight = styled.div`
	width: 5%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 4px;
`;

export const PanelContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: '#eaeaea';
	padding: 20px 30px;
	box-shadow: inset 0px 5px 6px -8px rgba(0, 0, 0, 0.7);
`;

export const RegularPanel = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	/* background-color: '#ffffff'; */
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
`;
