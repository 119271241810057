import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	List,
	ListItemButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Typography,
	Zoom,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material/';
import { usePO } from '../../../utils/POContext';
import { Column } from '../../../styles';
import { OfferToolTip } from '../../../helpers';
import { useAuth0 } from '../../../react-auth0-spa';
import SelectClientModal from '../../SelectClientModal';
import UserProfileModal from '../../UserProfileModal';
import SelectStoreModal from '../BottomMenuIcons/SelectStoreModal';
import TermsOfUseModal from '../../TermsModal';
import { makeStyles } from '@mui/styles';
import SelectVersionModal from './SelectVersionModal';

interface Props {
	denseMenu: boolean;
}

const BottomMenuIcons: React.FC<Props> = ({ denseMenu }: Props) => {
	const { selectedTheme, selectedClient } = usePO();
	const { t } = useTranslation();
	const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();

	const [openSelectClientModal, setOpenSelectClientModal] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [openUserTerms, setOpenUserTerms] = useState(false);
	const [pinToMenu, setPinToMenu] = useState();
	const [openUserProfileModal, setOpenUserProfileModal] = useState(false);
	const [openSelectStoreModal, setOpenSelectStoreModal] = useState(false);
	const [openSelectVersionModal, setOpenSelectVersionModal] = useState(false);

	const handleOpenSelectStore = () => {
		setMenuIsOpen(false);
		setOpenSelectStoreModal(!openSelectStoreModal);
	};

	const handleOpenUserProfile = () => {
		setMenuIsOpen(false);
		setOpenUserProfileModal(!openUserProfileModal);
	};

	const handleOpenSelectVersion = () => {
		setMenuIsOpen(false);
		setOpenSelectVersionModal(!openSelectVersionModal);
	};

	const openSelectClient = () => {
		setMenuIsOpen(false);
		setOpenSelectClientModal(!openSelectClientModal);
	};

	// Auth0
	const logoutWithRedirect = () =>
		logout({
			returnTo: window.location.origin,
		});

	const userAvatarClicked = (event: any) => {
		setMenuIsOpen(!menuIsOpen);
		setPinToMenu(event.currentTarget);
	};

	const handleOpenTerms = () => {
		setMenuIsOpen(false);
		setOpenUserTerms(!openUserTerms);
	};

	const typographyStyleClient = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.background;
		}
	};

	const useStyles = makeStyles(() => ({
		list: {
			padding: '0',
		},
		root: {
			background:
				selectedTheme.id === 'dark' ? selectedTheme.vertMenuColor : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			},
		},
	}));
	const classes = useStyles();

	const renderSelectedClientName = () => {
		return (
			<OfferToolTip
				placement="right"
				title={selectedClient?.name}
				arrow
				enterDelay={200}
				enterNextDelay={200}
				TransitionComponent={Zoom}
			>
				<Box
					width="130px"
					minHeight="15px"
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
					marginBottom="60px"
					onClick={openSelectClient}
					style={{
						transform: 'rotate(-90deg)',
						opacity: 0.55,
						cursor: 'pointer',
						color: 'transparent',
					}}
				>
					<Typography
						noWrap
						variant="caption"
						style={{ fontWeight: 'bolder', color: typographyStyleClient() }}
					>
						{selectedClient?.name || ''}
					</Typography>
				</Box>
			</OfferToolTip>
		);
	};

	const renderOfflineIcon = () => {
		return (
			<Column style={{ justifyContent: 'flex-end', overflow: 'hidden' }}>
				<ListItemButton
					dense={!!denseMenu}
					onClick={() => loginWithRedirect({})}
				>
					<ListItemIcon>
						<AccountCircle
							sx={{ fontSize: denseMenu ? 'default' : 'large' }}
							id="qsLoginBtn"
							style={{ fill: 'grey', marginLeft: 8 }}
						/>
					</ListItemIcon>
				</ListItemButton>
			</Column>
		);
	};

	const renderAvatar = () => {
		return (
			<Column
				style={{
					justifyContent: 'flex-end',
					overflow: 'hidden',
				}}
			>
				<ListItemButton
					dense={!!denseMenu}
					data-tut="tour-userInfo"
					onClick={userAvatarClicked}
					data-testid="buttonMenuOpen"
				>
					<ListItemIcon>
						<Avatar
							src={user.picture}
							alt={user.name}
							style={{
								width: denseMenu ? 35 : 40,
								height: denseMenu ? 35 : 40,
								marginLeft: denseMenu ? 10 : 8,
							}}
						/>
					</ListItemIcon>
				</ListItemButton>
				<Menu
					classes={{ list: classes.list }}
					id="simple-menu"
					anchorEl={pinToMenu}
					open={menuIsOpen}
					onClose={userAvatarClicked}
					style={{ marginLeft: 45, padding: 0 }}
				>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						onClick={() =>
							window.open(
								'https://chrome.google.com/webstore/detail/offer-platform/llcnocgbbgfbhhedinjnboajcmokphbc',
								'_blank'
							)
						}
					>
						{t('VertMenu.Extensão')}
					</MenuItem>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						onClick={handleOpenSelectStore}
					>
						{t('VertMenu.Aplicativo')}
					</MenuItem>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						onClick={handleOpenSelectVersion}
					>
						{t('VertMenu.Histórico de versões')}
					</MenuItem>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						data-testid="termsOfUse"
						onClick={handleOpenTerms}
					>
						{t('VertMenu.Termos e Condições')}
					</MenuItem>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						onClick={openSelectClient}
					>
						{t('VertMenu.Selecionar Cliente')}
					</MenuItem>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						onClick={handleOpenUserProfile}
						data-testid="perfilButtonModal"
					>
						{t('VertMenu.Perfil')}
					</MenuItem>
					<MenuItem
						classes={{
							root: classes.root,
						}}
						onClick={() => logoutWithRedirect()}
					>
						{t('VertMenu.Desconectar')}
					</MenuItem>
				</Menu>
			</Column>
		);
	};

	return (
		<div>
			<Column style={{ position: 'absolute', bottom: 0 }}>
				{renderSelectedClientName()}

				<List data-testid="userAvatarButton">
					{isAuthenticated ? renderAvatar() : renderOfflineIcon()}
				</List>
			</Column>
			<SelectClientModal
				open={openSelectClientModal}
				close={openSelectClient}
			/>
			<UserProfileModal
				open={openUserProfileModal}
				close={handleOpenUserProfile}
			/>
			<SelectStoreModal
				open={openSelectStoreModal}
				handleClose={handleOpenSelectStore}
			/>
			<SelectVersionModal
				open={openSelectVersionModal}
				handleClose={handleOpenSelectVersion}
				url="https://docs.offertech.com.br/share/792ebbf7-7fc0-41cd-ae86-dff4b946f5f5"
			/>
			<TermsOfUseModal
				open={openUserTerms}
				handleClose={handleOpenTerms}
				isoledComponent
			/>
		</div>
	);
};

export default BottomMenuIcons;
