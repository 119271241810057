import React from 'react';
import { IconButton, Typography, Zoom } from '@mui/material';
import { ResponsiveCalendar } from '@nivo/calendar';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import {
	ArrowForwardIosOutlined,
	ArrowBackIosOutlined,
} from '@mui/icons-material/';
import { ResponsiveBar } from '@nivo/bar';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../helpers';
import StarterCard from '../../../components/StarterCard';
import BackdropLoading from '../../../components/BackdropLoading';

interface Props {
	calendarChartData: any;
	filteredYear: number;
	leftBarChartData: any;
	leftCardData: any;
	loading: boolean;
	middleCardData: any;
	middleFlipCardData: any;
	rightBarChartData: any;
	rightCardData: any;
	rightFlipCardData: any;
	setFilteredYear: any;
	darkThemeChart: any;
	themeColors: any;
}

const ClientDashboard: React.FC<Props> = ({
	calendarChartData,
	filteredYear,
	leftBarChartData,
	leftCardData,
	loading,
	middleCardData,
	middleFlipCardData,
	rightBarChartData,
	rightCardData,
	rightFlipCardData,
	setFilteredYear,
	darkThemeChart,
	themeColors,
}: Props) => {
	const { selectedTheme, userData } = usePO();
	const { t } = useTranslation();

	const today = new Date();
	const platformOfferFirstDate = '2022-03-01T00:00:00';

	const LegendFormated = (value) => {
		switch (value) {
			case 0:
				return `${t('Inicio.Algumas Denúncias')}`;
			default:
				return `${value}+ ${t('Inicio.Denúncias')}`;
		}
	};

	const handleNumberAbbreviation = (value) => {
		return Intl.NumberFormat(userData?.i18nID, {
			notation: 'compact',
			maximumFractionDigits: 1,
		}).format(value);
	};

	const renderTopCards = () => (
		<>
			<Line
				style={{
					maxWidth: '100%',
					justifyContent: 'center',
					display: 'flex',
					gap: '10%',
				}}
			>
				<StarterCard
					tooltipTitle={leftCardData.toLocaleString(userData.i18nID)}
					cardTitle={handleNumberAbbreviation(leftCardData)}
					cardSubtitle={`${t('Inicio.Suspeitos para analisar')}`}
				/>
				<StarterCard
					tooltipTitle={middleCardData.toLocaleString(userData.i18nID)}
					cardTitle={handleNumberAbbreviation(middleCardData)}
					cardSubtitle={`${t('Inicio.Infrações detectadas')}`}
					flipCard
					backTooltipTitle={middleFlipCardData.toLocaleString(userData.i18nID)}
					backCardTitle={handleNumberAbbreviation(middleFlipCardData)}
					backCardSubtitle={`${t('Inicio.Perfis detectados')}`}
				/>
				<StarterCard
					tooltipTitle={rightCardData.toLocaleString(userData.i18nID)}
					cardTitle={handleNumberAbbreviation(rightCardData)}
					cardSubtitle={`${t('Inicio.Infrações removidas')}`}
					flipCard
					backTooltipTitle={rightFlipCardData.toLocaleString(userData.i18nID)}
					backCardTitle={handleNumberAbbreviation(rightFlipCardData)}
					backCardSubtitle={`${t('Inicio.Seguidores dos perfis detectados')}`}
				/>
			</Line>
		</>
	);

	const renderCalendarChart = () => (
		<>
			<Line
				style={{
					height: '30%',
				}}
			>
				<OfferToolTip
					placement="right"
					title={`${t('Inicio.Ano Anterior')}`}
					arrow
					enterDelay={200}
					enterNextDelay={200}
					TransitionComponent={Zoom}
				>
					<div>
						<IconButton
							size="small"
							key={filteredYear}
							aria-label="close"
							color="inherit"
							disabled={
								filteredYear === Number(platformOfferFirstDate.slice(0, 4))
									? true
									: false
							}
							onClick={() => setFilteredYear(filteredYear - 1)}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<ArrowBackIosOutlined
								sx={{
									fontSize: '50px',
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							/>
						</IconButton>
					</div>
				</OfferToolTip>
				<ResponsiveCalendar
					data={calendarChartData}
					key={filteredYear}
					from={`${filteredYear}-01-02`}
					to={`${filteredYear}-12-31`}
					emptyColor={
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#eeeeee'
					}
					colors={themeColors()}
					margin={{ top: 40, right: 20, bottom: 30, left: 40 }}
					monthBorderColor={selectedTheme.id === 'dark' ? '#444444' : '#ffffff'}
					monthLegendPosition="after"
					dayBorderWidth={2}
					dayBorderColor={selectedTheme.id === 'dark' ? '#444444' : '#ffffff'}
					legendFormat={(value) => LegendFormated(value)}
					legends={[
						{
							anchor: 'top',
							direction: 'row',
							translateY: -40,
							itemCount: 4,
							itemWidth: 150,
							itemHeight: 36,
							itemsSpacing: 10,
							itemDirection: 'left-to-right',
						},
					]}
					theme={darkThemeChart}
				/>

				<OfferToolTip
					placement="right"
					title={`${t('Inicio.Ano Posterior')}`}
					arrow
					enterDelay={200}
					enterNextDelay={200}
					TransitionComponent={Zoom}
				>
					<div>
						<IconButton
							size="small"
							key={filteredYear}
							aria-label="close"
							color="inherit"
							disabled={filteredYear === today.getFullYear() ? true : false}
							onClick={() => setFilteredYear(filteredYear + 1)}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<ArrowForwardIosOutlined
								sx={{
									fontSize: '50px',
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							/>
						</IconButton>
					</div>
				</OfferToolTip>
			</Line>
			<Typography
				variant="subtitle2"
				sx={{
					textAlign: 'center',
					marginTop: '-25px',
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Inicio.Distribuição de denúncias do usuário por dia')}`}
			</Typography>
		</>
	);

	const RenderLeftBarChart = (data) => (
		<Column style={{ height: '100%', width: '90%' }}>
			<ResponsiveBar
				data={data}
				margin={{ top: 50, right: 130, bottom: 50, left: 40 }}
				colors={themeColors()}
				colorBy={'indexValue'}
				labelTextColor={selectedTheme.id === 'main' ? '#000000' : '#fff'}
				enableGridY={false}
				labelSkipHeight={10}
				tooltip={({ indexValue, value, color }) => (
					<div
						style={{
							backgroundColor: selectedTheme.id === 'dark' ? '#595959' : '#fff',
							fontSize: 13,
							borderRadius: 4,
							padding: '5px 9px',
							boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
						}}
					>
						<Line>
							<div
								style={{
									width: 13,
									height: 13,
									backgroundColor: color,
									marginRight: 7,
								}}
							/>
							<Typography
								variant="subtitle2"
								style={{
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{`${countries.getName(
									indexValue,
									userData.i18nID.substring(0, 2),
									{ select: 'official' }
								)}`}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									fontWeight: 'bold',
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							>
								{`: ${value}`}
							</Typography>
						</Line>
					</div>
				)}
				legends={[
					{
						dataFrom: 'indexes',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 110,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
				theme={darkThemeChart}
			/>
			<Typography
				variant="subtitle2"
				align="left"
				sx={{
					marginTop: '-10px',
					paddingLeft: '40px',
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Inicio.Infrações por país')}`}
			</Typography>
		</Column>
	);

	const RenderRightBarChart = (data) => (
		<Column style={{ height: '100%', width: '90%' }}>
			<ResponsiveBar
				data={data}
				margin={{ top: 50, right: 150, bottom: 50, left: 50 }}
				colors={themeColors()}
				axisBottom={{ tickRotation: data?.length > 5 ? 20 : 0 }}
				colorBy={'indexValue'}
				labelTextColor={selectedTheme.id === 'main' ? '#000000' : '#fff'}
				enableGridY={false}
				labelSkipHeight={10}
				tooltip={({ indexValue, value, color }) => (
					<div
						style={{
							backgroundColor: selectedTheme.id === 'dark' ? '#595959' : '#fff',
							fontSize: 13,
							borderRadius: 4,
							padding: '5px 9px',
							boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
						}}
					>
						<Line>
							<div
								style={{
									width: 13,
									height: 13,
									backgroundColor: color,
									marginRight: 7,
								}}
							/>
							<Typography
								variant="subtitle2"
								style={{
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' ? selectedTheme.primary : '',
								}}
							>
								{`${indexValue}`}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									fontWeight: 'bold',
									fontSize: 13,
									color:
										selectedTheme.id === 'dark' ? selectedTheme.primary : '',
								}}
							>
								{`: ${value}`}
							</Typography>
						</Line>
					</div>
				)}
				legends={[
					{
						dataFrom: 'indexes',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 160,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 150,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
				theme={darkThemeChart}
			/>
			<Typography
				variant="subtitle2"
				align="left"
				sx={{
					marginTop: '-10px',
					paddingLeft: '40px',
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Inicio.Infrações por plataforma')}`}
			</Typography>
		</Column>
	);

	return (
		<>
			{renderTopCards()}
			{renderCalendarChart()}
			<Line
				style={{
					height: '40%',
					marginTop: -45,
					justifyContent: 'space-between',
				}}
			>
				{RenderLeftBarChart(leftBarChartData?.hits)}
				{RenderRightBarChart(rightBarChartData?.hits)}
			</Line>
			{loading ? <BackdropLoading /> : null}
		</>
	);
};

export default ClientDashboard;
