import React from 'react';
import { Router } from 'react-router-dom';
import Loading from './components/LoadingOffer';
import { useAuth0 } from './react-auth0-spa';
import history from './utils/history';
import Routes from './routes';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

// styles
import './App.css';

const App = () => {
	const { loading } = useAuth0();

	if (loading) {
		return (
			<div
				className="spinner"
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: ' center',
					alignItems: 'center',
					position: 'fixed',
					right: 0,
					bottom: 0,
					minWidth: '100%',
					minHeight: '100%',
				}}
			>
				<Loading width={900} height={270} />
			</div>
		);
	}
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Router history={history}>
					<Routes />
				</Router>
			</PersistGate>
		</Provider>
	);
};

export default App;
