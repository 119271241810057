import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Typography,
	Zoom,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { DoubleArrowRounded } from '@mui/icons-material/';
import { HandleGetThemeColor, OfferToolTip } from '../../../../helpers';
import { runSearchNow } from '../../../../services/searches';
import { makeStyles } from '@mui/styles';

interface Props {
	reloadTable: any;
	rowData: any;
}

const RunSearchDialog: React.FC<Props> = ({ reloadTable, rowData }: Props) => {
	const { t } = useTranslation();
	const { updateCursorMode, selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = React.useState(false);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [isDisabled, setIsDisabled] = React.useState(false);

	const useStyles = makeStyles(() => ({
		root: {
			background:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
		},
	}));

	const classes = useStyles();

	React.useEffect(() => {
		if (rowData?.inProgress) {
			setIsDisabled(true);
		}
	}, [rowData]);

	const handleToggleDialog = () => {
		setOpenDialog((prevOpen) => !prevOpen);
	};

	const handleConfirm = async () => {
		setLoading(true);
		updateCursorMode('wait');
		const response: any = await runSearchNow(
			rowData.searchID,
			rowData.clientID
		);
		if (response.success === true) {
			enqueueSnackbar(t('Buscas.Tabs.Buscas Salvas.Busca iniciada'), {
				variant: 'success',
			});
		} else if (response.success === false) {
			enqueueSnackbar(
				t(
					response.message || 'Buscas.Tabs.Buscas Salvas.Erro ao iniciar busca'
				),
				{
					variant: 'error',
				}
			);
		}
		updateCursorMode('default');
		handleToggleDialog();
		setLoading(false);
		reloadTable();
	};

	const renderRunSearchButton = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Rodar busca imediatamente')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<span>
				<IconButton
					disabled={isDisabled}
					onClick={handleToggleDialog}
					data-testid={`run-search-button${rowData.rowIndex}`}
					style={{ padding: '10px 5px' }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<DoubleArrowRounded
						style={{
							fontSize: 23,
							color: HandleGetThemeColor({
								themeID: 'dark',
								primaryThemeColor: isDisabled
									? selectedTheme.textColorDisable
									: selectedTheme.textColorHigh,
								alternateThemeColor: '',
							}),
						}}
					/>
				</IconButton>
			</span>
		</OfferToolTip>
	);

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '20px 30px',
				width: 350,
			}}
			classes={{
				root: classes.root,
			}}
		>
			<Typography
				align="center"
				variant="h6"
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				{`${t('Buscas.Tabs.Buscas Salvas.Rodar busca imediatamente')}?`}
			</Typography>
		</DialogContent>
	);

	const renderDialogFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Button
					disabled={loading}
					data-testid="cancelButton"
					onClick={handleToggleDialog}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Regras.Cancelar')}
				</Button>
				<Button
					disabled={loading}
					onClick={handleConfirm}
					data-testid="confirm-button"
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.primaryDark,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: selectedTheme.foreground
						}`,
					}}
				>
					{loading ? (
						<LoadingDots height={25} width={60} loop />
					) : (
						t('Buscas.Tabs.Buscas Salvas.Confirmar')
					)}
				</Button>
			</Line>
		</DialogActions>
	);

	return (
		<>
			{renderRunSearchButton()}
			<Dialog open={openDialog} data-testid="dialog-content">
				{renderDialogContent()}
				{renderDialogFooter()}
			</Dialog>
		</>
	);
};

export default RunSearchDialog;
