import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../utils/POContext';
import OfferTable from '../../../../components/OfferTable';
import {
	OfferToolTip,
	convertDate,
	handlePlatformINCountry,
} from '../../../../helpers';
import PlatformIcon from '../../../../components/PlatformIcon';
import OpenOfferInPlatform from './OpenOfferInPlatform';
import { Card, IconButton, Typography, Zoom } from '@mui/material';
import FullSizeImageDialog from '../../../../components/FullSizeImageModal';
import { Refresh } from '@mui/icons-material/';
interface Props {
	data: any;
	reFetchData: () => void;
}

const OffersFromAuthorsTable: React.FC<Props> = ({
	data,
	reFetchData,
}: Props) => {
	const { selectedClient, userData, selectedTheme } = usePO();
	const { t } = useTranslation();
	const imageIndex = 0;

	const [loading, setLoading] = useState(false);
	const [openImageModal, setOpenImageModal] = useState<boolean>(false);
	const [rowImage, setRowImage] = useState<string>('');
	const [tableData, setTableData] = useState<any[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			setTableData([]);
			try {
				await buildTableData(data);
			} catch (error) {
				console.log(`🚀 ~ Teste ~ error:`, error);
			}
			setLoading(false);
		};
		fetchData();
		// eslint-disable-next-line
	}, [data]);

	const colsData = [
		{
			columnTitle: '',
			fieldName: 'thumb',
			translateColumnData: false,
			cellWidth: 90,
			align: 'center',
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Autores.Anúncio'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 250,
		},
		{
			columnTitle: t('Autores.Plataforma'),
			fieldName: 'platform',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 150,
		},
		{
			columnTitle: t('Status'),
			fieldName: 'denounceStatus',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 150,
		},
		{
			columnTitle: t('FilterBar.Fonte da Denúncia'),
			fieldName: 'denounceReason',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 50,
		},
		{
			columnTitle: t('Autores.Data'),
			fieldName: 'date',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			marginTitle: '0 0 0 -5px',
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const countries = require('i18n-iso-countries');
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/en.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/es.json')
	);

	const handleOpenFullSizeImage = (urlRef: string) => {
		setRowImage(urlRef);
		setOpenImageModal(true);
	};

	const renderPlatformLogo = (data: string) => (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() => handleOpenFullSizeImage(data)}
		>
			<PlatformIcon
				data={data}
				dataType="image"
				size={40}
				borderRadius={4}
				cursor="pointer"
			/>
		</div>
	);

	const buildTableData = async (data) => {
		const tempData: any[] = [];
		const auxDataResponse = data.responses[0].hits;
		auxDataResponse.hits.map((el) => {
			const newPlatform = {
				date: convertDate(el._source.created, userData.i18nID, 'default'),
				permalink: el._source.offer.permalink,
				platform: handlePlatformINCountry(
					el._source.offer.platformINcountryID,
					userData.i18nID
				),
				client: el._source.client.clientID,
				denounceStatus: el._source.denounceStatus.denounceStatusID,
				denounceReason: t(
					'ClassificationType.' +
						el._source.classificationType.classificationTypeID
				),
				thumb: renderPlatformLogo(el._source.offer.thumb),
				title: el._source.offer.title,
			};
			tempData.push(newPlatform);
			return null;
		});
		setTableData(tempData);
	};

	const tableActions = [
		{
			component: OpenOfferInPlatform,
			props: {},
			action: () => {},
		},
	];

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={reFetchData}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Refresh
					style={{
						fontSize: 23,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<Card>
			<OfferTable
				colsData={colsData}
				tableData={tableData}
				tableFooter={tableData.length > 0}
				tableActions={tableActions}
				customRowsPerPage={4}
				customRowsPerPageOptions={[]}
				noShadow
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient ? (
						loading ? (
							t('Carregando...')
						) : (
							<Typography
								style={{
									fontWeight: 'bold',
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								}}
							>
								{t('Autores.Sem ofertas para esse autor')}
							</Typography>
						)
					) : (
						t('Selecione um cliente')
					)
				}
				customLeftFooterComponent={renderReloadTableData()}
			/>
			<FullSizeImageDialog
				open={openImageModal}
				setOpen={setOpenImageModal}
				imageIndexToShow={imageIndex}
				images={rowImage}
			/>
		</Card>
	);
};

export default OffersFromAuthorsTable;
