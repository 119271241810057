import React, { useEffect, useState } from 'react';
import { usePO } from '../../utils/POContext';
import {
	getBIIDbyClient,
	getDashboardToken,
} from '../../services/reports/index';
import { Column, Line } from '../../styles';
import {
	Card,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { SyncRounded } from '@mui/icons-material';
import 'react-loading-skeleton/dist/skeleton.css';
import RenderIFrameSuperSet from './RenderIFrameSuperSet';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import { useDispatch } from 'react-redux';

const SuperSetCharts: React.FC = () => {
	const { selectedClient, selectedTheme, userData } = usePO();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [reloadCharts, setReloadCharts] = useState<number>(0);
	const [biid, setBiid] = useState<string>('');
	const [supersetToken, setSupersetToken] = useState<string>('');

	useEffect(() => {
		dispatch(changeActiveMenu('painel'));
	}, [dispatch]);

	useEffect(() => {
		const fetchBIIDForDashboard = async () => {
			try {
				const response: any = await getBIIDbyClient(selectedClient.id);
				if (response.success) {
					return response.data.BIID;
				}
			} catch (error) {
				console.error('Failed to fetch guest token:', error);
				return null;
			}
		};

		const fetchTokenFromSuperSet = async (BIID: string) => {
			try {
				const response: any = await getDashboardToken(selectedClient.id, BIID);
				if (response.success) {
					return response.data.token;
				}
			} catch (error) {
				console.error('Failed to fetch guest token:', error);
				return null;
			}
		};

		const mountDashboard = async () => {
			setLoading(true);
			const powerByID = await fetchBIIDForDashboard();
			setBiid(powerByID);

			if (powerByID) {
				const token = await fetchTokenFromSuperSet(powerByID);
				setSupersetToken(token);
				setLoading(false);
			}
		};
		if (reloadCharts >= 1) {
			mountDashboard();
		}
	}, [reloadCharts]);

	const renderDashboard = () => {
		return !loading && biid.trim() !== '' && supersetToken.trim() !== '' ? (
			<Line style={{ width: '100%', height: '100%' }}>
				<RenderIFrameSuperSet BIID={biid} token={supersetToken} />
			</Line>
		) : (
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<div
					style={{
						width: '45%',
						height: '100%',
					}}
				>
					<Skeleton
						count={1}
						width="100%"
						height="45px"
						baseColor={
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#e7e7e7'
						}
						highlightColor={
							selectedTheme.id === 'dark'
								? selectedTheme.textColorDisable
								: undefined
						}
					/>
				</div>
			</div>
		);
	};
	return (
		<Card
			sx={{
				display: userData.roles.some((role) =>
					[
						'Administrador do cliente',
						'Administrador da ferramenta',
						'Analista do cliente',
						'Desenvolvedor',
					].includes(role)
				)
					? 'flex'
					: 'none',
				backgroundColor:
					selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
				border:
					selectedTheme.id === 'dark'
						? `1px solid ${selectedTheme.footerLine}`
						: 'none',
			}}
		>
			<div
				style={{
					height: '97.5vh',
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Column
					style={{
						height: '100%',
						width: '100%',
						justifyContent: 'center',
						alignContent: 'center',
						padding: '20px 30px',
					}}
				>
					{reloadCharts >= 1 ? (
						renderDashboard()
					) : (
						<div style={{ height: '45px', width: '45%' }}>
							<ListItemButton
								onClick={() => setReloadCharts(reloadCharts + 1)}
								sx={{
									borderRadius: '4px',
									border: `1px solid ${
										selectedTheme.id === 'dark'
											? selectedTheme.footerLine
											: 'transparent'
									}`,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.foreground,
									background: selectedTheme.primaryDark,
									'&.MuiListItemButton-root:hover': {
										backgroundColor:
											selectedTheme.id === 'dark'
												? selectedTheme.primaryLight
												: selectedTheme.primary,
									},
								}}
							>
								<div
									style={{
										width: '100%',
										justifyContent: 'center',
										display: 'flex',
									}}
								>
									<div
										style={{ display: 'flex', gap: 10, alignItems: 'center' }}
									>
										<ListItemIcon sx={{ minWidth: '0px' }}>
											<SyncRounded
												style={{
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: selectedTheme.foreground,
													fontSize: 20,
												}}
											/>
										</ListItemIcon>
										<ListItemText
											sx={{ display: 'flex', justifyContent: 'center' }}
										>
											<Typography>
												{t('Relatórios.Carregar Dashboard').toUpperCase()}
											</Typography>
										</ListItemText>
									</div>
								</div>
							</ListItemButton>
						</div>
					)}
				</Column>
			</div>
		</Card>
	);
};
export default SuperSetCharts;
