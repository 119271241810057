import React, { useState } from 'react';
import {
	Collapse,
	Divider,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Zoom,
} from '@mui/material';
import {
	AccountCircle,
	ZoomInRounded,
	ThumbsUpDown,
	SmsFailedRounded,
	Report,
	Home,
	LocalOffer,
	Policy,
	InsertChartRounded,
	PeopleRounded,
	BusinessCenter,
	Gavel,
	ExpandLess,
	ExpandMore,
	FileDownload,
	Equalizer,
} from '@mui/icons-material/';
import { changeActiveMenu } from '../../../redux/slices/menuSlice';
import { Line } from '../../../styles';
import { Link } from 'react-router-dom';
import { OfferToolTip } from '../../../helpers';
import { usePO } from '../../../utils/POContext';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeAllPanels } from '../../../redux/slices/expansibleTableSlice';
interface Props {
	openMenu: boolean;
	denseMenu: boolean;
}

const MenuItems: React.FC<Props> = ({ denseMenu, openMenu }: Props) => {
	const { selectedTheme, userData } = usePO();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const activeMenu = useSelector((state: any) => state.menu);
	const [reportsCollapseMenu, setReportsCollapseMenu] =
		useState<boolean>(false);

	const handleSelectMenuItem = (selectedMenu: string) => {
		dispatch(closeAllPanels());
		dispatch(changeActiveMenu(selectedMenu));
	};

	const handleReportMenuClick = () => {
		setReportsCollapseMenu(!reportsCollapseMenu);
	};

	const handleColorForCollapseMenu = (menuItem: string) => {
		if (activeMenu.selectedMenu === menuItem) {
			if (selectedTheme.id === 'main') {
				return '#ffffff66';
			} else {
				return '#ffffff1a';
			}
		} else {
			return selectedTheme.id === 'dark' ? '#00000042' : '#0000001f';
		}
	};

	return (
		<div style={{ marginTop: denseMenu ? 0 : 5 }}>
			<ListItemButton
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('inicio')}
				sx={{
					background:
						activeMenu.selectedMenu === 'inicio'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/inicio"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Início')}`}
							aria-label="Início"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<Home
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Início')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="searchesMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('busca')}
				sx={{
					display: userData.permissions?.includes('read:searches')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'busca'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/busca"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Busca')}`}
							aria-label="Busca"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<ZoomInRounded
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Busca')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				dense={!!denseMenu}
				data-testid="classificationsMenuItem"
				onClick={() => handleSelectMenuItem('classificacoes')}
				sx={{
					display: userData.permissions?.includes('read:offer')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'classificacoes'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/classificacoes"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Classificações')}`}
							aria-label="Classificações"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon style={{ marginTop: -3 }}>
								<ThumbsUpDown
									style={{
										fontSize: denseMenu ? 19 : 24,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Classificações')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="notificationsMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('notificacoes')}
				sx={{
					display: userData.permissions?.includes('read:offerClassification')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'notificacoes'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/notificacoes"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Notificações')}`}
							aria-label="Notificações"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<SmsFailedRounded
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Notificações')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('denuncias')}
				sx={{
					display: userData.permissions?.includes('read:denouncements')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'denuncias'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/denuncias"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Denúncias')}`}
							aria-label="Denuncias"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<Policy
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
										marginLeft: -1,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Denúncias')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="rolesMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('regras')}
				sx={{
					display: userData.permissions?.includes('read:users:roles')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'regras'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/regras"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Regras')}`}
							aria-label="Regras"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<Report
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
										marginLeft: -1,
										transform: 'rotate(180deg)',
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Regras')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="tagsMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('etiquetas')}
				sx={{
					display: userData.permissions?.includes('update:tags')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'etiquetas'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/etiquetas"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Etiquetas')}`}
							aria-label="Etiquetas"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<LocalOffer
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Etiquetas')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="authorsMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('autores')}
				sx={{
					display: userData.permissions?.includes('read:authors')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'autores'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/autores"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Autores')}`}
							aria-label="Autores"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<AccountCircle
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Autores')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="entitiesMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('entidades')}
				sx={{
					display: userData.permissions?.includes('read:users:roles')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'entidades'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/entidades"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Entidades')}`}
							aria-label="Entidades"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<Gavel
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
										fontSize: 20,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								marginLeft: -5,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
							}}
						>
							{t('VertMenu.Entidades')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				dense={!!denseMenu}
				onClick={handleReportMenuClick}
				sx={{
					display: userData.permissions?.includes('read:reports')
						? 'flex'
						: 'none',
					justifyContent: 'space-between',
					background:
						reportsCollapseMenu ||
						activeMenu.selectedMenu === 'relatorios' ||
						activeMenu.selectedMenu === 'painel'
							? selectedTheme.id === 'dark'
								? '#00000042'
								: '#0000001f'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: openMenu ? '100%' : '60%',
					}}
					to={'#'}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Relatórios')}`}
							aria-label="Relatórios"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<InsertChartRounded
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Relatórios')}
						</ListItemText>
					</Line>
				</Link>
				{reportsCollapseMenu ? (
					<ExpandLess
						sx={{
							fontSize: '1rem',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorHigh
									: selectedTheme?.vertMenuColor,
						}}
					/>
				) : (
					<ExpandMore
						sx={{
							fontSize: '1rem',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorHigh
									: selectedTheme?.vertMenuColor,
						}}
					/>
				)}
			</ListItemButton>
			<Collapse in={reportsCollapseMenu} timeout="auto" unmountOnExit>
				<ListItemButton
					dense={!!denseMenu}
					onClick={() => handleSelectMenuItem('relatorios')}
					sx={{
						display: userData.permissions?.includes('read:users:roles')
							? 'flex'
							: 'none',
						background: handleColorForCollapseMenu('relatorios'),
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
						},
					}}
				>
					<Link
						to="/relatorios"
						style={{
							marginLeft: denseMenu ? 3 : 6,
							textDecoration: 'none',
							width: '100%',
						}}
					>
						<Line>
							<OfferToolTip
								placement="right"
								title={`${t('VertMenu.Exportar')}`}
								aria-label="Exportar"
								arrow
								enterDelay={200}
								enterNextDelay={200}
								TransitionComponent={Zoom}
							>
								<ListItemIcon>
									<FileDownload
										sx={{
											fontSize: denseMenu ? 19 : 24,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme?.textColorHigh
													: selectedTheme?.vertMenuColor,
										}}
									/>
								</ListItemIcon>
							</OfferToolTip>
							<ListItemText
								style={{
									marginLeft: '-5px',
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: selectedTheme?.vertMenuColor,
								}}
							>
								{t('VertMenu.Exportar')}
							</ListItemText>
						</Line>
					</Link>
				</ListItemButton>
				<ListItemButton
					dense={!!denseMenu}
					onClick={() => handleSelectMenuItem('painel')}
					sx={{
						display: userData.roles?.some((role) =>
							[
								'Administrador do cliente',
								'Administrador da ferramenta',
								'Analista do cliente',
								'Desenvolvedor',
							].includes(role)
						)
							? 'flex'
							: 'none',
						background: handleColorForCollapseMenu('painel'),

						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
						},
					}}
				>
					<Link
						to="/painel"
						style={{
							marginLeft: denseMenu ? 3 : 6,
							textDecoration: 'none',
							width: '100%',
						}}
					>
						<Line>
							<OfferToolTip
								placement="right"
								title={`${t('VertMenu.Painel')}`}
								aria-label="Painel"
								arrow
								enterDelay={200}
								enterNextDelay={200}
								TransitionComponent={Zoom}
							>
								<ListItemIcon>
									<Equalizer
										sx={{
											fontSize: denseMenu ? 19 : 24,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme?.textColorHigh
													: selectedTheme?.vertMenuColor,
										}}
									/>
								</ListItemIcon>
							</OfferToolTip>
							<ListItemText
								sx={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: selectedTheme?.vertMenuColor,
									marginLeft: '-5px',
								}}
							>
								{t('VertMenu.Painel')}
							</ListItemText>
						</Line>
					</Link>
				</ListItemButton>
			</Collapse>
			<ListItemButton
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('clientes')}
				sx={{
					display: userData.permissions?.includes('read:clients:admin')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'clientes'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/clientes"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Clientes')}`}
							aria-label="Clientes"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<BusinessCenter
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Clientes')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<ListItemButton
				data-testid="usersMenuItem"
				dense={!!denseMenu}
				onClick={() => handleSelectMenuItem('usuarios')}
				sx={{
					display: userData.permissions?.includes('read:users')
						? 'flex'
						: 'none',
					background:
						activeMenu.selectedMenu === 'usuarios'
							? 'rgba(255,255,255,0.1)'
							: 'none',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.disabledBackground,
					},
				}}
			>
				<Link
					to="/usuarios"
					style={{
						marginLeft: denseMenu ? 3 : 6,
						textDecoration: 'none',
						width: '100%',
					}}
				>
					<Line>
						<OfferToolTip
							placement="right"
							title={`${t('VertMenu.Usuários')}`}
							aria-label="Usuários"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<ListItemIcon>
								<PeopleRounded
									sx={{ fontSize: denseMenu ? 19 : 24 }}
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.vertMenuColor,
									}}
								/>
							</ListItemIcon>
						</OfferToolTip>
						<ListItemText
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: selectedTheme?.vertMenuColor,
								marginLeft: '-5px',
							}}
						>
							{t('VertMenu.Usuários')}
						</ListItemText>
					</Line>
				</Link>
			</ListItemButton>
			<Divider
				sx={{
					opacity: 0.8,
					margin: denseMenu ? '0px 10px 5px 10px' : '15px 20px 10px 20px',
					background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
				}}
			/>
		</div>
	);
};

export default MenuItems;
