import React, { useEffect, useState } from 'react';
import { api } from '../../utils/api';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import {
	Autocomplete,
	Button,
	Card,
	IconButton,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Cached } from '@mui/icons-material/';
import { useDispatch } from 'react-redux';
import { usePO } from '../../utils/POContext';
import { useTranslation } from 'react-i18next';
import AnalystDashboard from './Analyst';
import ClientDashboard from './Client';
import InformativeSnackbar from '../../components/InformativeSnackbar';
import { useSnackbar } from 'notistack';
import { Column, Line } from '../../styles';
import { ptBR, enUS, esES } from '@mui/x-date-pickers/locales';
import { OfferToolTip } from '../../helpers';
import BackdropLoading from '../../components/BackdropLoading';
import AccessDisabledDialog from './AccessDisabledDialog';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone.js';

const Inicio: React.FC = () => {
	const {
		selectedClient,
		updateCursorMode,
		userData,
		selectedTheme,
		loadingContext,
		userActiveClients,
	} = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const nowInBrazil = dayjs()
		.tz('America/Sao_Paulo')
		.format('YYYY-MM-DDTHH:mm:ss');

	const today = new Date();
	const platformOfferFirstDate = '2022-03-01T00:00:00';

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const darkThemeChart = {
		text: {
			fill: selectedTheme.id !== 'dark' ? '#000' : '#fff',
		},
		axis: {
			domain: {
				line: {
					stroke: '#a0a0a0',
				},
			},
			legend: {
				text: {
					fill: selectedTheme.id !== 'dark' ? '#000' : '#fff',
				},
			},
			ticks: {
				line: {
					stroke: '#a0a0a0',
				},
				text: {
					fill: selectedTheme.id !== 'dark' ? '#000' : '#fff',
				},
			},
		},
		grid: {
			line: {
				stroke: '#a0a0a0',
			},
		},
		legends: {
			title: {
				text: {
					fontSize: 18,
				},
			},
			text: {
				fill: selectedTheme.id !== 'dark' ? '#000' : '#fff',
			},
		},
		tooltip: {
			container: {
				background: selectedTheme.id === 'dark' ? '#595959' : '#fff',
				color: selectedTheme.id === 'dark' ? '#fff' : '#000',
			},
		},
	};

	const themeColors = () => {
		switch (selectedTheme.id) {
			case 'red':
				return ['#e65b71', '#af2646', '#79001f', '#723681', '#440754'];
			case 'dark':
				return ['#7ed0ec', '#499fba', '#00708a', '#1d84b5', '#005785'];
			case 'summer':
				return ['#dac242', '#a59201', '#ffb48b', '#e1845d', '#ab5632'];
			case 'winter':
				return ['#82b3ff', '#4984dd', '#0058ab', '#6e3cb9', '#3b0d88'];
			case 'classic':
				return ['#61C2C8', '#409DA3', '#1998A0', '#2F7074', '#204E51'];
			default:
				return ['#D5DB56', '#B3BA22', '#6B76C7', '#4E5ABC', '#384394'];
		}
	};

	const [selectedStartDate, setSelectedStartDate] = useState<string>(
		`${new Date().getFullYear()}-${(new Date().getMonth() + 1)
			.toString()
			.padStart(2, '0')}-01T00:00:00`
	);

	const [selectedFinalDate, setSelectedFinalDate] = useState<string>(
		`${nowInBrazil}`
	);

	const [openAccessModal, setOpenAccessModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [userRoleSelector, setUserRoleSelector] = useState<any>();

	const [leftCardData, setLeftCardData] = useState<any>([]);
	const [middleCardData, setMiddleCardData] = useState<any>([]);
	const [middleFlipCardData, setMiddleFlipCardData] = useState<any>([]);
	const [rightFlipCardData, setRightFlipCardData] = useState<any>([]);
	const [rightCardData, setRightCardData] = useState<any>([]);

	const [calendarChartData, setCalendarChartData] = useState<any>([]);
	const [analystCalendarChartData, setAnalystCalendarChartData] = useState<any>(
		[]
	);

	const [rightBarChartData, setRightBarChartData] = useState<any>([]);
	const [leftBarChartData, setLeftBarChartData] = useState<any>([]);

	const [rightBarChartAnalystData, setRightBarChartAnalystData] = useState<any>(
		[]
	);
	const [leftBarChartAnalystData, setLeftBarChartAnalystData] = useState<any>(
		[]
	);

	const [reloadChartData, setReloadChartData] = useState(0);
	const [datePeriodInCharts, setDatePeriodInCharts] = useState(0);
	const [currentLocaleText, setCurrentLocalText] = useState<any>(ptBR);

	const [filteredYear, setFilteredYear] = useState<number>(today.getFullYear());
	const [openInformativeSnackbar, setOpenInformativeSnackbar] = useState(
		userData.preferences.showInformativeSnackbar ||
			userData.preferences.showInformativeSnackbar === undefined
	);

	useEffect(() => {
		if (loadingContext === false && userActiveClients.length < 1) {
			setOpenAccessModal(true);
		}
	}, [loadingContext]);

	const checkPermission = () => {
		if (
			userData.roles[0] === 'Denunciante' ||
			userData.roles[0] === 'Analista do cliente'
		) {
			return false;
		} else {
			return true;
		}
	};
	const getinitialRole = () => {
		if (
			userData.roles[0] === 'Denunciante' ||
			userData.roles[0] === 'Analista do cliente'
		) {
			setUserRoleSelector({ name: t('Inicio.Cliente'), role: 'analista' });
		} else {
			setUserRoleSelector({ name: t('Inicio.Cliente'), role: 'cliente' });
		}
	};

	const handleCloseAccessModal = () => {
		setOpenAccessModal(false);
	};

	const handleChange = (value: any) => {
		setUserRoleSelector(value);
	};

	const reFetchData = async () => {
		setReloadChartData(reloadChartData + 1);
	};

	const reFetchDatePeriod = async () => {
		setDatePeriodInCharts(datePeriodInCharts + 1);
	};

	const RoleSelectorData = [
		{ name: t('Inicio.Cliente'), role: 'cliente' },
		{ name: t('Inicio.Análise'), role: 'analista' },
	];

	const renderChartsPage = () => {
		switch (userRoleSelector?.role) {
			case 'cliente':
				return renderClientCharts();
			default:
				return renderAnalystCharts();
		}
	};

	const renderClientCharts = () => (
		<ClientDashboard
			darkThemeChart={darkThemeChart}
			themeColors={themeColors}
			calendarChartData={calendarChartData}
			filteredYear={filteredYear}
			leftBarChartData={leftBarChartData}
			leftCardData={leftCardData}
			loading={loading}
			middleCardData={middleCardData}
			middleFlipCardData={middleFlipCardData}
			rightBarChartData={rightBarChartData}
			rightCardData={rightCardData}
			rightFlipCardData={rightFlipCardData}
			setFilteredYear={setFilteredYear}
		/>
	);

	const renderAnalystCharts = () => (
		<AnalystDashboard
			darkThemeChart={darkThemeChart}
			themeColors={themeColors}
			calendarChartData={analystCalendarChartData}
			filteredYear={filteredYear}
			leftBarChartData={leftBarChartAnalystData}
			loading={loading}
			rightBarChartData={rightBarChartAnalystData}
			setFilteredYear={setFilteredYear}
		/>
	);

	useEffect(() => {
		dispatch(changeActiveMenu('inicio'));
		handleLocateDatePicker();
		const fetchData = async () => {
			setLoading(true);
			updateCursorMode('wait');
			setFilteredYear(today.getFullYear());
			try {
				const [
					UserClassificationResponse,
					UserClassificationByClientResponse,
					UserClassificationTotalProfilesResponse,
					UserClassificationByStatusResponse,
					UserClassificationTotalFollowersResponse,
					CalendarChartResponse,
					LeftBarChartResponse,
					RightBarChartResponse,
					AnalystCalendarChartResponse,
					RightBarChartAnalystResponse,
					LeftBarChartAnalystResponse,
				] = await Promise.all([
					api.get(
						`/reports/clients/metrics/classifications?clientID=${selectedClient.id}&startDate=${'2024-02-09T03:00:00.000Z'}&finalDate=${selectedFinalDate}&denounceStatusID=CLASSIFICADA&classificationTypeID=SUSPT`
					),
					api.get(
						`/reports/clients/metrics/classifications?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}&denounceStatusID=CLASSIFICADA&denounceStatusID=NOTIFICADA&denounceStatusID=REMOVIDA`
					),
					api.get(
						`/reports/clients/metrics/classification-total-profiles?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`
					),
					api.get(
						`/reports/clients/metrics/classifications?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}&denounceStatusID=REMOVIDA`
					),
					api.get(
						`/reports/clients/metrics/classification-total-followers?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`
					),
					api.get(
						`/reports/users/metrics/user-daily-denouncements?startDate=${platformOfferFirstDate}&finalDate=${today
							.toISOString()
							.slice(0, 10)}T23:59:59`
					),
					api.get(
						`/reports/clients/metrics/classification-by-country?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`
					),
					api.get(
						`/reports/clients/metrics/classification-by-platform?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`
					),
					api.get(
						`/reports/users/metrics/user-daily-classifications?startDate=${platformOfferFirstDate}&finalDate=${today
							.toISOString()
							.slice(0, 10)}T23:59:59`
					),
					api.get(
						`/reports/users/metrics/clients/tags?startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`
					),
					api.get(
						`/reports/users/metrics/clients/platformINcountry?startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`
					),
				]);
				setLeftCardData(UserClassificationResponse.data.total);
				setMiddleCardData(UserClassificationByClientResponse.data.total);
				setMiddleFlipCardData(
					UserClassificationTotalProfilesResponse.data.total
				);
				setRightCardData(UserClassificationByStatusResponse.data.total);
				setRightFlipCardData(
					UserClassificationTotalFollowersResponse.data.total
				);
				setCalendarChartData(CalendarChartResponse.data.hits);
				setLeftBarChartData(LeftBarChartResponse.data);
				setRightBarChartData(RightBarChartResponse.data);
				setAnalystCalendarChartData(AnalystCalendarChartResponse.data.hits);
				setRightBarChartAnalystData(RightBarChartAnalystResponse.data);
				setLeftBarChartAnalystData(LeftBarChartAnalystResponse.data);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(t('Erro ao obter os dados dos relatórios'));
				enqueueSnackbar(t('Erro interno, tente novamente mais tarde'), {
					variant: 'error',
				});
			}
			updateCursorMode('default');
			setLoading(false);
			if (userRoleSelector === undefined) {
				getinitialRole();
			}
		};
		if (selectedClient) {
			fetchData();
		} else if (loadingContext === false && userActiveClients.length < 1) {
			setOpenAccessModal(true);
		}
		// eslint-disable-next-line
	}, [selectedClient, reloadChartData, datePeriodInCharts, loadingContext]);

	const handleDisableInformativeSnackbar = async () => {
		try {
			await api.patch(`/users/${userData.id}/preferences`, {
				showInformativeSnackbar: false,
			});
		} catch (error) {
			console.log(t('Erro ao atualizar as preferências do usuário'));
		}
	};

	const renderInformativeSnackbar = () => (
		<InformativeSnackbar
			open={openInformativeSnackbar}
			setOpen={setOpenInformativeSnackbar}
			onSnackbarClose={handleDisableInformativeSnackbar}
			message={
				<Typography
					variant="body1"
					align="justify"
					style={{ fontWeight: 'bold' }}
				>
					{t(
						'Inicio.Nosso tema principal mudou! Confira este e outros esquemas de cores na tela de perfil de usuário'
					)}
				</Typography>
			}
		/>
	);

	const handleChangeStartDate = (value) => {
		if (value !== null && value.$d.toString() !== 'Invalid Date') {
			setSelectedStartDate(`${value.$d.toISOString().slice(0, -5)}`);
		}
	};

	const handleChangeFinalDate = (value) => {
		if (value !== null && value.$d.toString() !== 'Invalid Date') {
			setSelectedFinalDate(`${value.$d.toISOString().slice(0, -5)}`);
		}
	};

	const handleLocateDatePicker = () => {
		switch (userData?.i18nID) {
			case 'en-us':
				return setCurrentLocalText(enUS);
			case 'es-es':
				return setCurrentLocalText(esES);

			default:
				return setCurrentLocalText(ptBR);
		}
	};

	const renderReloadChartData = () => (
		<Line style={{ marginTop: -10, justifyContent: 'end' }}>
			<OfferToolTip
				title={`${t('Inicio.Atualizar dados dos gráficos')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					style={{ borderRadius: 5 }}
					size="small"
					onClick={reFetchData}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Typography
						variant="subtitle2"
						style={{
							fontWeight: 'bold',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							margin: '0px 10px',
						}}
					>
						{t('Inicio.Atualizar')}
					</Typography>
					<Cached
						sx={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							animation: loading ? '$spin 2s linear infinite' : 'normal',
							'@keyframes spin': loading
								? {
										'0%': {
											transform: 'rotate(360deg)',
										},
										'100%': {
											transform: 'rotate(0deg)',
										},
									}
								: null,
							zIndex: !loading ? 2 : 'auto',
						}}
					/>
				</IconButton>
			</OfferToolTip>
			{checkPermission() ? (
				<Autocomplete
					id="render-role-selector"
					value={userRoleSelector}
					key={userRoleSelector}
					options={RoleSelectorData}
					getOptionLabel={(option) => option.name}
					style={{ width: 200, marginLeft: 10 }}
					size="small"
					loading={loading}
					loadingText={t('Carregando...')}
					disableClearable
					classes={{
						paper: classes.option,
					}}
					onChange={(_, newValue) => {
						handleChange(newValue);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('Inicio.Seleção de Visualização')}
							variant="outlined"
							inputProps={{
								...params.inputProps,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
							}}
							InputLabelProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
					)}
				/>
			) : null}
		</Line>
	);

	const renderDataPickers = () => {
		if (currentLocaleText !== undefined) {
			return (
				<Line
					style={{ justifyContent: 'space-between', paddingBottom: '10px' }}
				>
					<Line>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							localeText={
								currentLocaleText?.components.MuiLocalizationProvider
									.defaultProps.localeText
							}
						>
							<DateTimePicker
								orientation="landscape"
								label={`${t('Inicio.Data Inicial')}`}
								value={dayjs(selectedStartDate)}
								onChange={(newValue) => handleChangeStartDate(newValue)}
								views={['year', 'month', 'day', 'hours', 'minutes']}
								format="DD/MM/YYYY HH:mm"
								ampm={false}
								openTo="month"
								minDateTime={dayjs(platformOfferFirstDate)}
								maxDateTime={dayjs(today)}
								sx={{
									marginRight: '10px',
									width: '190px',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorHigh,
										height: '40px',
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
							<DateTimePicker
								orientation="landscape"
								label={`${t('Inicio.Data Final')}`}
								value={dayjs(selectedFinalDate)}
								onChange={(newValue) => handleChangeFinalDate(newValue)}
								views={['year', 'month', 'day', 'hours', 'minutes']}
								format="DD/MM/YYYY HH:mm"
								ampm={false}
								openTo="month"
								minDateTime={dayjs(platformOfferFirstDate)}
								maxDateTime={dayjs(today)}
								sx={{
									marginRight: '10px',
									width: '190px',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorHigh,
										height: '40px',
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						</LocalizationProvider>
						<Button
							aria-label="submit-button"
							size="small"
							variant="contained"
							onClick={reFetchDatePeriod}
							sx={{
								border: `1px solid ${
									selectedTheme.id === 'dark'
										? selectedTheme.footerLine
										: 'transparent'
								}`,
								color:
									selectedTheme.tone === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							{t('Inicio.Gerar')}
						</Button>
					</Line>
					{renderReloadChartData()}
				</Line>
			);
		}
	};

	return (
		<>
			{!loadingContext ? (
				<AccessDisabledDialog
					openAccessModal={openAccessModal}
					handleClose={handleCloseAccessModal}
				/>
			) : (
				<BackdropLoading />
			)}

			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
				}}
			>
				<div
					style={{
						overflowY: 'hidden',
						height: '97.5vh',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 20,
					}}
				>
					<Column
						style={{
							height: '100%',
							width: '100%',
						}}
					>
						<>
							{renderDataPickers()}
							{userRoleSelector ? renderChartsPage() : <BackdropLoading />}
							{renderInformativeSnackbar()}
						</>
					</Column>
				</div>
			</Card>
		</>
	);
};

export default Inicio;
