import React, { useEffect, useState } from 'react';
import { MultiDropdownList, ReactiveList } from '@appbaseio/reactivesearch';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../utils/api';

import { useAuth0 } from '../../../react-auth0-spa';
import ResultsTable from './RemovedDenouncementTable';
import { usePO } from '../../../utils/POContext';
import { usePanelControls } from '../../../hooks/PanelControls';
import { getIsProcessing } from '../../../services/notifications/index';
import { enqueueSnackbar } from 'notistack';

interface Props {
	selectedPlatform: any;
}

const Removed: React.FC<Props> = ({ selectedPlatform }: Props) => {
	const { selectedClient, updateCursorMode } = usePO();
	const { t } = useTranslation();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [reloadTable, setReloadTable] = useState(1);

	const handleReloadTable = () => {
		setReloadTable(reloadTable + 1);
	};

	const { setFilterBarStates } = usePanelControls();

	useEffect(() => {
		fetchProcessingCount();
		setFilterBarStates(false);
		// eslint-disable-next-line
	}, [selectedClient.id]);

	const renderLoading = (isLoading) => {
		switch (isLoading) {
			case true:
				updateCursorMode('wait');
				break;
			case false:
				updateCursorMode('inherit');
				break;
			default:
				updateCursorMode('inherit');
				break;
		}
	};

	const fetchProcessingCount = async () => {
		const response = await getIsProcessing(selectedClient?.id);
		if (response && response.success) {
			if (response.data.length !== 0 && response.data.REMOVIDA)
				enqueueSnackbar(
					`${t('Notificações.Tabs.Removidas.Existem')} ${
						response.data.REMOVIDA
					} ${t(
						'Notificações.Tabs.Removidas.ofertas removidas em processamento!'
					)}`,
					{
						variant: 'success',
					}
				);
		}
	};

	const renderResults = (data: any, error: boolean, loading: boolean) => {
		if (selectedClient?.id !== undefined) {
			renderLoading(loading);
		}
		return (
			<ResultsTable
				data={data}
				loading={loading}
				error={error}
				reloadTable={handleReloadTable}
			/>
		);
	};

	const singlePlatformQuery = () => {
		return {
			query: {
				bool: {
					must: [
						{
							term: { 'offerClassification.denounceStatusID': 'REMOVIDA' },
						},
						{ term: { 'offerClassification.clientID': selectedClient?.id } },
						{
							term: { 'classificationType.ISgood': false },
						},
						{ term: { 'offer.platformINcountryID': selectedPlatform } },
					],
					must_not: { term: { ISprocessing: true } },
				},
			},
			size: 0,
		};
	};

	const allPlatformsQuery = () => {
		return {
			query: {
				bool: {
					must: [
						{
							term: { 'offerClassification.denounceStatusID': 'REMOVIDA' },
						},
						{ term: { 'offerClassification.clientID': selectedClient?.id } },
						{
							term: { 'classificationType.ISgood': false },
						},
					],
					must_not: { term: { ISprocessing: true } },
				},
			},
			size: 0,
		};
	};

	return (
		<>
			<MultiDropdownList
				componentId="headerPlatformSelector"
				dataField="offer.platformINcountryID"
				size={10000}
				sortBy="asc"
				queryFormat="or"
				showCount
				showSearch
				placeholder={`${t('Notificações.Tabs.Removidas.Categoria')}:`}
				searchPlaceholder={`${t('Notificações.Tabs.Removidas.Busca')}:`}
				showFilter
				filterLabel={t('Notificações.Tabs.Removidas.Categoria')}
				react={{
					and: [
						'tagsSelect',
						'observationSearch',
						'dateRangeSearch',
						'classificationTypeSearch',
						'sourceSearch',
						'denouncerSearch',
						'searchbox',
						'tagSearch',
						'titleSearch',
						'sellerSearch',
						'citySearch',
						'stateSearch',
						'countrySearch',
						'platformSearch',
					],
				}}
				URLParams={false}
				style={{
					width: 250,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
					padding: 0,
					borderRadius: 4,
					display: 'none',
				}}
			/>
			<ReactiveList
				key={selectedPlatform + reloadTable}
				componentId="ReactiveListResult"
				dataField="*"
				size={10000}
				pagination={false}
				stream={false}
				defaultQuery={
					selectedPlatform === '*' ? allPlatformsQuery : singlePlatformQuery
				}
				showLoader={false}
				react={{
					and: [
						'tagsSelect',
						'observationSearch',
						'dateRangeSearch',
						'classificationTypeSearch',
						'sourceSearch',
						'denouncerSearch',
						'searchbox',
						'tagSearch',
						'titleSearch',
						'sellerSearch',
						'citySearch',
						'stateSearch',
						'countrySearch',
						'platformSearch',
					],
				}}
				renderResultStats={() => {
					return null;
				}}
				renderNoResults={() => null}
				render={({ loading, error, data }) => {
					return renderResults(data, error, loading);
				}}
			/>
		</>
	);
};

export default Removed;
