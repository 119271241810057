import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const submitTags = async (payload: any, selectedClientID: string) => {
	try {
		const tagsResponse = await api.post(
			`/tagINoffer/elasticsearch?clientID=${selectedClientID}`,
			payload,
			{
				timeout: 300 * 1000,
			}
		);
		return {
			success: true,
			status: tagsResponse.status,
			data: tagsResponse.data,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const submitClassifications = async (payload: any) => {
	/** Submitting Cassifications */
	try {
		const response = await api.post(`/offerClassification`, payload, {
			timeout: 600000,
		});
		return {
			success: true,
			status: response.status,
			data: response.data,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
