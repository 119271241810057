import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';

import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { GetApp } from '@mui/icons-material';

const PlatformsReports = () => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const renderPlatformsReportActions = () => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					padding: '20px',
					gap: '15px',
					height: 500,
					overflow: 'scroll',
				}}
			>
				<div style={{ height: '45px', width: '45%', display: 'flex' }}>
					<ListItemButton
						sx={{
							borderRadius: '4px',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							'&.MuiListItemButton-root:hover': {
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.primaryLight
										: selectedTheme.primary,
							},
						}}
					>
						<ListItemIcon sx={{ minWidth: '0px' }}>
							<GetApp style={{ color: '#fff' }} />
						</ListItemIcon>
						<ListItemText sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography>
								{t('Relatórios.Remoções X Plataformas').toUpperCase()}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</div>
				<div style={{ height: '45px', width: '45%', display: 'flex' }}>
					<ListItemButton
						sx={{
							borderRadius: '4px',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							'&.MuiListItemButton-root:hover': {
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.primaryLight
										: selectedTheme.primary,
							},
						}}
					>
						<ListItemIcon sx={{ minWidth: '0px' }}>
							<GetApp style={{ color: '#fff' }} />
						</ListItemIcon>
						<ListItemText sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography>
								{t('Relatórios.Remoções X Plataformas por país').toUpperCase()}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</div>
				<div style={{ height: '45px', width: '45%', display: 'flex' }}>
					<ListItemButton
						sx={{
							borderRadius: '4px',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							'&.MuiListItemButton-root:hover': {
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.primaryLight
										: selectedTheme.primary,
							},
						}}
					>
						<ListItemIcon sx={{ minWidth: '0px' }}>
							<GetApp style={{ color: '#fff' }} />
						</ListItemIcon>
						<ListItemText sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography>
								{t('Relatórios.Remoções X Marketplaces').toUpperCase()}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</div>
				<div style={{ height: '45px', width: '45%', display: 'flex' }}>
					<ListItemButton
						sx={{
							borderRadius: '4px',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							'&.MuiListItemButton-root:hover': {
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.primaryLight
										: selectedTheme.primary,
							},
						}}
					>
						<ListItemIcon sx={{ minWidth: '0px' }}>
							<GetApp style={{ color: '#fff' }} />
						</ListItemIcon>
						<ListItemText sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography>
								{t('Relatórios.Remoções X Mídias Sociais').toUpperCase()}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</div>
				<div style={{ height: '45px', width: '45%', display: 'flex' }}>
					<ListItemButton
						sx={{
							borderRadius: '4px',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							'&.MuiListItemButton-root:hover': {
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.primaryLight
										: selectedTheme.primary,
							},
						}}
					>
						<ListItemIcon sx={{ minWidth: '0px' }}>
							<GetApp style={{ color: '#fff' }} />
						</ListItemIcon>
						<ListItemText sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography>
								{t('Relatórios.Remoções X Categoria').toUpperCase()}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</div>
				<div style={{ height: '45px', width: '45%', display: 'flex' }}>
					<ListItemButton
						sx={{
							borderRadius: '4px',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							'&.MuiListItemButton-root:hover': {
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.primaryLight
										: selectedTheme.primary,
							},
						}}
					>
						<ListItemIcon sx={{ minWidth: '0px' }}>
							<GetApp style={{ color: '#fff' }} />
						</ListItemIcon>
						<ListItemText sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography>
								{t('Relatórios.Remoções X SKU').toUpperCase()}
							</Typography>
						</ListItemText>
					</ListItemButton>
				</div>
			</div>
		);
	};
	return renderPlatformsReportActions();
};

export default PlatformsReports;
