import styled from 'styled-components';
import { Button } from '@mui/material';

export const Content = styled.div`
	z-index: 1;
	left: 60%; /* Ajuste a posição à esquerda conforme necessário */
	top: 25%;
	background: #2964cc;
	border-radius: 8px;
	padding: 16px;
	text-align: center;
`;

export const Title = styled.h1`
	color: #fff;
	font-size: 80px;
`;

export const WelcomeMessage = styled.h4`
	color: #f7f7f7;
	margin-bottom: 32px;
`;

export const DisconectAccount = styled.h3`
	margin: 0px;
	cursor: pointer;
	text-decoration-line: underline;
	color: #fff;
	padding: 12px 4px;
	font-size: 14px;
	&:hover {
		color: #dbdbdb;
	}
`;

export const LoginButton = styled(Button).attrs({
	variant: 'outlined',
	size: 'large',
	href: '/',
})`
	&& {
		background-color: #fff;
		color: #2964cc;
		display: flex;
		align-items: center;
		padding: 12px 20px;
		&&:hover {
			background-color: #dbdbdb;
			color: #fff;
		}
	}
`;
