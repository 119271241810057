import React, { useState } from 'react';
import { Column } from '../../../../styles';
import UpperCards from './UpperCard';
import LowerCard from './LowerCard';

interface Props {
	offerData: any;
	denounceValue: any;
	setDenounceValue: (value) => void;
	denounceObs: any;
	setDenounceObs: (value) => void;
}

const ClassificationModalLeft: React.FC<Props> = ({
	offerData,
	denounceValue,
	setDenounceValue,
	setDenounceObs,
	denounceObs,
}) => {
	const [expandCard, setExpandCard] = useState(false);

	return (
		<Column
			style={{
				height: 1000,
				justifyContent: 'flex-start',
				rowGap: 10,
				overflow: 'hidden',
			}}
		>
			<UpperCards
				offerData={offerData}
				expandCard={expandCard}
				handleExpandCard={setExpandCard}
			/>
			<LowerCard
				offerData={offerData}
				expandCard={expandCard}
				handleExpandCard={setExpandCard}
				denounceValue={denounceValue}
				setDenounceValue={setDenounceValue}
				denounceObs={denounceObs}
				setDenounceObs={setDenounceObs}
			/>
		</Column>
	);
};
export default ClassificationModalLeft;
