import React from 'react';
import { Chip, Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../utils/POContext';

type SizeType = 'small' | 'medium';

interface Props {
	setDataArray: (data) => void;
	dataArray: any;

	disabled?: boolean;
	fieldName?: string;
	height?: string;
	loading?: boolean;
	loadingText?: string;
	maxItems?: number;
	multiLine?: boolean;
	noAutoComplete?: boolean;
	readOnly?: boolean;
	rows?: number;
	select?: boolean;
	size?: SizeType;
	suggestions?: any;
	width?: string;
	colorChip?: string;
	formErrors?: any;
}
/**
 * @Componente ValuesOnTags
 * @API https://docs.offertech.com.br/doc/valuesontags-dK7xuybuvj
 * //
 * @setDataArray (Obrigatório) Função 'setState' que controla a variável de estado que recebe os dados contidos do array de itens
 * @dataArray (Obrigatório) Variável de estado que recebe os dados contidos do array de itens
 * @maxItems Limita o número de itens permitidos no array
 * @noAutoComplete Desativa a função de sugestões de entrada
 * @suggestions Array contendo as sugestões de entrada
 * @readOnly Define o componente como somente leitura
 */
const InsertValuesOnTags: React.FC<Props> = ({
	dataArray,
	disabled,
	fieldName = 'multiImput',
	height = '100%',
	loading,
	loadingText,
	maxItems,
	multiLine,
	noAutoComplete,
	readOnly,
	rows,
	select,
	setDataArray,
	size = 'small',
	suggestions = ['Please, provide array with suggestions'],
	width = '100%',
	colorChip,
	formErrors,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const useStyles = makeStyles(() => ({
		inputRootAutoComplete: {
			background: selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
		},
	}));

	const classes = useStyles();

	const checkInputIsFull = () => {
		if (maxItems && dataArray.length < maxItems) {
			return true;
		}
		return false;
	};

	const renderPlaceholder = () => {
		if (dataArray.length === 0) {
			return t(`Digite o termo e pressione 'Enter' para confirmar`);
		} else if (checkInputIsFull()) {
			return `${dataArray.length}/${maxItems}`;
		}
		return '';
	};

	const renderComponent = () => {
		if (select) {
			return renderSelector();
		} else {
			return renderAutoComplete();
		}
	};

	const renderSelector = () => (
		<>
			<Autocomplete
				size={size}
				value={dataArray}
				onChange={(_, newValue) => {
					setDataArray(newValue);
				}}
				limitTags={maxItems}
				disabled={disabled}
				id="controllable-states-selector"
				options={suggestions}
				disableClearable
				loading={loading}
				loadingText={loadingText}
				getOptionLabel={(option) => (option as { name: string }).name}
				renderOption={(props, option) => <li {...props}>{option.name}</li>}
				style={{ width }}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						multiline={multiLine}
						rows={rows}
						value={dataArray?.name}
						inputProps={{
							readOnly,
							...params.inputProps,
						}}
						style={{ width }}
						name={fieldName}
					/>
				)}
			/>
			<TextField
				value={dataArray?.name}
				name={fieldName}
				style={{ display: 'none' }}
				variant={'outlined'}
			/>
		</>
	);

	const renderAutoComplete = () => (
		<>
			<Autocomplete
				size={size}
				open={!noAutoComplete}
				multiple
				disabled={disabled}
				limitTags={maxItems}
				autoComplete
				autoHighlight
				clearOnBlur
				clearOnEscape
				disableClearable
				loading={loading}
				loadingText={loadingText}
				forcePopupIcon={false}
				value={[...dataArray]}
				filterSelectedOptions
				id="searchTerms"
				options={suggestions}
				freeSolo={checkInputIsFull()}
				onChange={(_, newValue) => {
					setDataArray(newValue);
					checkInputIsFull();
				}}
				style={{ width }}
				classes={{
					inputRoot: classes.inputRootAutoComplete,
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							{...getTagProps({ index })}
							key={option}
							variant="outlined"
							label={option}
							deleteIcon={
								<CloseRounded
									fontSize="small"
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: selectedTheme?.foreground,
									}}
								/>
							}
							style={{
								height: 30,
								margin: 2,
								backgroundColor: colorChip || selectedTheme.primary,
								borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
								borderRadius: '4px',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: selectedTheme.foreground,
								fontSize: 15,
							}}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						multiline={multiLine}
						rows={rows}
						name={fieldName}
						inputProps={{
							readOnly,
							...params.inputProps,
							maxLength: checkInputIsFull() ? 256 : 0,
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						sx={{
							'.MuiFormHelperText-root.Mui-error': {
								mt: '0px',
								color: selectedTheme.error,
								borderColor: selectedTheme.error,
							},
							'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
								{
									borderColor: selectedTheme.error,
								},
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
						error={formErrors}
						helperText={formErrors}
						placeholder={renderPlaceholder()}
						style={{
							height,
							width,
						}}
					/>
				)}
			/>
			<TextField
				value={dataArray}
				name={fieldName}
				style={{ display: 'none' }}
				variant={'outlined'}
			/>
		</>
	);

	return renderComponent();
};
export default InsertValuesOnTags;
