import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'vertMenu',
	initialState: {
		menuState: false,
	},
	reducers: {
		changeStateMenu(state, { payload }) {
			return { ...state, menuState: payload };
		},
	},
});

export const { changeStateMenu } = slice.actions;

export const menuStateItem = (state) => state;

export default slice.reducer;
