import React from 'react';
import { Column, Line } from '../../../../../styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { usePO } from '../../../../../utils/POContext';
import { t } from 'i18next';
import ShortCutIcon from '../../../../../components/ShortCutModal/ShortCutIcon';

const InformationModal = ({ dialogInfoOpen, handleCloseDialog }) => {
	const { selectedTheme } = usePO();

	const handleHeaderColor = () => {
		if (selectedTheme.id === 'main') {
			return selectedTheme.primary;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.foreground;
		}
	};

	const renderHeaderGuide = () => (
		<Line
			style={{
				justifyContent: 'space-between',
			}}
		>
			<Typography
				noWrap
				style={{
					fontSize: 20,
					fontWeight: 'bold',
					maxWidth: '90%',
					color: handleHeaderColor(),
				}}
			>
				{t('Entidades.Impressão.Guia para impressão')}
			</Typography>
			<IconButton
				onClick={handleCloseDialog}
				style={{ marginRight: '-16px' }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<CloseRounded
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				/>
			</IconButton>
		</Line>
	);

	const renderDialogContentGuide = () => (
		<Column style={{ gap: 25, width: 600 }}>
			<>
				<Line style={{ justifyContent: 'center', width: '100%' }}>
					<Typography
						style={{
							fontSize: 20,
							maxWidth: '100%',
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						{t('Entidades.Impressão.Para imprimir a página aperte')}
						<ShortCutIcon keyText={'Ctrl'} />
						{'+'}
						<ShortCutIcon keyText={'P'} />
					</Typography>
				</Line>

				<Divider
					style={{
						width: '100%',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
					}}
				/>
				<Line style={{ justifyContent: 'center', width: '100%' }}>
					<Typography
						style={{
							fontSize: 20,
							maxWidth: '100%',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						{t(
							'Entidades.Impressão.Retirar as margens nas configurações de impressão'
						)}
					</Typography>
				</Line>
				<Divider
					style={{
						width: '100%',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
					}}
				/>
				<Line style={{ justifyContent: 'center', width: '100%' }}>
					<Typography
						style={{
							fontSize: 20,
							maxWidth: '100%',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						{t('Entidades.Impressão.Layout da impressão no modo retrato')}
					</Typography>
				</Line>
			</>
		</Column>
	);

	const renderFooterGuide = () => (
		<Line
			style={{
				justifyContent: 'flex-end',
			}}
		>
			<Button
				size="small"
				onClick={handleCloseDialog}
				style={{
					color: selectedTheme.error,
				}}
				data-testid="closeModal"
			>
				{t('Fechar')}
			</Button>
		</Line>
	);
	return (
		<Dialog
			open={dialogInfoOpen}
			onClose={handleCloseDialog}
			disableEscapeKeyDown
			maxWidth="xl"
		>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				{renderHeaderGuide()}
			</DialogTitle>
			<DialogContent
				style={{
					padding: '25px 30px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground,
				}}
			>
				{renderDialogContentGuide()}
			</DialogContent>
			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					padding: '10px 15px',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				{renderFooterGuide()}
			</DialogActions>
		</Dialog>
	);
};

export default InformationModal;
