import { createTheme } from '@mui/material';

declare module '@mui/material' {
	interface ThemeOptions {
		themeName?: string; // optional
	}
}

const palette = {
	primary: { main: '#265C60' },
	secondary: { main: '#cf3227' },
};
const themeName = 'Daisy Bush Maroon Flush Trumpeter';

export default createTheme({ palette, themeName });
