import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import {
	CheckCircle,
	Cancel,
	NewReleases,
	VerifiedUser,
} from '@mui/icons-material/';
import {
	Box,
	Divider,
	IconButton,
	Link,
	Typography,
	Zoom,
	Rating,
} from '@mui/material';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { OfferToolTip, converteNomeParaSigla } from '../../../../../../helpers';
import { Line } from '../../../../../../styles';
import { usePO } from '../../../../../../utils/POContext';
import BarChart from '../RatingsChart';

interface Props {
	data: any;
}

const DetailsShopee: React.FC<Props> = ({ data }) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	return (
		<>
			<Line style={{ justifyContent: 'space-between' }}>
				<Line style={{ columnGap: 10, justifyContent: 'flex-start' }}>
					{data.author.authorData.verified?.value ? (
						<>
							<CheckCircle
								style={{
									color: selectedTheme.success,
									fontSize: 16,
									marginTop: -2,
								}}
							/>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Classificações.Loja verificada')}
							</Typography>
						</>
					) : (
						<>
							<Cancel
								style={{
									color: selectedTheme.error,
									fontSize: 16,
								}}
							/>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Classificações.Loja não verificada')}
							</Typography>
						</>
					)}
				</Line>
				<Line
					style={{
						columnGap: 10,
						justifyContent: 'center',
					}}
				>
					{data.author.authorData.official?.value ? (
						<>
							<VerifiedUser
								style={{
									color: selectedTheme.success,
									fontSize: 16,
								}}
							/>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Classificações.Loja oficial')}
							</Typography>
						</>
					) : (
						<>
							<NewReleases
								style={{
									color: selectedTheme.error,
									fontSize: 16,
								}}
							/>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Classificações.Loja não oficial')}
							</Typography>
						</>
					)}
				</Line>
			</Line>
			<Divider
				sx={{ opacity: 0.6 }}
				variant="middle"
				style={{ margin: '15px 0 5px 0' }}
			/>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{
						justifyContent: 'center',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Vendedor')}:
					</Typography>
					<IconButton
						style={{
							transform: 'rotate(45deg)',
							marginRight: -5,
						}}
						href={data.author.permalink}
						target="blank"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LinkIcon
							style={{
								fontSize: 15,
								color: selectedTheme.primary,
							}}
						/>
					</IconButton>
					<OfferToolTip
						title={data.author.authorData.nickname?.value}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						TransitionComponent={Zoom}
						arrow
					>
						<Box
							maxWidth="220px"
							overflow="hidden"
							textOverflow="ellipsis"
							whiteSpace="nowrap"
						>
							<Link
								href={data.author.permalink}
								variant="subtitle2"
								color="inherit"
								target="blank"
							>
								<Typography noWrap variant="subtitle2">
									{data.author.authorData.nickname?.value}
								</Typography>
							</Link>
						</Box>
					</OfferToolTip>
				</Line>
				{data.author.authorData.rating?.value ? (
					<Line style={{ justifyContent: 'flex-end', columnGap: 10 }}>
						<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
							{t('Classificações.Avaliação')}:
						</Typography>
						<Typography variant="subtitle2">{`(${data.author.authorData.rating?.value})`}</Typography>
						<Rating
							size="small"
							name="shop_rating"
							defaultValue={parseFloat(data.author.authorData.rating?.value)}
							precision={0.2}
							readOnly
							style={{ marginTop: -2 }}
						/>
					</Line>
				) : null}
			</Line>
			<Line style={{ justifyContent: 'space-between', marginTop: 6 }}>
				<Line
					style={{
						width: '33%',
						justifyContent: 'center',
						columnGap: 10,
						alignItems: 'center',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Seguidores')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{data.author.authorData.followers?.value}
					</Typography>
				</Line>
				<Line
					style={{
						width: '33%',
						justifyContent: 'center',
						columnGap: 10,
						alignItems: 'center',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Anúncios')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{data.author.authorData.products?.value}
					</Typography>
				</Line>
				<Line
					style={{
						width: '33%',
						justifyContent: 'center',
						columnGap: 10,
						alignItems: 'center',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Itens vendidos')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{data.offerData.items_sold.value}
					</Typography>
				</Line>
			</Line>
			<Line style={{ justifyContent: 'space-between', marginTop: 15 }}>
				<Line
					style={{
						width: '33%',
						justifyContent: 'center',
						columnGap: 10,
						alignItems: 'center',
						flexFlow: 'column wrap',
					}}
				>
					{data.author.stateName ? (
						<OfferToolTip
							title={`${data.author.cityName}, ${data.author.stateName}`}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							TransitionComponent={Zoom}
							arrow
						>
							<Box
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
							>
								<Typography noWrap variant="subtitle2">
									{`${data.author.cityName},`}
								</Typography>
								<Typography noWrap variant="subtitle2">
									{converteNomeParaSigla(data.author.stateName)}
								</Typography>
							</Box>
						</OfferToolTip>
					) : (
						<>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Localidade')}:
							</Typography>
							<Typography noWrap variant="subtitle2">
								{data.author.cityName !== null
									? `${data.author.cityName}`
									: t('Classificações.Não disponível')}
							</Typography>
						</>
					)}
				</Line>
				<Line
					style={{
						width: '33%',
						justifyContent: 'center',
						columnGap: 10,
						alignItems: 'center',
						flexFlow: 'column wrap',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Loja criada em')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{dateFormat(data.author.created, 'dd/mm/yyyy')}
					</Typography>
				</Line>
				<Line
					style={{
						width: '33%',
						justifyContent: 'center',
						columnGap: 10,
						alignItems: 'center',
						flexFlow: 'column wrap',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Última edição')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{dateFormat(data.author.updated, 'dd/mm/yyyy')}
					</Typography>
				</Line>
			</Line>
			<Divider
				sx={{ opacity: 0.6 }}
				variant="middle"
				style={{ margin: '20px 0 5px 0' }}
			/>
			<Line style={{ justifyContent: 'flex-start', columnGap: 10 }}>
				<Typography noWrap variant="subtitle2" style={{ fontWeight: 'bold' }}>
					{t('Classificações.Avaliações desta loja')}:
				</Typography>
				<Line style={{ height: 50, width: 300 }}>
					<BarChart data={data} />
				</Line>
			</Line>
		</>
	);
};
export default DetailsShopee;
