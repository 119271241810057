import React, { useState } from 'react';
import { Box, IconButton, Typography, Zoom } from '@mui/material';
import {
	Cached,
	FirstPage,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage,
	PlaylistAddRounded,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import OfferTable from '../../components/OfferTable';
import { OfferToolTip } from '../../helpers';
import { useAuth0 } from '../../react-auth0-spa';
import { Line } from '../../styles';
import { api, setupRequestToken } from '../../utils/api';
import { usePO } from '../../utils/POContext';
import SelectActiveClient from '../../components/SelectClient';
import OpenCurrentDenouncements from './OpenCurrentDenouncements';
import ImagePreview from './ImagePreview';
import TableSearch from '../../components/TableSearch';
import FilterBar from './DenouncementsFilterBar';
import BackDropLoading from '../../components/BackdropLoading';
import Unauthorized from '../Page401';

const Denuncias: React.FC = () => {
	const { selectedClient, updateCursorMode, selectedTheme, userData } = usePO();
	const { t } = useTranslation();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [tableData, setTableData] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	/** API pagination table controls */
	const [tableLength, setTableLength] = useState(1);
	const [displayCurrentPage, setDisplayCurrentPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageChange, setCurrentPageChange] = useState(0);
	const [searchText, setSearchText] = useState<string | null>(null);
	const [refreshTable, setRefreshTable] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1
	);
	const rowsPerPageOptions = [
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1,
		50,
		100,
	];
	const [isFilterBarOpen, setIsFilterBarOpen] = useState(false);

	const handleOpenFilterBar = () => {
		setIsFilterBarOpen(!isFilterBarOpen);
	};

	const colsData = [
		{
			columnTitle: t('Denúncias.Título'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 150,
			dataType: 'url',
		},
		{
			columnTitle: t('Denúncias.Status'),
			fieldName: 'denounceStatusID',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Denúncias.Origem'),
			fieldName: 'sourceID',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Denúncias.Denunciante'),
			fieldName: 'userID',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 100,
			dataType: 'userID',
		},
		{
			columnTitle: t('Denúncias.Plataforma'),
			fieldName: 'platformINcountryID',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
			dataType: 'platformINcountry',
		},
		{
			columnTitle: t('Denúncias.Data'),
			fieldName: 'updated',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 90,
			dataType: 'date',
		},
	];

	const buildTableData = async (data: any, totalHits?: number) => {
		if (totalHits) {
			setTableLength(totalHits);
		}
		setDisplayCurrentPage(currentPage);
		setLoading(true);
		updateCursorMode('wait');
		if (data.map) {
			setTableData([]);
			const tempData: any = [];
			data.map((el) => {
				const formatDenouncement = {
					title: el.title,
					id: el.id,
					url: el.url,
					userID: el.userID,
					sourceID: el.sourceID,
					screenshot: el.screenshot,
					denounceStatusID: el.denounceStatusID,
					platformINcountryID: el.platformINcountryID,
					updated: el.updated,
				};
				tempData.push(formatDenouncement);
				return null;
			});
			setTableData(tempData);
		}
		setLoading(false);
		updateCursorMode('default');
	};

	const handleRefreshTable = async () => {
		setRefreshTable(refreshTable + 1);
	};

	const handleChangeRowsPerPage = (event) => {
		if (currentPage > 0) {
			setCurrentPage(0);
			setDisplayCurrentPage(0);
			setLoading(true);
			setRowsPerPage(event.target.value);
		}
		if (currentPage === 0) {
			setRowsPerPage(event.target.value);
		}
	};

	const handleChangePage = (
		event: any,
		newPage: React.SetStateAction<number>
	) => {
		setLoading(true);
		setCurrentPage(newPage);
		setCurrentPageChange(currentPageChange + 1);
	};

	const handleKeyArrowStyle = (disableVerification) => {
		if (disableVerification) {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '';
		} else {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '';
		}
	};

	function TablePaginationActions() {
		const handleFirstPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, 0);
		};

		const handleBackButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage - 1);
		};

		const handleNextButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage + 1);
		};

		const handleLastPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(
				event,
				Math.max(0, Math.ceil(tableLength / rowsPerPage) - 1)
			);
		};

		return (
			<Box style={{ flexShrink: 0, marginLeft: 30 }}>
				<Line>
					<IconButton
						onClick={handleFirstPageButtonClick}
						disabled={currentPage === 0}
						aria-label="first page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FirstPage
							style={{
								color: handleKeyArrowStyle(currentPage === 0),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleBackButtonClick}
						disabled={currentPage === 0}
						aria-label="previous page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowLeft
							style={{
								color: handleKeyArrowStyle(currentPage === 0),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleNextButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="next page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowRight
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleLastPageButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="last page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LastPage
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
				</Line>
			</Box>
		);
	}

	const renderFilter = () => (
		<Line>
			<Typography
				style={{
					margin: '0 5px 0 20px',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
				}}
			>
				{t('Denúncias.Cliente')}:
			</Typography>
			<SelectActiveClient width="200px" />
		</Line>
	);

	const renderLeftFooterComponents = () => (
		<Line>
			<OfferToolTip
				title={`${t('Denúncias.Atualizar dados da tabela')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handleRefreshTable}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Cached
						sx={{
							animation: loading ? '$spin 2s linear infinite' : 'normal',
							'@keyframes spin': loading
								? {
										'0%': {
											transform: 'rotate(360deg)',
										},
										'100%': {
											transform: 'rotate(0deg)',
										},
									}
								: null,
							zIndex: !loading ? 2 : 'auto',
							fontSize: '22px',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<OfferToolTip
				title={
					!isFilterBarOpen
						? t('Mostrar barra de filtros')
						: t('Ocultar barra de filtros')
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handleOpenFilterBar}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<PlaylistAddRounded
						style={{
							marginRight: -5,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</Line>
	);

	return userData.permissions?.indexOf('read:denouncements') !== -1 ? (
		<div>
			<OfferTable
				customTablePagination={TablePaginationActions}
				customTableLength={tableLength}
				customSearch={
					<TableSearch
						setSearchText={setSearchText}
						customTooltip="Termos presentes no título da denúncia"
						loading={loading}
					/>
				}
				customRowsPerPageOptions={rowsPerPageOptions}
				customRowsPerPage={rowsPerPage}
				handleChangeCustomRowsPerPage={handleChangeRowsPerPage}
				customCurrentPage={displayCurrentPage}
				colsData={colsData}
				tableData={tableData}
				windowHeader
				windowTitle={t('Denúncias.Gerenciamento de Denúncias')}
				tableActions
				tableFooter
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient
						? loading
							? t('Carregando...')
							: t('Denúncias.Sem denúncias para este cliente')
						: t('Denúncias.Selecione um cliente')
				}
				customLeftFooterComponent={renderLeftFooterComponents()}
				customRightFooterComponent={renderFilter()}
				ActionComponent1={OpenCurrentDenouncements}
				ActionComponent2={ImagePreview}
			/>
			<FilterBar
				buildTableData={buildTableData}
				currentPage={currentPage}
				isFilterBarOpen={isFilterBarOpen}
				loading={loading}
				refreshTable={refreshTable}
				rowsPerPage={rowsPerPage}
				searchText={searchText || ''}
				setIsFilterBarOpen={setIsFilterBarOpen}
				setLoading={setLoading}
			/>
			<div>
				{tableData.length > 0 && loading && <BackDropLoading smallBackdrop />}
			</div>
		</div>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default Denuncias;
