import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	Avatar,
	DialogContent,
	DialogActions,
	Typography,
	Checkbox,
	Autocomplete,
	TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import FormDivider from '../FormDivider';
import LoadingDots from '../LoadingDots';
import { Column, Line } from '../../styles';
import { useAuth0 } from '../../react-auth0-spa';
import { usePO } from '../../utils/POContext';
import { api, isAxiosError, setupRequestToken } from '../../utils/api';
import { ErrorResponse, LanguageType, ThemeType } from '../../interfaces';
import offerThemes from '../../Themes/offerThemes.json';
import { makeStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';

interface Props {
	open: boolean;
	close: () => void;
}

const UserProfileModal: React.FC<Props> = ({ open, close }: Props) => {
	const {
		userData,
		updateUserData,
		languages,
		selectedTheme,
		setSelectedTheme,
	} = usePO();
	const { t } = useTranslation();
	const { token, user } = useAuth0();

	setupRequestToken(api, token);

	interface UserType {
		name: string;
		lastName: string;
		email: string;
		i18nID: string;
	}

	const [loading, setLoading] = useState(false);
	const [loadingPasswordChange, setLoadingPasswordChange] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState<any>([]);
	const [checkedShowTour, setCheckedShowTour] = useState(userData.tour);

	const [formData, setFormData] = useState<UserType>({
		name: userData?.name,
		lastName: userData?.lastName,
		email: userData?.email,
		i18nID: userData.i18nID,
	});
	const [formErrors, setFormErrors] = useState<any>({});

	const handleChangeShowTour = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedShowTour(event.target.checked);
	};

	useEffect(() => {
		languages?.map((e) => {
			if (e.id.indexOf(userData.i18nID) !== -1) {
				setFormData({ ...formData, i18nID: e.id });
				setSelectedLanguage(e);
				i18n.changeLanguage(e.code);
			}
			return null;
		});
	}, [languages, userData.i18nID]);

	const handleClose = () => {
		close();
	};

	const handleSaveData = async () => {
		setLoading(true);
		try {
			const response = await api.patch(`/users/${userData.id}`, formData);
			await api.patch(`/users/${userData.id}/preferences`, {
				selectedTheme: selectedTheme.id,
			});
			enqueueSnackbar(response.data.detail, { variant: 'success' });
			updateUserData();
			setLoading(false);
			handleClose();
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		}
	};

	const handleChangePassword = async () => {
		setLoadingPasswordChange(true);
		try {
			const response = await api.post(`/users/manage-users/password`);
			enqueueSnackbar(response.data.detail, { variant: 'success' });
			setLoadingPasswordChange(false);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		}
	};

	const handleChangeLanguage = (language) => {
		setFormData({ ...formData, i18nID: language.id });
		setSelectedLanguage(language);
		i18n.changeLanguage(language.code);
	};

	async function handleSubmit() {
		try {
			const schema = Yup.object().shape({
				name: Yup.string()
					.min(2, t('ProfileModal.O nome precisa ter no mínimo 2 caracteres'))
					.required(t('ProfileModal.O nome é obrigatório')),
				lastName: Yup.string()
					.min(
						2,
						t('ProfileModal.O sobrenome precisa ter no mínimo 2 caracteres')
					)
					.required(t('ProfileModal.O sobrenome é obrigatório')),
			});
			await schema.validate(formData, {
				abortEarly: false,
			});
			setFormErrors({});
			handleSaveData();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const classes = useStyles();

	const renderHeader = () => (
		<Line
			style={{
				height: 120,
				width: 450,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.tableHead
						: selectedTheme.gradient,
				justifyContent: 'center',
				boxShadow: ' 0px 2px 3px rgba(0, 0, 0, 0.3)',
			}}
		>
			<Avatar
				style={{
					width: 120,
					height: 120,
					marginBottom: -40,
					boxShadow: ' 2px 2px 3px rgba(0, 0, 0, 0.3)',
				}}
				alt={user.name}
				src={user.picture}
			/>
		</Line>
	);

	const renderForm = () => (
		<Column style={{ padding: '40px 20px 10px 20px' }}>
			<TextField
				aria-label="name"
				data-testid="nameID"
				variant="outlined"
				size="small"
				name="name"
				error={formErrors.name}
				helperText={formErrors.name}
				defaultValue={userData?.name}
				onChange={(e) => {
					setFormData({ ...formData, name: e.target.value });
				}}
				label={t('ProfileModal.Nome')}
				autoComplete="off"
				inputProps={{
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
					},
				}}
				InputLabelProps={{
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					},
				}}
				sx={{
					width: '100%',
					'& .MuiOutlinedInput-root': {
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
					},
					'& .MuiInputBase-root': {
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
			/>
			<TextField
				aria-label="lastName"
				data-testid="lastNameID"
				variant="outlined"
				size="small"
				name="lastName"
				error={formErrors.lastName}
				helperText={formErrors.lastName}
				defaultValue={userData?.lastName}
				onChange={(e) => {
					setFormData({ ...formData, lastName: e.target.value });
				}}
				label={t('ProfileModal.Sobrenome')}
				autoComplete="off"
				inputProps={{
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
					},
				}}
				InputLabelProps={{
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					},
				}}
				sx={{
					width: '100%',
					marginTop: '20px',
					'& .MuiOutlinedInput-root': {
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
					},
					'& .MuiInputBase-root': {
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
			/>
			<TextField
				aria-label="email"
				data-testid="emailID"
				variant="outlined"
				disabled
				size="small"
				name="email"
				error={formErrors.email}
				helperText={formErrors.email}
				defaultValue={userData?.email}
				onChange={(e) => {
					setFormData({ ...formData, email: e.target.value });
				}}
				label={t('ProfileModal.E-mail')}
				InputLabelProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					},
				}}
				autoComplete="off"
				InputProps={{
					readOnly: true,
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
				}}
				sx={{
					width: '100%',
					marginTop: '20px',
					background:
						selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor:
							selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '',
					},
					'& .MuiInputBase-root.Mui-disabled': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
							},
						},
					},
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
					'& .Mui-disabled .MuiSvgIcon-root': {
						fill:
							selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '',
					},
					'.MuiSvgIcon-root ': {
						fill:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
				}}
			/>
			<FormDivider
				name={t('ProfileModal.Preferências')}
				padding="5px 0px 0px 0px"
				color={
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.disableBackground
				}
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.background
				}
			/>
			<Autocomplete
				classes={{
					paper: classes.option,
				}}
				id="language-select"
				data-testid="languageID"
				style={{ width: '100%', marginTop: 5 }}
				options={languages as LanguageType[]}
				onChange={(_, value) => {
					handleChangeLanguage(value);
				}}
				value={selectedLanguage}
				autoHighlight
				autoComplete
				autoSelect
				selectOnFocus
				disableClearable
				getOptionLabel={(option) => (option as { name: string }).name}
				renderInput={(params) => (
					<TextField
						{...params}
						aria-label="language"
						name="language"
						size="small"
						label={t('ProfileModal.Idioma')}
						variant="outlined"
						inputProps={{
							...params.inputProps,
							autoComplete: 'new-password', // disable autocomplete and autofill
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: '',
							},
						}}
						InputLabelProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						sx={{
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
					/>
				)}
			/>
			<Autocomplete
				classes={{
					paper: classes.option,
				}}
				id="theme-select"
				data-testid="ThemeID"
				style={{ width: '100%', marginTop: 20 }}
				options={offerThemes as ThemeType[]}
				value={selectedTheme}
				onChange={(_, value) => {
					setSelectedTheme(value);
				}}
				autoHighlight
				autoComplete
				autoSelect
				selectOnFocus
				disableClearable
				getOptionLabel={(option) => (option as { name: string }).name}
				renderInput={(params) => (
					<TextField
						{...params}
						aria-label="theme"
						name="theme"
						size="small"
						label={t('ProfileModal.Tema')}
						variant="outlined"
						inputProps={{
							...params.inputProps,
							autoComplete: 'new-password', // disable autocomplete and autofill
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: '',
							},
						}}
						InputLabelProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						sx={{
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
					/>
				)}
			/>
			<Line style={{ justifyContent: 'space-between', marginTop: 10 }}>
				{loadingPasswordChange ? (
					<LoadingDots width={100} height={30} loop />
				) : (
					<Button
						aria-label="submit-button"
						data-testid="submitTest"
						type="submit"
						onClick={handleChangePassword}
						size="small"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
						}}
					>
						{t('ProfileModal.Alterar senha')}
					</Button>
				)}
				<Line style={{ width: '50%', justifyContent: 'flex-end' }}>
					<Checkbox
						aria-label="tour"
						checked={checkedShowTour}
						onChange={handleChangeShowTour}
						color="primary"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: selectedTheme.primaryDark,
						}}
					/>
					<Typography
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						{t('ProfileModal.Mostrar o tour')}
					</Typography>
				</Line>
			</Line>
		</Column>
		// </Form>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: 5,
				}}
			>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{
						color: selectedTheme.error,
					}}
					data-testid="closeProfileModal"
				>
					{t('ProfileModal.Fechar')}
				</Button>
				{loading ? (
					<LoadingDots width={100} height={30} loop />
				) : (
					<Button
						variant="contained"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('ProfileModal.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<Dialog open={open} onClose={handleClose} scroll="body">
				<DialogContent
					style={{
						padding: 0,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay6dp
								: selectedTheme?.foreground,
					}}
				>
					{renderHeader()}
					{renderForm()}
					{renderFooter()}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default UserProfileModal;
