import React from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	Box,
	IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { Column, Footer, Line } from '../../../../styles';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { usePO } from '../../../../utils/POContext';
import SelectClient from '../../../../components/SelectClient';
import { useSnackbar } from 'notistack';
import { submitClassification } from '../../../../services/classifications';
import { OfferToolTip } from '../../../../helpers';
import { Share } from '@mui/icons-material/';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateGoodCards,
	updateNotGoodCards,
} from '../../../../redux/slices/cardFeedbackSlice';

interface Props {
	offerData: any;
	denounceValue?: any;
	denounceObs?: string;
	tagsToSubmit?: any;
	setDenounceValue: (value) => void;
	enableClassification: boolean;
	setEnableClassification: (value) => void;
	carouselPosition?: string;
}

const DenouncecConfirmationDialog: React.FC<Props> = ({
	offerData,
	denounceValue,
	denounceObs,
	tagsToSubmit,
	setDenounceValue,
	enableClassification,
	setEnableClassification,
	carouselPosition,
}: Props) => {
	const dispatch = useDispatch();
	const { token } = useAuth0();
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, userData, classificationTypes } =
		usePO();
	const { enqueueSnackbar } = useSnackbar();
	setupRequestToken(api, token);
	const notGoodCardsList = useSelector(
		(state: any) => state.cardFeedback.notGoodCardsList
	);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [denounce, setDenounce] = React.useState(true);
	const [permit, setPermit] = React.useState(false);

	const handleClickDenounce = () => {
		if (denounceValue !== 'NOT_SET') {
			// check if role is admin
			if (userData.roles.includes('Analista de posts')) {
				if (tagsToSubmit.length > 0) {
					setOpen(true);
				} else {
					enqueueSnackbar(t('Classificações.Adicione uma ou mais etiquetas'), {
						variant: 'warning',
					});
				}
			} else {
				setOpen(true);
			}
		} else {
			enqueueSnackbar(
				t(
					'Classificações.Por favor, especifique um motivo para a classificação.'
				),
				{ variant: 'success' }
			);
		}
	};

	const handleClickPermit = () => {
		setDenounce(false);
		setPermit(true);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setDenounce(true);
		}, 300);
	};

	const handleSave = async () => {
		if (denounceValue !== 'NOT_SET') {
			setLoading(true);
			const payload = {
				offerID: [offerData.offerID],
				classificationModel: {
					classificationTypeID: permit ? 'PSTLG' : denounceValue.id, // check if denounce or permit
					denounceStatusID: 'CLASSIFICADA',
					clientID: selectedClient.id,
					observation: denounceObs,
				},
			};
			const response: any = await submitClassification(payload);
			if (response.success === true) {
				enqueueSnackbar(t(response.message), {
					variant: 'success',
				});
				setEnableClassification(false);
				if (permit) {
					setDenounceValue(classificationTypes.find((el) => el.id === 'PSTLG'));
					dispatch(updateGoodCards(goodCardsList.concat(offerData.offerID)));
				} else if (permit === false) {
					dispatch(
						updateNotGoodCards(notGoodCardsList.concat(offerData.offerID))
					);
				}
				setOpen(false);
				setLoading(false);
			}
			if (response?.success === false) {
				enqueueSnackbar(t(response.message), {
					variant: 'error',
				});
				setLoading(false);
			}
		}
	};

	const handleCopyLinkToURL = () => {
		const { protocol, host } = window.location;
		const url = `${protocol}//${host}/offerID/${offerData.offerID}`;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				enqueueSnackbar(
					t('Classificações.Link copiado para a área de transferência'),
					{ variant: 'success' }
				);
			})
			.catch((err) => {
				console.error('Error copying to clipboard', err);
			});
	};

	const renderDennounceMessage = () =>
		denounce ? (
			<Line>
				<Typography
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Classificações.Classificar o produto')}:
				</Typography>
				<Box
					maxWidth={300}
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
				>
					<Typography
						noWrap
						sx={{
							fontWeight: 'bold',
							marginLeft: '5px',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{offerData.title}
					</Typography>
				</Box>
				<Typography
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					?
				</Typography>
			</Line>
		) : (
			<>
				<Line>
					<Typography
						sx={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t(
							'Classificações.Classificar o anúncio como permitido para o cliente'
						)}
					</Typography>
					<Typography
						sx={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							fontWeight: 'bold',
							marginLeft: '5px',
						}}
					>
						{`${selectedClient.name || ''}`}
					</Typography>
					<Typography
						sx={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						?
					</Typography>
				</Line>
			</>
		);

	const renderFooter = () => {
		if (denounce) {
			return (
				<Line style={{ justifyContent: 'space-between' }}>
					<Button
						size="small"
						onClick={handleClose}
						style={{ color: loading ? 'gray' : selectedTheme.error }}
					>
						{t('Classificações.Cancelar')}
					</Button>
					{loading ? (
						<LoadingDots height={35} width={70} loop />
					) : (
						<Button
							size="small"
							disabled={
								selectedClient === null ||
								selectedClient === undefined ||
								userData.roles.includes('denunciante')
							}
							onClick={handleSave}
							style={{
								border: `1px solid ${
									selectedTheme.id === 'dark'
										? selectedTheme.footerLine
										: 'transparent'
								}`,
								color:
									selectedTheme.tone === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
							}}
						>
							{t('Classificações.Classificar')}
						</Button>
					)}
				</Line>
			);
		}
		return (
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Classificações.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={35} width={70} loop />
				) : (
					<Button
						data-testid="cardModalConfirm"
						size="small"
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
						}}
						onClick={handleSave}
					>
						{t('Classificações.Classificar')}
					</Button>
				)}
			</Line>
		);
	};

	const renderDenounceButtons = () => (
		<Footer
			key={carouselPosition}
			style={{
				padding: 10,
				height: 50,
				borderRadius: '0 0 6px 6px',
				justifyContent: 'space-between',
				alignItems: 'center',
				minWidth: 'max-content',
				borderTop: `1px solid ${selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#f2f2f2'}`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
				boxShadow:
					'0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
			}}
		>
			<OfferToolTip title={t('Classificações.Compartilhar')} arrow>
				<IconButton
					size="small"
					onClick={handleCopyLinkToURL}
					sx={{
						marginLeft: '5px',
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Share
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
							fontSize: 20,
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<div style={{ marginLeft: 15 }}>
				<Typography
					variant="subtitle2"
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{carouselPosition}
				</Typography>
			</div>
			<Line
				style={{
					width: '100%',
					justifyContent: 'flex-end',
					opacity: enableClassification ? 1 : 0,
					visibility: enableClassification ? 'visible' : 'hidden',
					transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out',
				}}
			>
				<Button
					size="small"
					key={offerData.offerMarketplaceID}
					variant="contained"
					data-testid="cardModalPermited"
					disabled={!enableClassification}
					onClick={handleClickPermit}
					style={{
						backgroundColor: selectedTheme.success,
						color: 'white',
					}}
				>
					{t('Classificações.Permitir')}
				</Button>
				<Button
					size="small"
					disabled={!enableClassification}
					key={offerData.offerMarketplaceID + 2}
					variant="contained"
					onClick={handleClickDenounce}
					style={{
						backgroundColor: selectedTheme.error,
						color: 'white',
						marginLeft: 20,
					}}
				>
					{t('Classificações.Classificar')}
				</Button>
			</Line>
		</Footer>
	);

	return (
		<>
			{renderDenounceButtons()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="lg"
			>
				<DialogContent
					style={{
						padding: '20px 30px',
						background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					}}
				>
					{selectedClient !== null && selectedClient !== undefined ? (
						renderDennounceMessage()
					) : (
						<Column style={{ rowGap: 10 }}>
							<Typography>
								{t(
									'Classificações.Escolha um cliente para classificar o anúncio'
								)}
								:
							</Typography>
							<SelectClient />
						</Column>
					)}
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: '#eaeaea',
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay3dp
								: selectedTheme.foreground,
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DenouncecConfirmationDialog;
