import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Typography, Divider, IconButton } from '@mui/material';
import { Line } from '../../styles/index';
import { RangeInput } from '@appbaseio/reactivesearch';
import { ChevronRight } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { Column } from '../../styles/index';
import { usePO } from '../../utils/POContext';
import { enqueueSnackbar } from 'notistack';

const RangeSelector = (props) => {
	const { dataField, rangeValues, setRangeValues, label, componentId } = props;
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const useStyles = makeStyles({
		root: {
			width: 300,
			fontSize: 10,
		},
		marks: {},
	});

	const classes = useStyles();

	const [valuesToSearch, setValuesToSearch] = useState(rangeValues);
	const [inputRangeValues, setInputRangeValues] = useState({
		start: '',
		end: '',
	});
	const [valueErrors, setValueErrors] = useState(false);

	useEffect(() => {
		if (rangeValues.start !== 0 || rangeValues.end !== 999999999) {
			setValuesToSearch(rangeValues);
			setInputRangeValues({ start: rangeValues.start, end: rangeValues.end });
		}
		if (rangeValues.start === 0 && rangeValues.end === 999999999) {
			setInputRangeValues({ start: '', end: '' });
			setValuesToSearch({ start: 0, end: 999999999 });
		}
	}, [rangeValues]);

	const checkValueErrors = () => {
		if (inputRangeValues.start > inputRangeValues.end) {
			setValueErrors(true);
			enqueueSnackbar(t('PriceSlider.Valores incompatíveis'), {
				variant: 'warning',
			});
		}
	};

	const handleRangeValuesChange = () => {
		if (
			inputRangeValues.start !== '' &&
			inputRangeValues.end !== '' &&
			inputRangeValues.start <= inputRangeValues.end
		) {
			setValueErrors(false);
			setValuesToSearch(inputRangeValues);
		}
		if (inputRangeValues.start === '' && inputRangeValues.end === '') {
			setRangeValues({ start: 0, end: 999999999 });
			setValuesToSearch({ start: 0, end: 999999999 });
		}
		checkValueErrors();
	};

	const handleInputChangeMin = (event) => {
		const value = event.target.value;
		setInputRangeValues({ ...inputRangeValues, start: value });
	};

	const handleInputChangeMax = (event) => {
		const value = event.target.value;
		setInputRangeValues({ ...inputRangeValues, end: value });
	};

	const handleEnterKey = (event) => {
		if (event.key === 'Enter') {
			handleRangeValuesChange(inputRangeValues);
		}
	};

	const InputMinPrice = () => (
		<Line style={{ padding: '0px 0px 0px 15px' }}>
			<TextField
				onChange={handleInputChangeMin}
				value={inputRangeValues.start}
				size="small"
				autoFocus
				onKeyDown={handleEnterKey}
				autoComplete="off"
				variant="standard"
				sx={{
					width: '75px',
					textAlign: 'right',
					margin: '0 0 -2px -8px',
					'.MuiInputBase-input': {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						fontSize: '14px',
					},
				}}
				InputProps={{
					disableUnderline: true,
				}}
			/>
		</Line>
	);

	const InputMaxPrice = () => (
		<Line style={{ padding: '0px 0px 0px 15px' }}>
			<TextField
				onChange={handleInputChangeMax}
				value={inputRangeValues.end}
				size="small"
				autoFocus
				onKeyDown={handleEnterKey}
				autoComplete="off"
				variant="standard"
				sx={{
					width: '75px',
					textAlign: 'right',
					margin: '0 0 -2px -8px',
					'.MuiInputBase-input': {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						fontSize: '14px',
					},
				}}
				InputProps={{
					disableUnderline: true,
				}}
			/>
		</Line>
	);

	const renderDynamicBorderStyle = () => {
		if (valueErrors) {
			return selectedTheme.id === 'dark'
				? `1px solid ${selectedTheme.error}`
				: '1px solid red';
		} else {
			return selectedTheme.id === 'dark' ? '1px solid #575757' : 'none';
		}
	};

	const renderPriceInputs = () => (
		<>
			<RangeInput
				componentId={componentId || dataField}
				dataField={dataField}
				showHistogram={false}
				includeNullValues={valuesToSearch.end === 999999999}
				tooltipTrigger="hover"
				onValueChange={(value) => setRangeValues(value)}
				value={valuesToSearch}
				rangeLabels={{
					start: t('FilterBar.De'),
					end: t('FilterBar.Até'),
				}}
				range={{
					start: 0,
					end: 999999999,
				}}
				defaultValue={{
					start: 0,
					end: 999999999,
				}}
				showFilter
				filterLabel={`${t(`FilterBar.${label}`)}`}
				stepValue={100}
				debounce={1500}
				style={{ display: 'none' }}
			/>
			<Line
				style={{
					width: '100%',
					justifyContent: 'flex-start',
					marginTop: 9,
					height: 40,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.overlay3dp
							: selectedTheme.foreground,
					border: renderDynamicBorderStyle(),
					borderRadius: 4,
					padding: 5,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
				}}
			>
				<Line
					style={{
						justifyContent: 'flex-start',
						width: '45%',
					}}
				>
					<Typography
						variant="subtitle2"
						sx={{
							marginLeft: '10px',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('PriceSlider.De')}:
					</Typography>
					{InputMinPrice()}
				</Line>
				<Divider
					sx={{
						height: '28px',
						background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
					}}
					orientation="vertical"
				/>
				<Line
					style={{ justifyContent: 'center', width: '50%', paddingLeft: 35 }}
				>
					<Typography
						variant="subtitle2"
						sx={{
							marginLeft: '10px',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('PriceSlider.Até')}:
					</Typography>
					{InputMaxPrice()}
					<Divider
						sx={{
							height: '28px',
							background:
								selectedTheme.id === 'dark' && selectedTheme.footerLine,
						}}
						orientation="vertical"
					/>
					<IconButton
						size="small"
						onClick={handleRangeValuesChange}
						sx={{
							padding: '7px',
							borderRadius: '0px 5px 5px 0px ',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<ChevronRight
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						/>
					</IconButton>
				</Line>
			</Line>
		</>
	);

	return (
		<Column
			style={{
				width: '100%',
				justifyContent: 'center',
				marginTop: -10,
			}}
			className={classes.root}
		>
			{renderPriceInputs()}
		</Column>
	);
};

export default RangeSelector;
