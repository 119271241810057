import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Unauthorized from '../Page401';
import { usePO } from '../../utils/POContext';
import WindowTabs from '../../components/WindowTabs';
import BuildSearch from './BuildSearchForm';
import Plataformas from './Platforms';
import { useDispatch } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import SavedSearches from './SavedSearches';

const Busca: React.FC = () => {
	const { userData } = usePO();
	const { t } = useTranslation();

	const renderTab1 = () => <BuildSearch />;
	const renderTab2 = () => <SavedSearches />;
	const renderTab3 = () => <Plataformas />;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(changeActiveMenu('busca'));
	}, [dispatch]);

	return (
		<>
			{userData?.permissions?.indexOf('read:searches') !== -1 ? (
				<WindowTabs
					tab1Component={renderTab1()}
					tab2Component={renderTab2()}
					tab3Component={renderTab3()}
					tab1Title={`${t('Buscas.Nova Busca')}`}
					tab2Title={`${t('Buscas.Buscas Salvas')}`}
					tab3Title={`${t('Buscas.Plataformas')}`}
					windowTitle={`${t('Buscas.Gerenciamento de Buscas')}`}
				/>
			) : (
				<div style={{ marginLeft: '-75px' }}>
					<Unauthorized />
				</div>
			)}
		</>
	);
};

export default Busca;
