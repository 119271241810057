import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'menu',
	initialState: {
		selectedMenu: '',
	},
	reducers: {
		changeActiveMenu(state, { payload }) {
			return { ...state, selectedMenu: payload };
		},
	},
});

export const { changeActiveMenu } = slice.actions;

export const selectedMenuItem = (state) => state;

export default slice.reducer;
