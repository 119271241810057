import React, { useEffect, useState } from 'react';
import { api, setupRequestToken } from '../../../utils/api';
import { MultiDropdownList, ReactiveList } from '@appbaseio/reactivesearch';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePanelControls } from '../../../hooks/PanelControls';
import { usePO } from '../../../utils/POContext';
import ResultsTable from './UnderAnalysisTable';
import { getIsProcessing } from '../../../services/notifications/index';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

interface Props {
	selectedPlatform: any;
}

const UnderAnalysisTab: React.FC<Props> = ({ selectedPlatform }: Props) => {
	const { t } = useTranslation();
	const { selectedClient, updateCursorMode } = usePO();
	const { token } = useAuth0();
	const { setFilterBarStates } = usePanelControls();
	setupRequestToken(api, token);

	const [reloadTable, setReloadTable] = useState(1);

	useEffect(() => {
		fetchProcessingCount();
		setFilterBarStates(false);
		// eslint-disable-next-line
	}, [selectedClient.id, reloadTable]);

	const handleReloadTable = () => {
		setReloadTable(reloadTable + 1);
	};

	const renderLoading = (isLoading) => {
		switch (isLoading) {
			case true:
				updateCursorMode('wait');
				break;
			case false:
				updateCursorMode('inherit');
				break;
			default:
				updateCursorMode('inherit');
				break;
		}
	};

	const fetchProcessingCount = async () => {
		const response = await getIsProcessing(selectedClient?.id);
		if (response && response.success) {
			if (response.data.length !== 0 && response.data.NOTIFICADA)
				enqueueSnackbar(
					`${t('Notificações.Tabs.Notificadas.Existem')} ${
						response.data.NOTIFICADA
					} ${t(
						'Notificações.Tabs.Notificadas.ofertas notificadas em processamento!'
					)}`,
					{
						variant: 'success',
					}
				);
		}
	};

	const renderResults = (data: any, error: boolean, loading: boolean) => {
		if (selectedClient?.id !== undefined) {
			renderLoading(loading);
		}
		return (
			<ResultsTable
				data={data}
				loading={loading}
				error={error}
				reloadTable={handleReloadTable}
			/>
		);
	};

	const singlePlatformQuery = () => {
		return {
			query: {
				bool: {
					must: [
						{
							term: {
								'offerClassification.denounceStatusID': 'NOTIFICADA',
							},
						},
						{ term: { 'offerClassification.clientID': selectedClient?.id } },
						{
							term: { 'classificationType.ISgood': false },
						},
						{ term: { 'offer.platformINcountryID': selectedPlatform } },
					],
					must_not: { term: { ISprocessing: true } },
				},
			},
			size: 0,
		};
	};

	const allPlatformsQuery = () => {
		return {
			query: {
				bool: {
					must: [
						{
							term: {
								'offerClassification.denounceStatusID': 'NOTIFICADA',
							},
						},
						{ term: { 'offerClassification.clientID': selectedClient?.id } },
						{
							term: { 'classificationType.ISgood': false },
						},
					],
					must_not: { term: { ISprocessing: true } },
				},
			},
			size: 0,
		};
	};

	return (
		<>
			<MultiDropdownList
				componentId="headerPlatformSelector"
				dataField="offer.platformINcountryID"
				size={10000}
				sortBy="asc"
				queryFormat="or"
				URLParams={false}
				style={{
					width: 250,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
					padding: 0,
					borderRadius: 4,
					display: 'none',
				}}
			/>
			<ReactiveList
				key={selectedPlatform + reloadTable}
				componentId="ReactiveListResult"
				dataField="*"
				size={10000}
				pagination={false}
				stream={false}
				defaultQuery={
					selectedPlatform === '*' ? allPlatformsQuery : singlePlatformQuery
				}
				showLoader={false}
				react={{
					and: [
						'tagsSelect',
						'searchbox',
						'headerPlatformSelector',
						'titleSearch',
						'sellerSearch',
						'tagSearch',
						'dateRangeSearch',
						'classificationTypeSearch',
						'sourceSearch',
						'platformSearch',
						'denouncerSearch',
						'countrySearch',
						'stateSearch',
						'citySearch',
						'observationSearch',
					],
				}}
				renderResultStats={() => {
					return null;
				}}
				renderNoResults={() => null}
				render={({ loading, error, data }) => {
					return renderResults(data, error, loading);
				}}
			/>
		</>
	);
};

export default UnderAnalysisTab;
