import styled from 'styled-components';

// Gradiente baseado no theme
export const GlobalVerticalGradientColor = `linear-gradient(180deg, #2A6367 0%, #2E7378 17.19%, #204E51 52.08%, #1F4C4F 59.9%, #1C4547 100%)`;
export const GlobalHorizontalGradient = `linear-gradient(90deg, #204E51 28.94%, #2F7074 100%)`;
export const Primary = '#265C60';
export const PrimaryDark = '#1e474a';
export const Secondary = '#cf3227';
export const Terceary = '#258024';

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	color: white;
	font-size: 18px;
	font-weight: bold;
	padding: 0 20px;
`;

export const NavBar = styled.div`
	width: 97%;
	height: 34px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin: 20px;
	background: #f2f2f2;
	border-radius: 5px;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Footer = styled.div`
	width: 100%;
	height: 55px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-top: 1px;
	border-top-style: solid;
	border-top-color: #f2f2f2;
	padding: 15px;
`;

export const FooterLeft = styled.div`
	display: flex;
	flex-direction: row;
	width: 25%;
	justify-content: flex-start;
`;

export const FooterRight = styled.div`
	display: flex;
	flex-direction: row;
	width: 25%;
	justify-content: flex-end;
	align-items: center;
`;

export const Line = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

export const Column = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin: 8px;
`;

export const OfferCard = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #ffffff;
	padding: 10px 20px;
	border-radius: 4px;
	align-items: center;
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const RegularPanel = styled.div`
	/* width: 100%; */
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	background-color: '#ffffff';
	box-shadow:
		0px 1px 3px rgba(0, 0, 0, 0.1),
		0px 0px 2px rgba(0, 0, 0, 0.2);
	margin-top: 5px;
`;

export const PanelContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 20px 30px;
	box-shadow: inset 0px 5px 6px -8px rgba(0, 0, 0, 0.7);
`;

export const GlobalButton = styled.button``;
