import React from 'react';
import { Chip, FormHelperText, TextField, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import SearchTermsInput from '../../../../components/OfferMultiInput';
import PlatformSelector from '../PlatformSelector';

interface Props {
	buildingSearch?: boolean;
	excludingTermsToSearch: any;
	formErrors?: any;
	readOnly?: boolean;
	searchData?: any;
	searchTitle?: string;
	selectedPlatforms: any;
	setExcludingTermsToSearch: (data) => void;
	setSearchTitle?: (data: string) => void;
	setSelectedPlatforms: (data) => void;
	setTearmsToSearch: (data) => void;
	termsToSearch: any;
}

const LeftColumn: React.FC<Props> = ({
	readOnly,
	searchTitle,
	setSearchTitle,
	selectedPlatforms,
	setSelectedPlatforms,
	setTearmsToSearch,
	termsToSearch,
	excludingTermsToSearch,
	setExcludingTermsToSearch,
	buildingSearch,
	formErrors,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const handleDeletePlatform = (deleteItem) => {
		if (buildingSearch) {
			setSelectedPlatforms((platforms) =>
				platforms.filter(
					(alreadySelected) => alreadySelected.inCountryID !== deleteItem
				)
			);
		}
		return null;
	};

	const renderSearchTermsInput = () => {
		if (buildingSearch) {
			return (
				<SearchTermsInput
					setDataArray={setTearmsToSearch}
					dataArray={termsToSearch}
					maxItems={1}
					noAutoComplete
					size="small"
					fieldName="terms"
					formErrors={formErrors?.terms}
					colorChip={
						selectedTheme.id === 'dark'
							? selectedTheme.primaryDark
							: selectedTheme.primaryDark
					}
				/>
			);
		}
		return (
			<Line
				style={{
					width: '100%',
					flexWrap: 'wrap',
					alignItems: 'start',
					borderStyle: 'solid',
					borderWidth: 1,
					borderColor: formErrors?.terms
						? 'red'
						: selectedTheme.tone === 'dark'
							? '#707070'
							: '#cacaca',
					borderRadius: 4,
					padding: 4,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				{termsToSearch.map((el) => (
					<Chip
						key={el}
						variant="outlined"
						label={el}
						style={{
							height: '30px',
							margin: 2,
							backgroundColor:
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: selectedTheme.primaryDark,
							borderColor: selectedTheme.id === 'dark' ? '' : '#eaeaea',
							borderRadius: '4px',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: selectedTheme?.foreground,
							fontSize: 15,
						}}
					/>
				))}
			</Line>
		);
	};

	const renderSearchExcludingTermsInput = () => {
		return (
			<SearchTermsInput
				setDataArray={setExcludingTermsToSearch}
				dataArray={excludingTermsToSearch}
				maxItems={99}
				noAutoComplete
				size="small"
				fieldName="excludingTerms"
				formErrors={formErrors?.excludingTerms}
				colorChip={
					selectedTheme.id === 'dark'
						? selectedTheme.primaryLight
						: selectedTheme.primaryLight
				}
			/>
		);
	};

	const renderPlatformSelector = () => {
		if (buildingSearch) {
			return (
				<PlatformSelector
					setSelectedPlatforms={setSelectedPlatforms}
					selectedPlatforms={selectedPlatforms}
				/>
			);
		}
		return null;
	};

	return (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				justifyContent: 'space-between',
				rowGap: 4,
				marginTop: 1,
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			}}
		>
			<Typography
				style={{
					marginBottom: 7,
				}}
			>
				{t('Buscas.Tabs.Nova Busca.Título da Busca')}:
			</Typography>
			<Line
				style={{
					height: 65,
					overflow: 'hidden',
					alignItems: 'start',
				}}
			>
				<TextField
					aria-label="title"
					variant="outlined"
					size="small"
					name="title"
					value={searchTitle}
					error={formErrors?.title}
					helperText={formErrors?.title}
					autoComplete="off"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setSearchTitle?.(event.target.value);
					}}
					InputProps={{
						readOnly,
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorMedium
									: '',
						},
					}}
					sx={{
						width: '100%',
						'.MuiFormHelperText-root.Mui-error': {
							mt: '0px',
							color: selectedTheme.error,
							borderColor: selectedTheme.error,
						},
						'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
							{
								borderColor: selectedTheme.error,
							},
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>

			<Typography style={{ marginTop: -7 }}>
				{t('Buscas.Tabs.Nova Busca.Termos de Busca')}:
			</Typography>
			<Line
				style={{
					marginTop: 7,
					height: 65,
					overflow: 'hidden',
					alignItems: 'start',
					color: selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
				}}
			>
				{renderSearchTermsInput()}
			</Line>
			<Typography style={{ marginTop: -7 }}>
				{t('Buscas.Tabs.Nova Busca.Termos Excludentes')}:
			</Typography>
			<Line
				style={{
					marginTop: 7,
					maxHeight: 250,
					overflow: 'auto',
					alignItems: 'start',
				}}
			>
				{renderSearchExcludingTermsInput()}
			</Line>
			<Column
				style={{
					height: '100%',
					alignItems: 'start',
					justifyContent: 'space-between',
				}}
			>
				<Typography style={{ marginTop: 15 }}>
					{t('Buscas.Tabs.Nova Busca.Plataformas')}:
				</Typography>
				<Line style={{ marginTop: 5 }}>
					{renderPlatformSelector()}
					<Line
						style={{
							minHeight: 77,
							width: '100%',
							flexWrap: 'wrap',
							alignItems: 'start',
							borderStyle: 'solid',
							borderWidth: 1,
							borderColor: formErrors?.platforms
								? selectedTheme.error
								: selectedTheme.tone === 'dark'
									? '#707070'
									: '#cacaca',
							borderRadius: 4,
							padding: 4,
							backgroundColor:
								selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
						}}
					>
						{selectedPlatforms?.map((el) => (
							<Chip
								key={buildingSearch ? `${el.name} - ${el.country}` : el}
								variant="outlined"
								label={buildingSearch ? `${el.name} - ${el.country}` : el}
								onDelete={() => handleDeletePlatform(el.inCountryID)}
								deleteIcon={
									<CloseRounded
										fontSize="small"
										style={{
											display: buildingSearch ? 'block' : 'none',
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorMedium
													: selectedTheme.foreground,
										}}
									/>
								}
								style={{
									height: '30px',
									margin: 2,
									backgroundColor:
										selectedTheme.id === 'dark'
											? selectedTheme?.primary
											: selectedTheme.primary,
									borderColor: selectedTheme.id === 'dark' ? '' : '#eaeaea',
									borderRadius: '4px',
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: selectedTheme.foreground,
									fontSize: 15,
								}}
							/>
						))}
					</Line>
				</Line>
				<FormHelperText
					sx={{
						color: selectedTheme.error,
						display: formErrors?.platforms ? 'inherit' : 'none',
						ml: '51px',
						mt: '0px',
					}}
				>
					{t('Buscas.Tabs.Nova Busca.Selecione ao menos uma plataforma')}
				</FormHelperText>
			</Column>
		</Column>
	);
};
export default LeftColumn;
