import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const getOfferByAuthor = async (requestBody) => {
	try {
		const response = await api.post(
			'offerClassification/elasticsearch/_msearch',
			requestBody
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};
