import React, { useState } from 'react';
import { Card, IconButton, Typography, Zoom } from '@mui/material';
import { OfferToolTip } from '../../helpers';
import { Column, Line } from '../../styles';
import { usePO } from '../../utils/POContext';
import CardFlip from 'react-card-flip';
import { SwapHorizRounded } from '@mui/icons-material/';

interface Props {
	tooltipTitle: string;
	cardTitle: string;
	cardSubtitle: string;
	flipCard?: boolean;
	backTooltipTitle?: string;
	backCardTitle?: string;
	backCardSubtitle?: string;
}

const StarterCard: React.FC<Props> = ({
	tooltipTitle,
	cardTitle,
	cardSubtitle,
	flipCard,
	backTooltipTitle,
	backCardTitle,
	backCardSubtitle,
}: Props) => {
	const { selectedTheme } = usePO();
	const [flip, setFlip] = useState(false);
	const [flipKey, setFlipKey] = useState(1);

	const handleFlip = () => {
		setFlip(!flip);
		setFlipKey(flipKey + 1);
	};

	const renderCardHeader = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				position: 'absolute',
				top: 0,
				padding: '3px 7px',
			}}
		>
			<IconButton
				size="small"
				onClick={handleFlip}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<SwapHorizRounded
					sx={{
						zIndex: 10000,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			</IconButton>
		</Line>
	);

	const defaultStarterCard = () => {
		return (
			<>
				<Card
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						width: '300px',
						height: '160px',
						backgroundColor:
							selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
						'&:hover': {
							boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
						},
					}}
				>
					<Column style={{ rowGap: 10 }}>
						<OfferToolTip
							placement="top"
							title={tooltipTitle}
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<Typography
								variant="h2"
								sx={{
									marginBottom: '-5px',
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							>
								{cardTitle}
							</Typography>
						</OfferToolTip>
						<Typography
							variant="subtitle2"
							sx={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{`${cardSubtitle}`}
						</Typography>
					</Column>
				</Card>
			</>
		);
	};

	const defaultFlipCard = () => {
		const transformFree = {
			front: { transformStyle: 'flat' },
			back: { transformStyle: 'flat' },
		};

		return (
			<>
				<CardFlip
					isFlipped={flip}
					flipDirection="horizontal"
					cardStyles={transformFree}
				>
					<Card
						key={flipKey}
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							width: '300px',
							height: '160px',
							backgroundColor:
								selectedTheme.id === 'dark'
									? selectedTheme.gradient
									: '#fafafa',
							border:
								selectedTheme.id === 'dark'
									? `1px solid ${selectedTheme.footerLine}`
									: 'none',
							'&:hover': {
								boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
							},
						}}
					>
						<Line>{renderCardHeader()}</Line>
						<Column style={{ rowGap: 10 }}>
							<OfferToolTip
								placement="top"
								title={`${tooltipTitle}`}
								arrow
								enterDelay={200}
								enterNextDelay={200}
								TransitionComponent={Zoom}
							>
								<Typography
									variant="h2"
									sx={{
										marginBottom: '-5px',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
									}}
								>
									{cardTitle}
								</Typography>
							</OfferToolTip>
							<Typography
								variant="subtitle2"
								sx={{
									fontWeight: 'bold',
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{`${cardSubtitle}`}
							</Typography>
						</Column>
					</Card>

					<Card
						key={flipKey}
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							width: '300px',
							height: '160px',
							backgroundColor:
								selectedTheme.id === 'dark'
									? selectedTheme.gradient
									: '#fafafa',
							border:
								selectedTheme.id === 'dark'
									? `1px solid ${selectedTheme.footerLine}`
									: 'none',
							'&:hover': {
								boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
							},
						}}
					>
						<Line>{renderCardHeader()}</Line>
						<Column style={{ rowGap: 10 }}>
							<OfferToolTip
								placement="top"
								title={`${backTooltipTitle}`}
								arrow
								enterDelay={200}
								enterNextDelay={200}
								TransitionComponent={Zoom}
							>
								<Typography
									variant="h2"
									sx={{
										marginBottom: '-5px',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
									}}
								>
									{`${backCardTitle}`}
								</Typography>
							</OfferToolTip>
							<Typography
								variant="subtitle2"
								sx={{
									fontWeight: 'bold',
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{`${backCardSubtitle}`}
							</Typography>
						</Column>
					</Card>
				</CardFlip>
			</>
		);
	};

	return flipCard ? defaultFlipCard() : defaultStarterCard();
};

export default StarterCard;
