import { Card, Typography } from '@mui/material';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { OfferToolTip, handleNumberAbbreviation } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';
import { Line } from '../../../../styles';

interface Props {
	cardValue: any;
	loading: boolean;
	bottomTitleOnCard: string;
}

const ReportsCard: React.FC<Props> = ({
	cardValue,
	loading,
	bottomTitleOnCard,
}: Props) => {
	const { selectedTheme, userData } = usePO();

	const renderReportCard = () => {
		let formattedValue;
		if (cardValue && cardValue.data) {
			const value =
				cardValue?.data?.total !== undefined
					? cardValue?.data.total
					: cardValue?.data.average;

			formattedValue = handleNumberAbbreviation(userData.i18nID, value);
		}
		return (
			<Card
				style={{
					width: '100%',
					height: 160,
					backgroundColor:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay4dp
							: selectedTheme.background,
					borderWidth: 1,
					borderColor: selectedTheme.tone === 'dark' ? '#707070' : '#cacaca',
					borderRadius: 6,
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				{!loading && cardValue?.data && formattedValue ? (
					<Line
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							maxWidth: '100%',
							padding: '0px 20px',
						}}
					>
						<OfferToolTip placement="top" title={formattedValue} arrow>
							<Typography
								style={{
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
									fontWeight: 'bold',
									textTransform: 'uppercase',
									fontSize: '2vw',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									maxWidth: '100%',
								}}
							>
								{formattedValue}
							</Typography>
						</OfferToolTip>
					</Line>
				) : (
					<div style={{ padding: '0px 30px', marginTop: 5 }}>
						<Skeleton
							width="100%"
							count={1}
							height={35}
							baseColor={
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: '#e7e7e7'
							}
							highlightColor={
								selectedTheme.id === 'dark' && selectedTheme.textColorDisable
							}
						/>
					</div>
				)}

				{!loading && cardValue?.data && formattedValue ? (
					<Line
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: '0px 20px',
							maxWidth: '100%',
						}}
					>
						<OfferToolTip placement="bottom" title={bottomTitleOnCard} arrow>
							<Typography
								style={{
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
									fontWeight: '500',
									fontSize: '0.8vw',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									padding: '0px',
									maxWidth: '100%',
									overflow: 'hidden',
								}}
							>
								{bottomTitleOnCard}
							</Typography>
						</OfferToolTip>
					</Line>
				) : (
					<div style={{ padding: '0px 30px', marginTop: 10 }}>
						<Skeleton
							width="100%"
							count={1}
							height={20}
							baseColor={
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: '#e7e7e7'
							}
							highlightColor={
								selectedTheme.id === 'dark' && selectedTheme.textColorDisable
							}
						/>
					</div>
				)}
			</Card>
		);
	};

	return !loading && cardValue?.data === null ? null : renderReportCard();
};

export default ReportsCard;
