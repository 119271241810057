import React, { useState } from 'react';
import {
	Typography,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Zoom,
	Checkbox,
	IconButton,
	Box,
} from '@mui/material';
import { CloseRounded, ShareRounded } from '@mui/icons-material/';
import convertCountryName from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../utils/api';
import { useAuth0 } from '../../../react-auth0-spa';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { OfferToolTip } from '../../../helpers';

import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import DenounceConfirmationDialog from './ModalDenounceConfirmationDialog';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';

convertCountryName.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);

interface Props {
	offerData?: any;
	cardAsGood?: boolean;
	handleRemoveCardAsGood: () => void;
	images: any;
	open: boolean;
	setOpen: (state) => void;
	handleSetTags: (cardTags) => void;
}

const ClassificationModal: React.FC<Props> = ({
	offerData,
	images,
	cardAsGood,
	handleRemoveCardAsGood,
	open,
	setOpen,
	handleSetTags,
}: Props) => {
	const { token } = useAuth0();
	const { selectedTheme, selectedClient, userData } = usePO();
	const { handleSelectCard, selectedCards } = useCardSelector();
	setupRequestToken(api, token);
	const { t } = useTranslation();

	const [tagFieldError, setTagFieldError] = useState(false);
	const [cardTags, setCardTags] = useState<any[]>([]);
	const [denounceValue, setDenounceValue] = useState('NOT_SET');
	const [denounceObs, setDenounceObs] = useState('');
	const [handleLoadingTags, setHandleLoadingTags] = useState(false);

	const handleClose = () => {
		handleSetTags(cardTags);
		setOpen(false);
		setTimeout(() => {
			setDenounceValue('NOT_SET');
			setDenounceObs('');
		}, 300);
	};

	const setLoadingTags = (value) => {
		setHandleLoadingTags(value);
	};

	const handleSelect = (event) => {
		handleSelectCard(
			{ offerID: offerData.offerID, tags: offerData.tags },
			event.target.checked ? 'add' : 'remove'
		);
	};

	const checkPermission = () => {
		if (userData.roles[0] === 'Denunciante') {
			return false;
		} else {
			return true;
		}
	};

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 55,
				paddingInline: 18,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				color: '#FFFFFF',
				display: 'flex',
			}}
			id="draggable-dialog-title"
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					noWrap
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						fontSize: 18,
						fontWeight: 'bold',
						maxWidth: '90%',
						marginTop: 2,
					}}
				>
					{offerData.title}
				</Typography>
				<IconButton
					onClick={handleClose}
					style={{ marginRight: '-14px' }}
					disabled={handleLoadingTags}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderContent = () => (
		<DialogContent
			sx={{
				padding: '0px',
				height: '520px',
				overflow: 'hidden',
			}}
		>
			<Divider />
			<Divider />
			<Line
				style={{
					padding: '8px 10px 6px 10px',
					columnGap: 10,
					alignItems: 'flex-start',
					justifyContent: 'center',
				}}
			>
				<Column
					style={{
						width: '43.5%',
						alignItems: 'flex-start',
					}}
				>
					<LeftColumn
						handleLoadingTags={setLoadingTags}
						tagFieldError={tagFieldError}
						offerData={offerData}
						images={images}
						handleSetCardTags={setCardTags}
					/>
				</Column>
				<RightColumn
					offerData={offerData}
					denounceValue={denounceValue}
					setDenounceValue={setDenounceValue}
					denounceObs={denounceObs}
					setDenounceObs={setDenounceObs}
				/>
			</Line>
		</DialogContent>
	);

	const renderFooter = () => (
		<Line
			style={{
				width: '100%',
				justifyContent: 'flex-end',
				height: 55,
				padding: '5px 12px 5px 8px',
			}}
		>
			<Line
				style={{
					justifyContent: 'flex-start',
					width: checkPermission() ? '50%' : '100%',
				}}
			>
				{checkPermission() ? (
					<Checkbox
						size="small"
						checked={
							selectedCards.find((card) => card.offerID === offerData.offerID)
								? true
								: false
						}
						onClick={handleSelect}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					/>
				) : null}
				<OfferToolTip
					title={`${t('Classificações.Compartilhar')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						style={{ padding: 6, marginLeft: -5 }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<ShareRounded
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
								fontSize: 20,
							}}
						/>
					</IconButton>
				</OfferToolTip>
				<Line style={{ justifyContent: 'end' }}>
					<Typography style={{ paddingLeft: 10, marginRight: 15 }}>
						{t('Buscas.Tabs.Buscas Salvas.Cliente')}:
					</Typography>
					<Box
						width="53%"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						<OfferToolTip
							title={selectedClient?.name}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<Typography
								data-testid={selectedClient?.name}
								noWrap
								style={{ fontWeight: 'bold' }}
							>
								{selectedClient?.name}
							</Typography>
						</OfferToolTip>
					</Box>
				</Line>
			</Line>
			{checkPermission() ? (
				<Line
					style={{
						width: '50%',
						justifyContent: 'flex-end',
					}}
				>
					<DenounceConfirmationDialog
						tagsToSubmit={cardTags}
						cardAsGood={cardAsGood}
						handleRemoveCardAsGood={handleRemoveCardAsGood}
						setTagFieldError={setTagFieldError}
						offerData={offerData}
						denounceObs={denounceObs}
						denounceValue={denounceValue}
						closeParent={handleClose}
						setDenounceValue={setDenounceValue}
					/>
				</Line>
			) : null}
		</Line>
	);

	return (
		<div>
			<Dialog
				open={open}
				scroll="body"
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
				maxWidth="md"
				style={{ minWidth: 1050 }}
			>
				{renderHeader()}
				{renderContent()}
				{renderFooter()}
			</Dialog>
		</div>
	);
};
export default ClassificationModal;
