import React from 'react';
import { Link, List, ListItemText, Typography } from '@mui/material';
import { Column, Line } from '../../../styles';

const PrivacyTerms: React.FC = () => {
	return (
		<Line
			style={{
				width: '94%',
				justifyContent: 'center',
				paddingBottom: 20,
			}}
		>
			<Column
				style={{
					marginTop: 40,
					rowGap: 15,
					padding: '50px 60px 60px 80px',
					width: '60%',
					borderStyle: 'solid',
					borderWidth: 1,
					borderColor: 'gray',
					background: 'white',
					boxShadow: '5px 5px 5px rgba(200,200,200, 0.5)',
				}}
			>
				<Typography
					align="center"
					variant="h5"
					style={{ fontWeight: 'bold', width: '100%' }}
				>
					Política de privacidade
				</Typography>
				<Typography
					align="center"
					style={{ fontWeight: 'bold', width: '100%', marginTop: -10 }}
				>
					Versão atualizada em 03/06/2024.
				</Typography>
				<Typography align="left" style={{ width: '100%', marginTop: 30 }}>
					Prezado(a) Usuário(a),
				</Typography>
				<Typography align="left" style={{ width: '100%' }}>
					Para que você possa utilizar a extensão é necessário ler atentamente
					os termos de uso a seguir.
				</Typography>
				<Typography align="justify" sx={{ textIndent: '2em' }}>
					A Offer Technologies é criadora e titular da ferramenta Plataforma
					Offer sediada em Florianópolis, Santa Catarina, Brasil, na Rua Santa
					Luzia, 100, Sala 801, CEP 88.036-540. A Plataforma Offer possibilita
					que o usuário possa de forma rápida e segura manifestar sua opinião
					sobre uma determinada oferta, conteúdo ou produto caso suspeite de que
					os mesmos estejam ofertando/divulgando a venda de produto(s) que, por
					sua vez, infrinja(m) direito(s) de propriedade intelectual alheios,
					tais como, produtos piratas ou falsificados. As opiniões do usuário
					serão chamadas de &quot;denúncia&quot;. As denúncias dos usuários
					serão analisadas por profissionais especializados, que confirmarão ou
					não a mesma. Em caso positivo, o titular do direito de propriedade
					intelectual infringido adotará as providências cabíveis para denúncia,
					exclusão da referida oferta ou conteúdo, e você poderá acompanhar o
					status da providência. Para que você possa utilizar a ferramenta, é
					necessário ler atentamente os termos de uso a seguir, bem como as
					permissões, e somente utilizar o sistema se você estiver de acordo com
					as mesmas. O usuário poderá denunciar URLs veiculados ou não por meio
					de redes sociais, e-commerces e sites através da ferramenta de
					denúncia digital. O usuário poderá também denunciar estabelecimentos
					comerciais por meio da ferramenta de denúncia física, onde indicará a
					localização do estabelecimento através do preenchimento de um
					formulário ou concessão da permissão de acesso à sua localização. A
					Offer Technologies compromete-se a manter estrito sigilo em relação às
					informações que o usuário compartilhar, bem como a garantir total
					privacidade em relação a outras informações compartilhadas pelo
					usuário, comprometendo-se a utilizar as informações da URL denunciada
					exclusivamente para o fim de monitoramento de legalidade do referido
					conteúdo. A Offer Technologies ficará isenta de responsabilidade
					perante o usuário caso tenha de fornecer informações por força de
					ordem judicial ou imposição legal. Após a obtenção da URL denunciada,
					a Offer Technologies Ltda passará a monitorar a mesma a partir de
					servidor próprio. O usuário que atender as condições comerciais
					estabelecidas pela Offer Technologies Ltda terá uma licença temporária
					de uso do software &quot;Plataforma Offer&quot;, na modalidade
					personal computer. O usuário compromete-se a manter sigilo de sua
					senha de acesso, não podendo compartilhá-la com outro(s),
					comprometendo-se também a utilizar a &quot;Plataforma Offer&quot;
					apenas em um computador de sua propriedade. Os direitos de propriedade
					intelectual referentes ao software, layouts, marcas, conteúdos que
					caracterizam a Plataforma Offer pertencem exclusivamente à OFFER
					TECHNOLOGIES LTDA. A Offer Technologies poderá, a qualquer momento,
					por sua mera liberalidade, realizar atualizações e modificações no
					sistema Plataforma Offer, independentemente de qualquer autorização
					prévia do usuário. A Offer Technologies poderá, a seu critério,
					descontinuar os serviços da Plataforma Offer, bastando avisar o
					usuário, através do email de seu cadastro, com 10 dias de
					antecedência. O usuário que infringir os termos de uso, ou que
					proceder de forma ilegal, terá seu acesso imediatamente bloqueado pela
					Offer Technologies, sem prejuízo de sofrer outras sanções de cunho
					civil ou penal.
				</Typography>
				<Typography align="left" style={{ width: '100%' }}>
					Aplicam-se ao presente termo as leis brasileiras.
				</Typography>
				<Typography
					variant="h1"
					align="left"
					style={{ width: '100%', fontSize: '1.5em', fontWeight: 'bold' }}
				>
					Coleta e Uso de Dados
				</Typography>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Tipos de Dados Coletados
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								<strong>Informações Pessoais:</strong>
								Nome, endereço de e-mail, localização.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								<strong>Informações Sensíveis:</strong> Dados de localização,
								informações sobre a denúncia.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Uso dos Dados
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Os dados coletados são utilizados exclusivamente para a análise
								das denúncias e monitoramento de legalidade dos conteúdos
								denunciados.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Dados de localização são utilizados para identificar
								estabelecimentos comerciais em denúncias físicas.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Compartilhamento de Dados
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Os dados pessoais e sensíveis podem ser compartilhados com
								profissionais especializados para análise das denúncias.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Em caso de confirmação da denúncia, os dados podem ser
								compartilhados com o titular do direito de propriedade
								intelectual infringido.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Procedimentos de Manipulação de Dados
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Os dados são armazenados de forma segura em servidores próprios.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								A Offer Technologies implementa medidas de segurança adequadas
								para proteger os dados contra acesso não autorizado.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Política de Retenção e Exclusão de Dados
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Os dados coletados serão mantidos pelo período necessário para a
								análise das denúncias e monitoramento, conforme exigido por lei.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Os dados podem ser excluídos a pedido do usuário ou quando não
								forem mais necessários para os fins para os quais foram
								coletados.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h1"
					align="left"
					style={{ width: '100%', fontSize: '1.5em', fontWeight: 'bold' }}
				>
					Permissões e Consentimento
				</Typography>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Solicitações de Permissões
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								Antes de acessar dados controlados por permissões do
								ANDROID/IOS, a Offer Technologies solicitará o consentimento
								explícito do usuário.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								A solicitação de consentimento será apresentada de maneira clara
								e inequívoca, exigindo uma ação de confirmação do usuário, como
								um toque para aceitar ou a marcação de uma caixa de seleção.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h2"
					align="left"
					style={{ width: '100%', fontSize: '1.1em', fontWeight: 'bold' }}
				>
					Divulgações em Destaque
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						width: '90%',
					}}
				>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								A coleta, uso e compartilhamento de dados serão divulgados
								dentro do próprio aplicativo, não somente na descrição dele ou
								em um site.
							</Typography>
						}
					/>
					<ListItemText
						sx={{ display: 'list-item' }}
						primary={
							<Typography variant="body1">
								A divulgação será exibida no uso normal do aplicativo e
								descreverá os dados que são acessados ou coletados, explicando
								como serão usados e/ou compartilhados.
							</Typography>
						}
					/>
				</List>

				<Typography
					variant="h1"
					align="left"
					style={{ width: '100%', fontSize: '1.5em', fontWeight: 'bold' }}
				>
					Segurança dos Dados
				</Typography>

				<Typography variant="body1" sx={{ textIndent: '2em' }}>
					A Offer Technologies adota práticas de segurança adequadas, incluindo
					a transmissão de dados com criptografia moderna, como HTTPS, para
					garantir a proteção dos dados pessoais e sensíveis dos usuários.
				</Typography>

				<Typography
					variant="h1"
					align="left"
					style={{ width: '100%', fontSize: '1.5em', fontWeight: 'bold' }}
				>
					Requisito de Exclusão de Contas
				</Typography>

				<Typography variant="body1" sx={{ textIndent: '2em' }}>
					Os usuários terão uma opção para iniciar a exclusão da conta de dentro
					do app ou acessando o site. Ao excluir uma conta do aplicativo com
					base na solicitação de um usuário, também serão excluídos os dados do
					usuário associados a essa conta.
				</Typography>

				<Typography
					variant="h1"
					align="left"
					style={{ width: '100%', fontSize: '1.5em', fontWeight: 'bold' }}
				>
					Contato
				</Typography>
				<Typography align="left" style={{ width: '100%' }}>
					Em caso de dúvidas ou sugestões, contate o email a seguir:
				</Typography>
				<Typography
					align="right"
					variant="h6"
					style={{ fontWeight: 'bold', width: '100%' }}
				>
					<Link
						href="mailto:contato@offertech.com.br"
						color="inherit"
						underline="hover"
					>
						contato@offertech.com.br
					</Link>
				</Typography>
			</Column>
		</Line>
	);
};

export default PrivacyTerms;
