import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../styles';
import { CloseRounded } from '@mui/icons-material/';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { ReactComponent as Logo } from '../../../assets/images/svg/offerLogo.svg';

interface Props {
	openModal: boolean;
	handleCloseModal: () => void;
}

interface FormData {
	name: string;
	email: string;
	description: string;
	phone: string;
}

const ContactUs: React.FC<Props> = ({ openModal, handleCloseModal }: Props) => {
	const { t } = useTranslation();

	const [formData, setFormData] = useState<FormData>({
		name: '',
		phone: '',
		email: '',
		description: '',
	});
	const [formErrors, setFormErrors] = useState<any>({});

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(t('Inicio.O campo Nome é obrigatório.')),
		email: Yup.string()
			.email(t('Inicio.Digite um E-mail válido.'))
			.required(t('Inicio.O campo E-mail é obrigatório.')),
		phone: Yup.string().required(t('Inicio.O campo Telefone é obrigatório.')),
	});

	const handleInitialStates = () => {
		setFormData({
			name: '',
			phone: '',
			email: '',
			description: '',
		});
		setFormErrors({});
	};
	const handleSubmitData = async () => {
		try {
			await validationSchema.validate(formData, { abortEarly: false });
			// eslint-disable-next-line
			const payload = {
				name: formData.name,
				email: formData.email,
				description: formData.description,
				phone: formData.phone,
			};
			// const response = await api.patch(`/users/${userData.id}`, payload);
			// await api.patch(`/users/${userData.id}/preferences`, {
			// 	selectedTheme: selectedTheme.id,
			// });
			handleCloseModal();
			handleInitialStates();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	};

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				background: '#DCDB00',
				color: '#FFFFFF',
				padding: 0,
				display: 'flex',
			}}
		>
			<Line
				style={{
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%',
					padding: '4px 15px',
				}}
			>
				<Typography
					noWrap
					style={{
						color: '#272E67',
						fontSize: 18,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{t('Inicio.Entre em contato com a Offer')}
				</Typography>
				<IconButton style={{ marginRight: -10 }} onClick={handleCloseModal}>
					<CloseRounded
						style={{
							color: '#272E67',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderContent = () => (
		<DialogContent
			style={{
				padding: '30px 35px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '720px',
			}}
		>
			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Column
					style={{
						display: 'flex',
						width: '45%',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 35,
						flexShrink: 0,
					}}
				>
					<Column style={{ gap: 35 }}>
						<Typography
							style={{
								color: '#272E67',
								fontWeight: 'normal',
								fontSize: 28,
							}}
						>
							{t('Inicio.Nossos Contatos')}
						</Typography>
						<div
							style={{
								width: '90%',
								alignItems: 'center',
								gap: 20,
								justifyContent: 'center',
								flexDirection: 'column',
								display: 'flex',
								textAlign: 'center',
							}}
						>
							<Typography
								variant="subtitle2"
								style={{
									color: '#272E67',
									fontWeight: 'normal',
									fontSize: 14,
								}}
							>
								{t('OFFER TECHNOLOGIES LTDA')}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									color: '#272E67',
									fontWeight: 'normal',
									fontSize: 14,
								}}
							>
								{t(
									'Rua Santa Luiza 100 Sala 801 Trindade Florianópolis SC 88025400'
								)}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									color: '#272E67',
									fontWeight: 'normal',
									fontSize: 14,
								}}
							>
								{t('contato@offertech.com.br')}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									color: '#272E67',
									fontWeight: 'normal',
									fontSize: 14,
								}}
							>
								{t('Inicio.Responsável LGPD alexandre@offertech.com.br')}
							</Typography>
						</div>
					</Column>
					<Logo
						style={{
							color: '#000000',
							filter: `invert(100%)`,
							position: 'absolute',
							opacity: '0.07',
						}}
						width={150}
					/>
				</Column>
				<Column style={{ width: '55%', gap: 10 }}>
					<Typography
						variant="subtitle2"
						style={{
							color: '#272E67',
							fontWeight: 'bold',
						}}
					>
						{t('Inicio.Solicite seu código de cliente pelo formulário abaixo')}
					</Typography>
					<TextField
						size="small"
						placeholder={t('Inicio.Nome')}
						name="name"
						id="name"
						variant="outlined"
						value={formData?.name}
						helperText={formErrors?.name}
						error={Boolean(formErrors?.name)}
						onChange={(e) => {
							setFormData({
								...formData,
								name: e.target.value,
							});
						}}
						sx={{
							'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#505682',
								},
							'& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#505682',
								},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#272E67',
								},
							},
							width: '100%',
						}}
					/>
					<InputMask
						size="small"
						mask="(99) 9 9999-9999"
						placeholder={t('Inicio.Telefone')}
						name="phone"
						id="phone"
						maskChar={null}
						value={formData?.phone}
						onChange={(e) => {
							setFormData({
								...formData,
								phone: e.target.value,
							});
						}}
					>
						{(inputProps: any) => (
							<TextField
								sx={{
									'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
										{
											borderColor: '#505682',
										},
									'& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline':
										{
											borderColor: '#505682',
										},
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: '#272E67',
										},
									},
									width: '100%',
								}}
								{...inputProps}
								variant="outlined"
								helperText={formErrors?.phone}
								error={Boolean(formErrors?.phone)}
							/>
						)}
					</InputMask>
					<TextField
						size="small"
						placeholder={t('E-mail')}
						name="email"
						id="email"
						fullWidth
						variant="outlined"
						value={formData?.email}
						helperText={formErrors?.email}
						error={Boolean(formErrors?.email)}
						onChange={(e) => {
							setFormData({
								...formData,
								email: e.target.value,
							});
						}}
						sx={{
							'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#505682',
								},
							'& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#505682',
								},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#272E67',
								},
							},
						}}
					/>
					<TextField
						size="medium"
						placeholder={t('Inicio.Descrição')}
						name="description"
						id="description"
						rows={2}
						multiline
						variant="outlined"
						value={formData?.description}
						helperText={formErrors?.description}
						error={Boolean(formErrors?.description)}
						onChange={(e) => {
							setFormData({
								...formData,
								description: e.target.value,
							});
						}}
						sx={{
							'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#505682',
								},
							'& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#505682',
								},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#272E67',
								},
							},
							width: '100%',
						}}
					/>
				</Column>
			</Line>
		</DialogContent>
	);

	const renderFooter = () => (
		<Line
			style={{
				height: 60,
				borderTopColor: '#f2f2f2',
				borderTopWidth: 1,
				borderTopStyle: 'solid',
				justifyContent: 'space-between',
				padding: 15,
			}}
		>
			<Button
				size="small"
				variant="text"
				onClick={handleCloseModal}
				style={{
					color: '#cf3227',
				}}
			>
				{t('Inicio.Cancelar')}
			</Button>
			<Button
				size="small"
				variant="contained"
				onClick={handleSubmitData}
				style={{
					background: '#272E67',
					color: 'white',
				}}
			>
				{t('Enviar')}
			</Button>
		</Line>
	);

	return (
		<Dialog maxWidth="md" open={openModal} onClose={handleCloseModal}>
			{renderHeader()}
			{renderContent()}
			{renderFooter()}
		</Dialog>
	);
};

export default ContactUs;
