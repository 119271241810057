import React from 'react';
import Lottie from 'react-lottie';

interface Props {
	animationData: any;
	height?: string;
	width?: string;
	loop?: boolean;
}

const LottieAnimation: React.FC<Props> = ({
	animationData,
	height,
	width,
	loop,
}: Props) => {
	const defaultOptions = {
		loop: loop || false,
		autoplay: true,
		animationData: animationData.default,
	};
	return (
		<div
			data-testid="LottieAnimation"
			style={{
				display: 'flex',
				width: width || 'auto',
				height: height || '100%',
				//marginLeft: '-50%',
				//translate: '50%',
			}}
		>
			<Lottie options={defaultOptions} />
		</div>
	);
};

export default LottieAnimation;
