import React from 'react';
import { Avatar } from '@mui/material';
import ImageUnavailable from '../../assets/images/image-unavailable.png';

type imageType = 'platform' | 'image';
type cursorMode = 'default' | 'pointer' | 'wait';

interface Props {
	data: string;
	dataType: imageType;
	size?: number;
	borderRadius?: number | string;
	onClick?: () => void;
	cursor: cursorMode;
	loading?: boolean;
}
/**
 *
 * @props platform: string; platformINcountryID (SHOPEE|BRA)
 *        size: number; Width or height of the log output (32: outputs 32x32 logo)
 *
 * @returns Avatar with platform logo
 */

const PlatformIcon: React.FC<Props> = ({
	data,
	dataType,
	size,
	borderRadius,
	onClick,
	cursor = 'default',
	loading = false,
}: Props) => {
	const imageStyle = {
		width: size,
		height: size,
		borderRadius: borderRadius,
		cursor: loading ? 'wait' : cursor,
	};

	return dataType === 'platform' ? (
		<Avatar
			onClick={onClick}
			variant="square"
			alt={`${data?.split('|')[0]} logo`}
			src={`https://storage.googleapis.com/dev-plataforma-offer/logos/plataforma/${
				data?.split('|')[0]
			}`}
			style={imageStyle}
			data-testid="platformComponent"
		/>
	) : (
		<Avatar
			onClick={onClick}
			variant="square"
			src={data || ImageUnavailable}
			style={imageStyle}
			data-testid="ImageComponent"
		/>
	);
};

export default PlatformIcon;
