import React, { useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { Column, Line } from '../../../styles';

interface Props {
	rowData: any;
}

const RulePopover: React.FC<Props> = ({ rowData }: Props) => {
	const [openPopover, setOpenPopover] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleTogglePopover = (event) => {
		setOpenPopover(!openPopover);
		setAnchorEl(event.currentTarget);
	};

	const renderOfferTooltip = () => (
		<Line
			onClick={handleTogglePopover}
			style={{ columnGap: 5, cursor: 'pointer' }}
		>
			{rowData?.map((el: any, index: number) => (
				<React.Fragment key={index}>
					<Typography>{el.fields}</Typography>
					<Typography style={{ fontWeight: 'bold' }}>{el.operators}</Typography>
					<Typography>{el.value}</Typography>
					<Typography style={{ fontWeight: 'bold' }}>
						{el.concatOperator || ''}
					</Typography>
				</React.Fragment>
			))}
		</Line>
	);

	const changeTextFields = (operators) => {
		switch (operators) {
			case 'contain':
				return 'contiver a expressão';
			case 'equal':
				return 'for igual a';
			case 'greater than':
				return 'for maior que';
			case 'less than':
				return 'for menor que';
			case 'greater than or equal':
				return 'for maior ou igual a';
			case 'less than or equal':
				return 'for menor ou igual a';
			case '&':
				return 'E';
			case '|':
				return 'OU';
			default:
				return operators;
		}
	};

	const renderPopoverContent = () => (
		<Column
			style={{
				padding: 10,
				height: '100%',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
			onBlur={() => setOpenPopover(false)}
		>
			{rowData?.map((el, index) => (
				<React.Fragment key={index}>
					<Line
						style={{
							columnGap: 5,
							display: 'inline-flex',
							justifyContent: 'center',
						}}
						key={index}
					>
						{index % 2 === 0 ? (
							<Typography>Se o campo</Typography>
						) : (
							<Typography>o campo</Typography>
						)}

						<Typography style={{ fontStyle: 'italic', fontWeight: 'bolder' }}>
							{el.fields}
						</Typography>
						<Typography>{changeTextFields(el.operators)}</Typography>
						<Typography>{`"${el.value}"`}</Typography>
					</Line>
					{el.concatOperator === '&' || el.concatOperator === '|' ? (
						<Typography style={{ fontWeight: 'bold', justifyItems: 'center' }}>
							{changeTextFields(el.concatOperator)}
						</Typography>
					) : null}
				</React.Fragment>
			))}
		</Column>
	);

	return (
		<>
			{renderOfferTooltip()}
			<Popover
				style={{ zIndex: 10000 }}
				anchorEl={anchorEl}
				open={openPopover}
				onClose={handleTogglePopover}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{renderPopoverContent()}
			</Popover>
		</>
	);
};
export default RulePopover;
