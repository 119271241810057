import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, List, ListItem, Zoom } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material/';
import OfferLogo from '../../../assets/images/svg/offer.svg';
import OfferLogoMain from '../../../assets/images/svg/offer-main.svg';
import { usePO } from '../../../utils/POContext';
import { Line } from '../../../styles';
import { OfferToolTip } from '../../../helpers';

interface Props {
	openMenu: boolean;
	denseMenu: boolean;
	handleOpenDrawer: () => void;
}

const TopMenuIcons: React.FC<Props> = ({
	openMenu,
	denseMenu,
	handleOpenDrawer,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const handleChangedTheme = () => {
		if (selectedTheme.id === 'main') {
			return selectedTheme.primary;
		} else if (selectedTheme.id === 'dark') {
			return selectedTheme.textColorHigh;
		} else {
			return 'white';
		}
	};

	const renderOpenMenuButton = () => {
		if (openMenu) {
			return (
				<ChevronLeft
					style={{
						fontSize: denseMenu ? 28 : 32,
						color: handleChangedTheme(),
					}}
				/>
			);
		} else {
			return (
				<OfferToolTip
					placement="right"
					title={`${t('VertMenu.Expandir Menu')}`}
					aria-label="Expandir Menu"
					arrow
					enterDelay={200}
					enterNextDelay={200}
					TransitionComponent={Zoom}
				>
					<ChevronRight
						style={{
							fontSize: denseMenu ? 28 : 32,
							marginRight: -3,
							color: handleChangedTheme(),
						}}
					/>
				</OfferToolTip>
			);
		}
	};

	return (
		<div
			style={{
				height: 70,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginRight: 12,
				gap: denseMenu ? 0 : 7,
			}}
		>
			<List>
				<ListItem>
					<Line
						style={{
							marginTop: denseMenu ? 0 : 15,
							marginRight: denseMenu ? -20 : -14,
						}}
					>
						<IconButton
							href="https://www.offertech.com.br"
							target="/blank"
							disabled={!openMenu}
							style={{ borderRadius: 10 }}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<img
								alt="Offer logo"
								src={selectedTheme.id === 'main' ? OfferLogoMain : OfferLogo}
								style={denseMenu ? { transform: 'scale(0.8,0.8)' } : {}}
							/>
						</IconButton>
					</Line>
				</ListItem>
			</List>
			<IconButton
				sx={{
					marginTop: denseMenu ? '0px' : '10px',
					marginRight: denseMenu ? '-2px' : '0px',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
				onClick={handleOpenDrawer}
			>
				{renderOpenMenuButton()}
			</IconButton>
		</div>
	);
};

export default TopMenuIcons;
