import styled from 'styled-components';

export const DivContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const ButtonPagination = styled.button`
  margin-right: 4px;
  margin-left: 4px;
  padding: 12px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
