import React from 'react';
import {
	Dialog,
	DialogContent,
	DialogActions,
	IconButton,
	Typography,
	DialogTitle,
	Button,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SelectClient from '../SelectClient';
import { Column, Line } from '../../styles';
import { usePO } from '../../utils/POContext';

interface Props {
	open: boolean;
	close: () => void;
	customTextComponent?: any;
}

const SelectClientModal: React.FC<Props> = ({
	open,
	close,
	customTextComponent,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, selectedTheme } = usePO();

	const handleThemeFooterButton = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.primaryDark;
		}
	};

	const handleThemeTextHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleThemeCloseIcon = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleClose = () => {
		close();
	};

	const renderFooter = () => (
		<Line
			style={{
				justifyContent: 'flex-end',
			}}
		>
			<Button
				size="small"
				data-testid="closeButton"
				onClick={handleClose}
				style={{
					color: handleThemeFooterButton(),
				}}
			>
				{t('SelectClientModal.Fechar')}
			</Button>
		</Line>
	);

	return (
		<div>
			<Dialog open={open} onClose={handleClose} scroll="body">
				<DialogTitle
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						width: 500,
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.primary
								: selectedTheme.foreground,
					}}
					id="max-width-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						<Typography
							noWrap
							style={{
								color: handleThemeTextHeader(),
								fontSize: 20,
								fontWeight: 'bold',
								maxWidth: '90%',
							}}
						>
							{t('SelectClientModal.Selecione um Cliente')}
						</Typography>
						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded
								style={{
									color: handleThemeCloseIcon(),
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent
					style={{
						padding: '20px 30px',
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay6dp
								: selectedTheme?.foreground,
					}}
				>
					<Column
						style={{
							height: '100%',
							alignItems: 'start',
							justifyContent: 'space-between',
							rowGap: 10,
						}}
					>
						{customTextComponent || null}
						<SelectClient variant="outlined" width="95%" />
						{selectedClient?.logo ? (
							<Line style={{ padding: 25, justifyContent: 'center' }}>
								<img
									key={selectedClient.logo}
									style={{ maxWidth: 210 }}
									alt={`${selectedClient?.name} logo`}
									src={selectedClient?.logo || null}
								/>
							</Line>
						) : null}
					</Column>
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: selectedTheme.disabled,
						padding: 10,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay3dp
								: selectedTheme?.foreground,
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SelectClientModal;
