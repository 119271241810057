import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	Dialog,
	DialogContent,
	Box,
	CircularProgress,
	IconButton,
	Zoom,
} from '@mui/material';
import { getAppraisals } from '../../../services/entities';
import { enqueueSnackbar } from 'notistack';
import { usePO } from '../../../utils/POContext';
import { getOfferByAuthor } from '../../../services/authors/offersByAuthorID';
import { useTranslation } from 'react-i18next';
import A4Component from './A4Component';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { OfferToolTip } from '../../../helpers';
import InformationModal from './A4Component/InformationModal';

interface Offer {
	offerID: string;
	title: string;
	permalink: string;
	thumb: string;
	authorID: string;
	stock: number | null;
	itemsSold: number | null;
	price: number | null;
	currency: string | null;
	platformINcountryID: string;
	sourceID: string;
	ISads: boolean | null;
	ISprofile: boolean | null;
	tags: Array<{
		name: string;
		description: string;
		tagTypeID: string;
		clientID: string;
		textColor: string;
		badgeColor: string;
		ISactive: boolean;
		tagID: string;
	}>;
	valueStock: number;
	valueItemsSold: number;
}

interface Hits {
	_id: string;
	_source: {
		offer: Offer;
	};
}

interface OffersData {
	hits: Hits[];
}

interface AppraisalData {
	id: string;
	clientID: string;
	entityID: string;
	offerClassificationIDs: string[];
	created: string;
	updated: string;
}

const EntityAppraisalPage = () => {
	const { selectedClient, selectedTheme } = usePO();
	const { entityID } = useParams<any>();
	const { t } = useTranslation();

	const [singleAppraisalData, setSingleAppraisalData] =
		useState<AppraisalData | null>(null);
	const [offersData, setOffersData] = useState<OffersData | null>(null);
	const [loading, setLoading] = useState(false);
	const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(true);

	useEffect(() => {
		if (entityID) {
			fetchAppraisalsData();
		}
	}, [entityID]);

	useEffect(() => {
		if (singleAppraisalData) {
			fetchData();
		}
	}, [singleAppraisalData]);

	useEffect(() => {
		const handlePrintPage = () => {
			setDialogInfoOpen(false);
			setButtonVisible(false);
		};

		const handleAfterPrint = () => {
			setButtonVisible(true);
		};

		window.addEventListener('beforeprint', handlePrintPage);
		window.addEventListener('afterprint', handleAfterPrint);

		return () => {
			window.removeEventListener('beforeprint', handlePrintPage);
			window.removeEventListener('afterprint', handleAfterPrint);
		};
	}, []);
	const fetchAppraisalsData = async () => {
		setLoading(true);
		try {
			const response = await getAppraisals();
			if (response?.success) {
				const appraisal = response.data.find(
					(appraisal: AppraisalData) => appraisal?.entityID === entityID
				);
				setSingleAppraisalData(appraisal || null);
			} else {
				enqueueSnackbar(response?.message, { variant: 'error' });
			}
		} catch (error) {
			enqueueSnackbar(t(`Entidades.Laudo não encontrado`), {
				variant: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const fetchData = async () => {
		setLoading(true);
		try {
			const offerClassificationIDs =
				singleAppraisalData?.offerClassificationIDs || [];
			const query = `{"preference":"ReactiveListResult"}\n{"query":{"bool":{"must":[{"terms":{"offerClassification.offerClassificationID": ${JSON.stringify(offerClassificationIDs)}}},{"match":{"offerClassification.clientID": "${selectedClient.id}"}}]}},"size": 100}\n`;
			const response: any = await getOfferByAuthor(query);
			if (response?.success === true) {
				setOffersData(response.data.responses[0].hits);
			} else if (response && response.status >= 400) {
				enqueueSnackbar(t(`${response.message}`), { variant: 'error' });
			}
		} catch (error) {
			enqueueSnackbar('Failed to fetch offers', { variant: 'error' });
		} finally {
			setLoading(false);
		}
	};

	const handleIconClick = () => {
		setDialogInfoOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogInfoOpen(false);
	};

	return (
		<div>
			<Dialog open fullScreen>
				{buttonVisible && (
					<OfferToolTip
						title={t('Entidades.Impressão.Guia para impressão')}
						aria-label="inativo"
						enterDelay={300}
						enterNextDelay={300}
						TransitionComponent={Zoom}
						placement={'left'}
						arrow
					>
						<IconButton
							onClick={handleIconClick}
							style={{
								position: 'fixed',
								right: 20,
								bottom: 20,
								backgroundColor: 'white',
							}}
						>
							<InfoRoundedIcon
								fontSize="large"
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.currentPageButton
											: selectedTheme.primary,
								}}
							/>
						</IconButton>
					</OfferToolTip>
				)}

				<DialogContent key={`${offersData}`}>
					{loading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							minHeight="200px"
						>
							<CircularProgress />
						</Box>
					) : offersData && offersData.hits ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
								gap: 30,
								breakInside: 'avoid',
								pageBreakInside: 'avoid',
							}}
						>
							{offersData.hits.map((el: any, index) => (
								<A4Component data={el} key={`onlyOfferPage${index}`} />
							))}
						</div>
					) : null}
				</DialogContent>
			</Dialog>
			<InformationModal
				dialogInfoOpen={dialogInfoOpen}
				handleCloseDialog={handleCloseDialog}
			/>
		</div>
	);
};

export default EntityAppraisalPage;
