import { DataSearch } from '@appbaseio/reactivesearch';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { SearchRounded } from '@mui/icons-material/';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../utils/POContext';

const BasicseachHeader = () => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const [basicSearch, setBasicSearch] = useState('');
	const [basicSearchValue, setBasicSearchValue] = useState('');

	// Basic Saerch
	const handleBasicSearchTextChange = (event) => {
		setBasicSearchValue(event.target.value);
		if (event.target.value === '') {
			setBasicSearch('');
		}
	};
	const handleBasicSearch = () => {
		setBasicSearch(basicSearchValue);
	};
	const handleBasicSearchByKeyPress = (event) => {
		if (event.keyCode === 13) {
			setBasicSearch(basicSearchValue);
		}
	};

	const renderSearchField = () => (
		<TextField
			variant="standard"
			placeholder={t('Autores.Busca nos resultados')}
			onChange={handleBasicSearchTextChange}
			onKeyDown={handleBasicSearchByKeyPress}
			style={{
				minWidth: 300,
				height: 40,
				background:
					selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '#ffff',
				borderRadius: 20,
				margin: '0 10px',
				boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
				border:
					selectedTheme.id === 'dark'
						? `1px solid ${selectedTheme.border}`
						: 'none',
			}}
			InputProps={{
				style: {
					height: 40,
					paddingLeft: 20,
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				},
				disableUnderline: true,
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							id="basicSearch"
							size="small"
							onClick={handleBasicSearch}
							style={{
								paddingRight: 15,
								borderRadius: '0px 20px 20px 0px ',
							}}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<SearchRounded
								fontSize="small"
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								}}
							/>
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);

	return (
		<>
			<DataSearch
				componentId="searchbox"
				placeholder={t('Autores.Buscar por palavra-chave, link ou ID')}
				dataField="*"
				value={basicSearch}
				showIcon={false}
				autosuggest={false}
				highlight={false}
				fuzziness="AUTO"
				queryFormat="and"
				debounce={600}
				filterLabel="Busca"
				style={{
					display: 'none',
				}}
			/>
			{renderSearchField()}
		</>
	);
};
export default BasicseachHeader;
