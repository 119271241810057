import React from 'react';
import { IconButton, Dialog, DialogContent, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';

interface props {
	open: boolean;
	setOpen: any;
	imageIndexToShow: any;
	images: any;
}

const FullSizeImageDialog: React.FC<props> = ({
	open,
	setOpen,
	imageIndexToShow,
	images,
}: props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={open}
				maxWidth="xl"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				slotProps={{
					backdrop: {
						style: {
							backdropFilter: 'blur(15px)',
						},
					},
				}}
				PaperProps={{
					style: {
						backgroundColor: '#ffffff03',
					},
				}}
			>
				<DialogContent>
					<Line
						style={{
							justifyContent: 'space-between',
							position: 'fixed',
							top: 0,
							left: 0,
							zIndex: 300,
						}}
					>
						<Typography
							variant="subtitle2"
							color="primary"
							style={{
								margin: 15,
								opacity: 0.7,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primary,
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.vertMenuColor
										: selectedTheme.foreground,
								borderRadius: 4,
								padding: '4px 8px',
							}}
						>
							{`${t("Classificações.Pressione 'Esc' para sair")}`}
						</Typography>
						<IconButton
							onClick={handleClose}
							style={{
								width: 30,
								opacity: 0.7,
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.vertMenuColor
										: selectedTheme.foreground,
								borderRadius: 4,
								padding: '4px 0',
								margin: 15,
							}}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.primary,
								}}
							/>
						</IconButton>
					</Line>

					<img
						alt="imagem"
						src={images[imageIndexToShow]}
						style={{ maxHeight: '80vh', maxWidth: '80vw' }}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default FullSizeImageDialog;
