import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'cardFeedback',
	initialState: {
		goodCardsList: [],
		notGoodCardsList: [],
		underInvestigationCardList: [],
	},
	reducers: {
		updateGoodCards(state, { payload }) {
			return { ...state, goodCardsList: payload };
		},
		updateNotGoodCards(state, { payload }) {
			return {
				...state,
				notGoodCardsList: payload,
			};
		},
		updateUnderInvestigationCard(state, { payload }) {
			return { ...state, underInvestigationCardList: payload };
		},
	},
});

export const {
	updateGoodCards,
	updateUnderInvestigationCard,
	updateNotGoodCards,
} = slice.actions;

export const cardsFeedbackList = (state) => state;

export default slice.reducer;
