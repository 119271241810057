import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/Loading/loading_dots.json';
import { Line } from '../../styles';

interface Props {
  height?: number;
  width?: number;
  loop?: boolean;
}

const LottieAnimation: React.FC<Props> = ({ height, width, loop }: Props) => {
  const defaultOptions = {
    loop: loop || false,
    autoplay: true,
    animationData,
  };
  return (
    <Line
      data-testid="LoadingDotsComponent"
      style={{
        display: 'flex',
        height: height || '100%',
        width: width || 'auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie options={defaultOptions} />
    </Line>
  );
};

export default LottieAnimation;
