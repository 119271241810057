/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios, { AxiosError } from 'axios';

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	timeout: 40 * 1000,
});

export const setupRequestToken = (api, token) => {
	api.interceptors.request.use(async (config) => {
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	});
};

export function isAxiosError(candidate: any): candidate is AxiosError {
	return candidate.isAxiosError === true;
}
