import React from 'react';
import * as animationData from '../../assets/animations/Unauthorized/401.json';
import Animation from '../../components/LottieAnimation';

import {
	Content,
	Title,
	WelcomeMessage,
	LoginButton,
	DisconectAccount,
} from './styles';
import { Line } from '../../styles';
import { useAuth0 } from '../../react-auth0-spa';

const Page401: React.FC = () => {
	const { logout } = useAuth0();

	const logoutWithRedirect = () =>
		logout({
			returnTo: window.location.origin,
		});

	return (
		<Line style={{ marginTop: 150, gap: 100, justifyContent: 'center' }}>
			<div
				style={{
					width: '100%',
					height: 'auto',
					maxWidth: '500px',
					maxHeight: '500px',
				}}
			>
				<Animation animationData={animationData} loop={true} />
			</div>
			<Content>
				<Title>Ops..</Title>
				<WelcomeMessage>Página não autorizada</WelcomeMessage>
				<WelcomeMessage>Solicite acesso ao administrador.</WelcomeMessage>
				<LoginButton>Voltar ao inicio</LoginButton>
				<DisconectAccount onClick={() => logoutWithRedirect()}>
					Desconectar da plataforma
				</DisconectAccount>
			</Content>
		</Line>
	);
};

export default Page401;
