import React from 'react';
import { IconButton, Zoom } from '@mui/material';
import {
	Dns,
	FiberManualRecord,
	FiberManualRecordOutlined,
} from '@mui/icons-material/';
import { Link } from 'react-router-dom';
import { OfferToolTip } from '../../../../helpers';
import { usePanelControls } from '../../../../hooks/PanelControls';
import { useViewSearch } from '../../../../hooks/ViewSearch';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../utils/POContext';

interface Props {
	rowData: any;
}

const OpenCurrentSearch: React.FC<Props> = ({ rowData }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const { setSaveTagID, setSaveINcountryID } = useViewSearch();
	const { setFilterBarStates } = usePanelControls();

	const [isLoading, setIsLoading] = React.useState(false);

	const handleOpenSavedSearch = () => {
		setSaveTagID(`${rowData.tagID}`);
		setSaveINcountryID([`${rowData.platformINcountryID}`]);
		setFilterBarStates(true);
	};

	const renderLoadingAnimation = () => {
		setTimeout(() => {
			setIsLoading(!isLoading);
		}, 400);
		return isLoading ? (
			<FiberManualRecord
				style={{
					color: 'green',
					fontSize: 20,
					marginTop: 6,
					marginRight: -5,
					marginLeft: -3,
				}}
			/>
		) : (
			<FiberManualRecordOutlined
				style={{
					color: 'green',
					fontSize: 20,
					marginTop: 6,
					marginRight: -5,
					marginLeft: -3,
				}}
			/>
		);
	};
	return rowData.inProgress ? (
		<div style={{ paddingInline: 12 }}>{renderLoadingAnimation()}</div>
	) : (
		<OfferToolTip
			title={`${t(
				'Buscas.Tabs.Buscas Salvas.Visualizar resultados desta busca'
			)}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				component={Link}
				to="/classificacoes"
				onClick={handleOpenSavedSearch}
				style={{ padding: '10px 5px' }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Dns
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);
};

export default OpenCurrentSearch;
