import React, { createContext, ReactNode, useContext, useState } from 'react';

type VertMenuTypes = 'thin' | 'normal' | 'expanded';
type VertMenuStates = true | false;
type FilterBarTypes = 'thin' | 'normal' | 'expanded';
type FilterBarStates = true | false;

interface PanelControlsData {
	filterBarStates: FilterBarStates;
	filterBarType: FilterBarTypes;
	vertMenuState: VertMenuStates;
	vertMenuType: VertMenuTypes;
	setFilterBarStates: (state: FilterBarStates) => void;
	setFilterBarType: (type: FilterBarTypes) => void;
	setVertMenuState: (state: VertMenuStates) => void;
	setVertMenuType: (type: VertMenuTypes) => void;
}

const PanelControlsContext = createContext<PanelControlsData>(
	{} as PanelControlsData
);

interface Props {
	children: ReactNode;
}

export const PanelControls: React.FC<Props> = ({ children }: Props) => {
	const [filterBarStates, setFilterBarStates] =
		useState<FilterBarStates>(false);
	const [filterBarType, setFilterBarType] = useState<FilterBarTypes>('normal');
	const [vertMenuState, setVertMenuState] = useState<VertMenuStates>(false);
	const [vertMenuType, setVertMenuType] = useState<VertMenuTypes>('normal');

	return (
		<PanelControlsContext.Provider
			value={{
				filterBarStates,
				filterBarType,
				vertMenuState,
				vertMenuType,
				setFilterBarStates,
				setFilterBarType,
				setVertMenuState,
				setVertMenuType,
			}}
		>
			{children}
		</PanelControlsContext.Provider>
	);
};

export function usePanelControls(): PanelControlsData {
	return useContext(PanelControlsContext);
}
