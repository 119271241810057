import React, { useEffect, useState } from 'react';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Paper,
	Typography,
	Zoom,
} from '@mui/material';
import { CloseRounded, InsertChartRounded, GetApp } from '@mui/icons-material/';
import { Column, Line } from '../../../../../styles';
import { HandleGetThemeColor, OfferToolTip } from '../../../../../helpers';
import { usePO } from '../../../../../utils/POContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import BackdropLoading from '../../../../../components/BackdropLoading';
import { Chart } from 'react-google-charts';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import BiggestSellersTable from './BiggestSellersTable';
import {
	fetchSearchDataReports,
	fetchSearchReports,
	fetchUSDCurrencyRate,
} from '../../../../../services/searches/searchDetails';
import OfferLogo from '../../../../../assets/images/logos/logo_offer_completo.png';
import countryNames from 'i18n-iso-countries';
import { useHistory, useParams } from 'react-router-dom';

countryNames.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);
countryNames.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/en.json')
);
countryNames.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/es.json')
);

interface Props {
	rowData: any;
	searchData: any;
	currency: string;
}

const SearchReportsDialog: React.FC<Props> = ({
	rowData,
	searchData,
	currency,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, userActiveClients, selectedClient, userData } =
		usePO();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { searchID, searchKey, offerCurrency } = useParams<{
		searchID: string;
		searchKey: string;
		offerCurrency: string;
	}>();

	useEffect(() => {
		if (searchID && searchKey) {
			handleReportSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchID, searchKey]);

	const clientLogo = userActiveClients.find(
		(client) => client.id === selectedClient.id
	)?.logo;

	const [loading, setLoading] = useState(false);
	const [loadedChartData, setLoadedChartData] = useState<boolean>(false);
	const [openSearchReportModal, setOpenSearchReportModal] =
		useState<boolean>(false);
	const [fetchSearchData, setFetchSearchData] = useState<any>([]);
	const [searchTotalOffers, setSearchTotalOffers] = useState<any>();
	const [searchTotalItems, setSearchTotalItems] = useState<any>();
	const [searchTotalStockItems, setSearchTotalStockItems] = useState<any>();
	const [searchTotalAuthors, setSearchTotalAuthors] = useState<any>();
	const [searchMeanPrice, setSearchMeanPrice] = useState<any>();
	const [searchTotalItemsSold, setSearchTotalItemsSold] = useState<any>();
	const [searchTotalItemsStock, setSearchTotalItemsStock] = useState<any>();
	const [searchTopSellers, setSearchTopSellers] = useState<any>([]);
	const [offerStateDistribution, setOfferStateDistribution] = useState<any>([]);
	const [offerCityDistribution, setOfferCityDistribution] = useState<any>([]);
	const [offerPriceDistribution, setOfferPriceDistribution] = useState<any>([]);
	const [USDCurrencyRate, setUSDCurrencyRate] = useState<number>();

	(function () {
		const supportsPassive = (() => {
			let passiveSupported = false;
			try {
				const options = {
					get passive() {
						passiveSupported = true;
						return false;
					},
				};
				const testEventListener = () => {};
				window.addEventListener('test', testEventListener, options);
				window.removeEventListener('test', testEventListener);
			} catch (err) {
				passiveSupported = false;
			}
			return passiveSupported;
		})();

		if (supportsPassive) {
			const addEventListenerOriginal = EventTarget.prototype.addEventListener;
			EventTarget.prototype.addEventListener = function (
				type,
				listener,
				options
			) {
				if (
					type === 'mousewheel' ||
					type === 'touchstart' ||
					type === 'touchmove'
				) {
					if (typeof options === 'object') {
						options.passive = true;
					} else {
						options = { passive: true };
					}
				}
				addEventListenerOriginal.call(this, type, listener, options);
			};
		}
	})();

	const chartColorThemes = {
		red: ['#e65b71'],
		dark: ['#7ed0ec'],
		summer: ['#dac242'],
		winter: ['#82b3ff'],
		default: ['#ff781f'],
	};

	const chartColumnOptions = {
		legend: 'none',
		colors: chartColorThemes['default'],
		vAxis: { textStyle: { bold: true } },
		hAxis: {
			slantedText: true,
			slantedTextAngle: 35,
			textStyle: {
				bold: true,
				fontSize: 12,
			},
		},
	};

	const chartBarOptions = {
		legend: 'none',
		colors: chartColorThemes['default'],
		vAxis: { direction: -1, textStyle: { bold: true } },
		hAxis: {
			textStyle: { bold: true },
		},
	};

	const getUSDCurrencyRate = async () => {
		const exchangeResponse: any = await fetchUSDCurrencyRate(offerCurrency);
		if (exchangeResponse.success) {
			setUSDCurrencyRate(exchangeResponse.data.result);
		} else {
			enqueueSnackbar(t(exchangeResponse.error), { variant: 'error' });
		}
	};

	const handleReportSearch = async () => {
		setLoading(true);
		const searchDataResponse: any = await fetchSearchDataReports(
			searchID,
			selectedClient.id
		);
		setFetchSearchData(searchDataResponse.data);
		const searchReportsResponse: any = await fetchSearchReports(
			searchID,
			searchKey
		);
		if (searchReportsResponse.success) {
			// Check if all data has been returned. if not show warning
			if (Object.keys(searchReportsResponse).length > 2) {
				Object.keys(searchReportsResponse).length !== 12 &&
					enqueueSnackbar(
						t(
							'Buscas.Tabs.Buscas Salvas.Alguns dados deste relatório não estão disponíveis.'
						),
						{ variant: 'warning' }
					);
			}
			await getUSDCurrencyRate();
			setSearchTotalOffers(searchReportsResponse.searchTotalOffers || []);
			setSearchTotalItems(searchReportsResponse.searchTotalItems || []);
			setSearchTotalStockItems(
				searchReportsResponse.searchTotalStockItems || []
			);
			setSearchTotalAuthors(searchReportsResponse.searchTotalAuthors || []);
			setSearchMeanPrice(searchReportsResponse.searchMeanPrice || []);
			setSearchTotalItemsSold(searchReportsResponse.searchTotalItemsSold || []);
			setSearchTotalItemsStock(
				searchReportsResponse.searchTotalItemsStock || []
			);
			setOfferStateDistribution(
				searchReportsResponse.offerStateDistribution || []
			);
			setOfferCityDistribution(
				searchReportsResponse.offerCityDistribution || []
			);
			setOfferPriceDistribution(
				formattedChartData(searchReportsResponse.offerPriceDistribution) || []
			);
			setSearchTopSellers(searchReportsResponse.searchTopSellers || []);
			setLoadedChartData(true);
		} else {
			enqueueSnackbar(
				t(
					'Buscas.Tabs.Buscas Salvas.Os dados deste relatório não estão disponíveis.'
				),
				{ variant: 'warning' }
			);
			setLoading(false);
			handleClose();
		}
		setLoading(false);
	};

	const handleClose = () => {
		history.push('/busca');
		setOpenSearchReportModal(false);
	};

	const handleOpen = () => {
		const searchID = searchData.searchID;
		const searchKey = rowData.clickHouseSearchKey;
		const offerCurrency = currency;
		const newUrl = `/searchID/${searchID}/searchKey/${searchKey}/${offerCurrency}`;
		window.open(newUrl, '_blank');
	};

	const renderReportsButton = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Visualizar relatório da busca')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={handleOpen}
				style={{ marginRight: -4 }}
				disabled={rowData.clickHouse === false}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<InsertChartRounded
					style={{
						fontSize: 22,
						color: HandleGetThemeColor({
							themeID: 'dark',
							primaryThemeColor:
								rowData.clickHouse === false
									? selectedTheme.textColorDisable
									: selectedTheme.textColorHigh,
							alternateThemeColor: '',
						}),
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				background: '#f1f1f1',
				padding: '15px 25px',
				display: 'flex',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					variant="subtitle2"
					color="primary"
					style={{
						opacity: 0.7,
						fontWeight: 'bold',
						color: '#272E67',
						background: '#fff',
						borderRadius: 4,
						padding: '8px 12px',
						boxShadow:
							'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
					}}
				>
					{`${t("Classificações.Pressione 'Esc' para sair")}`}
				</Typography>
				<IconButton
					onClick={handleClose}
					style={{
						opacity: 0.7,
						background: '#fff',
						borderRadius: 4,
						padding: '8px 12px',
						boxShadow:
							'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
					}}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color: '#272E67',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const handleCurrencyConverter = (
		data: any,
		i18nID: string,
		rateValue: number
	) => {
		const newValue = data * rateValue;
		return parseFloat(`${newValue}`).toLocaleString(i18nID, {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
		});
	};

	const handleCurrencyValue = (
		data: number,
		i18nID: string,
		currency?: string
	) => {
		if (data) {
			if (currency) {
				return data.toLocaleString(i18nID, {
					style: 'currency',
					currency: currency,
					minimumFractionDigits: 2,
				});
			} else {
				return data.toLocaleString(i18nID);
			}
		} else return '-';
	};

	const renderReportHeader = () => (
		<Line
			style={{
				flexDirection: 'column',
				padding: '25px 0px 10px',
				display: 'flex',
				alignItems: 'flex-start',
				width: '100%',
				gap: 15,
			}}
		>
			<Box>
				<img
					src={OfferLogo}
					style={{
						height: '9vw',
						filter: 'brightness(1)',
					}}
					alt="Offer Technologies Logo"
				/>
			</Box>
			<Box>
				<Typography
					align="center"
					style={{
						fontSize: '3.5vw',
						fontWeight: 'bold',
						color: '#ff781f',
						marginBottom: -5,
						textTransform: 'uppercase',
					}}
				>
					{t('Buscas.Tabs.Buscas Salvas.Relatório de busca')}
				</Typography>
			</Box>
			{clientLogo !== null ? (
				<Box justifyContent="center" alignContent="center">
					<Line style={{ justifyContent: 'flex-start' }}>
						<img
							src={clientLogo}
							alt={selectedClient.id}
							style={{ height: '8vw' }}
						/>
					</Line>
				</Box>
			) : null}
		</Line>
	);

	const renderReportInfo = () => {
		let longDate: string = '';
		let platformData: string = '';
		if (searchKey) {
			const formatedDate = searchKey.replace(/_/g, '-');
			longDate = new Date(formatedDate).toLocaleDateString(userData.i18nID, {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
		}

		if (fetchSearchData?.platformINcountryID != null) {
			const platformInfo = fetchSearchData.platformINcountryID.split('|');
			const platformName =
				platformInfo[0].charAt(0).toUpperCase() +
				platformInfo[0].slice(1).toLowerCase();

			platformData = `${platformName} - ${countryNames.getName(
				platformInfo[1],
				userData.i18nID.substring(0, 2),
				{
					select: 'official',
				}
			)}`;
		}
		return (
			<Line>
				<Box width={'100%'} style={{ color: '#000' }}>
					<Line style={{ justifyContent: 'flex-start', marginBottom: '10px' }}>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
								paddingRight: '10px',
							}}
						>
							{`${t('Buscas.Tabs.Buscas Salvas.Cliente')}:`}
						</Typography>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
							}}
						>
							{selectedClient?.name}
						</Typography>
					</Line>
					<Line
						style={{
							justifyContent: 'flex-start',
							marginBottom: '10px',
						}}
					>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
								paddingRight: '10px',
							}}
						>
							{`${t('Buscas.Tabs.Buscas Salvas.Data de Busca')}:`}
						</Typography>
						<Typography
							style={{
								fontSize: '1.8vw',
								textTransform: 'uppercase',
								fontWeight: 'bold',
							}}
						>
							{longDate}
						</Typography>
					</Line>
					<Line style={{ justifyContent: 'flex-start', marginBottom: '10px' }}>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
								paddingRight: '10px',
							}}
						>
							{`${t('Buscas.Tabs.Buscas Salvas.Termo de Busca')}:`}
						</Typography>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
							}}
						>
							{fetchSearchData?.terms}
						</Typography>
					</Line>
					<Line style={{ justifyContent: 'flex-start', marginBottom: '10px' }}>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
								paddingRight: '10px',
							}}
						>
							{`${t('Buscas.Tabs.Buscas Salvas.Plataforma')}:`}
						</Typography>
						<Typography
							style={{
								fontSize: '1.8vw',
								fontWeight: 'bold',
								textTransform: 'uppercase',
							}}
						>
							{platformData}
						</Typography>
					</Line>
				</Box>
			</Line>
		);
	};

	const renderReportContent = () => (
		<Line>
			<Box key={USDCurrencyRate} width="100%">
				<Line style={{ alignItems: 'end', padding: '10px 0px 50px' }}>
					<Typography
						style={{
							color: '#ff781f',
							fontSize: '5vw',
							fontWeight: 'bold',
							textTransform: 'uppercase',
							marginBottom: '-1.05vw',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.pi')}`}
					</Typography>
					<Typography
						style={{
							fontSize: '2.5vw',
							marginLeft: '15px',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Principais Indicadores')}`}
					</Typography>
				</Line>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Total - para analisar')}`}
					</Typography>
					<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
						{searchTotalOffers
							? handleCurrencyValue(searchTotalOffers.value, userData.i18nID)
							: '-'}
						{` ${t('Buscas.Tabs.Buscas Salvas.ofertas')}`}
					</Typography>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line style={{ justifyContent: 'space-between' }}>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Total de ofertas')}`}
					</Typography>
					<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
						{searchTotalOffers
							? handleCurrencyValue(searchTotalOffers.value, userData.i18nID)
							: '-'}
						{` ${t('Buscas.Tabs.Buscas Salvas.ofertas')}`}
					</Typography>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Total de vendedores')}`}
					</Typography>
					<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
						{searchTotalAuthors
							? handleCurrencyValue(searchTotalAuthors.value, userData.i18nID)
							: '-'}
						{` ${t('Buscas.Tabs.Buscas Salvas.vendedores')}`}
					</Typography>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line style={{ justifyContent: 'space-between' }}>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Preço médio dos produtos')}`}
					</Typography>
					<Column style={{ maxWidth: '30%', alignItems: 'end' }}>
						<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
							{searchMeanPrice && offerCurrency
								? handleCurrencyValue(
										searchMeanPrice.value,
										userData.i18nID,
										offerCurrency
									)
								: '-'}
						</Typography>

						<Typography style={{ fontSize: '1.4vw', fontWeight: 'bold' }}>
							{searchMeanPrice &&
							searchMeanPrice.value &&
							searchMeanPrice.value !== '0' &&
							USDCurrencyRate &&
							offerCurrency !== 'USD'
								? handleCurrencyConverter(
										searchMeanPrice.value,
										userData.i18nID,
										USDCurrencyRate
									)
								: ''}
						</Typography>
					</Column>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Total de produtos vendidos')}`}
					</Typography>
					<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
						{searchTotalItems &&
						searchTotalItems.value &&
						searchTotalItems.value !== ''
							? `${handleCurrencyValue(searchTotalItems.value, userData.i18nID)} ${t('Buscas.Tabs.Buscas Salvas.unidades')}`
							: '-'}
					</Typography>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line style={{ justifyContent: 'space-between' }}>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t(
							'Buscas.Tabs.Buscas Salvas.Valor total dos produtos vendidos'
						)}:`}
					</Typography>
					<Column style={{ maxWidth: '30%', alignItems: 'end' }}>
						<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
							{searchTotalItemsSold && offerCurrency
								? handleCurrencyValue(
										searchTotalItemsSold.value,
										userData.i18nID,
										offerCurrency
									)
								: '-'}
						</Typography>

						<Typography style={{ fontSize: '1.4vw' }}>
							{searchTotalItemsSold &&
							searchTotalItemsSold.value &&
							searchTotalItemsSold.value !== '0' &&
							USDCurrencyRate &&
							offerCurrency !== 'USD'
								? handleCurrencyConverter(
										searchTotalItemsSold.value,
										userData.i18nID,
										USDCurrencyRate
									)
								: ''}
						</Typography>
					</Column>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line style={{ justifyContent: 'space-between' }}>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Total de itens em estoque')}`}
					</Typography>
					<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
						{searchTotalStockItems &&
						searchTotalStockItems.value &&
						searchTotalStockItems.value !== ''
							? `${handleCurrencyValue(searchTotalStockItems.value, userData.i18nID)} ${t('Buscas.Tabs.Buscas Salvas.unidades')}`
							: '-'}
					</Typography>
				</Line>
				<Divider style={{ marginBlock: '1vw' }} />
				<Line style={{ justifyContent: 'space-between' }}>
					<Typography
						style={{
							fontSize: '1.7vw',
							textTransform: 'uppercase',
							fontWeight: 'bold',
						}}
					>
						{`${t(
							'Buscas.Tabs.Buscas Salvas.Valor total dos produtos em estoque'
						)}:`}
					</Typography>
					<Column style={{ maxWidth: '30%', alignItems: 'end' }}>
						<Typography style={{ fontSize: '1.7vw', fontWeight: 'bold' }}>
							{searchTotalItemsStock && offerCurrency
								? handleCurrencyValue(
										searchTotalItemsStock.value,
										userData.i18nID,
										offerCurrency
									)
								: '-'}
						</Typography>

						<Typography style={{ fontSize: '1.4vw' }}>
							{searchTotalItemsStock &&
							searchTotalItemsStock.value &&
							searchTotalItemsStock.value !== '' &&
							USDCurrencyRate &&
							offerCurrency !== 'USD'
								? handleCurrencyConverter(
										searchTotalItemsStock.value,
										userData.i18nID,
										USDCurrencyRate
									)
								: ''}
						</Typography>
					</Column>
				</Line>
			</Box>
		</Line>
	);

	const renderStateCharts = () => (
		<Line>
			<Box
				width="100%"
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '30px',
				}}
			>
				<Line style={{ alignItems: 'end', padding: '10px 0px' }}>
					<Typography
						style={{
							color: '#ff781f',
							fontSize: '4vw',
							fontWeight: 'bold',
							textTransform: 'uppercase',
							marginBottom: '-0.6vw',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.de')}`}
					</Typography>
					<Typography
						style={{
							fontSize: '2.5vw',
							marginLeft: '15px',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Distribuição por Estado')}`}
					</Typography>
				</Line>
				{loadedChartData ? (
					<div>{RenderStateDistributionChart(offerStateDistribution)}</div>
				) : null}
			</Box>
		</Line>
	);

	const renderCityCharts = () => (
		<Line>
			<Box
				width="100%"
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '30px',
				}}
			>
				<Line style={{ alignItems: 'end', padding: '10px 0px ' }}>
					<Typography
						style={{
							color: '#ff781f',
							fontSize: '4vw',
							fontWeight: 'bold',
							textTransform: 'uppercase',
							marginBottom: '-0.6vw',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.dc')}`}
					</Typography>
					<Typography
						style={{
							fontSize: '2.5vw',
							marginLeft: '15px',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Distribuição por Cidade')}`}
					</Typography>
				</Line>
				{loadedChartData ? (
					<>{RenderCityDistributionChart(offerCityDistribution)}</>
				) : null}
			</Box>
		</Line>
	);

	const renderPriceCharts = () => (
		<Line>
			<Box
				width="100%"
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '30px',
				}}
			>
				<Line style={{ alignItems: 'end', padding: '10px 0px ' }}>
					<Typography
						style={{
							color: '#ff781f',
							fontSize: '4vw',
							fontWeight: 'bold',
							textTransform: 'uppercase',
							marginBottom: '-0.6vw',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.dp')}`}
					</Typography>
					<Typography
						style={{
							fontSize: '2.5vw',
							marginLeft: '15px',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Distribuição por Preço')}`}
					</Typography>
				</Line>
				{loadedChartData ? (
					<>{RenderPriceDistributionChart(offerPriceDistribution)}</>
				) : null}
			</Box>
		</Line>
	);

	const renderTopSellersContent = () => (
		<Line>
			<Box width="100%">
				<Line style={{ alignItems: 'end', padding: '10px 0px 50px' }}>
					<Typography
						style={{
							color: '#ff781f',
							fontSize: '4vw',
							fontWeight: 'bold',
							textTransform: 'uppercase',
							marginBottom: '-0.6vw',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.mv')}`}
					</Typography>
					<Typography
						style={{
							fontSize: '2.5vw',
							marginLeft: '15px',
						}}
					>
						{`${t('Buscas.Tabs.Buscas Salvas.Maiores Vendedores')}`}
					</Typography>
				</Line>
				{loadedChartData ? (
					<BiggestSellersTable data={searchTopSellers} />
				) : null}
			</Box>
		</Line>
	);

	const RenderStateDistributionChart = (data) => {
		const transformData = () => {
			const result = [['Estado', 'Quantidade']];
			data.forEach((item) => {
				result.push([item.id, item.value]);
			});
			return result;
		};

		const arrayData = transformData();

		return (
			<div style={{ display: 'grid', height: 400 }}>
				<Chart
					chartType="BarChart"
					data={arrayData}
					width="100%"
					height="400px"
					options={chartBarOptions}
					legend_toggle={false}
				/>
			</div>
		);
	};

	const RenderCityDistributionChart = (data) => {
		const transformData = (data) => {
			const result = [['Cidade', 'Quantidade']];
			data.forEach((item) => {
				result.push([item.id, item.value]);
			});
			return result;
		};

		const arrayData = transformData(data);
		return (
			<div style={{ display: 'grid', height: 400 }}>
				<Chart
					chartType="BarChart"
					data={arrayData}
					width="100%"
					height="400px"
					options={chartBarOptions}
					legend_toggle={false}
				/>
			</div>
		);
	};

	const formattedChartData = (data) => {
		if (data.length > 0) {
			let tempData: any[] = [];

			data.map((obj: any) => {
				obj.id &&
					obj.id.split('-').map((el: string) => {
						const formattedCurrency = parseFloat(el).toLocaleString(
							userData.i18nID,
							{
								style: 'currency',
								currency: offerCurrency || 'BRL',
								minimumFractionDigits: 2,
							}
						);
						tempData.push(formattedCurrency);
						return null;
					});
				if (obj.id === null) {
					obj.id = t('Buscas.Tabs.Buscas Salvas.Desconhecido');
				} else {
					obj.id = `${tempData[0]} - ${tempData[1]}`;
				}
				tempData = [];
				return null;
			});
			setOpenSearchReportModal(true);
			return data;
		} else {
			setLoading(false);
			enqueueSnackbar(
				t(
					'Buscas.Tabs.Buscas Salvas.Os dados deste relatório não estão disponíveis.'
				),
				{ variant: 'warning' }
			);
			return null;
		}
	};

	const RenderPriceDistributionChart = (data) => {
		const transformData = (data) => {
			const result = [['Distruibuição', 'Valor']];
			data.forEach((item) => {
				result.push([item.id, item.value]);
			});
			return result;
		};

		const arrayData = transformData(data);
		return (
			<div style={{ display: 'grid', height: 450 }}>
				<Chart
					chartType="ColumnChart"
					data={arrayData}
					width="100%"
					height="450px"
					options={chartColumnOptions}
					legend_toggle={false}
				/>
			</div>
		);
	};

	const renderReportInfoPage = () => (
		<Column
			key={`${fetchSearchData?.platformINcountryID}`}
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				background: 'transparent',
			}}
		>
			<Column id="divToPrint0" style={{ padding: '20px 120px' }}>
				{renderReportHeader()}
				{renderReportInfo()}
			</Column>
		</Column>
	);

	const renderMainIndicatorsPage = () => (
		<Paper
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '100px 0px',
				padding: '20px 0px',
				margin: '20px 0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#000',
				background: '#fff',
			}}
		>
			<div id="divToPrint1" style={{ padding: '20px 120px' }}>
				<Column style={{ rowGap: 20 }}>{renderReportContent()}</Column>
			</div>
			<Line style={{ justifyContent: 'end', padding: '10px 120px' }}>
				<Divider style={{ marginBlock: '1vw' }} />
				<Typography
					style={{
						fontSize: '0.8vw',
						fontWeight: 'bold',
						textTransform: 'uppercase',
					}}
				>
					{`${selectedClient.name} - ${t(
						'Buscas.Tabs.Buscas Salvas.Página'
					)} 1`}
				</Typography>
			</Line>
		</Paper>
	);

	const renderStateDistributionPage = () => (
		<Paper
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '100px 0px',
				padding: '20px 0px',
				margin: '20px 0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#000',
				background: '#fff',
			}}
		>
			<div id="divToPrint2" style={{ padding: '20px 120px' }}>
				{renderStateCharts()}
			</div>
			<Line style={{ justifyContent: 'end', padding: '10px 120px' }}>
				<Divider style={{ marginBlock: '1vw' }} />
				<Typography
					style={{
						fontSize: '0.8vw',
						fontWeight: 'bold',
						textTransform: 'uppercase',
					}}
				>
					{`${selectedClient.name} - ${t(
						'Buscas.Tabs.Buscas Salvas.Página'
					)} 2`}
				</Typography>
			</Line>
		</Paper>
	);

	const renderCityDistributionPage = () => (
		<Paper
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '100px 0px',
				padding: '20px 0px',
				margin: '20px 0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#000',
				background: '#fff',
			}}
		>
			<div id="divToPrint3" style={{ padding: '20px 120px' }}>
				{renderCityCharts()}
			</div>
			<Line style={{ justifyContent: 'end', padding: '10px 120px' }}>
				<Divider style={{ marginBlock: '1vw' }} />
				<Typography
					style={{
						fontSize: '0.8vw',
						fontWeight: 'bold',
						textTransform: 'uppercase',
					}}
				>
					{`${selectedClient.name} - ${t(
						'Buscas.Tabs.Buscas Salvas.Página'
					)} 3`}
				</Typography>
			</Line>
		</Paper>
	);

	const renderOfferDistributionPage = () => (
		<Paper
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '100px 0px',
				padding: '20px 0px',
				margin: '20px 0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#000',
				background: '#fff',
			}}
		>
			<div id="divToPrint4" style={{ padding: '20px 120px' }}>
				{renderPriceCharts()}
			</div>
			<Line style={{ justifyContent: 'end', padding: '10px 120px' }}>
				<Divider style={{ marginBlock: '1vw' }} />
				<Typography
					style={{
						fontSize: '0.8vw',
						fontWeight: 'bold',
						textTransform: 'uppercase',
					}}
				>
					{`${selectedClient.name} - ${t(
						'Buscas.Tabs.Buscas Salvas.Página'
					)} 4`}
				</Typography>
			</Line>
		</Paper>
	);

	const renderTopSellersTable = () => (
		<Paper
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '100px 0px',
				padding: '20px 0px',
				margin: '20px 0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#000',
				background: '#fff',
			}}
		>
			<div id="divToPrint5" style={{ padding: '20px 120px' }}>
				{renderTopSellersContent()}
			</div>
			<Line style={{ justifyContent: 'end', padding: '10px 120px' }}>
				<Divider style={{ marginBlock: '1vw' }} />
				<Typography
					style={{
						fontSize: '0.8vw',
						fontWeight: 'bold',
						textTransform: 'uppercase',
					}}
				>
					{`${selectedClient.name} - ${t(
						'Buscas.Tabs.Buscas Salvas.Página'
					)} 5`}
				</Typography>
			</Line>
		</Paper>
	);

	const RenderContent = () => (
		<>
			<DialogContent
				style={{
					padding: '20px 40px',
					background: '#f1f1f1',
				}}
			>
				{renderReportInfoPage()}
				<Column style={{ rowGap: 20 }}>
					{renderMainIndicatorsPage()}
					{offerStateDistribution && offerStateDistribution.length > 0
						? renderStateDistributionPage()
						: null}
					{offerCityDistribution && offerCityDistribution.length > 0
						? renderCityDistributionPage()
						: null}
					{offerPriceDistribution && offerPriceDistribution.length > 0
						? renderOfferDistributionPage()
						: null}

					{searchTopSellers && searchTopSellers.length > 0
						? renderTopSellersTable()
						: null}
				</Column>
			</DialogContent>
			<DownloadButton />
		</>
	);

	const DownloadButton = () => (
		<div
			style={{
				backgroundColor: 'transparent',
				bottom: 0,
				display: 'flex',
				justifyContent: 'flex-end',
				position: 'absolute',
				width: '100%',
				padding: '15px 25px',
			}}
		>
			<IconButton
				onClick={printDocument}
				style={{
					backgroundColor: '#272E67',
					border: `1px solid ${
						selectedTheme.id === 'dark'
							? selectedTheme.footerLine
							: 'transparent'
					}`,
				}}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<GetApp
					style={{
						color: '#fff',
					}}
				/>
			</IconButton>
		</div>
	);

	// get current timestamp in iso format
	const getTimestamp = () => {
		const date = new Date();
		return date.toISOString();
	};

	const printDocument = async () => {
		enqueueSnackbar(
			`${t('Buscas.Tabs.Buscas Salvas.Preparando arquivo para download')}...`,
			{
				variant: 'success',
			}
		);
		const tempImage: any[] = [];

		for (let i = 0; i <= 5; i++) {
			const element = document.getElementById(`divToPrint${i}`);
			if (element) {
				await htmlToImage
					.toPng(element, { quality: 0.95 })
					.then(function (dataUrl) {
						tempImage.push(dataUrl);
					});
			}
		}

		const pdfObject = new jsPDF();
		let sumImageSizes = 10;
		const page: number = 0;

		const localeString =
			fetchSearchData.lastSearch.split(', ') || getTimestamp().split(', ');

		tempImage.map(async (el) => {
			const imgProps = pdfObject.getImageProperties(el);
			const pdfWidth = pdfObject.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

			if (page === 0 && sumImageSizes + pdfHeight <= 245) {
				pdfObject.addImage(
					el,
					'JPEG',
					0,
					sumImageSizes,
					pdfWidth,
					pdfHeight,
					'',
					'FAST'
				);
			} else if (page === 1 && sumImageSizes + pdfHeight <= 275) {
				pdfObject.addImage(
					el,
					'JPEG',
					0,
					sumImageSizes,
					pdfWidth,
					pdfHeight,
					'',
					'FAST'
				);
			} else {
				pdfObject.addPage();
				sumImageSizes = 10;
				pdfObject.addImage(
					el,
					'JPEG',
					0,
					sumImageSizes,
					pdfWidth,
					pdfHeight,
					'',
					'FAST'
				);
			}
			sumImageSizes += pdfHeight + 5;
		});
		pdfObject.save(
			`Relatório_busca_${fetchSearchData.terms}_${localeString[0]}.pdf`
		);
	};

	return (
		<>
			{!searchID && !searchKey ? renderReportsButton() : null}
			{openSearchReportModal && (
				<Dialog
					open={openSearchReportModal}
					aria-label="confirm-edit-dialog"
					fullScreen
					onClose={handleClose}
				>
					{renderHeader()}
					<RenderContent />
					{loading ? <BackdropLoading zIndex={3} /> : null}
				</Dialog>
			)}
			{loading ? (
				<BackdropLoading zIndex={1300} width={220} height={220} />
			) : null}
		</>
	);
};

export default SearchReportsDialog;
