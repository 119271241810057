import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const getEntities = async (payload) => {
	const filteredPayload = Object.entries(payload).reduce(
		(acc, [key, value]) => {
			if (
				value !== '' &&
				value !== null &&
				!(Array.isArray(value) && value.length === 0)
			) {
				acc[key] = value;
			}
			return acc;
		},
		{}
	);
	try {
		const response = await api.post('/entities/filters', filteredPayload);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const createNewEntity = async (
	payload: { [key: string]: any },
	files: File[]
) => {
	const responseMessages: string[] = [];
	const filteredPayload = Object.entries(payload).reduce(
		(acc, [key, value]) => {
			if (
				value !== '' &&
				value !== null &&
				!(Array.isArray(value) && value.length === 0)
			) {
				acc[key] = value;
			}
			return acc;
		},
		{} as { [key: string]: any }
	);
	try {
		const formResponse = await api.post('/entities', filteredPayload);
		responseMessages.push(formResponse.data.detail);
		if (files.length > 0) {
			const formData = new FormData();
			files.forEach((file) => {
				formData.append('attachments', file, file.name);
			});
			const filesResponse = await api.post(
				`/entities/${formResponse.data.id}/files/upload`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					timeout: 2 * 60 * 1000, // 2 minutes
				}
			);
			responseMessages.push(filesResponse.data.detail);
		}
		return {
			success: true,
			message: responseMessages,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateEntity = async (payload, files: File[], entityID) => {
	try {
		let filesResponse = { data: { detail: '' } };
		const formResponse: any = await api.patch(
			`/entities/${entityID}`,
			payload,
			{
				timeout: 2 * 60 * 1000, // 2 minutes
			}
		);
		if (files.length > 0) {
			const formData = new FormData();
			files.forEach((file) => {
				formData.append('attachments', file, file.name);
			});
			filesResponse = await api.post(
				`/entities/${entityID}/files/upload?entityID=${entityID}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					timeout: 2 * 60 * 1000, // 2 minutes
				}
			);
		}
		return {
			success: true,
			message: [formResponse.data.detail, filesResponse.data.detail || null],
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const removeEntitiesFiles = async (
	entityID: string,
	files: string[]
) => {
	try {
		const response = await api.delete(
			`/entities/${entityID}/files/remove/?files=${files}`
		);
		return {
			success: true,
			message: response.data.detail,
			status: response.status,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getAuthorsOnEntity = async (entityID: string) => {
	try {
		const response = await api.get(
			`/authorINentity/entities/${entityID}/authors`
		);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getEntityById = async (entityID: string) => {
	try {
		const response = await api.get(`/entities/${entityID}`);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getEntityOnAuthor = async (authorID: string) => {
	try {
		const response = await api.get(
			`/authorINentity/authors/${authorID}/entity`
		);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getAuthorsOffersOnEntity = async (requestBody) => {
	try {
		const response = await api.post(
			'offerClassification/elasticsearch/_msearch',
			requestBody
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};

export const getAppraisals = async () => {
	try {
		const response = await api.get(`/appraisals`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const createNewAppraisals = async (aprraisalPayload) => {
	try {
		const response = await api.post(`/appraisals`, aprraisalPayload);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateAppraisal = async (appraisalID, aprraisalPayload) => {
	try {
		const response = await api.patch(
			`/appraisals/${appraisalID}`,
			aprraisalPayload
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getClientData = async (clientID: 'string') => {
	try {
		const response = await api.get(`/clientINcountry/clients/${clientID}`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
