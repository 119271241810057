import { embedDashboard } from '@superset-ui/embedded-sdk';
import React, { useEffect, useRef } from 'react';

interface Props {
	BIID: string;
	token: string;
}

const RenderIFrameSuperSet: React.FC<Props> = ({ BIID, token }: Props) => {
	const mountPointRef = useRef<HTMLDivElement | null>(null);

	const fetchGuestToken = () => Promise.resolve(token);

	useEffect(() => {
		const renderEmbed = async () => {
			if (mountPointRef.current) {
				await embedDashboard({
					id: BIID,
					supersetDomain: 'https://superset.offertech.com.br',
					fetchGuestToken,
					dashboardUiConfig: {
						hideTitle: true,
						hideChartControls: true,
						hideTab: true,
					},
					mountPoint: mountPointRef.current as HTMLElement,
				});

				const iframe = mountPointRef.current.querySelector('iframe');
				if (iframe) {
					iframe.style.width = '100%';
					iframe.style.height = '100%';
				}
			}
		};

		if (mountPointRef.current) {
			renderEmbed();
		}
	}, [BIID, token]);

	return (
		<div
			id="my-superset-container"
			ref={mountPointRef}
			style={{ width: ' 100%', height: '100%' }}
		/>
	);
};

export default RenderIFrameSuperSet;
