import React from 'react';
import { Typography } from '@mui/material';
import { usePO } from '../../utils/POContext';
import { SizeType } from '../../interfaces';
import { OfferToolTip } from '../../helpers';
import { useTranslation } from 'react-i18next';

interface Props {
	dataArray: any;
	setDataArray?: (data) => void;
	classifySize?: SizeType;
}

const ClassificationSelector: React.FC<Props> = ({
	dataArray,
	classifySize,
}: Props) => {
	const { selectedTheme, classificationTypes } = usePO();
	const { t } = useTranslation();

	const displayClassificationTooptip = () => {
		const selectedClassification =
			classificationTypes.find((el) => dataArray.includes(el.id)) ?? dataArray;
		return selectedClassification ? (
			<>
				<Typography
					style={{ paddingRight: '5px' }}
					variant="subtitle1"
				>{`${t('Regras.Classificar')}:`}</Typography>
				<OfferToolTip
					title={
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>
							{selectedClassification.name ?? '-'}
						</Typography>
					}
				>
					<div>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
								maxWidth: '100%',
								margin: 2,
								borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
								borderRadius: 4,
								fontSize: classifySize === 'small' ? 12 : 15,
								fontWeight: 'bold',
							}}
						>
							{selectedClassification.id ?? selectedClassification}
						</Typography>
					</div>
				</OfferToolTip>
			</>
		) : (
			'-'
		);
	};

	return displayClassificationTooptip();
};

export default ClassificationSelector;
