import React from 'react';
import { CloseRounded, Search } from '@mui/icons-material/';
import {
	Popover,
	Zoom,
	TextField,
	IconButton,
	InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Line } from '../../styles';
import { OfferToolTip, HandleGetThemeColor } from '../../helpers';
import LoadingDots from '../LoadingDots';
import { usePO } from '../../utils/POContext';
import { SearchParameter } from '../../interfaces';

interface Props {
	setSearchText: any;
	customTooltip?: string;
	loading?: boolean;
	noTheme?: boolean;
	fields?: object[];
	setSearchFieldsArray?: any;
	searchFieldsArray?: SearchParameter[];
}

const TableSearch: React.FC<Props> = ({
	setSearchText,
	customTooltip,
	loading,
	noTheme = false,
	fields,
	setSearchFieldsArray,
	searchFieldsArray,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const useStyles = makeStyles(() => ({
		paper: {
			backgroundColor: HandleGetThemeColor(
				{
					themeID: 'dark',
					primaryThemeColor: selectedTheme.overlay3dp,
					alternateThemeColor: '#fff',
				},
				{ noTheme: noTheme, noThemeColor: '#fff' }
			),
		},
	}));
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [text, setText] = React.useState('');
	const [multiSearchParameters, setMultiSearchParameters] = React.useState<
		SearchParameter[]
	>(
		fields?.map((fieldObj: any) => ({
			field: fieldObj.field,
			value:
				searchFieldsArray?.find((param) => param.field === fieldObj.field)
					?.value || '',
		})) || []
	);

	const handleSingleTextChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setText(event.target.value);
	};

	const handleTextChange = (
		fieldName: string,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newValue = event.target.value;
		setMultiSearchParameters((prevParams) =>
			prevParams.map((param) =>
				param.field === fieldName ? { ...param, value: newValue } : param
			)
		);
	};

	const searchByKeyPress = (event) => {
		if (event.keyCode === 13 && !setSearchFieldsArray) {
			setText(event.target.value);
			setSearchText(text);
		}
		if (event.keyCode === 13 && setSearchFieldsArray) {
			setSearchFieldsArray(multiSearchParameters);
		}
	};

	const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setSearchText(text);
	};

	const handleClearSearch = () => {
		setText('');
		setSearchText('');
		setAnchorEl(null);
	};

	const handleCloseSearch = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'popover' : undefined;

	return fields ? (
		<Line
			style={{
				justifyContent: 'flex-start',
				marginBottom: -2,
			}}
		>
			<OfferToolTip
				aria-label={'Busca'}
				arrow
				enterDelay={200}
				enterNextDelay={200}
				title={customTooltip || `${t('Busca sensível a letras maiúsculas')}`}
				TransitionComponent={Zoom}
			>
				<IconButton
					aria-label="Search"
					onClick={handleSearch}
					edge="end"
					size="small"
					sx={{
						'&:hover': {
							backgroundColor: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.primaryLight,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '' }
							),
						},
					}}
				>
					<Search
						style={{
							fontSize: 22,
							color: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.textColorHigh,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '' }
							),
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleCloseSearch}
				style={{ overflowY: 'hidden' }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				classes={{ paper: classes.paper }}
			>
				<Line
					style={{
						columnGap: 10,
						rowGap: 10,
						padding: 10,
					}}
				>
					{fields?.map((fieldObj: any) => (
						<TextField
							key={fieldObj.field}
							id={fieldObj.field}
							aria-label={fieldObj.name}
							onKeyDown={(e: any) => searchByKeyPress(e)}
							onChange={(e: any) => handleTextChange(fieldObj.field, e)}
							value={
								multiSearchParameters.find(
									(param) => param.field === fieldObj.field
								)?.value || ''
							}
							autoComplete="off"
							autoFocus
							variant="outlined"
							size="small"
							placeholder={t(fieldObj.name)}
							inputProps={{
								style: {
									color: HandleGetThemeColor(
										{
											themeID: 'dark',
											primaryThemeColor: selectedTheme.textColorMedium,
											alternateThemeColor: '#202020',
										},
										{ noTheme: noTheme, noThemeColor: '#202020' }
									),
								},
							}}
							InputLabelProps={{
								style: {
									color: HandleGetThemeColor(
										{
											themeID: 'dark',
											primaryThemeColor: selectedTheme.textColorMedium,
											alternateThemeColor: '#202020',
										},
										{ noTheme: noTheme, noThemeColor: '#202020' }
									),
								},
							}}
							sx={{
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background: HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme?.overlay3dp,
										alternateThemeColor: '',
									},
									{ noTheme: noTheme, noThemeColor: '' }
								),
							}}
						/>
					))}
				</Line>
			</Popover>
		</Line>
	) : (
		<Line
			style={{
				justifyContent: 'flex-start',
				marginBottom: -2,
			}}
		>
			<OfferToolTip
				aria-label={'Busca'}
				arrow
				enterDelay={200}
				enterNextDelay={200}
				title={customTooltip || `${t('Busca rápida na tabela')}`}
				TransitionComponent={Zoom}
			>
				<IconButton
					data-testid="searchUsersButton"
					aria-label="Search"
					onClick={handleSearch}
					edge="end"
					size="small"
					sx={{
						'&:hover': {
							backgroundColor: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.primaryLight,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '' }
							),
						},
					}}
				>
					<Search
						style={{
							fontSize: 22,
							color: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.textColorHigh,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '' }
							),
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleCloseSearch}
				style={{ overflowY: 'hidden' }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				classes={{ paper: classes.paper }}
			>
				<TextField
					data-testid="searchInputField"
					id="searchField"
					aria-label="searchField"
					onChange={handleSingleTextChange}
					onKeyDown={(e) => searchByKeyPress(e)}
					autoComplete="off"
					autoFocus
					size="small"
					value={text}
					placeholder={t('Busca')}
					style={{
						width: 250,
						margin: 10,
						overflow: 'hidden',
					}}
					inputProps={{
						style: {
							color: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.textColorMedium,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '' }
							),
						},
					}}
					InputLabelProps={{
						style: {
							color: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.textColorMedium,
									alternateThemeColor: '#202020',
								},
								{ noTheme: noTheme, noThemeColor: '#202020' }
							),
						},
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								<IconButton
									data-testid="closeSearch"
									aria-label="Search"
									onClick={handleClearSearch}
									style={{ marginRight: -15 }}
									size="small"
									sx={{
										'&:hover': {
											backgroundColor: HandleGetThemeColor(
												{
													themeID: 'dark',
													primaryThemeColor: selectedTheme.primaryLight,
													alternateThemeColor: '',
												},
												{ noTheme: noTheme, noThemeColor: '' }
											),
										},
									}}
								>
									{loading ? (
										<LoadingDots width={40} />
									) : (
										<CloseRounded
											style={{
												fontSize: 16,
												color: HandleGetThemeColor(
													{
														themeID: 'dark',
														primaryThemeColor: selectedTheme.textColorMedium,
														alternateThemeColor: '#202020',
													},
													{ noTheme: noTheme, noThemeColor: '#202020' }
												),
											}}
										/>
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Popover>
		</Line>
	);
};

export default TableSearch;
