import React from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material/';
import { makeStyles } from '@mui/styles';
import { ButtonPagination, DivContainer } from './styles';
import { usePO } from '../../utils/POContext';
import { useCardSelector } from '../../hooks/Classificacoes/CardSelector';

interface PaginationProps {
	clearSelectionOnPageChange?: boolean;
	currentPage: number;
	pages: number;
	setPage: (param) => void;
	totalPages: number;
}
const Pagination: React.FC<PaginationProps> = ({
	clearSelectionOnPageChange,
	currentPage,
	pages,
	setPage,
	totalPages,
}) => {
	const { selectedTheme } = usePO();
	const allPages = Array.from({ length: totalPages }, (_, i) => i + 1);
	const { handleSelectCard } = useCardSelector();

	const useStyles = makeStyles({
		root: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			borderStyle: selectedTheme.id === 'dark' ? 'solid' : '',
			borderWidth: selectedTheme.id === 'dark' ? 1 : '',
			borderColor:
				selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#dedede',
		},
	});

	const classes = useStyles();

	const handlePageChange = (page) => {
		if (clearSelectionOnPageChange) {
			handleSelectCard(null, 'clear');
		}
		setPage(page);
	};

	const renderPaginator = () => {
		return (
			<DivContainer>
				<ButtonPagination
					className={classes.root}
					type="button"
					data-testid="PreviousPageButton"
					disabled={currentPage + 1 === allPages[0]}
					onClick={() => handlePageChange(currentPage - 1)}
					style={currentPage + 1 === allPages[0] ? {} : { cursor: 'pointer' }}
				>
					<KeyboardArrowLeft />
					Anterior
				</ButtonPagination>
				{allPages.slice(currentPage, currentPage + pages).map((item) => (
					<ButtonPagination
						className={classes.root}
						key={item}
						type="button"
						onClick={() => handlePageChange(item - 1)}
						style={
							item === currentPage + 1
								? {
										backgroundColor:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay3dp
												: selectedTheme.primary,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.foreground,
										cursor: 'pointer',
										// eslint-disable-next-line no-mixed-spaces-and-tabs
									}
								: { cursor: 'pointer' }
						}
					>
						{item}
					</ButtonPagination>
				))}
				<ButtonPagination
					className={classes.root}
					type="button"
					data-testid="NextPageButton"
					disabled={currentPage + 1 === allPages.slice(-1)[0]}
					onClick={() => handlePageChange(currentPage + 1)}
					style={
						currentPage + 1 === allPages.slice(-1)[0]
							? {}
							: { cursor: 'pointer' }
					}
				>
					Próximo
					<KeyboardArrowRight />
				</ButtonPagination>
			</DivContainer>
		);
	};

	return allPages.length > 0 ? renderPaginator() : null;
};

export default Pagination;
