import React from 'react';
import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ptBR } from '@mui/material/locale';
import { usePO } from '../utils/POContext';
import { createTheme } from '@mui/material';

interface Props {
	children?: React.ReactNode;
}

const SelectedTone: React.FC<Props> = ({ children }: Props) => {
	const { selectedTheme } = usePO();

	return (
		<ThemeProvider
			theme={() =>
				createTheme(
					{
						palette: {
							mode: selectedTheme?.tone as 'light' | 'dark',
						},
					},
					ptBR
				)
			}
		>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};

export default SelectedTone;
