import React from 'react';
import { Column } from '../../../../styles';

interface Props {
	enabled: boolean;
	color: string;
}

const LeftVerticalLine: React.FC<Props> = ({ enabled, color }) => {
	return (
		<Column
			style={{
				width: 3,
				height: 330,
				background: enabled ? color : 'transparent',
				opacity: 0.7,
			}}
		/>
	);
};
export default LeftVerticalLine;
