import React, { ReactNode } from 'react';
import { POProvider } from '../utils/POContext';
import { PanelControls } from '../hooks/PanelControls';
import { CardSelector } from '../hooks/Classificacoes/CardSelector';
import { PlatformData } from '../hooks/OfferPlatformData';
import { CardVisualFeedback } from '../hooks/Classificacoes/CardVisualFeedback';
import { Provider } from 'react-redux';
import SelectedTone from '../Themes';
import { ViewSearch } from '../hooks/ViewSearch';
import { SnackbarProvider } from 'notistack';
import { useAuth0 } from '../react-auth0-spa';
import Landpage from '../pages/Landpage';
import { store } from '../redux/store';
import VertMenu from '../components/VertMenu';

interface PrivateLayoutProps {
	children: ReactNode;
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
	const { token, user } = useAuth0();

	const checkUserStatus = () => {
		if (user['https://offertech.com.br/roles'].length === 0) {
			setTimeout(() => {
				window.location.reload();
			}, 1500);
			return <Landpage />;
		}
		return null;
	};

	return (
		<SnackbarProvider
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			maxSnack={3}
			style={{ margin: 5 }}
		>
			<POProvider accessToken={token}>
				<SelectedTone>
					{checkUserStatus()}
					<PanelControls>
						<PlatformData>
							<CardVisualFeedback>
								<CardSelector>
									<ViewSearch>
										<Provider store={store}>
											<VertMenu>{children}</VertMenu>
										</Provider>
									</ViewSearch>
								</CardSelector>
							</CardVisualFeedback>
						</PlatformData>
					</PanelControls>
				</SelectedTone>
			</POProvider>
		</SnackbarProvider>
	);
};

export default PrivateLayout;
