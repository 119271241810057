import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { Error } from '@mui/icons-material/';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { Line } from '../../../../styles';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { usePO } from '../../../../utils/POContext';
import { MessageType } from '../../../../interfaces';
import SubmitedResumeDialog from '../../components/SubmitedResumeDialog';
import { sendNotificationsData } from '../../../../services/notifications/sendNotifications';
import { useSnackbar } from 'notistack';

interface Props {
	data: any;
	setData?: any;
	reloadTable: any;
	setTableData: (data: any) => void;
}

const ApplyDenouncementDialog: React.FC<Props> = ({
	data,
	setData,
	reloadTable,
	setTableData,
}: Props) => {
	const { token } = useAuth0();
	setupRequestToken(api, token);
	const { t } = useTranslation();
	const { selectedTheme, cursorMode, selectedClient, userData } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	useHotkeys('ctrl+alt+Enter', () => handleKeyShortcutSend());

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitDataResults, setSubmitDataResults] = useState<any>();
	const [openSummaryDialog, setOpenSummaryDialog] = useState(false);

	const handleClose = () => {
		setSubmitDataResults(undefined);
		setOpen(false);
	};

	const handleCloseAndReload = () => {
		setSubmitDataResults(undefined);
		setOpen(false);
		reloadTable();
	};

	const keepFailedSelected = () => {
		if (submitDataResults?.good.length > 0) {
			submitDataResults?.good.map((el) => {
				setData((itemsList) => itemsList.filter((item) => item.id !== el.id));
				setTableData((itemsList) =>
					itemsList.filter((item) => item.id !== el.id)
				);
			});
		}
	};

	const handleCloseDialog = (clear?: boolean) => {
		if (clear) {
			setOpenSummaryDialog(false);
			handleCloseAndReload();
		}
		if (!clear) {
			keepFailedSelected();
			setOpenSummaryDialog(false);
			setOpen(false);
		}
	};

	/** Checks if user meets conditions to apply item */
	const selectDisable = () => {
		if (
			userData.roles?.indexOf('Desenvolvedor') !== -1 ||
			userData.roles?.indexOf('Administrador da ferramenta') !== -1 ||
			userData.roles?.indexOf('Analista de posts') !== -1
		) {
			if (data.length === 0) {
				return true;
			}
			return false;
		}
		return true;
	};

	const handleSnackbarMessage = (message: any, type: MessageType) => {
		enqueueSnackbar(message, { variant: type });
	};

	const prepareDataToSubmit = () => {
		// create chunked dataset to send to backend
		const chunkSize = 100;
		const chunks = new Set();
		const chunkedData = data.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / chunkSize);
			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = [];
				chunks.add(chunkIndex);
			}
			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);
		return { chunkedData, chunks };
	};

	const handleSendData = async () => {
		const { chunkedData } = prepareDataToSubmit();
		if (chunkedData.length > 0) {
			setLoading(true);
			try {
				const response: any = await sendNotificationsData(
					chunkedData,
					selectedClient?.id,
					'NOTIFICADA'
				);
				if (response?.success) {
					const goodItems = [...response.data.goodItems];
					const notGoodItems = [...response.data.notGoodItems];
					setSubmitDataResults({
						good: goodItems,
						notGood: notGoodItems,
					});
					if (notGoodItems.length === 0) {
						handleSnackbarMessage(
							t('Anúncios alterados com sucesso'),
							'success'
						);
					}
					if (goodItems.length > 4 || notGoodItems.length > 0) {
						setOpenSummaryDialog(true);
					}
				} else if (response?.error) {
					handleSnackbarMessage(response?.error, 'error');
				}
			} catch (error) {
				console.error('Erro ao enviar dados:', error);
				handleSnackbarMessage(
					'Erro ao enviar os dados. Tente novamente.',
					'error'
				);
			} finally {
				setLoading(false);
				handleClose();
			}
		}
	};

	const applyButtomBackground = () => {
		if (!selectDisable() && data.length !== 0) {
			return selectedTheme?.primaryDark;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.overlay3dp
				: selectedTheme.foreground;
		}
	};

	const applyButtomColors = () => {
		if (!selectDisable() && data.length !== 0) {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.foreground;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorDisable
				: '#aaaaaa';
		}
	};

	const handleKeyShortcutSend = () => {
		if (data.length !== 0) {
			setOpen(true);
		} else {
			enqueueSnackbar(t('Não há itens selecionados'), {
				variant: 'warning',
			});
		}
	};

	const renderFooterButton = () => (
		<Button
			size="small"
			variant="contained"
			disabled={selectDisable()}
			onClick={() => setOpen(true)}
			type="submit"
			style={{
				background: applyButtomBackground(),
				color: applyButtomColors(),
			}}
		>
			{data.length < 2
				? t('Notificações.Tabs.Classificadas.Efetivar')
				: `${t('Notificações.Tabs.Classificadas.Efetivar')} ${data.length} ${t(
						'Notificações.Tabs.Classificadas.Itens'
					)}`}
		</Button>
	);

	const renderWarning = () => (
		<Line
			style={{
				width: '100%',
				background: selectedTheme.background,
				justifyContent: 'center',
				opacity: 0.7,
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '10px 15px',
			}}
		>
			<Error style={{ fontSize: 30, color: 'orange' }} />
			<Typography
				variant="caption"
				sx={{
					width: '70%',
					marginLeft: '20px',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{t(
					'Notificações.Tabs.Classificadas.O processamento de muitos itens simultaneamente pode levar alguns minutos. Por favor, aguarde.'
				)}
			</Typography>
		</Line>
	);

	const renderDialogContent = () => (
		<Line
			style={{
				justifyContent: 'center',
				padding: '15px 25px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			{data.length < 2 ? (
				<Typography
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{t(
						'Notificações.Tabs.Classificadas.Deseja efetivar a denúncia selecionada'
					)}
					?
				</Typography>
			) : (
				<Typography
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{`${t('Notificações.Tabs.Classificadas.Deseja efetivar as')} 
          ${data.length} 
          ${t('Notificações.Tabs.Classificadas.denúncias selecionadas')}?`}
				</Typography>
			)}
		</Line>
	);

	const renderFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
			}}
		>
			<Button
				size="small"
				variant="text"
				onClick={handleClose}
				disabled={loading}
				style={{ color: loading ? 'gray' : selectedTheme.error }}
			>
				{t('Notificações.Tabs.Classificadas.Cancelar')}
			</Button>
			{loading ? (
				<LoadingDots height={30} width={70} loop />
			) : (
				<Button
					size="small"
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: 'transparent'
						}`,
						color:
							selectedTheme.tone === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.foreground,
						background: selectedTheme.primaryDark,
					}}
					onClick={handleSendData}
				>
					{t('Notificações.Tabs.Classificadas.Efetivar')}
				</Button>
			)}
		</Line>
	);

	return (
		<div>
			{renderFooterButton()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
				style={{
					cursor: cursorMode,
					display: openSummaryDialog ? 'none' : 'block',
				}}
			>
				<DialogContent
					style={{
						padding: 0,
						background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					}}
				>
					{renderDialogContent()}
					{data.length > 1 ? renderWarning() : null}
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						padding: 10,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
			<SubmitedResumeDialog
				open={openSummaryDialog}
				close={handleCloseDialog}
				data={submitDataResults}
			/>
		</div>
	);
};

export default ApplyDenouncementDialog;
