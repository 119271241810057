import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';

interface Props {
	data: any;
}

const RatingsChart: React.FC<Props> = ({ data }: Props) => {
	const { t } = useTranslation();

	const chartData = [
		{
			type: `${t('Classificações.Avaliações')}`,
			Ruim: data.author.authorData.rating_bad?.value,
			Neutro: data.author.authorData.rating_normal?.value,
			Bom: data.author.authorData.rating_good?.value,
		},
	];

	return (
		<ResponsiveBar
			data={chartData}
			keys={[
				`${t('Classificações.Ruim')}`,
				`${t('Classificações.Neutro')}`,
				`${t('Classificações.Bom')}`,
			]}
			indexBy="type"
			margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
			padding={0.3}
			layout="horizontal"
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={['#ff6b7e', '#94afe0', '#73b477']}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			layers={['bars']}
		/>
	);
};
export default RatingsChart;
