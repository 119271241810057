import React, { useState } from 'react';
import { IconButton, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../helpers';
import FullSizeImageDialog from '../../../components/FullSizeImageModal';
import { CameraAlt } from '@mui/icons-material/';
import { usePO } from '../../../utils/POContext';

interface Props {
	rowData: any;
}

const ImagePreview: React.FC<Props> = ({ rowData }: Props) => {
	const { t } = useTranslation();
	const [openFileModal, setOpenFileModal] = useState(false);
	const { selectedTheme } = usePO();

	const actionElement2 = () => (
		<OfferToolTip
			title={`${t('Denúncias.Exibir captura de tela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setOpenFileModal(true)}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<CameraAlt
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<>
			{actionElement2()}
			<FullSizeImageDialog
				open={openFileModal}
				setOpen={setOpenFileModal}
				images={rowData.screenshot?.original}
			/>
		</>
	);
};

export default ImagePreview;
