import React from 'react';
import { IconButton, Zoom } from '@mui/material';
import { HandleGetThemeColor, OfferToolTip } from '../../../../../helpers';
import { GetApp } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../../utils/POContext';
import { useSnackbar } from 'notistack';
import { downloadSearchData } from '../../../../../services/searches/searchDetails';
interface Props {
	searchData: any;
	rowData: any;
	setLoadingDownloading: (state) => void;
}

const CallDownloadSearch: React.FC<Props> = ({
	searchData,
	rowData,
	setLoadingDownloading,
}: Props) => {
	const { t } = useTranslation();
	const { updateCursorMode, selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const handleDownloadSearch = async (SearchKey) => {
		enqueueSnackbar(
			t(
				'Buscas.Tabs.Buscas Salvas.O download do arquivo iniciará automaticamente'
			),
			{ variant: 'info' }
		);
		updateCursorMode('wait');
		setLoadingDownloading(true);
		const downloadSearchResponse = await downloadSearchData(
			searchData.searchID,
			searchData.clientID,
			SearchKey
		);
		if (downloadSearchResponse.success) {
			enqueueSnackbar(
				t('Buscas.Tabs.Buscas Salvas.Arquivo de busca baixado com sucesso'),
				{ variant: 'success' }
			);
		} else {
			enqueueSnackbar(
				t(
					'Buscas.Tabs.Buscas Salvas.Não existem arquivos dessa busca para download'
				),
				{ variant: 'error' }
			);
		}
		updateCursorMode('default');
		setLoadingDownloading(false);
	};

	return (
		<>
			<OfferToolTip
				title={`${t('Buscas.Tabs.Buscas Salvas.Download do arquivo de busca')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<div>
					<IconButton
						onClick={() => handleDownloadSearch(rowData.storageSearchKey)}
						disabled={rowData.storage === false}
						style={{ marginRight: -4 }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<GetApp
							style={{
								fontSize: 22,
								color: HandleGetThemeColor({
									themeID: 'dark',
									primaryThemeColor:
										rowData.storage === false
											? selectedTheme.textColorDisable
											: selectedTheme.textColorHigh,
									alternateThemeColor: '',
								}),
							}}
						/>
					</IconButton>
				</div>
			</OfferToolTip>
		</>
	);
};

export default CallDownloadSearch;
