import React from 'react';
import { Chip, IconButton, Link, Typography, Zoom } from '@mui/material';
import { Line } from '../../../styles';
import { Brightness1 } from '@mui/icons-material/';
import dateFormat from 'dateformat';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { OfferToolTip } from '../../../helpers';
import countryNames from 'i18n-iso-countries';

interface Props {
	row: any;
	col: any;
	denseText?: boolean;
}

const DataTypes: React.FC<Props> = ({ row, col, denseText }: Props) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedTheme, userData } = usePO();

	// Generic function that copies values to the clipboard
	const CopyToClipBoard = (rowData) => {
		navigator.clipboard.writeText(rowData);
		enqueueSnackbar(t('Valor copiado com sucesso'), {
			variant: 'success',
		});
	};

	countryNames.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);
	countryNames.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/en.json')
	);
	countryNames.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/es.json')
	);

	const renderPlatformInfo = (data) => {
		if (!data) {
			return '-';
		}
		const platformInfo = data.split('|');
		const platformName =
			platformInfo[0].charAt(0).toUpperCase() +
			platformInfo[0].slice(1).toLowerCase();

		return `${platformName} - ${countryNames.getName(
			platformInfo[1],
			userData.i18nID.substring(0, 2),
			{
				select: 'official',
			}
		)}`;
	};

	const renderUserEmail = (data) => {
		const platformInfo = data.split('|');
		return `${platformInfo[2]}`;
	};

	const tempFormatedPlatform = renderPlatformInfo(row?.platformINcountryID);

	// Function that returns the type of dataType used in the column
	const handleDataType = () => {
		switch (col.dataType) {
			case 'bold':
				return (
					<Typography
						style={{
							fontSize: denseText ? 14 : 16,
							fontWeight: 700,
						}}
						onClick={() => CopyToClipBoard(row[col.fieldName])}
						data-testid={
							typeof row[col.fieldName] === 'object' &&
							row[col.fieldName] !== null
								? decodeURIComponent(row[col.fieldName]?.join(', '))
								: row[col.fieldName] || '-'
						}
						noWrap
					>
						{typeof row[col.fieldName] === 'object' &&
						row[col.fieldName] !== null
							? decodeURIComponent(row[col.fieldName]?.join(', '))
							: row[col.fieldName] || '-'}
					</Typography>
				);
			case 'url':
				return (
					<Link
						onClick={() => window.open(row?.url)}
						href={row?.url}
						color="inherit"
						target="blank"
						data-testid={
							typeof row[col.fieldName] === 'object'
								? row[col.fieldName]?.join(', ')
								: row[col.fieldName]
						}
						noWrap
						style={{
							textDecoration: 'underline',
							cursor: 'pointer',
							fontSize: denseText ? 14 : 16,
						}}
					>
						{typeof row[col.fieldName] === 'object'
							? row[col.fieldName]?.join(', ')
							: row[col.fieldName]}
					</Link>
				);
			case 'platformINcountry':
				return (
					<Typography
						style={{
							fontSize: denseText ? 14 : 16,
						}}
						onClick={() => CopyToClipBoard(tempFormatedPlatform)}
						data-testid={tempFormatedPlatform}
						noWrap
					>
						{tempFormatedPlatform}
					</Typography>
				);
			case 'userID':
				return (
					<Typography
						style={{
							fontSize: denseText ? 14 : 16,
						}}
						onClick={() => CopyToClipBoard(renderUserEmail(row[col.fieldName]))}
						data-testid={renderUserEmail(row[col.fieldName])}
						noWrap
					>
						{renderUserEmail(row[col.fieldName])}
					</Typography>
				);
			case 'date':
				return (
					<Typography
						style={{
							fontSize: denseText ? 14 : 16,
						}}
						onClick={() =>
							CopyToClipBoard(dateFormat(row[col.fieldName], 'dd/mm/yyyy'))
						}
						data-testid={dateFormat(row[col.fieldName], 'dd/mm/yyyy')}
						noWrap
					>
						{dateFormat(row[col.fieldName], 'dd/mm/yyyy')}
					</Typography>
				);
			case 'reactObject':
				return <>{row[col.fieldName]}</>;
			case 'color':
				if (col.fieldName === 'badgeColor') {
					return (
						<IconButton
							onClick={() => CopyToClipBoard(row?.badgeColor)}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<Brightness1
								style={{
									fontSize: 22,
									color: row?.badgeColor,
									borderRadius: 16,
									border:
										selectedTheme.tone === 'light'
											? '2px solid #67676754'
											: '2px solid #bdbdbd54',
								}}
							/>
						</IconButton>
					);
				} else if (col.fieldName === 'textColor') {
					return (
						<IconButton
							onClick={() => CopyToClipBoard(row?.textColor)}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<Brightness1
								style={{
									fontSize: 22,
									color: row?.textColor,
									borderRadius: 16,
									border:
										selectedTheme.tone === 'light'
											? '2px solid #67676754'
											: '2px solid #bdbdbd54',
								}}
							/>
						</IconButton>
					);
				}
			// falls through
			case 'tag':
				if (row.badgeColor && row.textColor) {
					return (
						<Line style={{ justifyContent: 'center' }}>
							<Chip
								label={row?.name}
								variant="outlined"
								onClick={() => CopyToClipBoard(row.id)}
								style={{
									maxWidth: '98%',
									height: 25,
									backgroundColor: row.badgeColor,
									borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
									color: row.textColor,
									borderRadius: 6,
									fontSize: 15,
								}}
							/>
						</Line>
					);
				}
			// falls through
			default:
				return (
					<Typography
						style={{
							fontSize: denseText ? 14 : 16,
						}}
						onClick={() => CopyToClipBoard(row[col.fieldName])}
						data-testid={
							typeof row[col.fieldName] === 'object' &&
							row[col.fieldName] !== null
								? decodeURIComponent(row[col.fieldName]?.join(', '))
								: row[col.fieldName] || '-'
						}
						noWrap
					>
						{typeof row[col.fieldName] === 'object' &&
						row[col.fieldName] !== null
							? decodeURIComponent(row[col.fieldName]?.join(', '))
							: row[col.fieldName] || '-'}
					</Typography>
				);
		}
	};

	const handleDataTypeTooltip = () => {
		switch (col.dataType) {
			case 'platformINcountry':
				return renderPlatformInfo(row.platformINcountryID);
			case 'userID':
				return renderUserEmail(row[col.fieldName]);
			case 'date':
				return dateFormat(row[col.fieldName], 'dd/mm/yyyy');
			case 'reactObject':
				return null;
			default:
				return typeof row[col.fieldName] === 'object'
					? row[col.fieldName] === null
						? '-'
						: decodeURIComponent(row[col.fieldName]?.join(', '))
					: row[col.fieldName];
		}
	};

	return (
		<OfferToolTip
			title={handleDataTypeTooltip() || ' '}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			{handleDataType()}
		</OfferToolTip>
	);
};

export default DataTypes;
