import React, { useState } from 'react';
import { Snackbar, IconButton, Checkbox, Typography } from '@mui/material';
import { CallReceived, Close } from '@mui/icons-material/';
import { Line } from '../../styles';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../utils/POContext';

function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	message: any;
	onSnackbarClose: (showAgain: boolean) => void;
}

const InformativeSnackbar: React.FC<Props> = ({
	open,
	message,
	onSnackbarClose,
	setOpen,
}) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const [dontShowAgain, setDontShowAgain] = useState(false);

	const handleChangeShowAgain = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDontShowAgain(event.target.checked);
	};

	const handleClose = (
		event: React.SyntheticEvent | MouseEvent,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}
		onSnackbarClose(dontShowAgain);
		setOpen(false);
	};

	return (
		<Snackbar
			data-testid="InformativeSnackbar"
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			autoHideDuration={5000000}
			style={{ marginBottom: 10, marginLeft: 10, opacity: 1 }}
		>
			<Alert
				severity="info"
				icon={
					<Line>
						<CallReceived
							style={{
								color: 'white',
								position: 'absolute',
								bottom: 0,
								left: 0,
							}}
						/>
					</Line>
				}
			>
				<Line>
					{message}
					<Line style={{ width: 235 }}>
						<Checkbox
							checked={dontShowAgain}
							size="small"
							onChange={handleChangeShowAgain}
							style={{ marginLeft: 30, color: 'white' }}
						/>
						<Typography
							variant="subtitle2"
							noWrap
							style={{ marginRight: 10, color: 'white' }}
						>
							{t('Inicio.Não mostrar novamente')}
						</Typography>
					</Line>
					<IconButton
						aria-label="close"
						color="inherit"
						onClick={handleClose}
						style={{ marginRight: -10 }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<Close style={{ fontSize: 24 }} />
					</IconButton>
				</Line>
			</Alert>
		</Snackbar>
	);
};
export default InformativeSnackbar;
