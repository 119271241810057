import { ErrorResponse } from '../interfaces';
import { api, isAxiosError } from '../utils/api';

export const fetchRules = async (selectedClientID) => {
	try {
		const response = await api.get(`/policies/client/${selectedClientID}`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: '',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};

export const getRuleByID = async (ruleID) => {
	try {
		const response = await api.get(`/policies/${ruleID}`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: '',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};

export const toggleRule = async (ruleID, clientID, state) => {
	const payload = {
		ISactive: state,
	};
	try {
		const response = await api.patch(
			`/policies/${ruleID}/clients/${clientID}`,
			payload
		);
		return {
			success: true,
			status: response.status,
			data: [],
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};
