import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ViewAuthorData {
	viewAuthorByName: string;
	setViewAuthorByName: (authorId: string) => void;
}

export const ViewAuthorContext = createContext<ViewAuthorData>(
	{} as ViewAuthorData
);

interface Props {
	children: ReactNode;
}

export const ViewAuthor: React.FC<Props> = ({ children }: Props) => {
	const [viewAuthorByName, setViewAuthorByName] = useState('');

	return (
		<ViewAuthorContext.Provider
			value={{
				viewAuthorByName,
				setViewAuthorByName,
			}}
		>
			{children}
		</ViewAuthorContext.Provider>
	);
};

export function useViewAuthor(): ViewAuthorData {
	return useContext(ViewAuthorContext);
}
