import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';
import LoadingDots from '../components/LoadingDots';

// Private Pages
import PrivateLayout from '../routes/privateLayout';
import Inicio from '../pages/Inicio';
import Busca from '../pages/Busca';
import Notificacoes from '../pages/Notificacoes';
import Relatorios from '../pages/Relatorios';
import Classificacoes from '../pages/Classificacoes';
import Autores from '../pages/Autores';
import Terms from '../pages/Termos';
import Ajuda from '../pages/Ajuda';
import SingleItemDisplay from '../pages/Classificacoes/SingleItemDisplay';
import Clientes from '../pages/Clientes';
import Configuracoes from '../pages/Configuracoes';
import Denuncias from '../pages/Denuncias';
import Entidades from '../pages/Entidades';
import Etiquetas from '../pages/Etiquetas';
import Regras from '../pages/Regras';
import Usuarios from '../pages/Usuarios';
import EntityAppraisalPage from '../pages/Entidades/EntityAppraisalPage';

// Public Pages
import Landpage from '../pages/Landpage';
import Page401 from '../pages/Page401';
import Page404 from '../pages/Page404';
import PrivacyTerms from '../pages/Termos/Privacidade';
import SuperSetCharts from '../pages/PowerBI';
import SearchReportsDialog from '../pages/Busca/SavedSearches/SavedSearchesDetails/SearchReportsDialog';

const AppRoutes: React.FC = () => {
	const { isAuthenticated, isLoading, error } = useAuth0();

	if (isLoading) {
		return <LoadingDots width={100} height={30} loop />;
	}
	if (error) {
		return <div>Erro ao carregar!</div>;
	}

	return (
		<Router>
			<Switch>
				{isAuthenticated && (
					<PrivateLayout>
						<Route exact path="/">
							<Redirect to="/inicio" />
						</Route>
						<Route exact path="/busca" component={Busca} />
						<Route
							path="/searchID/:searchID/searchKey/:searchKey/:offerCurrency"
							component={SearchReportsDialog}
						/>
						<Route path="/inicio" component={Inicio} />
						<Route path="/termos/extensao" component={Terms} />
						<Route path="/privacidade" component={PrivacyTerms} />
						<Route
							path="/entity-appraisal/:entityID"
							component={EntityAppraisalPage}
						/>
						<Route path="/classificacoes" component={Classificacoes} />
						<Route path="/offerID/:offerID" component={SingleItemDisplay} />
						<Route path="/notificacoes" component={Notificacoes} />
						<Route path="/denuncias" component={Denuncias} />
						<Route path="/regras" component={Regras} />
						<Route path="/etiquetas" component={Etiquetas} />
						<Route path="/autores" component={Autores} />
						<Route path="/authorID/:authorID" component={Autores} />
						<Route path="/entidades" component={Entidades} />
						<Route path="/entityID/:entityID" component={Entidades} />
						<Route path="/relatorios" component={Relatorios} />
						<Route path="/painel" component={SuperSetCharts} />
						<Route path="/clientes" component={Clientes} />
						<Route path="/usuarios" component={Usuarios} />
						<Route path="/ajuda" component={Ajuda} />
						<Route path="/configuracoes" component={Configuracoes} />
					</PrivateLayout>
				)}
				{/* Public Routes */}
				<Route exact path="/" component={Landpage} />
				<Route path="/termos/extensao" component={Terms} />
				<Route path="/privacidade" component={PrivacyTerms} />
				<Route path="/unauthorized" component={Page401} />

				{/* 404 Redirect */}
				{isAuthenticated && <Redirect exact from="/" to="/inicio" />}
				<Route component={Page404} />
			</Switch>
		</Router>
	);
};

export default AppRoutes;
