/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import OfferTable from '../../../../../../components/OfferTable';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../../../utils/POContext';

interface Props {
	data: any;
}

const BiggestSellersTable: React.FC<Props> = ({ data }: Props) => {
	const { selectedClient, userData } = usePO();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState<any[]>([]);

	const colsData = [
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Nome').toUpperCase(),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 300,
			columnTitleBold: 800,
			dataType: 'bold',
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Localização').toUpperCase(),
			fieldName: 'location',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 100,
			columnTitleBold: 800,
			dataType: 'bold',
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Vendidos').toUpperCase(),
			fieldName: 'productsSold',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 120,
			columnTitleBold: 800,
			dataType: 'bold',
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.ofertas').toUpperCase(),
			fieldName: 'totalOffers',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 100,
			columnTitleBold: 800,
			dataType: 'bold',
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Estoque').toUpperCase(),
			fieldName: 'productsInStock',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 120,
			columnTitleBold: 800,
			dataType: 'bold',
		},
	];

	const countries = require('i18n-iso-countries');
	countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
	countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
	countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

	const handleLocationConvert = (location: string) => {
		const splitedLocation = location.split('/');
		const countryName = `${countries.getName(
			splitedLocation[0],
			userData.i18nID.substring(0, 2),
			{
				select: 'official',
			}
		)}`;
		const city = splitedLocation[2];
		const state = splitedLocation[1];
		if (city && state) {
			return `${city} / ${state} - ${countryName}`;
		} else {
			return `${countryName}`;
		}
	};

	const handleCurrencyValue = (
		data: any,
		i18nID: string,
		currency?: string
	) => {
		if (currency) {
			return parseFloat(data).toLocaleString(i18nID, {
				style: 'currency',
				currency: currency,
				minimumFractionDigits: 2,
			});
		} else {
			return parseFloat(data).toLocaleString(i18nID);
		}
	};

	const buildTableData = (data) => {
		const tempData: any[] = [];
		data &&
			data?.map((el) => {
				const newPlatform = {
					name: el.name.toUpperCase(),
					location:
						el.location && handleLocationConvert(el.location).toUpperCase(),
					productsSold:
						el.products_sold &&
						handleCurrencyValue(
							el.products_sold,
							userData.i18nID
						).toUpperCase(),
					totalOffers: handleCurrencyValue(
						el.total_offers,
						userData.i18nID
					).toUpperCase(),
					productsInStock:
						el.products_in_stock &&
						handleCurrencyValue(
							el.products_in_stock,
							userData.i18nID
						).toUpperCase(),
				};
				tempData.push(newPlatform);
				return null;
			});
		setTableData(tempData);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			setTableData([]);
			try {
				buildTableData(data);
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};
		if (tableData.length === 0 && data) {
			fetchData();
		}
	}, [data]);

	return (
		<>
			<OfferTable
				colsData={colsData}
				tableData={tableData}
				loading={loading}
				noTheme
				tableFooter={tableData.length > 0}
				customNoDataMessage={
					selectedClient
						? loading
							? t('Carregando...')
							: t('Sem vendedores para essa busca')
						: t('Selecione um cliente')
				}
			/>
		</>
	);
};

export default BiggestSellersTable;
