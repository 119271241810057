import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Drawer, Paper, Typography } from '@mui/material';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-google-charts';
import OfferTable from '../../../components/OfferTable';
import { Cached, ChevronLeft, ChevronRight } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { enqueueSnackbar } from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';

import {
	fetchReportsPanel,
	verifyQueryParameters,
} from '../../../services/classifications/reportsPanel';
import { ReportsClassificationData } from '../../../interfaces';
import ReportsCard from './ReportsCard';
import {
	converteNomeParaSigla,
	handleNumberAbbreviation,
} from '../../../helpers';
import * as countryNames from 'i18n-iso-countries';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countryNames.registerLocale(require('i18n-iso-countries/langs/pt.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countryNames.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countryNames.registerLocale(require('i18n-iso-countries/langs/es.json'));

interface Props {
	openMenu: boolean;
	numberOfResults?: number;
	setOpenMenu: () => void;
	queryData: any;
}

type FailedLoadingCharts = 'default' | 'queryExceedsLimit' | 'rejected';

const ReportPanel: React.FC<Props> = ({ openMenu, setOpenMenu, queryData }) => {
	const { selectedTheme, userData } = usePO();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [tableDataReport, setTableDataReport] = useState<any[]>([]);
	const [reportsData, setReportsData] = useState<any>([]);
	const [reloadReportData, setReloadReportData] = useState<number>(0);
	const [failedLoadingCharts, setFailedLoadingCharts] =
		useState<FailedLoadingCharts>('default');

	const chartColorThemes = {
		red: ['#e65b71'],
		dark: ['#7ed0ec'],
		summer: ['#dac242'],
		winter: ['#82b3ff'],
		default: ['#ff781f'],
	};

	const chartColumnOptions = {
		legend: 'none',
		colors: chartColorThemes[selectedTheme.id],
		vAxis: {
			textStyle: {
				bold: true,
				color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
			},
		},
		hAxis: {
			// slantedText: true,
			// slantedTextAngle: 35,
			textStyle: {
				bold: true,
				fontSize: 12,
				color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
			},
		},
		backgroundColor:
			selectedTheme.id === 'dark'
				? selectedTheme.overlay4dp
				: selectedTheme.background,
	};

	const colsDataReport = [
		{
			columnTitle: t('Classificações.Relatórios.Vendedor'),
			fieldName: 'sellerName',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 150,
		},
		{
			columnTitle: t('Classificações.Relatórios.Produtos vendidos'),
			fieldName: 'productsSold',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 10,
		},
		{
			columnTitle: t('Classificações.Relatórios.Produtos em estoque'),
			fieldName: 'productsInStock',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 10,
		},
		{
			columnTitle: t('Classificações.Relatórios.Total de ofertas'),
			fieldName: 'totalOffers',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 10,
		},
		{
			columnTitle: t('Classificações.Relatórios.Cidade'),
			fieldName: 'city',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 100,
		},
		{
			columnTitle: t('Classificações.Relatórios.Estado'),
			fieldName: 'state',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 100,
		},
		{
			columnTitle: t('Classificações.Relatórios.País'),
			fieldName: 'country',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -5px',
			cellWidth: 100,
		},
	];

	const formatCurrencyString = (input) => {
		let [value1, value2] = input.split('-');

		value1 = parseFloat(value1);
		value2 = parseFloat(value2);

		const formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});

		const formattedValue1 = formatter.format(value1);
		const formattedValue2 = formatter.format(value2);

		return `${formattedValue1} - ${formattedValue2}`;
	};

	const transformChartData = async (data: any) => {
		const result = [['Distruibuição', 'Valor']];
		data.forEach((item) => {
			result.push([formatCurrencyString(item.id), item.value]);
		});
		return result;
	};

	useEffect(() => {
		const determineLoadingState = (reportsData: any): FailedLoadingCharts => {
			const totalItems = Object.keys(reportsData).length;
			const rejectedCount = Object.keys(reportsData).reduce((count, key) => {
				const report = reportsData[key];
				if (report.status === 'rejected' || report.status > 300) {
					return count + 1;
				}
				return count;
			}, 0);

			if (rejectedCount === totalItems) {
				return 'queryExceedsLimit';
			} else if (rejectedCount > 0) {
				return 'rejected';
			} else {
				return 'default';
			}
		};

		const fetchData = async () => {
			setFailedLoadingCharts('default');
			setLoading(true);
			let response: ReportsClassificationData;
			enqueueSnackbar(
				t(
					'Classificações.Relatórios.Iremos verificar se a quantidade de itens filtrados é permitida para o carregamento dos dados. Por favor, aguarde.'
				),
				{
					variant: 'warning',
				}
			);
			const responseVerifyQuery = await verifyQueryParameters(queryData);
			if (
				responseVerifyQuery &&
				responseVerifyQuery.message !== 'O número de dados é muito grande.'
			) {
				enqueueSnackbar(
					`${t(
						'Classificações.Relatórios.A solicitação de dados está dentro do limite permitido. Carregando gráficos'
					)}...`,
					{
						variant: 'success',
					}
				);
				response = await fetchReportsPanel(queryData);
				setReportsData(response);
				setFailedLoadingCharts(determineLoadingState(response));
				if (response && response.top100SellersResponse.status <= 300) {
					buildTableData(response.top100SellersResponse.data);
				} else {
					setTableDataReport([]);
				}
				if (response && response.offersByPriceResponse.status <= 300) {
					const transformedData = await transformChartData(
						response.offersByPriceResponse.data
					);
					setReportsData((prevState) => ({
						...prevState,
						offersByPriceResponse: {
							...prevState.offersByPriceResponse,
							data: transformedData,
						},
					}));
				}
				setLoading(false);
			} else {
				setLoading(false);
				setFailedLoadingCharts('queryExceedsLimit');
				enqueueSnackbar(
					t(
						'Classificações.Relatórios.A quantidade de itens filtrados excede o limite permitido. Reduza para até 30.000 itens e tente novamente.'
					),
					{
						variant: 'error',
					}
				);
				setReloadReportData(0);
			}
		};
		if (openMenu == true && queryData && reloadReportData !== 0) {
			fetchData();
		}
		// eslint-disable-next-line
	}, [queryData, reloadReportData, openMenu]);

	const buildTableData = async (data) => {
		const tempData: any[] = [];
		if (data && data?.hits.length >= 1) {
			await data.hits.map((el) => {
				const locationParts = el.location.split('/');
				const topSellerData = {
					sellerName: el.name || el.seller_id,
					city: locationParts[0],
					state: converteNomeParaSigla(locationParts[1]),
					country: locationParts[2],
					totalOffers: handleNumberAbbreviation(
						userData.i18nID,
						el.totalOffers || el.total_offers
					),
					productsInStock: handleNumberAbbreviation(
						userData.i18nID,
						el.productsINstock || el.products_in_stock
					),
					productsSold: handleNumberAbbreviation(
						userData.i18nID,
						el.productsSold || el.products_sold
					),
				};
				tempData.push(topSellerData);
			});
			setTableDataReport(tempData);
			return;
		}
	};

	const RenderTotalOffersCard = () => (
		<ReportsCard
			key={reportsData}
			cardValue={reportsData?.totalOffersResponse}
			loading={loading}
			bottomTitleOnCard={`${t('Classificações.Relatórios.Total de ofertas')}`}
		/>
	);

	const RenderMeanPriceCard = () => (
		<ReportsCard
			key={reportsData}
			cardValue={reportsData?.priceAverageResponse}
			loading={loading}
			bottomTitleOnCard={`${t('Classificações.Relatórios.Preço médio')}`}
		/>
	);

	const RenderTotalProductsSoldCard = () => (
		<ReportsCard
			key={reportsData}
			cardValue={reportsData?.totalItemsSoldResponse}
			loading={loading}
			bottomTitleOnCard={`${t('Classificações.Relatórios.Total de produtos vendidos')}`}
		/>
	);

	const RenderTotalValueOfProductsSoldCard = () => (
		<ReportsCard
			key={reportsData}
			cardValue={reportsData?.totalValueItemsSoldResponse}
			loading={loading}
			bottomTitleOnCard={`${t('Classificações.Relatórios.Valor total de produtos vendidos')}`}
		/>
	);

	const RenderTotalItemsInStockCard = () => (
		<ReportsCard
			key={reportsData}
			cardValue={reportsData?.totalProductsInStockResponse}
			loading={loading}
			bottomTitleOnCard={`${t('Classificações.Relatórios.Total de itens em estoque')}`}
		/>
	);

	const RenderTotalValueOfItemsInStockCard = () => (
		<ReportsCard
			key={reportsData}
			cardValue={reportsData?.totalValueProductsInStockResponse}
			loading={loading}
			bottomTitleOnCard={`${t('Classificações.Relatórios.Valor total de itens em estoque')}`}
		/>
	);

	const renderMainCardsContent = () => (
		<>
			<Line
				style={{ justifyContent: 'end', padding: '20px 20px 0px', gap: 10 }}
			>
				<Button
					onClick={() => setReloadReportData(reloadReportData + 1)}
					sx={{
						gap: '10px',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.foreground,
						background: selectedTheme.primaryDark,
						'&:hover': {
							background:
								selectedTheme.id === 'dark' ? '#3d3d3d' : selectedTheme.primary,
						},
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
						cursor: 'pointer',
						width: '30%',
					}}
				>
					<Typography>
						{reloadReportData === 0
							? t('Classificações.Relatórios.Carregar gráfico')
							: t('Classificações.Relatórios.Recarregar')}
					</Typography>
					<Cached
						sx={{
							animation: loading ? '$spin 2s linear infinite' : 'normal',
							'@keyframes spin': loading
								? {
										'0%': {
											transform: 'rotate(360deg)',
										},
										'100%': {
											transform: 'rotate(0deg)',
										},
									}
								: null,
							zIndex: !loading ? 2 : 'auto',
						}}
						style={{
							fontSize: 22,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</Button>
			</Line>
			<Line style={{ justifyContent: 'center' }}>
				<Typography
					sx={{
						fontWeight: 'bold',
						fontSize: '1.5em',
						textWrap: 'nowrap',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Classificações.Relatórios.Relatórios das classificações')}
				</Typography>
			</Line>
			{failedLoadingCharts !== 'queryExceedsLimit' && reloadReportData !== 0 ? (
				<Column style={{ gap: 20 }}>
					<Line
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: 20,
							padding: '0px 20px',
						}}
					>
						<RenderTotalOffersCard />
						<RenderMeanPriceCard />
					</Line>
					<Line
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: 20,
							padding: '0px 20px',
						}}
					>
						<RenderTotalProductsSoldCard />
						<RenderTotalValueOfProductsSoldCard />
					</Line>
					<Line
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: 20,
							padding: '0px 20px',
						}}
					>
						<RenderTotalItemsInStockCard />
						<RenderTotalValueOfItemsInStockCard />
					</Line>
				</Column>
			) : null}
		</>
	);

	const RenderPriceDistributionChart = () => {
		return !loading &&
			reportsData &&
			reportsData?.offersByPriceResponse?.data ? (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Chart
					chartType="ColumnChart"
					key={reportsData?.offersByPriceResponse}
					data={reportsData?.offersByPriceResponse.data}
					options={chartColumnOptions}
					legend_toggle={false}
					height={382}
					width={'100%'}
				/>
			</div>
		) : (
			<div style={{ margin: '30px 0px', padding: '0px 40px ' }}>
				<Skeleton
					width="100%"
					count={1}
					height={238}
					baseColor={
						selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#e7e7e7'
					}
					highlightColor={
						selectedTheme.id === 'dark' && selectedTheme.textColorDisable
					}
				/>
				<Skeleton
					width="100%"
					count={1}
					height={40}
					style={{ marginTop: 10 }}
					baseColor={
						selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#e7e7e7'
					}
					highlightColor={
						selectedTheme.id === 'dark' && selectedTheme.textColorDisable
					}
				/>
			</div>
		);
	};

	const renderPriceCharts = () => (
		<Box
			style={{
				width: '100%',
				height: '100%',
				padding: '0px 20px',
				justifyContent: 'center',
				display:
					!loading && reportsData?.offersByPriceResponse?.data === null
						? 'none'
						: 'flex',
			}}
		>
			<Paper
				style={{
					width: '100%',
					height: '100%',
					borderRadius: '6px',
					display: 'flex',
					flexDirection: 'column',
					color:
						selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '#000',
					backgroundColor:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay4dp
							: selectedTheme.background,
				}}
			>
				<Line>
					<Box
						width="100%"
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '20px',
							gap: loading ? 12 : 0,
						}}
					>
						{!loading &&
						reportsData &&
						reportsData?.offersByPriceResponse?.data ? (
							<Line
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '10px 0px 0px',
								}}
							>
								<Typography
									style={{
										fontSize: '1.2vw',
										marginBottom: '-5px',
									}}
								>
									{`${t('Classificações.Relatórios.Distribuição por faixa de preço')}`}
								</Typography>
							</Line>
						) : (
							<div style={{ padding: '0px 20px', marginTop: 16 }}>
								<Skeleton
									width="100%"
									count={1}
									height={55}
									baseColor={
										selectedTheme.id === 'dark'
											? selectedTheme.footerLine
											: '#e7e7e7'
									}
									highlightColor={
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorDisable
									}
								/>
							</div>
						)}
						<RenderPriceDistributionChart />
					</Box>
				</Line>
			</Paper>
		</Box>
	);

	const handleOpenPanelButton = () => {
		setOpenMenu();
	};

	const openDrawerComponent = () => (
		<div
			onClick={handleOpenPanelButton}
			style={{
				display: 'flex',
				flexDirection: 'column',
				cursor: 'pointer',
				transition: 'right 0.5s cubic-bezier(0, 0, 0.2, 1)',
				width: '40px',
				height: 'auto',
				padding: '6px',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '10px',
				borderRadius: '6px 0px 0px 6px',
				border:
					selectedTheme.id === 'dark'
						? `1px solid ${selectedTheme.border}`
						: 'none',
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay4dp
						: selectedTheme.foreground,
				boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
				position: 'fixed',
				top: '63px',
				right: openMenu ? '600px' : '10px',
				zIndex: 19,
			}}
		>
			{openMenu ? (
				<ChevronRight
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			) : (
				<ChevronLeft
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			)}
			<Typography
				sx={{
					fontWeight: 'bold',
					fontSize: '0.9em',
					writingMode: 'vertical-rl',
					textWrap: 'nowrap',
					transform: 'rotate(180deg)',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					marginBottom: '6px',
				}}
			>
				{t('Classificações.Relatórios.Relatórios das classificações')}
			</Typography>
		</div>
	);

	const renderDrawerContent = () => {
		if (!openMenu) {
			return null;
		}

		return (
			<div
				key={failedLoadingCharts}
				style={{
					gap: '20px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{renderMainCardsContent()}
				{failedLoadingCharts !== 'queryExceedsLimit' && reloadReportData !== 0
					? renderPriceCharts()
					: null}
				{failedLoadingCharts !== 'queryExceedsLimit' &&
				reloadReportData !== 0 ? (
					<Box
						style={{
							width: '100%',
							height: '100%',
							padding: '0px 20px 20px',
							display: 'flex',
						}}
					>
						<OfferTable
							colsData={colsDataReport}
							tableData={tableDataReport}
							loading={loading}
							tableFooter={tableDataReport.length >= 1}
							customRowsPerPage={5}
							customRowsPerPageOptions={[]}
						/>
					</Box>
				) : null}
				{!loading &&
				failedLoadingCharts === 'queryExceedsLimit' &&
				reloadReportData !== 0 ? (
					<Box
						style={{
							width: '100%',
							height: '100%',
							padding: '0px 20px',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Card
							style={{
								width: '100%',
								height: 160,
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay4dp
										: selectedTheme.background,
								borderWidth: 1,
								borderColor:
									selectedTheme.tone === 'dark' ? '#707070' : '#cacaca',
								borderRadius: 6,
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Line style={{ padding: ' 20px 30px', gap: 15 }}>
								<WarningIcon
									style={{ fontSize: 40, color: selectedTheme.error }}
								/>
								<Typography
									sx={{
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
										textAlign: 'center',
									}}
								>
									{t(
										'Classificações.Relatórios.A quantidade de itens filtrados excede o limite permitido. Reduza para até 30.000 itens e tente novamente.'
									)}
								</Typography>
							</Line>
						</Card>
					</Box>
				) : null}
				{!loading && reloadReportData === 0 ? (
					<Box
						style={{
							width: '100%',
							height: '100%',
							padding: '0px 20px',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Card
							style={{
								width: '100%',
								height: 160,
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay4dp
										: selectedTheme.background,
								borderWidth: 1,
								borderColor:
									selectedTheme.tone === 'dark' ? '#707070' : '#cacaca',
								borderRadius: 6,
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Line style={{ padding: ' 20px 30px', gap: 15 }}>
								<WarningIcon
									style={{ fontSize: 40, color: selectedTheme.warning }}
								/>
								<Typography
									sx={{
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
										textAlign: 'center',
									}}
								>
									{t(
										'Classificações.Relatórios.Para carregar os gráficos das classificações, clique no botão acima. Certifique-se de que o valor dos itens filtrados seja menor que 30.000.'
									)}
								</Typography>
							</Line>
						</Card>
					</Box>
				) : null}
			</div>
		);
	};

	return (
		<Column
			style={{
				display: 'flex',
				height: '100vh',
				width: 'auto',
			}}
		>
			{openDrawerComponent()}
			<Column
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<Drawer
					variant="permanent"
					anchor="right"
					style={{
						width: openMenu ? '600px' : '10px',
						transition: 'none',
						overflow: 'hidden',
					}}
					sx={{
						'.MuiDrawer-paper': {
							width: openMenu ? '600px' : '10px',
							overflowY: openMenu ? 'auto' : 'hidden',
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay8dp
									: '#e2e2e2',
							transition: 'width 0.5s cubic-bezier(0, 0, 0.2, 1)',
							height: '100%',
							rowGap: '20px',
							borderRadius: '3px',
							border: '1px solid',
							borderColor:
								selectedTheme.id === 'dark'
									? selectedTheme.foreground
									: '#e9e9e9',
							filter: 'brightness(1.03)',
							boxShadow: 'rgba(0, 0, 0, 0.2) 3px -2px 9px 1px',
							zIndex: 1000,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-center',
						},
					}}
				>
					{renderDrawerContent()}
				</Drawer>
			</Column>
		</Column>
	);
};

export default ReportPanel;
