import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { api, isAxiosError } from '../../../../utils/api';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { enqueueSnackbar } from 'notistack';
import { ErrorResponse } from '../../../../interfaces';

interface Props {
	open: boolean;
	setOpen: any;
	platformData: any;
	refresh: () => void;
}

const DesactivatePlatformDialog: React.FC<Props> = ({
	platformData,
	refresh,
	open,
	setOpen,
}: Props) => {
	const { selectedTheme, cursorMode } = usePO();
	const { t } = useTranslation();

	const [loading, setLoading] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClientStatusChange = async () => {
		setLoading(true);
		const payload = { ISactive: !platformData.ISactive }
		try {
			const platformINcountryResponse = await api.patch(
				`/platformINcountry/${platformData.inCountryID
				}`, payload
			);
			setLoading(false);
			enqueueSnackbar(platformINcountryResponse.data.detail, { variant: 'success' });
			handleClose();
			refresh();
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		} finally {
			handleClose();
			refresh();
		}
	};

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '20px 30px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			}}
		>
			<Typography variant="h6">
				{platformData?.ISactive === true
					? `${t('Buscas.Tabs.Plataformas.Desativar a plataforma')}: ${platformData?.name
					} - ${platformData?.country}?`
					: `${t('Buscas.Tabs.Plataformas.Ativar a plataforma')}: ${platformData?.name
					} - ${platformData?.country}?`}
			</Typography>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Buscas.Tabs.Plataformas.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						onClick={handleClientStatusChange}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{platformData?.ISactive === true
							? t('Buscas.Tabs.Plataformas.Desativar')
							: t('Buscas.Tabs.Plataformas.Ativar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{ cursor: cursorMode }}
			>
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default DesactivatePlatformDialog;
