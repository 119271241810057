import React from 'react';
import {
	Card,
	FormControl,
	InputLabel,
	Select,
	Typography,
	TextField,
	MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../../styles';
import { usePO } from '../../../../../utils/POContext';
import { useSelector } from 'react-redux';

interface Props {
	offerData: any;
	expandCard: boolean;
	denounceValue: string;
	setDenounceValue: (value) => void;
	handleExpandCard: (value) => void;
	denounceObs: string;
	setDenounceObs: (value) => void;
}

const UpperCard: React.FC<Props> = ({
	offerData,
	expandCard,
	denounceValue,
	setDenounceValue,
	handleExpandCard,
	denounceObs,
	setDenounceObs,
}) => {
	const { t } = useTranslation();
	const { classificationTypes, selectedTheme } = usePO();

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	const handleDenounceReasonChange = (event: any) => {
		setDenounceValue(event.target.value);
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const renderDenounceReason = () => (
		<Column
			style={{
				justifyContent: 'space-between',
				height: 260,
				alignItems: 'center',
				padding: '10px 20px',
			}}
		>
			<FormControl
				style={{
					width: '100%',
				}}
			>
				<InputLabel htmlFor="Denunciation-reason" style={{ zIndex: 2 }}>
					{t('Classificações.Escolha um motivo')}
				</InputLabel>
				<Select
					disabled={goodCardsList.includes(offerData.offerID)}
					value={denounceValue}
					onChange={handleDenounceReasonChange}
					style={{
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: `1px solid #dedede`,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
					}}
					sx={{
						'&.Mui-selected': {
							padding: '5px 10px',
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							'&:hover': {
								background:
									selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
							},
						},
						'.MuiSelect-icon': {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
						'& [aria-expanded=true]': {
							background:
								selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
						},
					}}
					inputProps={{
						MenuProps: {
							PaperProps: {
								sx: {
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay3dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
							},
						},
					}}
				>
					{/* <MenuItem aria-label="none" value="" /> */}
					{classificationTypes.map((item: any) => {
						if (item.ISactive && !item.ISgood) {
							return (
								<MenuItem value={item.id} key={item.name}>
									{item.name}
								</MenuItem>
							);
						}
						return null;
					})}
				</Select>
			</FormControl>
			{denounceValue !== 'NOT_SET' && denounceValue !== 'PSTLG' ? (
				<Line
					style={{
						overflow: 'scroll',
						marginTop: 10,
					}}
				>
					<Typography
						variant="caption"
						style={{
							opacity: 0.8,
						}}
					>
						{classificationTypes.map((item) =>
							item.id === denounceValue ? item.description : null
						)}
					</Typography>
				</Line>
			) : (
				<Line style={{ height: 35 }} />
			)}
			<Line />
			<TextField
				id="denounce_obs"
				label={t('Classificações.Observações')}
				value={denounceObs}
				onChange={handleObservationChange}
				multiline
				rows={2}
				variant="outlined"
				style={{ width: '100%' }}
			/>
		</Column>
	);

	return (
		<Card
			style={{
				height: 310,
				width: 520,
				padding: 0,
				zIndex: 1,
				position: 'relative',
				bottom: 0,
				cursor: expandCard ? 'pointer' : 'auto',
			}}
			onClick={() => handleExpandCard(false)}
		>
			<Line
				style={{
					height: 40,
					background: '#EA932D',
					justifyContent: 'center',
				}}
			>
				<Typography
					align="center"
					noWrap
					variant="subtitle2"
					style={{ color: 'white', fontWeight: 'bold' }}
				>
					{t('Classificações.Motivo da Classificação')}
				</Typography>
			</Line>
			{renderDenounceReason()}
		</Card>
	);
};

export default UpperCard;
