import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CardFlip from 'react-card-flip';
import Front from './Front';
import Back from './Back';
import { useSelector } from 'react-redux';
import { usePO } from '../../../utils/POContext';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';

const useStyles = makeStyles(() => ({
	card: {
		'&:hover': {
			boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
		},
	},
	disabledCards: {
		transitionDelay: '1s',
		opacity: 0.3,
		transition: 'opacity 1.5s ease-in',
		pointerEvents: 'none',
	},
}));

interface Props {
	data: any;
	margin?: number;
	index: number;
	dataPackage: object[];
}

const FlippingCard: React.FC<Props> = ({
	data,
	margin,
	index,
	dataPackage,
}) => {
	const { selectedClient } = usePO();

	const notGoodCardsList = useSelector(
		(state: any) => state.cardFeedback.notGoodCardsList
	);

	const { selectedCards, handleSelectCard } = useCardSelector();

	const classes = useStyles();

	const [flip, setFlip] = useState(false);
	const [newKey, setNewKey] = useState(0);

	const [cardAsGood, setCardAsGood] = useState(false);

	useEffect(() => {
		if (data.clientsClassifiedAsGood?.includes(selectedClient?.id)) {
			setCardAsGood(true);
		}
		// eslint-disable-next-line
	}, [selectedClient]);

	/**
	 *A HandleSettags Function UPDATES as Cardless Tags, Modal Sorting
	 * and Unitalia Denunciation Modal, It's called a SetNewKey RespoLavel By
	 * render only the card that has been added a tag so that it appears as modifications.
	 * the selectedCards check is for when the Post Analyst user will report in bulk the tags are updated
	 * @param tagsData
	 */

	const handleRemoveCardAsGood = () => {
		setCardAsGood(false);
		setNewKey(newKey + 1);
	};

	const handleSetTags = (tagsData) => {
		data.tags = tagsData;
		setNewKey(newKey + 1);
		if (selectedCards.length > 0) {
			handleSelectCard({ offerID: data.offerID, tags: data.tags }, 'remove');
			handleSelectCard({ offerID: data.offerID, tags: data.tags }, 'add');
		}
	};

	const handleFlip = () => {
		setFlip(!flip);
	};

	const checksDataInRedux = (list, data) => {
		return list?.includes(data);
	};

	const transformFree = {
		front: {
			transformStyle: 'flat',
		},
		back: { transformStyle: 'flat' },
	};

	return (
		<div style={{ margin }} key={data.offerID}>
			<CardFlip
				isFlipped={flip}
				flipDirection="horizontal"
				cardStyles={transformFree}
			>
				<Front
					offersDataPackage={dataPackage}
					index={index}
					key={newKey}
					data={data}
					cardAsGood={cardAsGood}
					handleRemoveCardAsGood={handleRemoveCardAsGood}
					thumb={data.thumb}
					handleFlip={handleFlip}
					handleSetTags={handleSetTags}
					className={
						/** If card is not listed as disabled */
						checksDataInRedux(notGoodCardsList, data.offerID) ||
						data.ISdenounced
							? classes.disabledCards
							: classes.card
					}
				/>
				<Back
					offersDataPackage={dataPackage}
					index={index}
					key={newKey}
					data={data}
					cardAsGood={cardAsGood}
					handleRemoveCardAsGood={handleRemoveCardAsGood}
					handleFlip={handleFlip}
					handleSetTags={handleSetTags}
					className={
						/** If card is not listed as disabled */
						checksDataInRedux(notGoodCardsList, data.offerID)
							? classes.disabledCards
							: classes.card
					}
				/>
			</CardFlip>
		</div>
	);
};

export default FlippingCard;
