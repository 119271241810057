import React, { useState } from 'react';
import {
	Card,
	Dialog,
	Typography,
	DialogTitle,
	IconButton,
	CircularProgress,
} from '@mui/material';
import { usePO } from '../../../../utils/POContext';
import { Line } from '../../../../styles';
import { t } from 'i18next';
import { CloseRounded } from '@mui/icons-material';

const SelectVersionModal = ({ open, handleClose, url }) => {
	const { selectedTheme } = usePO();
	const [loading, setLoading] = useState(true);

	const handleThemeTextHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const RenderDialogHeader = () => (
		<DialogTitle
			style={{
				padding: '10px 20px',
				borderBottom: `2px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.footerLine
						: selectedTheme.disabled
				}`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.tableHead
						: selectedTheme.gradient,
				display: 'flex',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					variant="h6"
					style={{
						fontWeight: 'bold',
						color: handleThemeTextHeader(),
					}}
				>
					{t('VertMenu.Plataforma Offer')}
				</Typography>
				<IconButton
					data-testid="closeVersionButton"
					onClick={handleClose}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const handleIframeLoading = () => {
		setLoading(false);
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
			<RenderDialogHeader />
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : 'white',
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
				}}
			>
				<div
					style={{
						overflowY: 'hidden',
						height: '95vh',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '20px 20px 0px',
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
					}}
				>
					{loading && (
						<CircularProgress
							size={50}
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						/>
					)}
					<iframe
						src={
							url ||
							'https://docs.offertech.com.br/share/792ebbf7-7fc0-41cd-ae86-dff4b946f5f5'
						}
						title="Version Content"
						style={{
							width: '100%',
							height: '100%',
							border: 'none',
							visibility: loading ? 'hidden' : 'visible',
						}}
						onLoad={handleIframeLoading}
					/>
				</div>
			</Card>
		</Dialog>
	);
};

export default SelectVersionModal;
