import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const bindAuthorAndEntity = async (
	authorID: string,
	entityID: string
) => {
	const payload: any = {
		authorID,
		entityID,
	};
	try {
		const response = await api.post(`/authorINentity`, payload);
		return {
			success: true,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};

export const unbindAuthorFromEntity = async (authorINentityID: string) => {
	try {
		const response = await api.delete(`/authorINentity/${authorINentityID}`);
		return {
			success: true,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};

export const submitAuthorsTags = async (
	payload: any,
	selectedClientID: string
) => {
	try {
		const tagsResponse = await api.post(
			`/tagINauthor/elasticsearch?clientID=${selectedClientID}`,
			payload,
			{
				timeout: 300 * 1000,
			}
		);
		return {
			success: true,
			status: tagsResponse.status,
			data: tagsResponse.data,
			message: tagsResponse.data.detail || 'Etiquetas atualizadas com sucesso',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getAuthorByElasticSearch = async (requestBody) => {
	try {
		const response = await api.post(
			'/authors/elasticsearch/_msearch',
			requestBody
		);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
