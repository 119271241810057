import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OfferTable from '../../components/OfferTable';
import { OfferToolTip } from '../../helpers';
import { api, isAxiosError, setupRequestToken } from '../../utils/api';
import { usePO } from '../../utils/POContext';
import Unauthorized from '../Page401';
import { Refresh, Visibility, VisibilityOff } from '@mui/icons-material/';
import EditTagModal from './EditTagModal';
import { Line } from '../../styles';
import { useAuth0 } from '../../react-auth0-spa';
import SelectClient from '../../components/SelectClient';
import AddTag from './AddTag';
import { useDispatch } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import { ErrorResponse } from '../../interfaces';
import TagStatusChangeDialog from './TagStatusChangeDialog';

const Tags: React.FC = () => {
	const { selectedClient, updateCursorMode, selectedTheme, userData } = usePO();
	const { t } = useTranslation();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [tableData, setTableData] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [showInactiveTags, setShowInactiveTags] = useState(false);

	const colsData = [
		{
			columnTitle: t('Etiqueta.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 150,
		},
		{
			columnTitle: t('Etiqueta.Descrição'),
			fieldName: 'description',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Etiqueta.Tipo'),
			fieldName: 'tagTypeID',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Etiqueta.Amostra'),
			fieldName: 'id',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 150,
			dataType: 'tag',
		},
		{
			columnTitle: t('Etiqueta.Fundo'),
			fieldName: 'badgeColor',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 50,
			dataType: 'color',
		},
		{
			columnTitle: t('Etiqueta.Texto'),
			fieldName: 'textColor',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 50,
			dataType: 'color',
		},
	];

	const buildTableData = (data: any) => {
		if (Array.isArray(data)) {
			const filteredData = data
				.filter((el) => !['BUSCA', 'IDE', 'USUARIO'].includes(el.tagTypeID))
				.filter((el) => showInactiveTags || el.ISactive)
				.map((el) => ({
					id: el.id,
					name: el.name,
					description: el.description,
					tagTypeID: el.tagTypeID,
					textColor: el.textColor,
					badgeColor: el.badgeColor,
					ISactive: el.ISactive,
				}));

			setTableData(filteredData);
		}
	};
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(changeActiveMenu('etiquetas'));
		if (selectedClient !== undefined) {
			setLoading(true);
			updateCursorMode('wait');
			const fetchData = async () => {
				try {
					const tagsData = await api.get(`/tags/client/${selectedClient.id}`);
					buildTableData(tagsData.data);
					setReload(false);
					setLoading(false);
					updateCursorMode('default');
				} catch (error) {
					if (isAxiosError(error)) {
						const errorResponse = error.response as ErrorResponse | undefined;
						if (errorResponse && errorResponse.data) {
							return {
								success: false,
								status: errorResponse.status,
								message: errorResponse.data.detail,
								data: [],
							};
						}
					}
				}
				setLoading(false);
				updateCursorMode('default');
			};
			fetchData();
		}
		// eslint-disable-next-line
	}, [selectedClient?.id, reload, dispatch]);

	const reFetchData = async () => {
		setReload(true);
	};

	const toggleShowInactiveTags = () => {
		setShowInactiveTags((prev) => !prev);
		reFetchData();
	};

	const action1Props = { refresh: () => reFetchData() };
	const action2Props = { refresh: () => reFetchData() };
	const CustomHeaderComponentProps = { refresh: () => reFetchData() };

	const renderReloadTableData = () => (
		<>
			<OfferToolTip
				title={`${t('Etiqueta.Atualizar dados da tabela')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={() => reFetchData()}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Refresh
						style={{
							fontSize: 23,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<OfferToolTip
				title={
					showInactiveTags
						? `${t('Etiqueta.Mostrar apenas etiquetas ativas')}`
						: `${t('Etiqueta.Mostrar todos as etiquetas')}`
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={toggleShowInactiveTags}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					{showInactiveTags ? (
						<Visibility
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								fontSize: 20,
							}}
						/>
					) : (
						<VisibilityOff
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						/>
					)}
				</IconButton>
			</OfferToolTip>
		</>
	);

	const rightFooterComponent = () => (
		<Line
			style={{
				justifyContent: 'flex-end',
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			}}
		>
			<Typography>{t('Cliente')}:</Typography>
			<div
				style={{
					width: 200,
					marginBottom: -5,
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				<SelectClient />
			</div>
		</Line>
	);

	return userData.permissions?.includes('read:tags') ? (
		<>
			<OfferTable
				colsData={colsData}
				tableData={tableData}
				windowHeader
				windowTitle={t('Etiqueta.Gerenciamento de Etiquetas')}
				tableActions
				tableFooter
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient
						? loading
							? t('Carregando...')
							: t('Sem etiquetas para este cliente')
						: t('Selecione um cliente')
				}
				customLeftFooterComponent={renderReloadTableData()}
				customRightFooterComponent={rightFooterComponent()}
				CustomHeaderComponent={AddTag}
				CustomHeaderComponentProps={CustomHeaderComponentProps}
				ActionComponent1={EditTagModal}
				ActionComponent1Props={action1Props}
				ActionComponent2={
					userData.roles?.indexOf('Desenvolvedor') !== -1 ||
					userData.roles?.indexOf('Administrador da ferramenta') !== -1
						? TagStatusChangeDialog
						: null
				}
				ActionComponent2Props={action2Props}
			/>
		</>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default Tags;
