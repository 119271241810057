import React, { useState } from 'react';
import {
	CircularProgress,
	Grid,
	IconButton,
	Typography,
	Zoom,
} from '@mui/material';
import {
	ChevronLeftRounded,
	ChevronRightRounded,
	CloseRounded,
} from '@mui/icons-material/';
import { Column, Header, OfferCard } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import DenounceConfirmationDialog from './DenounceConfirmationDialog';

import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../helpers';

/* 
  🚨 Maintenance Note 🚨
	Please be cautious when modifying the height calculations on the main return 
  on this component. They are calculated dynamically based on viewport and other
  elements' heights.	Make sure to thoroughly test any changes on different screen sizes. 
*/

interface Props {
	carouselPosition?: string;
	handleCycleOffer?: (direction: string) => void;
	loading: boolean;
	offerData: any;
	useCarousel?: boolean;
	setOpen?: (value) => void;
}

const OfferDataFrames: React.FC<Props> = ({
	carouselPosition,
	handleCycleOffer,
	loading,
	offerData,
	useCarousel,
	setOpen,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const [enableClassification, setEnableClassification] = useState(false);
	const [denounceValue, setDenounceValue] = useState<any>({ id: 'NOT_SET' });
	const [denounceObs, setDenounceObs] = useState('');

	const processElementColor = () => {
		if (selectedTheme.id === 'dark') {
			return selectedTheme.textColorHigh;
		} else if (selectedTheme.id === 'main') {
			return selectedTheme.primary;
		} else {
			return selectedTheme.background;
		}
	};

	const checkFirst = () => {
		if (carouselPosition) {
			const parts = carouselPosition.split('/').map(Number);
			if (parts.length === 2 && parts.every((part) => !isNaN(part))) {
				const [firstPart] = parts;
				if (firstPart === 1) return true;
			}
		}
	};

	const checkLast = () => {
		if (carouselPosition) {
			const parts = carouselPosition.split('/').map(Number);
			if (parts.length === 2 && parts.every((part) => !isNaN(part))) {
				const [firstPart, secondPart] = parts;
				if (firstPart === secondPart) return true;
			}
		}
	};

	const handleCloseModal = () => {
		if (setOpen && useCarousel) {
			setOpen(false);
		}
	};

	const resetDenouceParameters = () => {
		setDenounceValue({ id: 'NOT_SET' });
		setDenounceObs('');
	};

	const handleNextOffer = () => {
		if (handleCycleOffer) {
			handleCycleOffer('forward');
			resetDenouceParameters();
		}
	};

	const handlePreviousOffer = () => {
		if (handleCycleOffer) {
			handleCycleOffer('backward');
			resetDenouceParameters();
		}
	};

	const renderArrows = () => (
		<>
			<Column
				style={{
					position: 'absolute',
					top: 'calc(50vh - 50px)',
					left: 0,
					justifyContent: 'center',
					alignItems: 'center',
					height: 50,
					width: 50,
					zIndex: 999,
				}}
			>
				<IconButton
					onClick={handlePreviousOffer}
					disabled={checkFirst()}
					style={{ color: 'white', opacity: checkFirst() ? 0.3 : 1 }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<ChevronLeftRounded style={{ fontSize: 80 }} />
				</IconButton>
			</Column>
			<Column
				style={{
					position: 'absolute',
					top: 'calc(50vh - 50px)',
					right: 0,
					justifyContent: 'center',
					alignItems: 'center',
					height: 50,
					width: 50,
					zIndex: 999,
				}}
			>
				<IconButton
					onClick={handleNextOffer}
					disabled={checkLast()}
					style={{ color: 'white', opacity: checkLast() ? 0.3 : 1 }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<ChevronRightRounded style={{ fontSize: 80 }} />
				</IconButton>
			</Column>
		</>
	);

	const renderHeader = () => (
		<Header
			style={{
				padding: 20,
				height: 60,
				borderRadius: '6px 6px 0 0',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.vertMenuColor
						: selectedTheme.gradient,
				color: processElementColor(),
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			<OfferToolTip
				title={
					Object.entries(offerData).length < 1
						? t('Carregando...')
						: offerData.title
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Typography noWrap variant="h6" style={{ width: '90%' }}>
					{Object.entries(offerData).length < 1
						? t('Carregando...')
						: offerData.title}
				</Typography>
			</OfferToolTip>
			{useCarousel && (
				<IconButton
					onClick={handleCloseModal}
					data-testid="cardCloseModal"
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color: processElementColor(),
						}}
					/>
				</IconButton>
			)}
		</Header>
	);

	const renderNoData = () => (
		<Column
			style={{
				background: selectedTheme.foreground,
				justifyContent: 'center',
				alignContent: 'center',
				width: 'calc(100vw - 90px)',
				height: 'calc(100vh - 130px)',
				rowGap: 15,
			}}
		>
			{loading ? (
				<CircularProgress size={25} />
			) : (
				<Typography
					variant="subtitle1"
					style={{ color: selectedTheme.primaryDark }}
				>
					{t('Classificações.Carregando')}...
				</Typography>
			)}
		</Column>
	);

	const renderOffer = () => (
		<OfferCard
			style={{
				padding: '15px 20px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.lightBackground,
			}}
		>
			<Grid container spacing={2}>
				<LeftColumn offerData={offerData} />
				<RightColumn
					enableClassification={enableClassification}
					denounceValue={denounceValue}
					setDenounceValue={setDenounceValue}
					denounceObs={denounceObs}
					setDenounceObs={setDenounceObs}
					offerData={offerData}
					setEnableClassification={setEnableClassification}
				/>
			</Grid>
		</OfferCard>
	);

	return (
		<div
			style={{
				padding: useCarousel ? '1vh 4vw' : undefined,
				background: 'rgba(0,0,0,0.3)',
			}}
		>
			{useCarousel && renderArrows()}
			{renderHeader()}
			{Object.entries(offerData).length < 1 ? renderNoData() : renderOffer()}
			<DenounceConfirmationDialog
				carouselPosition={carouselPosition}
				denounceObs={denounceObs}
				denounceValue={denounceValue}
				enableClassification={enableClassification}
				offerData={offerData}
				setDenounceValue={setDenounceValue}
				setEnableClassification={setEnableClassification}
			/>
		</div>
	);
};
export default OfferDataFrames;
