import React from 'react';
import {
	Box,
	Divider,
	IconButton,
	Link,
	Typography,
	Zoom,
} from '@mui/material';
import { NewReleases, VerifiedUser } from '@mui/icons-material/';
import LinkIcon from '@mui/icons-material/Link';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../../../../helpers';
import { Line } from '../../../../../../styles';
import { usePO } from '../../../../../../utils/POContext';
import RatingsChart from '../RatingsChart';

interface Props {
	data: any;
}
const DetailsEnjoei: React.FC<Props> = ({ data }) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	return (
		<>
			<Line style={{ columnGap: 10, justifyContent: 'space-between' }}>
				<Line style={{ columnGap: 10, justifyContent: 'flex-start' }}>
					{data.author.authorData.official.value === true ? (
						<>
							<VerifiedUser
								style={{
									color: selectedTheme.success,
									fontSize: 16,
								}}
							/>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Classificações.Loja oficial')}
							</Typography>
						</>
					) : (
						<>
							<NewReleases
								style={{
									color: selectedTheme.error,
									fontSize: 16,
								}}
							/>
							<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
								{t('Classificações.Loja não oficial')}
							</Typography>
						</>
					)}
				</Line>
				<Line
					style={{
						columnGap: 10,
						justifyContent: 'center',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Vendas')}:
					</Typography>

					<Typography variant="subtitle2">
						{data.author.authorData.items_sold.value}
					</Typography>
				</Line>
				<Line style={{ justifyContent: 'flex-end', columnGap: 10 }}>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Estoque')}:
					</Typography>
					<Typography variant="subtitle2">{`(${data.author.authorData.products.value})`}</Typography>
				</Line>
			</Line>
			<Divider
				sx={{ opacity: 0.6 }}
				variant="middle"
				style={{ margin: '15px 0 5px 0' }}
			/>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{
						justifyContent: 'flex-start',
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Vendedor')}:
					</Typography>
					<IconButton
						style={{
							transform: 'rotate(45deg)',
							marginRight: -5,
						}}
						href={data.author.permalink}
						target="blank"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LinkIcon
							style={{
								fontSize: 15,
								color: selectedTheme.primary,
							}}
						/>
					</IconButton>
					<OfferToolTip
						title={data.author.authorData.nickname.value}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						TransitionComponent={Zoom}
						arrow
					>
						<Box
							maxWidth="220px"
							overflow="hidden"
							textOverflow="ellipsis"
							whiteSpace="nowrap"
						>
							<Link
								href={data.author.permalink}
								variant="subtitle2"
								color="inherit"
								target="blank"
							>
								<Typography noWrap variant="subtitle2">
									{data.author.authorData.nickname.value}
								</Typography>
							</Link>
						</Box>
					</OfferToolTip>
				</Line>
				<RatingsChart data={data} />
				<OfferToolTip
					title={`${data.author.cityName}, ${data.author.stateName}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					TransitionComponent={Zoom}
					arrow
				>
					<Box
						width="33% "
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						<Line
							style={{
								justifyContent: 'flex-end',
								columnGap: 10,
							}}
						>
							<Typography noWrap variant="subtitle2">
								{`${data.author.cityName},`}
							</Typography>
							<Typography noWrap variant="subtitle2">
								{data.author?.stateName}
							</Typography>
						</Line>
					</Box>
				</OfferToolTip>
			</Line>
			<Line style={{ justifyContent: 'space-between', marginTop: 6 }}>
				{data.author.authorData.followers?.value ? (
					<Line
						style={{
							width: '33%',
							justifyContent: 'flex-start',
							columnGap: 10,
						}}
					>
						<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
							{t('Classificações.Seguidores')}:
						</Typography>

						<Typography noWrap variant="subtitle2">
							{data.author.authorData.followers.value}
						</Typography>
					</Line>
				) : null}
				{data.author.authorData.following?.value ? (
					<Line
						style={{
							width: '33%',

							justifyContent: 'flex-end',
							columnGap: 10,
						}}
					>
						<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
							{t('Classificações.Seguindo')}:
						</Typography>

						<Typography noWrap variant="subtitle2">
							{data.author.authorData.following.value}
						</Typography>
					</Line>
				) : null}
			</Line>
			<Line style={{ justifyContent: 'space-between', marginTop: 15 }}>
				<Line
					style={{
						justifyContent: 'flex-start',
						columnGap: 10,
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Loja criada em')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{dateFormat(data.author.created, 'dd/mm/yyyy')}
					</Typography>
				</Line>
				<Line
					style={{
						justifyContent: 'flex-end',
						columnGap: 10,
					}}
				>
					<Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Última edição')}:
					</Typography>

					<Typography noWrap variant="subtitle2">
						{dateFormat(data.author.updated, 'dd/mm/yyyy')}
					</Typography>
				</Line>
			</Line>
			<Divider
				sx={{ opacity: 0.6 }}
				variant="middle"
				style={{ margin: '20px 0 5px 0' }}
			/>
			<Line style={{ justifyContent: 'flex-start', columnGap: 10 }}>
				<Typography noWrap variant="subtitle2" style={{ fontWeight: 'bold' }}>
					{t('Classificações.Informações deste produto')}:
				</Typography>
			</Line>
			<Line
				style={{ columnGap: 10, justifyContent: 'flex-start', marginTop: 10 }}
			>
				{data.offerData.used.value === true ? (
					<Typography variant="subtitle2">
						{t('Classificações.Produto usado')}
					</Typography>
				) : (
					<Typography variant="subtitle2">
						{t('Classificações.Produto novo')}
					</Typography>
				)}
			</Line>
		</>
	);
};
export default DetailsEnjoei;
