import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	IconButton,
	Zoom,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../components/LoadingDots';
import { Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { OfferToolTip } from '../../../helpers';
import { unbindAuthorFromEntity } from '../../../services/authors';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material/';

interface Props {
	reloadTable?: any;
	rowData?: any;
}

const DeleteDenouncementDialog: React.FC<Props> = ({
	reloadTable,
	rowData,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, cursorMode, userData } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	/** Checks if user meets conditions to Delete the item */
	const selectDisable = () => {
		if (
			userData.roles?.indexOf('Desenvolvedor') !== -1 ||
			userData.roles?.indexOf('Administrador da ferramenta') !== -1
		) {
			return false;
		}
		if (rowData?.classificationTypeID === 'SUSPT') {
			return false;
		}
		if (rowData?.denouncerEmail === userData.email) {
			return false;
		}
		return true;
	};

	const handleUnbindAuthor = async () => {
		setLoading(true);
		const authorInEntityID = `${rowData.id}|${rowData.entityID}`;
		const response: any = await unbindAuthorFromEntity(authorInEntityID);
		if (response.success === true) {
			enqueueSnackbar(
				t(response.message || 'Entidades.Autor desvinculado com sucesso'),
				{ variant: 'success' }
			);
			handleClose();
			reloadTable();
		}
		if (response.success === false) {
			enqueueSnackbar(
				t(response.message || 'Entidades.Autor desvinculado com sucesso'),
				{ variant: 'error' }
			);
		}
	};

	const renderDeleteIcon = () => (
		<>
			<OfferToolTip
				title={`${t('Entidades.Desvincular autor')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
				data-testid="deletIcon"
			>
				<IconButton
					size="small"
					aria-label="edit"
					style={{ marginRight: -5 }}
					disabled={selectDisable()}
					onClick={() => setOpen(true)}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Close
						style={{
							color: !selectDisable()
								? selectedTheme.id === 'dark'
									? selectedTheme.error
									: selectedTheme.error
								: selectedTheme.id === 'dark'
									? '#4F4F4F'
									: 'lightgray',
							transform: 'rotateY(180deg)',
							fontSize: 20,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</>
	);

	const renderDialogContent = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				padding: '3px 10px 0 10px',
			}}
			data-testid="modalDeleteDenounce"
		>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
				}}
				variant="h6"
			>
				{t('Entidades.Deseja desvincular o autor')}:
			</Typography>
			<OfferToolTip
				title={`${rowData?.title || ''}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Typography
					noWrap
					variant="h6"
					style={{
						marginLeft: 5,
						maxWidth: 300,
						color: selectedTheme.textColorMedium,
					}}
				>
					{`${rowData?.name || ''}`}
				</Typography>
			</OfferToolTip>
			<Typography
				variant="h6"
				style={{
					color:
						selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
				}}
			>
				?
			</Typography>
		</Line>
	);

	const renderFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
			}}
		>
			<Button
				size="small"
				onClick={handleClose}
				disabled={loading}
				style={{
					color: loading ? 'gray' : selectedTheme.error,
				}}
				data-testid="closeModal"
			>
				{t('Entidades.Cancelar')}
			</Button>
			{loading ? (
				<LoadingDots height={30} width={70} loop />
			) : (
				<Button
					data-testid="confirmDelete"
					size="small"
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.primaryDark,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: selectedTheme.foreground
						}`,
					}}
					onClick={handleUnbindAuthor}
				>
					{t('Entidades.Desvincular')}
				</Button>
			)}
		</Line>
	);

	return (
		<div>
			{renderDeleteIcon()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{ cursor: cursorMode }}
			>
				<DialogContent
					style={{
						padding: '20px 30px',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
					}}
				>
					{renderDialogContent()}
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						padding: '10px 15px',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DeleteDenouncementDialog;
