import React, { useState } from 'react';
import { Chip, TextField, Typography, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePO } from '../../utils/POContext';
import { CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { SizeType, TagObjectType } from '../../interfaces';
import { Line } from '../../styles';
import { OfferToolTip } from '../../helpers';
interface Props {
	dataArray: any;
	setDataArray?: (data) => void;
	suggestions?: any;
	displayOnly?: boolean;
	autoSave?: boolean;
	disabled?: boolean;
	error?: boolean;
	handleSaveTags?: any;
	limitTags?: number;
	loading?: boolean;
	loadingText?: string;
	maxItems?: number;
	placeholder?: string;
	readOnly?: boolean;
	rows?: number;
	size?: SizeType;
	tagSize?: SizeType;
	width?: string;
}
/**
 * @component TAG SELECTOR
 * @API https://docs.offertech.com.br/doc/tag-selector-CGsmQVMUcH
 *
 * @setDataArray (Obrigatório) Função 'setState' que controla a variável de estado que recebe os dados contidos do array de itens
 * @dataArray (Obrigatório) Variável de estado que recebe os dados contidos do array de itens
 * @suggestions (Obrigatório) Array contendo as sugestões de entrada
 * @size Define a altura da caixa de texto que encapsula as tags
 * @autoSave Define se o componente deve salvar as tags automaticamente
 * @width Valor em string para a largura da caixa de texto
 * @rows Define o número mínimo de linhas (altura) para a caixa de texto
 * @maxItems Limita o número de itens permitidos no array
 * @limitTags Limita o número de itens mostrados no componente
 * @loadingText Texto a ser usado no estado de carregamento
 * @disabled A opção desativada é inutilizável e o componente não pode ser clicada.
 * @readOnly Define o componente como somente leitura
 */
const TagSelector: React.FC<Props> = ({
	autoSave,
	dataArray,
	disabled,
	error,
	handleSaveTags,
	limitTags,
	loading,
	loadingText,
	maxItems,
	placeholder,
	readOnly,
	rows,
	setDataArray,
	size,
	tagSize,
	suggestions,
	width,
	displayOnly,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, tagsActiveClient, selectedClient } = usePO();

	const [isOpen, setIsOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	// function that sets the limit of selected items
	const handleMaxItems = () => {
		if (maxItems && dataArray.length >= maxItems) {
			return false;
		}
		return true;
	};
	/** Handle auto save when enabled */
	if (handleSaveTags && isSaving && autoSave && !isOpen) {
		handleSaveTags();
		setIsSaving(false);
	}
	// Depending on the action's reason it adds os removes the item
	const handleChangeTags = (value, reason: string) => {
		if (setDataArray && reason === 'removeOption') {
			setDataArray(value);
			setIsSaving(true);
		}
		if (setDataArray && reason === 'selectOption' && handleMaxItems()) {
			setDataArray(value);
		}
	};
	// render placeholder in the component
	const handlePlaceholder = () => {
		if (dataArray.length === 0) return placeholder || t(`Busca por etiquetas`);
		if (isOpen && maxItems) {
			return `${dataArray.length}/${maxItems}`;
		} else return '';
	};
	const handleMaxLength = () => {
		if (!maxItems || maxItems === undefined) return 256;
		if (maxItems && handleMaxItems()) {
			return 256;
		} else return 0;
	};

	const renderDeleteIcon = () => (
		<CloseRounded
			style={{
				opacity: loading ? 0.2 : 1,
				cursor: loading ? 'not-allowed' : 'pointer',
				color:
					selectedTheme.id === 'dark'
						? selectedTheme?.textColorMedium
						: selectedTheme?.foreground,
			}}
		/>
	);

	const renderOnlyDisplayTags = () => {
		const tagsToDisplay: TagObjectType[] = [];
		tagsActiveClient?.forEach((tag) => {
			dataArray.forEach((tagIDfromDataArray) => {
				if (tag.tagID === tagIDfromDataArray) {
					tagsToDisplay.push(tag);
				}
			});
		});

		return tagsToDisplay.length > 0 ? (
			<OfferToolTip
				arrow
				title={tagsToDisplay.map((tag) => (
					<Chip
						key={tag.name}
						label={tag.name}
						variant="outlined"
						size={tagSize || 'medium'}
						style={{
							maxWidth: '98%',
							margin: 2,
							backgroundColor: tag.badgeColor,
							borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
							color: tag.textColor,
							borderRadius: 4,
							fontSize: tagSize === 'small' ? 12 : 15,
						}}
					/>
				))}
			>
				<Line
					style={{
						columnGap: 2,
						justifyContent: 'center',
						display: 'flex',
						paddingLeft: tagsToDisplay.length > 1 ? 5 : 0,
					}}
				>
					<Chip
						key={tagsToDisplay[0].name}
						label={tagsToDisplay[0].name}
						variant="outlined"
						size={tagSize || 'medium'}
						style={{
							maxWidth: 80,
							margin: 2,
							backgroundColor: tagsToDisplay[0].badgeColor,
							borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
							color: tagsToDisplay[0].textColor,
							borderRadius: 4,
							fontSize: tagSize === 'small' ? 12 : 15,
						}}
					/>
					{tagsToDisplay.length > 1 ? (
						<Typography
							key={tagsToDisplay[0].name}
							style={{
								marginBottom: -3,
								fontSize: 10,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
							}}
						>{`+${tagsToDisplay.length - 1}`}</Typography>
					) : null}
				</Line>
			</OfferToolTip>
		) : (
			<div />
		);
	};

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const renderAutoComplete = () => (
		<div
			style={{
				display: 'flex',
				alignItems: 'flex-end',
				width: width || '100%',
			}}
		>
			<Autocomplete
				data-testid="autocomplete-tag"
				key={selectedClient?.id}
				openOnFocus
				size={size || 'small'}
				multiple
				disabled={disabled}
				limitTags={limitTags}
				autoComplete
				disableCloseOnSelect
				autoHighlight
				clearOnBlur
				clearOnEscape
				includeInputInList
				disableClearable
				loading={loading}
				loadingText={loadingText}
				forcePopupIcon={false}
				value={[...dataArray]}
				filterSelectedOptions
				id="searchTerms"
				options={suggestions || ['Please, provide array with suggestions']}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				getOptionLabel={(option) => option.name}
				freeSolo={false}
				style={{
					width: width || '100%',
				}}
				classes={{
					paper: classes.option,
				}}
				onChange={
					loading
						? undefined
						: (_, reason, newValue) => {
								handleChangeTags(reason, newValue);
							}
				}
				renderOption={(props, option) => (
					<li {...props}>
						<Chip
							key={Math.random().toString()}
							label={option?.name}
							variant="outlined"
							size={tagSize || 'medium'}
							style={{
								maxWidth: '98%',
								margin: -3,
								backgroundColor: option?.badgeColor,
								borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
								color: option?.textColor,
								borderRadius: 4,
								fontSize: tagSize === 'small' ? 12 : 15,
							}}
						/>
					</li>
				)}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => {
						return (
							<Chip
								{...getTagProps({ index })}
								key={Math.random().toString()}
								label={option?.name}
								variant="outlined"
								size={tagSize || 'medium'}
								style={{
									maxWidth: '98%',
									margin: 2,
									backgroundColor: option?.badgeColor,
									borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
									color: option?.textColor,
									borderRadius: 4,
									fontSize: tagSize === 'small' ? 12 : 15,
								}}
								deleteIcon={renderDeleteIcon()}
							/>
						);
					})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						onBlur={autoSave ? handleSaveTags : null}
						rows={rows}
						maxRows={rows}
						error={error}
						inputProps={{
							readOnly,
							...params.inputProps,
							maxLength: { handleMaxLength },
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
						}}
						InputLabelProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						placeholder={handlePlaceholder()}
						sx={{
							'& .MuiOutlinedInput-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							borderRadius: '4px',
							overflow: 'hidden',
						}}
					/>
				)}
			/>
		</div>
	);

	return displayOnly ? renderOnlyDisplayTags() : renderAutoComplete();
};
export default TagSelector;
