import React, { createContext, ReactNode, useContext, useState } from 'react';

interface CardFeedbackColtrolData {
	goodCardList: any;
	underInvestigationCardList: any;
	handleDisableOffer: (itemID) => void;
	handleMarkAsUnderInvestigation: (itemID) => void;
	handleMarkAsGoodCard: (itemID) => void;
	handleUnMarkAsGoodCard: (itemID) => void;
	notGoodCardList: any;
}

export const CardVisualFeedbackContext = createContext<CardFeedbackColtrolData>(
	{} as CardFeedbackColtrolData
);

interface Props {
	children: ReactNode;
}

export const CardVisualFeedback: React.FC<Props> = ({ children }: Props) => {
	const [goodCardList, setGoodCardList] = useState<any[]>([]);
	const [notGoodCardList, setNotGoodCardList] = useState<any[]>([]);
	const [underInvestigationCardList, setUnderInvestigationCardList] = useState<
		any[]
	>([]);

	const handleUnMarkAsGoodCard = (itemID) => {
		setGoodCardList((cardsList) => cardsList.filter((card) => card !== itemID));
	};

	const handleMarkAsUnderInvestigation = (itemID) => {
		setUnderInvestigationCardList((newMarked) => [...newMarked, itemID]);
	};

	const handleMarkAsGoodCard = (itemID) => {
		setGoodCardList((newMarked) => [...newMarked, itemID]);
	};

	const handleDisableOffer = (itemID) => {
		setNotGoodCardList((newDisabled) => [...newDisabled, itemID]);
	};

	return (
		<CardVisualFeedbackContext.Provider
			value={{
				goodCardList,
				handleDisableOffer,
				handleMarkAsGoodCard,
				handleUnMarkAsGoodCard,
				handleMarkAsUnderInvestigation,
				underInvestigationCardList,
				notGoodCardList,
			}}
		>
			{children}
		</CardVisualFeedbackContext.Provider>
	);
};

export function useCardVisualFeedback(): CardFeedbackColtrolData {
	return useContext(CardVisualFeedbackContext);
}
