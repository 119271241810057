import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const setTermsAccept = async () => {
	try {
		const response = await api.patch(`/users/terms/accept`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
