import React, { useState, memo } from 'react';
import { Collapse, Divider, TableCell } from '@mui/material';
import { usePO } from '../../../utils/POContext';
import { Line } from '../../../styles';
import OfferTable from '..';
import { useSelector } from 'react-redux';

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.index === nextProps.index &&
		prevProps.open === nextProps.open &&
		prevProps.rowData === nextProps.rowData &&
		prevProps.expandedTableData === nextProps.expandedTableData
	);
};

interface Props {
	rowData?: any;
	index: number;
	colSpan: number;
	expandedTableActions?: any;
	expandedTableData?: any[];
	noDataMessage?: any;
	expandedColsData: any[];
	expandedDataFetch?: (data) => any; //TODO Arturo retirar any
}

const ExpansibleTableRow: React.FC<Props> = ({
	rowData,
	index,
	colSpan,
	expandedTableActions,
	expandedTableData,
	noDataMessage,
	expandedColsData,
	expandedDataFetch,
}: Props) => {
	const { selectedTheme } = usePO();
	const openPanels = useSelector(
		(state: any) => state.expansibleTable.openPanels
	);
	const open = openPanels.includes(index);

	const [loading, setLoading] = useState(false);
	const [currentExpandedTableData, setCurrentExpandedTableData] = useState<
		any[]
	>([]);

	React.useEffect(() => {
		if (open && expandedDataFetch) {
			setLoading(true);
			expandedDataFetch(rowData).then((response) => {
				setCurrentExpandedTableData(response);
				setLoading(false);
			});
		}
	}, [open, expandedDataFetch, rowData]);

	return (
		<TableCell
			colSpan={colSpan}
			style={{ padding: 0, borderStyle: 'none' }}
			key={rowData?.id}
		>
			<Collapse in={open} timeout="auto">
				<Line
					style={{
						justifyContent: 'center',
						background: selectedTheme.overlay8dp || '#fcfcfc',
						width: '100%',
						padding: '5px 0px 10px 0px',
						boxShadow:
							'inset 0px 5px 6px -5px rgba(0,0,0,0.4), inset 0px -5px 6px -5px rgba(0,0,0,0.2)',
					}}
				>
					<div>
						<OfferTable
							denseText
							tableActions={expandedTableActions}
							customNoDataMessage={noDataMessage}
							nestedTable
							tableData={
								expandedDataFetch ? currentExpandedTableData : expandedTableData
							}
							colsData={expandedColsData}
							loading={loading}
						/>
						<Divider
							style={{
								background: selectedTheme.overlay8dp || '#fcfcfc',
								opacity: 0.5,
							}}
						/>
						<Divider
							style={{
								background: selectedTheme.overlay8dp || '#fcfcfc',
								opacity: 0.5,
							}}
						/>
					</div>
				</Line>
			</Collapse>
		</TableCell>
	);
};
export default memo(ExpansibleTableRow, areEqual);
