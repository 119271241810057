import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Backup, InsertDriveFileOutlined, Close } from '@mui/icons-material/';
import {
	IconButton,
	Zoom,
	Card,
	Typography,
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	Divider,
} from '@mui/material';
import { usePO } from '../../utils/POContext';
import { useSnackbar } from 'notistack';
import { OfferToolTip } from '../../helpers';
import { Column, Line } from '../../styles';
import LoadingDots from '../LoadingDots';
import { makeStyles } from '@mui/styles';
import { DropContainer } from './styles';
import FullSizeImageDialog from '../FullSizeImageModal';
import filesize from 'filesize';

interface Props {
	setUploadedFiles: (file: any) => void;
	preloadUrlFiles?: string[];
	customMessage?: string;
	useTextField?: boolean;
	maxFiles?: number;
	handleRemoveFileFromServer?: (file: any) => void;
}

const MultiFileCard: React.FC<Props> = ({
	setUploadedFiles,
	preloadUrlFiles,
	customMessage,
	useTextField,
	maxFiles,
	handleRemoveFileFromServer,
}) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const { enqueueSnackbar } = useSnackbar();

	const useStyles = makeStyles(() => ({
		root: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 5,
			width: '100%',
			background:
				selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : 'white',
		},
		textField: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: selectedTheme.border,
			borderRadius: 5,
			width: '100%',
			background:
				selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : 'transparent',
		},
	}));

	const classes = useStyles();

	const [droppedFiles, setDroppedFiles] = useState<any[]>([]);
	const [imageToDisplay, setImageToDisplay] = useState<any[]>([]);
	const [showImageModal, setShowImageModal] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState<any>(null);

	const processUrltoFile = async (urls: string[]) => {
		const newUploadedFiles: any = urls.map((url) => ({
			name: url.split('/').pop(),
			readableSize: '',
			preview: url,
			progress: 0,
			uploaded: false,
			error: false,
			url: url,
		}));
		setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
		setDroppedFiles((prev) => [...prev, ...newUploadedFiles]);
	};

	useEffect(() => {
		if (preloadUrlFiles) {
			processUrltoFile(preloadUrlFiles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preloadUrlFiles]);

	const onDrop = async (acceptedFiles) => {
		if (maxFiles && droppedFiles.length + acceptedFiles.length > maxFiles) {
			enqueueSnackbar(t(`FileCard.Não é possível enviar mais arquivos`), {
				variant: 'warning',
			});
			return;
		}
		setLoading(true);
		const newFiles = acceptedFiles.filter(
			(file) =>
				!droppedFiles.some((existingFile) => existingFile.name === file.name)
		);
		if (newFiles.length < acceptedFiles.length) {
			enqueueSnackbar(t('FileCard.Arquivo já existe na lista'), {
				variant: 'warning',
			});
		}
		const newUploadedFiles = newFiles.map((file) => ({
			file,
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: '',
		}));
		try {
			setDroppedFiles((prev) => [...prev, ...newUploadedFiles]);
			setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
			setLoading(false);
			if (newFiles.length > 0) {
				enqueueSnackbar(
					t('FileCard.Arquivo inserido e renomeado com sucesso'),
					{
						variant: 'success',
					}
				);
			}
		} catch (error) {
			enqueueSnackbar(t('FileCard.Erro ao inserir arquivo'), {
				variant: 'error',
			});
		}
	};

	const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
		useDropzone({
			multiple: true,
			accept: [
				'image/png',
				'image/jpeg',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/pdf',
			],
			onDrop,
			noClick: true,
		});

	const promptRemoveFile = (file) => {
		setSelectedFile(file);
		setIsDialogOpen(true);
	};

	const handleRemoveFile = async () => {
		setIsDialogOpen(false);

		if (selectedFile) {
			let deletedFilesResponse;
			if (selectedFile.url && handleRemoveFileFromServer) {
				deletedFilesResponse = await handleRemoveFileFromServer(selectedFile);
			}
			if (deletedFilesResponse === true) {
				const filteredFiles = droppedFiles.filter(
					(el) => el.name !== selectedFile.name
				);
				setDroppedFiles(filteredFiles);
			}
			if (selectedFile.preview && !selectedFile.url) {
				const filteredFiles = droppedFiles.filter(
					(el) => el.name !== selectedFile.name
				);
				setDroppedFiles(filteredFiles);
			}
			setSelectedFile(null);
		}
	};

	const handleShowFile = (file) => {
		const tempPath = file.url || file.preview;
		const fileType = file.preview && file?.file?.type;
		const isImageFile = fileType?.startsWith('image/');
		const isImageUrl = tempPath && /\.(jpg|jpeg|png|gif|bmp)$/i.test(tempPath);

		if (isImageUrl || isImageFile) {
			const imageSource = tempPath;
			setImageToDisplay(imageSource);
			setShowImageModal(true);
			if (isImageFile) {
				setTimeout(() => URL.revokeObjectURL(imageSource), 1000);
			}
		} else if (tempPath) {
			window.open(tempPath, '_blank');
		} else if (file instanceof File) {
			const tempUrl = URL.createObjectURL(file);
			const a = document.createElement('a');
			a.href = tempUrl;
			a.download = file.name || 'download';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			setTimeout(() => URL.revokeObjectURL(tempUrl), 100);
		}
	};

	const renderDragMessage = () => {
		if (!isDragActive) {
			return (
				<>
					{loading ? (
						<Line style={{ justifyContent: 'center', padding: 20 }}>
							<LoadingDots width={110} height={60} key="loading" loop />
						</Line>
					) : (
						renderCard()
					)}
				</>
			);
		}
		if (isDragReject) {
			enqueueSnackbar(
				t('FileCard.Não é possível enviar este formato de arquivo'),
				{ variant: 'error' }
			);
		}
		return renderCard();
	};

	const renderCard = () => (
		<DropContainer {...getRootProps()} style={{ width: '100%' }}>
			<Line
				style={{
					justifyContent: 'flex-start',
					width: '100%',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : '',
				}}
			>
				<input {...getInputProps()} disabled={loading} />
				<Line
					style={{
						width: 60,
						height: droppedFiles.length > 0 ? 80 : 40,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<OfferToolTip
						title={`${t('FileCard.Adicionar Documentos')}`}
						aria-label="tooltip"
						arrow
						TransitionComponent={Zoom}
					>
						<IconButton
							onClick={open}
							style={{ height: 40, borderRadius: 10 }}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<Backup
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.primary,
								}}
							/>
						</IconButton>
					</OfferToolTip>
					<Divider
						light
						style={{ height: 28, marginLeft: -1 }}
						orientation="vertical"
					/>
				</Line>
				<Line style={{ padding: 8 }}>
					<Line>
						{droppedFiles.map((el) => (
							<Card
								key={el.name}
								style={{
									marginInline: 6,
									backgroundColor: 'transparent',
									position: 'relative',
									background:
										selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
								}}
							>
								<IconButton
									size="small"
									style={{ borderRadius: 26, width: 55 }}
									onClick={() => handleShowFile(el)}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<OfferToolTip title={el.name} arrow>
										<div style={{ marginBottom: -5 }}>
											<InsertDriveFileOutlined
												style={{
													color: '#494949',
													fontSize: 55,
												}}
											/>
											<Typography
												style={{
													fontSize: 10,
													fontWeight: 'bold',
													position: 'absolute',
													top: 33,
													left: 15,
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: '',
												}}
											>
												{el.name.split('.').pop()}
											</Typography>
										</div>
									</OfferToolTip>
								</IconButton>
								<IconButton
									style={{
										position: 'absolute',
										top: 3,
										right: 3,
										padding: 0,
										borderRadius: 5,
										width: 10,
										height: 10,
										backgroundColor: selectedTheme.error,
										zIndex: 500,
									}}
									onClick={() => promptRemoveFile(el)}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<Close
										style={{
											color: selectedTheme.foreground,
											fontSize: 10,
										}}
									/>
								</IconButton>
							</Card>
						))}
						{customMessage ||
							(droppedFiles.length === 0 && (
								<Typography
									variant="subtitle2"
									noWrap
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
										marginBottom: -2,
									}}
								>
									{t('FileCard.Arraste arquivos aqui ou use o botão de upload')}
								</Typography>
							))}
					</Line>
					{maxFiles && (
						<Typography
							variant="caption"
							style={{
								position: 'relative',
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
								marginBottom: droppedFiles.length > 0 ? 0 : -2,
							}}
						>{`${droppedFiles.length}/${maxFiles}`}</Typography>
					)}
				</Line>
			</Line>
		</DropContainer>
	);

	const renderDeleteFileDialog = () => (
		<Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
			<DialogContent
				sx={{
					padding: '20px 40px 10px 40px',
					background: selectedTheme.id === 'dark' && selectedTheme?.overlay6dp,
				}}
			>
				<Typography
					sx={{
						color:
							selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					}}
				>
					{t('FileCard.Remover o arquivo')}:{` ${selectedFile?.name}`}?
				</Typography>
			</DialogContent>

			<DialogActions
				sx={{
					background: selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
				}}
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Button
						size="small"
						sx={{
							color: selectedTheme.error,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
						onClick={() => setIsDialogOpen(false)}
					>
						{t('FileCard.Cancelar')}
					</Button>
					<Button
						size="small"
						onClick={handleRemoveFile}
						autoFocus
						sx={{
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,

							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						{t('Ok')}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{useTextField ? (
				<Column className={classes.textField}>{renderDragMessage()}</Column>
			) : (
				<Card className={classes.root}>{renderDragMessage()}</Card>
			)}
			<FullSizeImageDialog
				images={imageToDisplay}
				open={showImageModal}
				setOpen={setShowImageModal}
			/>
			{renderDeleteFileDialog()}
		</>
	);
};

export default MultiFileCard;
