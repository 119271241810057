import { combineReducers } from 'redux';
import menuReducer from './slices/menuSlice';
import pageReducer from './slices/rowPerPageSlice';
import cardFeedbackReducer from './slices/cardFeedbackSlice';
import panelControlReducer from './slices/panelControl';
import vertMenuReducer from './slices/vertMenuSlice';
import filterReducer from './slices/filterSlice';
import expansibleTableSlice from './slices/expansibleTableSlice';

const rootReducer = combineReducers({
  menu: menuReducer,
  rowPerPage: pageReducer,
  cardFeedback: cardFeedbackReducer,
  panelControl: panelControlReducer,
  vertMenu: vertMenuReducer,
  filters: filterReducer,
  expansibleTable: expansibleTableSlice
});

export default rootReducer;
