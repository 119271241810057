import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Zoom } from '@mui/material';
import { Cached, LocalOffer, ThumbsUpDown } from '@mui/icons-material/';
import { usePO } from '../../utils/POContext';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../react-auth0-spa';
import { api, setupRequestToken } from '../../utils/api';
import { useDispatch } from 'react-redux';
import Unauthorized from '../Page401';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import { OfferToolTip } from '../../helpers';
import { Line } from '../../styles';
import SelectClient from '../../components/SelectClient';
import OfferTable from '../../components/OfferTable';
import RuleModal from './RuleModal';
import { useSnackbar } from 'notistack';
import { fetchRules } from '../../services/rules';
import { apiResponse } from '../../interfaces';
import RuleToggleDialog from './RuleToggleDialog';
import RulePopover from './RulePopover';
import TagSelector from '../../components/TagSelector';
import ClassificationSelector from '../../components/ClassificationSelector';

const Rules: React.FC = () => {
	const { selectedClient, updateCursorMode, userData, selectedTheme } = usePO();
	const { t } = useTranslation();
	const { token } = useAuth0();
	setupRequestToken(api, token);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const switchesToolTip = {
		true: t('Regras.Regra ativada'),
		false: t('Regras.Regra desativada'),
	};

	const colsData = [
		{
			columnTitle: t('Regras.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 200,
		},
		{
			columnTitle: t('Regras.Regras'),
			fieldName: 'policy',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 200,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Regras.Procedimento'),
			fieldName: 'actions',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 200,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Regras.Propriedades'),
			fieldName: 'actionsText',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 200,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Regras.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 90,
			dataType: 'date',
		},
	];

	const [tableData, setTableData] = useState<any[]>([]);
	const [openToggleDialog, setOpenToggleDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [receivedDataSwitch, setReceivedDataSwitch] = useState<any>();

	useEffect(() => {
		dispatch(changeActiveMenu('regras'));
		if (selectedClient !== undefined) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	const buildTableData = async (data: any) => {
		if (data) {
			setTableData([]);
			const tempTableData: any = [];
			await data.map((el: any) => {
				const formatedRule = {
					name: el.name || '',
					description: el.description || '',
					id: el.id,
					actions: processActions(el.actions),
					actionsText: processActionsText(el.actions),
					policy: processRule(el.policy),
					actionsData: el.actions,
					policyData: el.policy,
					created: el.created,
					ISactive: el.ISactive,
				};

				tempTableData.push(formatedRule);
				return null;
			});
			setTableData(tempTableData);
		}
	};

	const fetchData = async () => {
		setLoading(true);
		updateCursorMode('wait');
		const response: apiResponse | undefined = await fetchRules(
			selectedClient.id
		);
		if (response && response.status !== undefined) {
			if (response.status < 300) {
				buildTableData(response.data);
			} else if (response.status >= 400) {
				const message = response.message;
				enqueueSnackbar(t(`${message}`), { variant: 'error' });
				buildTableData([]);
			}
		}
		setLoading(false);
		updateCursorMode('default');
	};

	const handleOpenToggleRuleDialog = () => {
		setOpenToggleDialog(true);
	};

	const processRule = (data: object[]) => {
		if (!data) {
			return null;
		}
		return <RulePopover rowData={data} />;
	};

	const handleIconColor = (boolean) => {
		if (boolean) {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.primary;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorDisable
				: selectedTheme.disabled;
		}
	};

	const processActions = (actions: any) => {
		return (
			<Line style={{ columnGap: 15, justifyContent: 'center' }}>
				<OfferToolTip
					title={
						actions.some((obj) => obj.action === 'etiquetar')
							? t('Regras.Etiquetar')
							: t('Regras.Não etiquetar')
					}
				>
					<LocalOffer
						style={{
							fontSize: 18,
							color: handleIconColor(
								actions.some((obj) => obj.action === 'etiquetar')
							),
						}}
					/>
				</OfferToolTip>
				<OfferToolTip
					title={
						actions.some((obj) => obj.action === 'classificar')
							? t('Regras.Classificar')
							: t('Regras.Não classificar')
					}
				>
					<ThumbsUpDown
						style={{
							fontSize: 18,
							color: handleIconColor(
								actions.some((obj) => obj.action === 'classificar')
							),
						}}
					/>
				</OfferToolTip>
			</Line>
		);
	};

	const processActionsText = (actions: any) => {
		let tempTags: any = [];
		let tempClassification: any = [];

		actions.forEach((el: any) => {
			if (el.action === 'etiquetar') {
				tempTags = el.properties;
			}
			if (el.action === 'classificar') {
				tempClassification = el.properties;
			}
		});

		if (tempTags.length === 0 && tempClassification.length === 0) {
			return '-';
		}

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{tempTags.length > 0 && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Typography
							style={{ paddingRight: '5px' }}
							variant="subtitle1"
						>{`${t('Regras.Etiquetar')}:`}</Typography>
						<TagSelector displayOnly dataArray={tempTags} tagSize="small" />
					</div>
				)}
				{tempClassification.length > 0 && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ClassificationSelector dataArray={tempClassification} />
					</div>
				)}
			</div>
		);
	};

	const action1Props = {
		refresh: fetchData,
		isEditing: true,
	};
	const CustomHeaderComponentProps = { refresh: fetchData };

	const rightFooterComponent = () => (
		<Line style={{ justifyContent: 'flex-end' }}>
			<Typography
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
				}}
			>
				{t('Regras.Cliente')}:
			</Typography>
			<div style={{ width: 200, marginBottom: -5 }}>
				<SelectClient />
			</div>
		</Line>
	);

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Regras.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={fetchData}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': {
										transform: 'rotate(360deg)',
									},
									'100%': {
										transform: 'rotate(0deg)',
									},
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
						fontSize: '22px',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return userData.permissions?.indexOf('read:users:roles') !== -1 ? (
		<>
			<OfferTable
				colsData={colsData}
				tableData={tableData}
				windowHeader
				windowTitle={t('Regras.Gerenciamento de Regras')}
				tableActions
				tableFooter
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient
						? loading
							? t('Carregando...')
							: t('Regras.Sem regras para este cliente')
						: t('Selecione um cliente')
				}
				customLeftFooterComponent={renderReloadTableData()}
				customRightFooterComponent={rightFooterComponent()}
				CustomHeaderComponent={RuleModal}
				CustomHeaderComponentProps={CustomHeaderComponentProps}
				ActionComponent1={RuleModal}
				ActionComponent1Props={action1Props}
				switches
				switchesToolTip={switchesToolTip}
				dataFromSwitch={setReceivedDataSwitch}
				onSwitchChange={handleOpenToggleRuleDialog}
			/>
			<RuleToggleDialog
				open={openToggleDialog}
				setOpen={setOpenToggleDialog}
				refresh={fetchData}
				data={receivedDataSwitch}
			/>
		</>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default Rules;
