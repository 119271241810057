import React, { useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	Zoom,
	Autocomplete,
	TextField,
	Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import { makeStyles } from '@mui/styles';

interface Props {
	searchData?: any;
	disableSeachType?: boolean;
	periodicity: any;
	setPeriodicity: (data) => any;
	periodicityOptions: any;
	searchType: any;
	setSearchType: (data) => any;
	searchTypeOptions: any;
	setExistentTag?: any;
	existentTag?: any;
	displayExistentTag?: any;
	setDescription?: (data: string) => void;
	description?: string;
	formErrors?: any;
}

const RightColumn: React.FC<Props> = ({
	periodicity,
	setPeriodicity,
	periodicityOptions,
	searchType,
	disableSeachType,
	setSearchType,
	searchTypeOptions,
	setExistentTag,
	existentTag,
	displayExistentTag,
	setDescription,
	description,
	formErrors,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, searchTagsActiveClient, selectedTheme } = usePO();

	const [currentPeriodicity, setCurrentPeriodicity] = useState(periodicity);
	const [currentSearchType, setCurrentSearchType] = useState(searchType);

	const useStyles = makeStyles(() => ({
		periodicityStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
		menuItem: {
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.background,
			},
		},
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		textFieldStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
		},
		dialogPaper: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
		},
		renderDenounceStyles: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			borderBottom: `1px solid ${
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.border
			}`,
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
				borderBottom: `1px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.border
				}`,
			},
		},
		renderDenounceReason: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
		},
	}));

	const classes = useStyles();

	useEffect(() => {
		if (displayExistentTag) {
			setExistentTag(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	const handlePeriodicityChange = (event) => {
		periodicityOptions.map((el) => {
			if (el.value === event.target.value) {
				setCurrentPeriodicity(el);
				setPeriodicity(el);
			}
			return null;
		});
	};

	const handleSeachTypeChange = (event) => {
		searchTypeOptions.map((el) => {
			if (el.value === event.target.value) {
				setCurrentSearchType(el);
				setSearchType(el);
			}
			return null;
		});
	};

	const renderExistentTag = () => {
		if (displayExistentTag) {
			return (
				<Column
					style={{
						paddingLeft: 10,
						alignItems: 'start',
						rowGap: 10,
					}}
				>
					<Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
						<OfferToolTip
							placement="right"
							title={`${t(
								'Buscas.Tabs.Nova Busca.Chave de busca já existente'
							)}`}
							aria-label="Chave de busca já existente"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<Typography noWrap style={{ marginBottom: 2 }}>
								{t('Buscas.Tabs.Nova Busca.Chave de busca já existente')}:
							</Typography>
						</OfferToolTip>
					</Box>
					<Autocomplete
						id="tag-select"
						size="small"
						style={{ width: '100%' }}
						value={existentTag}
						options={searchTagsActiveClient}
						onChange={(_, newValue) => {
							setExistentTag(newValue);
						}}
						autoComplete
						autoSelect
						getOptionLabel={(option) => option.id}
						renderOption={(props, option) => (
							<li {...props}>{option.id as string}</li>
						)}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								name="selectedExistentTag"
								variant="outlined"
								error={formErrors.selectedExistentTag}
								helperText={formErrors.selectedExistentTag}
								inputProps={{
									...params.inputProps,
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
								sx={{
									'.MuiFormHelperText-root.Mui-error': {
										mt: '0px',
										color: selectedTheme.error,
										borderColor: selectedTheme.error,
										position: 'absolute',
										bottom: '-21px',
									},
									'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
										{
											borderColor: selectedTheme.error,
										},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
					/>
				</Column>
			);
		}
		return null;
	};

	return (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				justifyContent: 'space-between',
				paddingLeft: '30px',
				rowGap: 10,
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			}}
		>
			<Line
				style={{
					width: '100%',
				}}
			>
				<Column
					style={{
						paddingRight: displayExistentTag ? 10 : 0,
						alignItems: 'start',
						rowGap: 10,
						width: displayExistentTag ? '50%' : '100%',
					}}
				>
					<Typography style={{ marginBottom: 2 }}>
						{t('Buscas.Tabs.Nova Busca.Periodicidade')}:
					</Typography>
					<Select
						size="small"
						name="periodicity"
						aria-label="periodicity"
						key={currentPeriodicity.value}
						value={currentPeriodicity.value}
						onChange={handlePeriodicityChange}
						variant={'outlined'}
						classes={{
							select: classes.periodicityStyle,
							root: classes.menuItem,
						}}
						MenuProps={{
							MenuListProps: {
								disablePadding: true,
							},
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						style={{
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
						}}
						sx={{
							width: '100%',
							height: '40px',
							'.MuiOutlinedInput-notchedOutline': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
							'.MuiSelect-select': {
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
							'.MuiSelect-icon': {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
							'& [aria-expanded=true]': {
								background:
									selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
							},
						}}
						inputProps={{
							MenuProps: {
								MenuListProps: {
									disablePadding: true,
								},
								PaperProps: {
									sx: {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay3dp
												: selectedTheme.foreground,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									},
								},
							},
						}}
					>
						{periodicityOptions.map((el) => (
							<MenuItem
								key={el.name}
								value={el.value}
								sx={{
									background:
										selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
									},
									'&.Mui-selected': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
										},
									},
								}}
							>
								{el.name}
							</MenuItem>
						))}
					</Select>
				</Column>
				{renderExistentTag()}
			</Line>
			<Line
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					marginTop: 12,
				}}
			>
				<Column
					style={{
						paddingRight: 10,
						alignItems: 'start',
						rowGap: 10,
						width: '50%',
					}}
				>
					<Typography style={{ marginBottom: 0.5 }}>
						{t('Buscas.Tabs.Nova Busca.Tipo de Busca')}:
					</Typography>
					<Select
						size="small"
						name="searchType"
						variant="outlined"
						aria-label="searchType"
						key={currentSearchType.value}
						defaultValue={currentSearchType.value}
						onChange={handleSeachTypeChange}
						disabled={disableSeachType}
						classes={{
							select: classes.periodicityStyle,
							root: classes.menuItem,
						}}
						MenuProps={{
							MenuListProps: {
								disablePadding: true,
							},
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						style={{
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
						}}
						sx={{
							width: '100%',
							height: '40px',
							'& .MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor:
									selectedTheme.id === 'dark' && selectedTheme.textColorDisable,
							},
							'&.Mui-disabled': {
								'.MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
								},
								'.MuiSelect-icon': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorDisable,
								},
							},
							'.MuiOutlinedInput-notchedOutline': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
							'.MuiSelect-select': {
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							},
							'.MuiSelect-icon': {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
							'& [aria-expanded=true]': {
								background:
									selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
							},
						}}
						inputProps={{
							MenuProps: {
								MenuListProps: {
									disablePadding: true,
								},
								PaperProps: {
									sx: {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay3dp
												: selectedTheme.foreground,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									},
								},
							},
						}}
					>
						{searchTypeOptions.map((el) => (
							<MenuItem
								classes={{
									selected: classes.menuItem,
									root: classes.menuItem,
								}}
								key={el.name}
								value={el.value}
							>
								{el.name}
							</MenuItem>
						))}
					</Select>
				</Column>
				<Column
					style={{
						paddingLeft: 10,
						alignItems: 'start',
						rowGap: 10.5,
						width: '50%',
					}}
				>
					<Typography>{t('Buscas.Tabs.Nova Busca.Cliente')}:</Typography>
					<TextField
						size="small"
						variant="outlined"
						name="selectedClient"
						value={selectedClient?.name}
						sx={{
							fontWeight: 'bold',
							width: '100%',
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
						InputProps={{
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						classes={{
							root: classes.textFieldStyle,
						}}
					/>
				</Column>
			</Line>
			<Typography style={{ marginTop: 12, marginBottom: -1 }}>
				{t('Buscas.Tabs.Nova Busca.Descrição')}:
			</Typography>
			<TextField
				variant="outlined"
				size="small"
				aria-label="description"
				name="description"
				value={description}
				onChange={(event) => setDescription?.(event.target.value)}
				autoComplete="off"
				multiline
				rows={3}
				InputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
				}}
				sx={{
					width: '100%',
					'& .MuiInputBase-root': {
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
			/>
		</Column>
	);
};
export default RightColumn;
