import React from 'react';
import { IconButton, Zoom } from '@mui/material';
import { Link } from 'react-router-dom';
import { Dns } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { useViewSearch } from '../../../hooks/ViewSearch';
import { OfferToolTip } from '../../../helpers';
import { usePanelControls } from '../../../hooks/PanelControls';
import { usePO } from '../../../utils/POContext';

interface Props {
	rowData: any;
}

const OpenCurrentDenouncements: React.FC<Props> = ({ rowData }: Props) => {
	const { t } = useTranslation();
	const { setSaveDenouncementID } = useViewSearch();
	const { setFilterBarStates } = usePanelControls();
	const { selectedTheme } = usePO();

	const handleOpenSavedSearch = () => {
		setSaveDenouncementID(rowData.id);
		setFilterBarStates(true);
	};

	const handleDisableButton = (data) => {
		switch (data) {
			case 'ENCAMINHADA':
				return false;
			case 'DENUNCIADA':
				return false;
			case 'EM PROCESSAMENTO':
				return false;
			default:
				return true;
		}
	};

	return (
		<OfferToolTip
			title={`${t('Denúncias.Visualizar resultados desta denúncia')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				component={Link}
				to="/classificacoes"
				onClick={handleOpenSavedSearch}
				disabled={handleDisableButton(rowData.denounceStatusID)}
				style={{ marginRight: -4 }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Dns
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);
};

export default OpenCurrentDenouncements;
