import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const sendNotificationsData = async (
	chunkedData: any,
	selectedClientID: string,
	denounceStatusID: string
) => {
	const sentItemsReport: any = { goodItems: [], notGoodItems: [] };
	let successMessage: string = '';
	try {
		await Promise.all(
			chunkedData.map(async (chunk) => {
				const payload = {
					offerClassificationID: chunk.map((el) => el.id),
					denounceStatusID: denounceStatusID,
					clientID: selectedClientID,
				};

				const response = await api.patch('/offerClassification', payload, {
					timeout: 6000000,
				});
				successMessage = response.data.detail;
				response.data.map((item) => {
					if (item.status < 300) {
						sentItemsReport.goodItems.push(item);
					}
					if (item.status >= 400) {
						sentItemsReport.notGoodItems.push(item);
					}
					return null;
				});
			})
		);

		return {
			success: true,
			data: sentItemsReport,
			message: successMessage || 'Lista com o status de cada oferta atualizada',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
