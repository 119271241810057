import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { api, setupRequestToken } from '../utils/api';
import { useAuth0 } from '../react-auth0-spa';
import { usePO } from '../utils/POContext';
import SnackbarMessage from '../components/SnackbarMessage';
import { useTranslation } from 'react-i18next';

interface OfferPlatformData {
	platforms: any;
	platformINcountry: any;
	updateSelectedClient: (client: string) => void;
	children: ReactNode;
}

const OfferPlatformDataContext = createContext<OfferPlatformData>(
	{} as OfferPlatformData
);
interface Props {
	children: ReactNode;
}
export const PlatformData: React.FC<Props> = ({ children }: Props) => {
	const { t } = useTranslation();
	const { token } = useAuth0();
	const { userData, setSelectedClient, userActiveClients, selectedClient } =
		usePO();

	setupRequestToken(api, token);

	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState('');
	const [messageType, setMessageType] = useState('');
	const [platforms, setPlatforms] = useState<any[]>();
	const [platformINcountry, setPlatformINcountry] = useState<any[]>();
	const [lastClient, setLastClient] = useState<ClientTypes>();

	useEffect(() => {
		const getData = async () => {
			try {
				const platformsResponse = await api.get(`/platforms`);
				setPlatforms(platformsResponse.data);
				const platformINcountryResponse = await api.get(`/platformINcountry`);
				setPlatformINcountry(platformINcountryResponse.data);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Erro ao recuperar dados de plataformas');
			}
		};
		getData();
		getSelectedClient();
		// eslint-disable-next-line
	}, []);

	const getSelectedClient = () => {
		if (userData.selectedClient) {
			setSelectedClient(
				userActiveClients.find(
					(client: any) => client.id === userData.preferences.selectedClient?.id
				)
			);
		}
		return null;
	};

	const handleUpdateClient = async (newSelectedClient: any) => {
		const payload = {
			selectedClient: newSelectedClient.id,
		};
		setLastClient(selectedClient);
		try {
			await api.patch(`/users/${userData.id}/preferences`, payload);
			setSelectedClient(newSelectedClient);
		} catch {
			setMessageType('error');
			setMessage(t('Erro ao selecionar cliente. Retornando ao anterior'));
			setOpenMessage(true);
			setSelectedClient(lastClient);
		}
	};

	const updateSelectedClient = (client: any) => {
		handleUpdateClient(client);
	};

	return (
		<>
			<OfferPlatformDataContext.Provider
				value={{
					platforms,
					platformINcountry,
					updateSelectedClient,
					children,
				}}
			>
				{children}
			</OfferPlatformDataContext.Provider>
			<SnackbarMessage
				open={openMessage}
				setOpen={setOpenMessage}
				message={message}
				type={messageType}
			/>
		</>
	);
};

export function useOfferPlatformData(): OfferPlatformData {
	return useContext(OfferPlatformDataContext);
}
