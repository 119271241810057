import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	Checkbox,
	Divider,
	IconButton,
	Link,
	Typography,
	Zoom,
} from '@mui/material';
import {
	ReportProblem,
	ShareRounded,
	SwapHorizRounded,
	VerifiedUser,
} from '@mui/icons-material/';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';

import AuthorBindModal from '../../../../components/AuthorBindModal';
import PlatformIcon from '../../../../components/PlatformIcon';
import { useCardSelector } from '../../../../hooks/Classificacoes/CardSelector';
import CardDenounceConfirmationDialog from '../CardActionsConfirmationDialog';

import { useSelector, useDispatch } from 'react-redux';
import LeftVerticalLine from '../LeftVerticalLine';
import {
	updateGoodCards,
	updateUnderInvestigationCard,
} from '../../../../redux/slices/cardFeedbackSlice';
import FullSizeImageDialog from '../../../../components/FullSizeImageModal';
import AuthorDetailsModal from '../../AuthorModal';
import { useSnackbar } from 'notistack';
interface Props {
	author: any;
	handleFlip: () => void;
	className?: any;
}

const CardFront: React.FC<Props> = ({ author, handleFlip, className }) => {
	const { selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedCards, handleSelectCard } = useCardSelector();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const imageIndex = 0;

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);
	const underInvestigationCardList = useSelector(
		(state: any) => state.cardFeedback.underInvestigationCardList
	);

	const [openImageModal, setOpenImageModal] = useState(false);
	const [imageError, setImageError] = useState(false);
	const [openAuthorDetailsModal, setOpenAuthorDetailsModal] = useState(false);

	useEffect(() => {
		if (author?.authorData.official?.value) {
			dispatch(updateGoodCards([...goodCardsList, author.authorID]));
		}
		if (author?.authorData.under_investigation?.value) {
			dispatch(
				updateUnderInvestigationCard([
					...underInvestigationCardList,
					author.authorID,
				])
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checksDataInRedux = (list, data) => {
		return list?.includes(data);
	};

	const checkCardVerticalLineColor = () => {
		if (checksDataInRedux(goodCardsList, author.authorID)) {
			return selectedTheme.success;
		} else if (checksDataInRedux(underInvestigationCardList, author.authorID)) {
			return selectedTheme.warning;
		}
	};

	const handleSelect = (event) => {
		handleSelectCard(author.authorID, event.target.checked ? 'add' : 'remove');
	};

	const handleCopyLinkToURL = () => {
		const { protocol, host } = window.location;
		const url = `${protocol}//${host}/authorID/${author.authorID}`;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				enqueueSnackbar(
					t('Classificações.Link copiado para a área de transferência'),
					{ variant: 'success' }
				);
			})
			.catch((err) => {
				console.error('Error copying to clipboard', err);
			});
	};

	const renderCardHeader = () => (
		<Line
			style={{
				alignItems: 'center',
				justifyContent: 'space-between',
				position: 'absolute',
				top: 0,
				padding: '5px 2px 0 5px',
			}}
		>
			<IconButton
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
				data-testid="flip-button"
				size="small"
				onClick={handleFlip}
			>
				<SwapHorizRounded
					sx={{
						zIndex: 10,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
			<OfferToolTip
				title={
					<Typography variant="subtitle2" align="center">{`${t(
						'Autores.Abrir perfil do autor na plataforma de origem'
					)} (${author.platformINcountryID.replace('|', ' - ')})`}</Typography>
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Link href={author.permalink} target="blank">
					<PlatformIcon
						data={author.platformINcountryID}
						dataType="platform"
						size={20}
						cursor="pointer"
					/>
				</Link>
			</OfferToolTip>
			<AuthorBindModal
				authorData={author}
				display="menu"
				setBoundTo={() => {}}
			/>
		</Line>
	);

	const renderImageError = (e, author: string) => {
		setImageError(true);
		e.target.src = `https://robohash.org/${author}`;
	};

	const renderCardAvatar = () => {
		return (
			<Column
				style={{
					justifyContent: 'center',
					height: 200,
					zIndex: 1,
					cursor: 'pointer',
					padding: '20px 0',
				}}
			>
				{author.authorData.images !== undefined ? (
					<img
						alt="Foto do autor"
						src={author.authorData.images.value}
						style={{
							width: 160,
							height: 160,
							borderRadius: '50%',
							objectFit: 'cover',
						}}
						onClick={() => {
							if (!imageError) {
								setOpenImageModal(true);
							}
						}}
						onError={(e) => renderImageError(e, author.authorID)}
					/>
				) : (
					<img
						alt="Foto do autor"
						src={`https://robohash.org/${author.authorName || author.authorData.nickname?.value}`}
						style={{
							width: 160,
							height: 160,
							borderRadius: '50%',
							objectFit: 'cover',
						}}
					/>
				)}
			</Column>
		);
	};

	const renderStatusBadge = () => {
		if (checksDataInRedux(underInvestigationCardList, author.authorID)) {
			return (
				<OfferToolTip
					aria-label="inativo"
					arrow
					enterDelay={700}
					enterNextDelay={700}
					title={`${t('Classificações.Loja oficial')}`}
					TransitionComponent={Zoom}
				>
					<ReportProblem
						style={{
							marginRight: -10,
							marginTop: 3,
							fontSize: 16,
							color: selectedTheme.warning,
						}}
					/>
				</OfferToolTip>
			);
		}
		if (checksDataInRedux(goodCardsList, author.authorID)) {
			return (
				<OfferToolTip
					aria-label="inativo"
					arrow
					enterDelay={700}
					enterNextDelay={700}
					title={`${t('Classificações.Loja oficial')}`}
					TransitionComponent={Zoom}
				>
					<VerifiedUser
						style={{
							marginRight: -10,
							marginTop: 3,
							fontSize: 16,
							color: selectedTheme.success,
						}}
					/>
				</OfferToolTip>
			);
		} else {
			return null;
		}
	};

	const renderCardDetails = () => (
		<Column
			style={{
				justifyContent: 'space-between',
				padding: '5px 15px',
				height: 80,
				gap: 4,
			}}
		>
			<OfferToolTip
				title={`${t('Autores.Abrir página do autor na plataforma')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Box
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
					width="200px"
					style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
				>
					<Typography
						align="center"
						noWrap
						onClick={() => setOpenAuthorDetailsModal(true)}
						style={{
							fontWeight: 'bold',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							cursor: 'pointer',
						}}
					>
						{author.authorName || author?.authorData.nickname?.value}
					</Typography>
					{renderStatusBadge()}
				</Box>
			</OfferToolTip>

			<IconButton
				size="small"
				sx={{
					fontSize: '16px',
					transform: 'rotate(45deg)',
					padding: '5px',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
				href={author.permalink}
				target="_blank"
			>
				<LinkIcon
					sx={{
						fontSize: '15px',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: selectedTheme.primary,
					}}
				/>
			</IconButton>

			<OfferToolTip
				title={author.cityName}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				TransitionComponent={Zoom}
				arrow
			>
				<Box
					width="100%"
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
				>
					<Typography
						style={{
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
						variant="subtitle2"
						align="center"
					>
						{author.cityName}
					</Typography>
				</Box>
			</OfferToolTip>
		</Column>
	);

	const renderCardFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				padding: '4px 10px 4px 8px',
			}}
		>
			<Line style={{ justifyContent: 'flex-start' }}>
				<Checkbox
					size="small"
					data-testid="selectCardButton"
					checked={selectedCards.indexOf(author.authorID) !== -1}
					onClick={handleSelect}
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						'&.Mui-checked': {
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						},
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				/>
				<OfferToolTip
					title={`${t('Autores.Compartilhar')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						onClick={handleCopyLinkToURL}
						sx={{
							padding: '6px',
							marginLeft: '-5px',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<ShareRounded
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
				</OfferToolTip>
			</Line>
			<Line style={{ justifyContent: 'flex-end' }}>
				<CardDenounceConfirmationDialog author={author} />
			</Line>
		</Line>
	);

	const handleAuthorModalClose = () => {
		setOpenAuthorDetailsModal(false);
	};

	return (
		<>
			<Card
				className={className}
				style={{
					width: 250,
					height: 330,
					background: selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
				}}
			>
				<Line>
					<LeftVerticalLine color={checkCardVerticalLineColor()} enabled />
					<div style={{ width: 247, marginLeft: -2 }}>
						<div>{renderCardHeader()}</div>
						<div
							data-testid="Clickable Card"
							onClick={() => setOpenAuthorDetailsModal(true)}
							style={{
								cursor: 'pointer',
							}}
						>
							{renderCardAvatar()}
							<Divider
								sx={{
									background:
										selectedTheme.id === 'dark' && selectedTheme.footerLine,
								}}
								style={{ margin: '0px 15px' }}
							/>
						</div>
						<div>
							{renderCardDetails()}
							<Divider
								sx={{
									background:
										selectedTheme.id === 'dark' && selectedTheme.footerLine,
								}}
								style={{ margin: '0px 15px' }}
							/>
							{renderCardFooter()}
						</div>
					</div>
				</Line>
			</Card>

			<AuthorDetailsModal
				open={openAuthorDetailsModal}
				handleClose={handleAuthorModalClose}
				authorData={author}
			/>
			<FullSizeImageDialog
				open={openImageModal}
				setOpen={setOpenImageModal}
				imageIndexToShow={imageIndex}
				images={author.authorData?.images?.value}
			/>
		</>
	);
};
export default CardFront;
