import React from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { Column, Line } from '../../../../styles';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { usePO } from '../../../../utils/POContext';
import SelectClient from '../../../../components/SelectClient';
import { MessageType } from '../../../../interfaces';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateGoodCards,
	updateNotGoodCards,
} from '../../../../redux/slices/cardFeedbackSlice';

interface Props {
	offerData: any;
	closeParent?: any;
	denounceValue?: string;
	denounceObs?: string;
	cardAsGood?: boolean;
	handleRemoveCardAsGood: () => void;
	tagsToSubmit?: any;
	setDenounceValue: (value) => void;
	setTagFieldError: (value) => void;
}

const DenouncecConfirmationDialog: React.FC<Props> = ({
	offerData,
	closeParent,
	cardAsGood,
	handleRemoveCardAsGood,
	denounceValue,
	denounceObs,
	tagsToSubmit,
	setDenounceValue,
	setTagFieldError,
}: Props) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, userData } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const notGoodCardsList = useSelector(
		(state: any) => state.cardFeedback.notGoodCardsList
	);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	setupRequestToken(api, token);

	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [denounce, setDenounce] = React.useState(true);

	const handleClickDenounce = () => {
		if (denounceValue !== 'NOT_SET') {
			// check if role is admin
			if (userData.roles.includes('Analista de posts')) {
				if (tagsToSubmit.length > 0) {
					setTagFieldError(false);
					setOpen(true);
				} else {
					setTagFieldError(true);
					enqueueSnackbar(t('Classificações.Adicione uma ou mais etiquetas'), {
						variant: 'warning',
					});
				}
			} else {
				setOpen(true);
			}
		} else {
			enqueueSnackbar(
				t(
					'Classificações.Por favor, especifique um motivo para a classificação.'
				),
				{ variant: 'success' }
			);
		}
	};

	const handleClickPermit = () => {
		setDenounce(false);
		setDenounceValue('PSTLG');
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setDenounce(true);
		}, 300);
	};

	const handleSnackbarMessage = (message: string, type: MessageType) => {
		enqueueSnackbar(message, { variant: type });
	};

	const handleSave = async () => {
		if (denounceValue !== 'NOT_SET') {
			setLoading(true);
			const payload = {
				offerID: [offerData.offerID],
				classificationModel: {
					classificationTypeID: denounceValue,
					denounceStatusID: 'CLASSIFICADA',
					clientID: selectedClient.id,
					observation: denounceObs,
				},
			};
			const response = await api.post('/offerClassification', payload);
			// TODO Implement library to show non numeric error codes
			response.data.map((item) => {
				if (item.status < 300) {
					/** If it's marking card as good */
					if (denounceValue === 'PSTLG') {
						handleSnackbarMessage(item.detail, 'success');
						/** Changes card footer to Classified as Good */
						dispatch(updateGoodCards(goodCardsList.concat(offerData.offerID)));
					}
					/** If it's a denunciation */
					if (denounceValue !== 'PSTLG') {
						handleSnackbarMessage(item.detail, 'success');
						/** Makes card less opaque and not clickable */
						dispatch(
							updateNotGoodCards(notGoodCardsList.concat(offerData.offerID))
						);
					}
				}
				if (item.status >= 400) {
					handleSnackbarMessage(item.detail, 'error');
				}
				return null;
			});
			setLoading(false);
			setOpen(false);
			closeParent();
		}
	};

	const handleRemove = async () => {
		setLoading(true);
		try {
			const response = await api.get(
				`/offerClassification/clients/${selectedClient.id}/offers/${offerData.offerID}`
			);
			const deleteResponse = await api.delete(
				`/offerClassification/${response.data.id}/clients/${selectedClient?.id}`
			);
			dispatch(
				updateGoodCards(
					goodCardsList.filter((offerID) => offerID !== offerData.offerID)
				)
			);
			handleRemoveCardAsGood();
			handleSnackbarMessage(deleteResponse.data.detail, 'success');

			closeParent();
			setLoading(false);
		} catch (error) {
			handleSnackbarMessage(
				t('Erro interno. Tente novamente mais tarde'),
				'error'
			);
		}
		closeParent();
		setLoading(false);
	};

	const renderDennounceMessage = () =>
		denounce ? (
			<Line>
				<Typography>{t('Classificações.Classificar o produto')}:</Typography>
				<Box
					maxWidth={300}
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
				>
					<Typography
						noWrap
						style={{
							fontWeight: 'bold',
							marginLeft: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.primary : 'black',
						}}
					>
						{offerData.title}
					</Typography>
				</Box>
				<Typography>?</Typography>
			</Line>
		) : (
			<>
				<Line>
					<Typography>
						{cardAsGood || goodCardsList.includes(offerData.offerID)
							? `${t(
									'Classificações.Remover o anúncio da lista de permitidos para o cliente'
								)}:`
							: `${t(
									'Classificações.Classificar o anúncio como permitido para o cliente'
								)}:`}
					</Typography>
					<Typography style={{ fontWeight: 'bold', marginLeft: 5 }}>
						{`${selectedClient.name || ''}`}
					</Typography>
					<Typography>?</Typography>
				</Line>
			</>
		);

	const renderFooter = () => {
		if (denounce) {
			return (
				<Line style={{ justifyContent: 'space-between' }}>
					<Button
						size="small"
						onClick={handleClose}
						disabled={loading}
						style={{ color: loading ? 'gray' : selectedTheme.error }}
					>
						{t('Classificações.Cancelar')}
					</Button>
					{loading ? (
						<LoadingDots height={35} width={70} loop />
					) : (
						<Button
							size="small"
							disabled={selectedClient === null || selectedClient === undefined}
							onClick={handleSave}
							style={{
								color: selectedTheme.primary,
							}}
						>
							{t('Classificações.Classificar')}
						</Button>
					)}
				</Line>
			);
		}
		return (
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Classificações.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={35} width={70} loop />
				) : (
					<Button
						size="small"
						style={{
							color: selectedTheme.primary,
						}}
						onClick={
							cardAsGood || goodCardsList.includes(offerData.offerID)
								? handleRemove
								: handleSave
						}
					>
						{cardAsGood || goodCardsList.includes(offerData.offerID)
							? `${t('Classificações.Remover')}`
							: `${t('Classificações.Classificar')}`}
					</Button>
				)}
			</Line>
		);
	};

	const renderDenounceButtons = () => (
		<Line style={{ width: '100%', justifyContent: 'flex-end' }}>
			<Button
				size="small"
				key={offerData.offerMarketplaceID}
				variant="contained"
				onClick={handleClickPermit}
				style={{
					backgroundColor: selectedTheme.success,
					color: 'white',
				}}
			>
				{cardAsGood || goodCardsList.includes(offerData.offerID)
					? `${t('Classificações.Remover dos permitidos')}`
					: `${t('Classificações.Permitir')}`}
			</Button>

			{cardAsGood || goodCardsList.includes(offerData.offerID) ? null : (
				<Button
					size="small"
					key={offerData.offerMarketplaceID + 2}
					variant="contained"
					onClick={handleClickDenounce}
					style={{
						backgroundColor: selectedTheme.error,
						color: 'white',
						marginLeft: 20,
					}}
				>
					{t('Classificações.Classificar')}
				</Button>
			)}
		</Line>
	);

	return (
		<>
			{renderDenounceButtons()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="lg"
			>
				<DialogContent style={{ padding: '20px 30px' }}>
					{selectedClient !== null && selectedClient !== undefined ? (
						renderDennounceMessage()
					) : (
						<Column style={{ rowGap: 10 }}>
							<Typography>
								{t(
									'Classificações.Escolha um cliente para classificar o anúncio'
								)}
								:
							</Typography>
							<SelectClient />
						</Column>
					)}
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: '#eaeaea',
						margin: '0 5px 0 5px',
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DenouncecConfirmationDialog;
