import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const fetchSearchDetails = async (rowData: any) => {
	const inCountryID = rowData.platformINcountryID.split('|')[1];
	try {
		const promises = [
			api.get(
				`/searches/${rowData.searchID}/storage/dates?clientID=${rowData.clientID}`
			),
			api.get(
				`/searches/${rowData.searchID}/clickhouse/dates?clientID=${rowData.clientID}`
			),
			api.get(`/countries/${inCountryID}`),
		];

		const [
			storageSearchResponse,
			clickHouseSearchResponse,
			currencyINCountryResponse,
		] = await Promise.all(promises);

		const searchDetailsData = {
			success: true,
			storageSearchResponse: storageSearchResponse.data,
			clickHouseSearchResponse: clickHouseSearchResponse.data,
			currencyINCountryResponse: currencyINCountryResponse.data,
		};
		return searchDetailsData;
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchUSDCurrencyRate = async (currency: string) => {
	const today = new Date();
	try {
		const USDCurrencyRateResponse = await api.get(
			`/currencies?currency_from=${currency}&currency_to=USD&date=${today
				.toISOString()
				.slice(0, 10)}`
		);
		return { success: true, data: USDCurrencyRateResponse.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

interface ChartData {
	id: string;
	value: number;
}

interface SearchData {
	success: boolean;
	searchTotalOffers?: any;
	searchTotalItems?: any;
	searchTotalStockItems?: any;
	searchTotalAuthors?: any;
	searchMeanPrice?: any;
	searchTotalItemsSold?: any;
	searchTotalItemsStock?: any;
	offerStateDistribution?: any;
	offerCityDistribution?: any;
	offerPriceDistribution?: any;
	searchTopSellers?: any;
}

export const fetchSearchReports = async (searchID, clickHouseSearchKey) => {
	try {
		const promises = [
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/total-offers`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/total-items-sold`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/total-stock-items`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/total-authors`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/mean-price`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/total-price-items-sold`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/metrics/clickhouse/total-price-items-stock`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/distribution/metrics/clickhouse/offers-by-state`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/distribution/metrics/clickhouse/offers-by-city`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/distribution/metrics/clickhouse/offers-by-price`,
				{ timeout: 60 * 1000 }
			),
			api.get(
				`/reports/searches/${searchID}/dates/${clickHouseSearchKey}/distribution/metrics/clickhouse/top100-sellers`,
				{ timeout: 60 * 1000 }
			),
		];

		const results = await Promise.allSettled(promises);

		const searchData: SearchData = {
			success: true,
		};

		const sortingChartData = (data: ChartData[]) => {
			return data.sort((a: ChartData, b: ChartData) => a.value - b.value);
		};

		let rejectedCount = 0;
		results.forEach((result, index) => {
			if (result.status === 'rejected') {
				console.error(
					`Chamada de API ${index} falhou com erro:`,
					result.reason
				);
				rejectedCount++;
			} else {
				switch (index) {
					case 0:
						searchData.searchTotalOffers = result.value.data;
						break;
					case 1:
						searchData.searchTotalItems = result.value.data;
						break;
					case 2:
						searchData.searchTotalStockItems = result.value.data;
						break;
					case 3:
						searchData.searchTotalAuthors = result.value.data;
						break;
					case 4:
						searchData.searchMeanPrice = result.value.data;
						break;
					case 5:
						searchData.searchTotalItemsSold = result.value.data;
						break;
					case 6:
						searchData.searchTotalItemsStock = result.value.data;
						break;
					case 7:
						searchData.offerStateDistribution = sortingChartData(
							result.value.data.hits
						);
						break;
					case 8:
						searchData.offerCityDistribution = sortingChartData(
							result.value.data.hits
						);
						break;
					case 9:
						searchData.offerPriceDistribution = result.value.data.hits;
						break;
					case 10:
						searchData.searchTopSellers = result.value.data.hits;
						break;
				}
			}
		});

		if (rejectedCount === results.length) {
			searchData.success = false;
		}
		return searchData;
	} catch (error) {
		console.log(error);
		return {
			success: false,
			error: 'Alguns dados deste relatório não estão disponíveis',
		};
	}
};

export const fetchSearchDataReports = async (searchID, clientID) => {
	try {
		const response = await api.get(
			`/searches/${searchID}/clients/${clientID}`,
			{
				timeout: 2 * 60 * 1000,
			}
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.statusText,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.statusText,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const downloadSearchData = async (
	searchID: string,
	clientID: string,
	SearchKey: string
) => {
	try {
		const downloadSearchResponse = await api.get(
			`/searches/${searchID}/storage/download?clientID=${clientID}&searchKey=${SearchKey}`,
			{
				timeout: 2 * 60 * 1000,
			}
		);
		const downloadUrl = downloadSearchResponse.data;
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.click();

		return { success: true };
	} catch (error) {
		console.log(error);
		return {
			success: false,
			error: 'Houve um erro ao baixar os dados dessa busca',
		};
	}
};
