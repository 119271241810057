import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import { AddCircleRounded, CloseRounded, Edit } from '@mui/icons-material/';
import { api, isAxiosError } from '../../../utils/api';
import { Column, Line } from '../../../styles';
import { OfferToolTip } from '../../../helpers';
import { usePO } from '../../../utils/POContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FormDivider from '../../../components/FormDivider';
import LoadingDots from '../../../components/LoadingDots';
import RuleFields from './RulesFields';
import SelectProceduresTypes from './SelectProceduresTypes';
import { ErrorResponse } from '../../../interfaces';

interface Props {
	rowData: any;
	isEditing: boolean;
	refresh: any;
}

const AddNewRuleModal: React.FC<Props> = ({
	rowData,
	isEditing,
	refresh,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, selectedClient } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const defaultRules = [
		{
			fields: '',
			operators: '',
			value: '',
			concatOperator: '',
		},
	];
	const defaultProcedureTypes = [
		{
			action: '',
			properties: [],
		},
	];

	const [amountRules, setAmountRules] = useState(0);
	const [description, setDescription] = useState('');
	const [hasFormErrors, setHasFormErrors] = useState(false);
	const [listOfRules, setListOfRules] = useState<any[]>(defaultRules);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [ruleName, setRuleName] = useState('');
	const [procedureTypeList, setProcedureTypeList] = useState<any[]>(
		defaultProcedureTypes
	);

	const handleClose = () => {
		setListOfRules(defaultRules);
		setProcedureTypeList(defaultProcedureTypes);
		setRuleName('');
		setDescription('');
		setHasFormErrors(false);
		setOpen(false);
		refresh();
	};

	const handleOpen = () => {
		if (isEditing) {
			setRuleName(rowData?.name);
			setDescription(rowData?.description);
			setListOfRules(rowData?.policyData || defaultRules);
			setProcedureTypeList(rowData?.actionsData || defaultProcedureTypes);
		}
		setOpen(true);
	};

	const texticonStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	// Remove the other fields and send only the ID.
	function transformProperties(procedureTypeList) {
		return procedureTypeList.map((item) => {
			const action = item.action;
			let properties = [];

			if (action === 'etiquetar') {
				properties = item.properties.map((prop) => prop.tagID);
				return {
					action,
					properties,
				};
			} else if (action === 'classificar') {
				properties = item.properties.id;
				return {
					action,
					properties: [properties],
				};
			}
			return null;
		});
	}

	const postNewRule = async () => {
		setLoading(true);
		// Checking if there are any empty fields
		const isInvalid =
			!ruleName ||
			listOfRules.some(
				(rule) => !rule.fields || !rule.operators || !rule.value
			) ||
			procedureTypeList.some(
				(procedure) => !procedure.action || procedure.properties.length === 0
			);

		const hasDuplicates = (arr) => {
			return arr.length !== new Set(arr.map((item) => item.action)).size;
		};
		if (!hasDuplicates(procedureTypeList)) {
			if (!isInvalid) {
				try {
					// Always send the last 'concatOperator' as empty
					const updatedRules = [...listOfRules];
					updatedRules[updatedRules.length - 1].concatOperator = '';
					const actions = transformProperties(procedureTypeList);
					const payload = {
						policy: updatedRules,
						actions,
						name: ruleName,
						description: description,
						clientID: selectedClient.id,
					};
					await api.post(`/policies`, payload);
					enqueueSnackbar(t('Regras.Regra adicionada com sucesso.'), {
						variant: 'success',
					});
					setLoading(false);
					handleClose();
				} catch (error) {
					if (isAxiosError(error)) {
						const errorResponse = error.response as ErrorResponse | undefined;
						if (errorResponse && errorResponse.data) {
							enqueueSnackbar(t('Regras.' + errorResponse.data.detail), {
								variant: 'error',
							});
						}
					}
				}
			} else {
				setHasFormErrors(true);
			}
		} else {
			enqueueSnackbar(
				t('Regras.Regra duplicada encontrada. Verifique e tente novamente.'),
				{ variant: 'warning' }
			);
		}
		setLoading(false);
	};

	const updateRule = async () => {
		setLoading(true);
		// Checking if there are any empty fields
		const isInvalid =
			!ruleName ||
			listOfRules.some(
				(rule) => !rule.fields || !rule.operators || !rule.value
			) ||
			procedureTypeList.some(
				(procedure) => !procedure.action || procedure.properties.length === 0
			);
		const hasDuplicates = (arr) => {
			return arr.length !== new Set(arr.map((item) => item.action)).size;
		};
		if (!hasDuplicates(procedureTypeList)) {
			if (!isInvalid) {
				// Always send the last 'concatOperator' as empty
				const updatedRules = [...listOfRules];
				updatedRules[updatedRules.length - 1].concatOperator = '';
				const actions = transformProperties(procedureTypeList);
				try {
					const payload = {
						policy: updatedRules,
						actions,
						name: ruleName,
						description: description,
						clientID: selectedClient.id,
					};
					await api.patch(
						`/policies/${rowData.id}/clients/${selectedClient.id}`,
						payload
					);
					enqueueSnackbar(t('Regras.Regra atualizada com sucesso'), {
						variant: 'success',
					});
					setLoading(false);
					handleClose();
				} catch (error) {
					if (isAxiosError(error)) {
						const errorResponse = error.response as ErrorResponse | undefined;
						if (errorResponse && errorResponse.data) {
							enqueueSnackbar(t('Regras.' + errorResponse.data.detail), {
								variant: 'error',
							});
						}
					}
				}
			} else {
				enqueueSnackbar(t('Regras.Verifique o formulário'), {
					variant: 'warning',
				});
				setHasFormErrors(true);
			}
		} else {
			enqueueSnackbar(
				t('Regras.Regra duplicada encontrada. Verifique e tente novamente.'),
				{ variant: 'warning' }
			);
		}
		setLoading(false);
	};

	const handleSubmit = async () => {
		if (isEditing) {
			await updateRule();
		} else {
			await postNewRule();
		}
	};

	// handleUpdateRuleField update the 'listOfRules' according to the index and the field.
	const handleUpdateRuleField = (
		index: number,
		field: string,
		value: string
	) => {
		const updatedRules = [...listOfRules];
		updatedRules[index][field] = value;
		setListOfRules(updatedRules);
	};

	// Add a new empty object to the 'listOfRules' list, creating a new component.
	const handleAddRuleFields = () => {
		setAmountRules(amountRules + 1);
		listOfRules[listOfRules.length - 1]['concatOperator'] = '&';
		setListOfRules([
			...listOfRules,
			{
				fields: '',
				operators: '',
				value: '',
				concatOperator: '',
			},
		]);
	};

	// Remove an object from the 'listOfRules' list according to the index, thus removing a component.
	const handleRemoveRuleFields = (index) => {
		const newRules = listOfRules?.filter(
			(rule, ruleIndex) => ruleIndex !== index
		);
		setListOfRules(newRules);
	};

	// Update the 'procedureTypeList' according to the index and the field
	const handleUpdateProceduresTypes = (
		index: number,
		field: string,
		value: string
	) => {
		const updatedProcedureTypes = [...procedureTypeList];
		updatedProcedureTypes[index][field] = value;
		setProcedureTypeList(updatedProcedureTypes);
	};

	// Add a new empty object to the 'procedureTypeList' list, creating a new component.
	const handleAddProceduresTypes = () => {
		setProcedureTypeList([
			...procedureTypeList,
			{
				action: '',
				properties: [],
			},
		]);
	};

	// Remove an object from the 'procedureTypeList' list according to the index, thus removing a component.
	const handleRemoveProceduresTypes = (index) => {
		const newProcedure = procedureTypeList?.filter(
			(rule, procedureIndex) => procedureIndex !== index
		);
		setProcedureTypeList(newProcedure);
	};

	const renderHeader = () => (
		<>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						noWrap
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
							fontSize: 20,
							fontWeight: 'bold',
							maxWidth: '90%',
						}}
					>
						{isEditing
							? t('Regras.Editar regra')
							: t('Regras.Adicionar nova regra')}
					</Typography>
					<IconButton onClick={handleClose} style={{ marginRight: '-16px' }}>
						<CloseRounded
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
		</>
	);

	const renderDialogContent = () => (
		<DialogActions
			style={{
				padding: '30px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			<Column
				style={{
					height: '100%',
					alignItems: 'start',
					justifyContent: 'space-between',
					rowGap: 15,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				}}
			>
				<TextField
					name="ruleName"
					value={ruleName}
					variant="outlined"
					size="small"
					placeholder={t('Regras.Nome da regra')}
					error={hasFormErrors && !ruleName}
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					onChange={(event) => {
						setRuleName(event.target.value);
					}}
					sx={{
						width: '100%',
						marginTop: '15px',
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
					}}
				/>
				{listOfRules?.map((rule, index) => (
					<RuleFields
						key={index}
						rule={rule}
						index={index}
						ruleEmpyt={hasFormErrors}
						listOfRules={listOfRules}
						handleUpdateRuleField={handleUpdateRuleField}
						handleAddRuleFields={handleAddRuleFields}
						handleRemoveRuleFields={handleRemoveRuleFields}
					/>
				))}
				<FormDivider
					name=""
					margin="0 0"
					opacity={0.3}
					background={selectedTheme.id === 'dark' && selectedTheme.primaryDark}
				/>
				{procedureTypeList.map((procedure, index) => (
					<SelectProceduresTypes
						key={index}
						index={index}
						procedureTypeList={procedureTypeList}
						procedure={procedure}
						ruleEmpty={hasFormErrors}
						handleUpdateProcedures={handleUpdateProceduresTypes}
						handleAddProcedures={handleAddProceduresTypes}
						handleRemoveProcedures={handleRemoveProceduresTypes}
					/>
				))}
				<FormDivider
					name=""
					margin="0 0 5px 0"
					opacity={0.3}
					background={selectedTheme.id === 'dark' && selectedTheme.primaryDark}
				/>
				<TextField
					name="description"
					value={description}
					variant="outlined"
					multiline
					rows="2"
					placeholder={t('Regras.Descrição')}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					onChange={(event) => {
						setDescription(event.target.value);
					}}
					sx={{
						width: '100%',
						marginTop: '15px',
						'& .MuiOutlinedInput-root': {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						},
					}}
				/>
			</Column>
		</DialogActions>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Regras.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						type="button"
						size="small"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
							background:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: selectedTheme.foreground
							}`,
						}}
					>
						{t('Regras.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	const renderEditIcon = () => (
		<OfferToolTip
			title={t('Regras.Editar regra')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
				onClick={handleOpen}
			>
				<Edit />
			</IconButton>
		</OfferToolTip>
	);

	const renderCustomHeaderComponent = () => (
		<OfferToolTip
			title={t('Regras.Adicionar nova regra')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				style={{ marginRight: '-15px', borderRadius: 5 }}
				onClick={() => setOpen(true)}
			>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 'bold',
						color: texticonStyleHeader(),
						marginRight: 15,
						marginTop: 2,
					}}
				>
					{t('Regras.Adicionar regra')}
				</Typography>
				<AddCircleRounded
					style={{
						color: texticonStyleHeader(),
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<>
			{isEditing ? renderEditIcon() : renderCustomHeaderComponent()}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-label="confirm-edit-dialog"
				fullWidth
				maxWidth="md"
				scroll="body"
			>
				{renderHeader()}
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default AddNewRuleModal;
