import { Box, Card, Link, Typography, Zoom } from '@mui/material';
import React from 'react';
import { OfferToolTip } from '../../../../helpers';
import { Column, Line } from '../../../../styles';
import MeirellesLogo from '../../../../assets/images/logos/meirelles_ipc_marca.webp';
import MeirellesRodapé from '../../../../assets/images/logos/meirelles_ipc_rodapé.png';

interface Props {
	data: any;
}

const A4Component: React.FC<Props> = ({ data }: Props) => {
	return (
		<Card
			style={{
				justifyContent: 'center',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '760px',
				height: '1090px',
				overflow: 'hidden',
				breakInside: 'avoid',
				pageBreakInside: 'avoid',
			}}
		>
			<Column style={{ justifyContent: 'space-between', height: '100%' }}>
				<Line
					style={{
						justifyContent: 'end',
						padding: '25px 0px',
						marginRight: '150px',
					}}
				>
					<img
						alt="MeirellesMarca"
						src={MeirellesLogo}
						style={{ width: '30%' }}
					/>
				</Line>
				<Card
					style={{
						justifyContent: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						overflow: 'hidden',
						pageBreakInside: 'avoid',
					}}
				>
					<Line style={{ justifyContent: 'center', padding: 15 }}>
						<OfferToolTip
							title={data._source.offer?.permalink}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							TransitionComponent={Zoom}
							arrow
						>
							<Link
								href={data._source.offer.permalink}
								variant="body2"
								color="inherit"
								target="blank"
								sx={{
									textDecoration: 'none',
									wordWrap: 'break-word',
									overflowWrap: 'break-word',
									whiteSpace: 'normal',
									wordBreak: 'break-all',
									display: 'block',
									maxWidth: '100%',
									textOverflow: 'ellipsis',
								}}
							>
								<Typography
									variant="body2"
									style={{
										wordWrap: 'break-word',
										overflowWrap: 'break-word',
										whiteSpace: 'normal',
										wordBreak: 'break-all',
										display: 'block',
										textOverflow: 'ellipsis',
									}}
								>
									{data._source.offer.permalink}
								</Typography>
							</Link>
						</OfferToolTip>
					</Line>

					<Box style={{ justifyContent: 'center' }}>
						<img
							loading="lazy"
							src={data._source.offer.thumb}
							alt={`Offer Thumbnail`}
							style={{
								width: '100%',
								objectFit: 'cover',
								marginBottom: '20px',
							}}
						/>
					</Box>
				</Card>
				<img alt="Title" src={MeirellesRodapé} style={{ width: '100%' }} />
			</Column>
		</Card>
	);
};

export default A4Component;
