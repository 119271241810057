import { createSlice } from '@reduxjs/toolkit';

export const expansibleTableSlice = createSlice({
	name: 'panels',
	initialState: {
		openPanels: [],
	},
	reducers: {
		openPanel: (state, action) => {
			state.openPanels.push(action.payload);
		},
		closePanel: (state, action) => {
			state.openPanels = state.openPanels.filter((id) => id !== action.payload);
		},
		closeAllPanels: (state) => {
			state.openPanels = [];
		}
	},
});

export const { openPanel, closePanel, closeAllPanels } = expansibleTableSlice.actions;

export default expansibleTableSlice.reducer;
