import React from 'react';
import { KeyboardArrowDownRounded } from '@mui/icons-material/';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Collapse,
	Typography,
	Zoom,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../../../styles';
import { usePO } from '../../../../../utils/POContext';
import { OfferToolTip } from '../../../../../helpers';
import DetailsShopee from './DetailsShopee';
import DetailsEnjoei from './DetailsEnjoei';

interface Props {
	offerData: any;
	expandCard: boolean;
	handleExpandCard: (value) => void;
}

const UpperCard: React.FC<Props> = ({
	offerData,
	expandCard,
	handleExpandCard,
}) => {
	const { userData, selectedTheme } = usePO();
	const { t } = useTranslation();

	const handleExpandOnClick = () => {
		if (!expandCard) {
			handleExpandCard(true);
		}
	};

	const renderPlatformDetails = () => {
		switch (offerData.platformINcountryID) {
			case 'SHOPEE|BRA':
				return <DetailsShopee data={offerData} />;
			case 'SHOPEE|CHL':
				return <DetailsShopee data={offerData} />;
			case 'SHOPEE|COL':
				return <DetailsShopee data={offerData} />;
			case 'SHOPEE|MEX':
				return <DetailsShopee data={offerData} />;
			case 'ENJOEI|BRA':
				return <DetailsEnjoei data={offerData} />;
			default:
				break;
		}
		return null;
	};

	const fixedTop = () => (
		<Card style={{ width: 520, padding: '10px 20px' }}>
			<Line style={{ justifyContent: 'space-between' }}>
				{offerData.offerData.price?.value ? (
					<Line style={{ justifyContent: 'flex-start', width: '45%' }}>
						<Typography
							variant="subtitle1"
							style={{ marginRight: 10, fontWeight: 'bold' }}
						>
							{t('Classificações.Preço')}:
						</Typography>
						<Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
							<Typography
								style={{
									maxWidth: 200,
									fontSize: 22,
									fontWeight: 'bold',
									marginTop: -2,
								}}
							>
								{parseFloat(offerData.offerData.price.value).toLocaleString(
									userData.i18nID,
									{
										style: 'currency',
										currency: offerData.offerData.currency.value,
										minimumFractionDigits: 2,
									}
								)}
							</Typography>
						</Box>
					</Line>
				) : null}
				<Line
					style={{ columnGap: 10, justifyContent: 'flex-end', width: '45%' }}
				>
					<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
						{t('Classificações.Categoria')}:
					</Typography>
					<OfferToolTip
						title={offerData.offerCategory || null}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						TransitionComponent={Zoom}
						arrow
					>
						<Box
							maxWidth="220px"
							overflow="hidden"
							textOverflow="ellipsis"
							whiteSpace="nowrap"
						>
							<Typography
								noWrap
								variant="subtitle2"
								style={{ marginBottom: -1 }}
							>
								{offerData.offerCategory || null}
							</Typography>
						</Box>
					</OfferToolTip>
				</Line>
			</Line>
		</Card>
	);

	const expansibleBottom = () => (
		<Card
			style={{
				width: 520,
				padding: 0,
				cursor: expandCard ? 'auto' : 'pointer',
			}}
			onClick={handleExpandOnClick}
		>
			<Collapse in={expandCard} collapsedSize={102}>
				<CardContent>{renderPlatformDetails()}</CardContent>
			</Collapse>
			<CardActions
				onClick={() => handleExpandCard(!expandCard)}
				style={{
					cursor: 'pointer',
					height: 17,
					padding: 0,
					borderTopStyle: 'solid',
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
					borderTopWidth: 1,
					boxShadow: '0px -2px 5px -2px rgba(0,0,0,0.2)',
				}}
			>
				<Line style={{ width: '100%', justifyContent: 'center' }}>
					<KeyboardArrowDownRounded
						style={{
							opacity: 0.5,
							transform: expandCard ? 'rotate(180deg)' : 'none',
						}}
					/>
				</Line>
			</CardActions>
		</Card>
	);

	return (
		<>
			{fixedTop()}
			{expansibleBottom()}
		</>
	);
};

export default UpperCard;
