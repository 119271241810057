import React, { useEffect, useState } from 'react';
import { usePO } from '../../utils/POContext';
import { useDispatch } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone.js';
import { ptBR, enUS, esES } from '@mui/x-date-pickers/locales';
import {
	Box,
	Button,
	Card,
	DialogActions,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Column, Line } from '../../styles';
import { useTranslation } from 'react-i18next';
import MainReport from './MainReports';
import PlatformsReports from './PlatformsReports';
import ProductsReports from './ProductsReports';
import SellersReports from './SellersReports';
import SelectClient from '../../components/SelectClient';

const Relatorios: React.FC = () => {
	const { userData, selectedTheme } = usePO();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const nowInBrazil = dayjs()
		.tz('America/Sao_Paulo')
		.format('YYYY-MM-DDTHH:mm:ss');

	const today = new Date();
	const platformOfferFirstDate = '2022-03-01T00:00:00';

	const [selectedStartDate, setSelectedStartDate] = useState<string>(
		`${new Date().getFullYear()}-${(new Date().getMonth() + 1)
			.toString()
			.padStart(2, '0')}-01T00:00:00`
	);
	const [selectedFinalDate, setSelectedFinalDate] = useState<string>(
		`${nowInBrazil}`
	);
	const [datePeriodInCharts, setDatePeriodInCharts] = useState(0);
	const [currentLocaleText, setCurrentLocalText] = useState<any>(ptBR);
	const [value, setValue] = useState('Geral');

	useEffect(() => {
		dispatch(changeActiveMenu('relatorios'));
		handleLocateDatePicker();
	}, [dispatch]);

	const indicatorStyleTabs = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return ' #c4c4c41f';
			default:
				return '#00000017';
		}
	};

	const useStyles = makeStyles(() => ({
		indicator: {
			background: indicatorStyleTabs(),
			height: '100%',
		},
	}));

	const classes = useStyles();

	const typographyStyleTabs = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryLight;
			case 'dark':
				return selectedTheme.textColorMedium;
			default:
				return selectedTheme.disabled;
		}
	};

	const typographyStyleTabsActive = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const reFetchDatePeriod = async () => {
		setDatePeriodInCharts(datePeriodInCharts + 1);
	};

	const handleChangeStartDate = (value) => {
		if (value !== null && value.$d.toString() !== 'Invalid Date') {
			setSelectedStartDate(`${value.$d.toISOString().slice(0, -5)}`);
		}
	};

	const handleChangeFinalDate = (value) => {
		if (value !== null && value.$d.toString() !== 'Invalid Date') {
			setSelectedFinalDate(`${value.$d.toISOString().slice(0, -5)}`);
		}
	};

	const handleLocateDatePicker = () => {
		switch (userData?.i18nID) {
			case 'en-us':
				return setCurrentLocalText(enUS);
			case 'es-es':
				return setCurrentLocalText(esES);

			default:
				return setCurrentLocalText(ptBR);
		}
	};

	const renderDataPickers = () => {
		if (currentLocaleText !== undefined) {
			return (
				<Line style={{ padding: '20px' }}>
					<Line>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							localeText={
								currentLocaleText?.components.MuiLocalizationProvider
									.defaultProps.localeText
							}
						>
							<DateTimePicker
								orientation="landscape"
								label={`${t('Relatórios.Data Inicial')}`}
								value={dayjs(selectedStartDate)}
								onChange={(newValue) => handleChangeStartDate(newValue)}
								views={['year', 'month', 'day', 'hours', 'minutes']}
								format="DD/MM/YYYY HH:mm"
								ampm={false}
								openTo="month"
								minDateTime={dayjs(platformOfferFirstDate)}
								maxDateTime={dayjs(today)}
								sx={{
									marginRight: '10px',
									width: '190px',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorHigh,
										height: '40px',
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
							<DateTimePicker
								orientation="landscape"
								label={`${t('Relatórios.Data Final')}`}
								value={dayjs(selectedFinalDate)}
								onChange={(newValue) => handleChangeFinalDate(newValue)}
								views={['year', 'month', 'day', 'hours', 'minutes']}
								format="DD/MM/YYYY HH:mm"
								ampm={false}
								openTo="month"
								minDateTime={dayjs(platformOfferFirstDate)}
								maxDateTime={dayjs(today)}
								sx={{
									marginRight: '10px',
									width: '190px',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorHigh,
										height: '40px',
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						</LocalizationProvider>
						<Button
							aria-label="submit-button"
							size="small"
							variant="contained"
							onClick={reFetchDatePeriod}
							sx={{
								border: `1px solid ${
									selectedTheme.id === 'dark'
										? selectedTheme.footerLine
										: 'transparent'
								}`,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							{t('Relatórios.Gerar')}
						</Button>
					</Line>
				</Line>
			);
		}
	};

	const renderFooter = () => (
		<DialogActions
			style={{
				height: '62px',
				width: '80%',
				border: `1px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.footerLine
						: selectedTheme.disabled
				}`,
				borderTop: 'none',
				borderRadius: '0px 0px 4px 4px',
				padding: '15px 20px 15px 15px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme?.foreground,
			}}
		>
			<Line style={{ justifyContent: 'center', gap: 5 }}>
				<Typography
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					{t('Relatórios.Cliente')}:
				</Typography>
				<SelectClient width="200px" />
			</Line>
		</DialogActions>
	);

	const renderTabsNavigation = () => {
		return (
			<Box
				sx={{
					width: '80%',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: `1px solid ${selectedTheme.borderLight}`,
					borderRadius: '3px 3px 0px 0px',
				}}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					centered
					classes={{ indicator: classes.indicator }}
					sx={{
						fontWeight: 'bold',
						borderRadius: '2px 2px 0px 0px',
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.vertMenuColor
								: selectedTheme.gradient,
					}}
				>
					<Tab
						value="Geral"
						sx={{
							fontWeight: 'bold',
							borderRadius: '4px',
							textTransform: 'none',
							'&.Mui-selected': {
								color: typographyStyleTabsActive(),
							},
							color: typographyStyleTabs(),
						}}
						label={
							t('Relatórios.Geral').charAt(0).toUpperCase() +
							t('Relatórios.Geral').slice(1).toLowerCase()
						}
					/>
					<Tab
						sx={{
							fontWeight: 'bold',
							borderRadius: '4px',
							textTransform: 'none',
							'&.Mui-selected': {
								color: typographyStyleTabsActive(),
							},
							color: typographyStyleTabs(),
						}}
						value="Plataformas"
						label={
							t('Relatórios.Plataformas').charAt(0).toUpperCase() +
							t('Relatórios.Plataformas').slice(1).toLowerCase()
						}
					/>
					<Tab
						sx={{
							fontWeight: 'bold',
							borderRadius: '4px',
							textTransform: 'none',
							'&.Mui-selected': {
								color: typographyStyleTabsActive(),
							},
							color: typographyStyleTabs(),
						}}
						value="Produtos"
						label={
							t('Relatórios.Produtos').charAt(0).toUpperCase() +
							t('Relatórios.Produtos').slice(1).toLowerCase()
						}
					/>
					<Tab
						sx={{
							fontWeight: 'bold',
							borderRadius: '4px',
							textTransform: 'none',
							'&.Mui-selected': {
								color: typographyStyleTabsActive(),
							},
							color: typographyStyleTabs(),
						}}
						value="Vendedores"
						label={
							t('Relatórios.Vendedores').charAt(0).toUpperCase() +
							t('Relatórios.Vendedores').slice(1).toLowerCase()
						}
					/>
				</Tabs>
				{value === 'Geral' && <MainReport />}
				{value === 'Plataformas' && <PlatformsReports />}
				{value === 'Produtos' && <ProductsReports />}
				{value === 'Vendedores' && <SellersReports />}
			</Box>
		);
	};

	return (
		<>
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
				}}
			>
				<div
					style={{
						overflowY: 'hidden',
						height: '97.5vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Column
						style={{
							height: '100%',
							width: '100%',
							justifyContent: 'flex-start',
							overflow: 'scroll',
						}}
					>
						<>
							{renderDataPickers()}
							<Line
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center',
									width: '100%',
									padding: '10px',
								}}
							>
								<Typography
									style={{
										fontSize: '3em',
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorMedium
												: selectedTheme.primary,
									}}
								>
									{t('Relatórios.RELATÓRIOS')}
								</Typography>
							</Line>
							{renderTabsNavigation()}
							{renderFooter()}
						</>
					</Column>
				</div>
			</Card>
		</>
	);
};

export default Relatorios;
