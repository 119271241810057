import React, { useState, useEffect } from 'react';
import { Popover, TextField } from '@mui/material';
import SingleOfferWindow from '../SingleOfferWindow';
import { getOffersByElasticSearch } from '../../../services/classifications';
import { useParams } from 'react-router-dom';

const SingleItemDisplay: React.FC = () => {
	const { offerID } = useParams<{ offerID: string }>();
	const [loading, setLoading] = useState(false);
	const [offerData, setOfferData] = useState({});
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [inputID, setInputID] = useState('');

	const closePopover = () => {
		setAnchorEl(null);
	};

	const fetchOfferByElasticSearch = async (id: string) => {
		setLoading(true);
		const query = `{"preference":"ReactiveListResult"}\n{"query":{"bool":{"must":[{"match":{"offerID": "${id}"}}]}},"size": 20}\n`;
		const response = await getOffersByElasticSearch(query);
		if (response?.success === true) {
			setOfferData(response.data.responses[0].hits.hits[0]._source);
			setLoading(false);
		}
		closePopover();
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.ctrlKey && event.altKey && event.code === 'KeyI') {
			setAnchorEl(event.currentTarget as HTMLButtonElement);
		}
	};

	const handleTextFieldKeyPress = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			fetchOfferByElasticSearch(inputID);
		}
	};

	useEffect(() => {
		if (offerID) {
			fetchOfferByElasticSearch(offerID);
		}
		// eslint-disable-next-line
	}, [offerID]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<SingleOfferWindow offerData={offerData} loading={loading} />
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={closePopover}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<TextField
					autoFocus
					placeholder="Offer ID"
					onChange={(e) => setInputID(e.target.value)}
					onKeyPress={handleTextFieldKeyPress}
					style={{ margin: 10 }}
				/>
			</Popover>
		</>
	);
};

export default SingleItemDisplay;
