import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../utils/POContext';
import { Column, Line } from '../../../../styles';
import Panel from '../../../../components/Panel';
import WindowTabs from '../../../../components/WindowTabs';

interface Props {
	data: any;
	open: boolean;
	close: (clear?: boolean) => void;
}

const SubmitedResumeDialog: React.FC<Props> = ({
	data,
	open,
	close,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [keepSelected, setKeepSelected] = useState(false);

	const handleClose = () => {
		if (keepSelected) {
			close(false);
		} else {
			close(true);
		}
	};

	const handleChangeCheckbox = (event) => {
		setKeepSelected(event.target.checked);
	};

	const renderResume = () => (
		<Column
			style={{
				padding: '20px 30px',
				alignItems: 'flex-start',
				background: selectedTheme.background,
				rowGap: 10,
			}}
		>
			<Typography variant="subtitle1">
				{`${t('Notificações.Tabs.Classificadas.Total de ítens enviados')}: ${
					data?.good.length + data?.notGood.length
				}`}
			</Typography>

			<Typography variant="subtitle1">
				{`${data?.notGood.length} ${t(
					'Notificações.Tabs.Classificadas.itens não foram efetivados'
				)}`}
			</Typography>

			<Typography variant="subtitle1">
				{`${data?.good.length} ${t(
					'Notificações.Tabs.Classificadas.itens foram efetivados'
				)}`}
			</Typography>
		</Column>
	);

	const renderFailedItems = () =>
		data?.notGood.length > 0 ? (
			<Column style={{ alignItems: 'flex-start' }}>
				{data?.notGood.map((el, index) => (
					<Panel
						key={el.id.toString()}
						title={el.title !== undefined ? el.title : el.id}
						noMargin={data?.notGood.length === index + 1}
						contentPadding="10px 20px 5px 45px"
					>
						<Column style={{ width: '100%', alignItems: 'flex-start' }}>
							<Typography variant="caption">
								{t('Notificações.ID do item')}: {el.id}
							</Typography>
							<Typography
								variant="caption"
								style={{ color: selectedTheme.error }}
							>
								{t('Notificações.Tabs.Classificadas.Motivo')}: {el.detail}
							</Typography>
						</Column>
					</Panel>
				))}
			</Column>
		) : (
			<Column
				style={{
					alignItems: 'flex-start',
					padding: '20px 30px',
					background: selectedTheme.background,
				}}
			>
				<Typography variant="subtitle1">
					{t('Notificações.Tabs.Classificadas.Nenhum item falhou')}
				</Typography>
			</Column>
		);

	const renderSuccessItems = () =>
		data?.good.length > 0 ? (
			<Column style={{ alignItems: 'flex-start' }}>
				{data?.good.map((el, index) => (
					<Panel
						key={el.id.toString()}
						title={el.title !== undefined ? el.title : el.id}
						noMargin={data?.good.length === index + 1}
						contentPadding="10px 20px 5px 45px"
					>
						<Column style={{ width: '100%', alignItems: 'flex-start' }}>
							<Typography variant="caption">
								{t('Notificações.ID do item')}: {el.id}
							</Typography>
							<Typography
								variant="caption"
								style={{ color: selectedTheme.success }}
							>
								{t('Notificações.Tabs.Classificadas.Status')}: {el.detail}
							</Typography>
						</Column>
					</Panel>
				))}
			</Column>
		) : (
			<Column
				style={{
					alignItems: 'flex-start',
					padding: '20px 30px',
					background: selectedTheme.background,
				}}
			>
				<Typography variant="subtitle1">
					{t('Notificações.Tabs.Classificadas.Nenhum item foi efetivado')}
				</Typography>
			</Column>
		);

	const renderFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				margin: '2px 10px 0px 10px',
				columnGap: 20,
			}}
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={keepSelected}
						onChange={handleChangeCheckbox}
						size="small"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					/>
				}
				label={
					<Typography
						variant="subtitle2"
						style={{
							marginBottom: -2,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
						}}
					>
						{t(
							'Notificações.Tabs.Classificadas.Manter seleção dos itens falhados'
						)}
					</Typography>
				}
				style={{ marginTop: -3, marginLeft: -5 }}
			/>
			<Button
				size="small"
				variant="text"
				onClick={handleClose}
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primary,
				}}
			>
				{t('Notificações.Tabs.Classificadas.Fechar')}
			</Button>
		</Line>
	);

	return (
		<WindowTabs
			useModal
			handleCloseModal={handleClose}
			openModal={open}
			modalFooter={renderFooter()}
			tab1Component={renderResume()}
			tab2Component={renderSuccessItems()}
			tab3Component={renderFailedItems()}
			tab1Title={t('Notificações.Resumo')}
			tab2Title={t('Notificações.Efetivados')}
			tab3Title={t('Notificações.Falhados')}
			windowTitle={
				<Typography
					style={{
						fontSize: 20,
						fontWeight: 'bold',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.primary,
					}}
				>
					{t('Notificações.Relatório de envio')}
				</Typography>
			}
		/>
	);
};

export default SubmitedResumeDialog;
