import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { SyncRounded, PlaylistAddRounded } from '@mui/icons-material/';
import { IconButton, Typography, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OfferTable from '../../../../components/OfferTable';
import { usePO } from '../../../../utils/POContext';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { Line } from '../../../../styles';
import SelectClient from '../../../../components/SelectClient';
import CheckProcessingItems from '../../components/CheckProcessingItems';
import OpenExternalLink from '../../components/OpenExternalLink';
import { changePanelState } from '../../../../redux/slices/panelControl';
import { useDispatch, useSelector } from 'react-redux';
import { OfferToolTip } from '../../../../helpers';
import ShortCutModal from '../../../../components/ShortCutModal';

interface Props {
	data: any;
	error: boolean;
	loading: boolean;
	reloadTable?: () => void;
}

const RemovedDenouncementTable: React.FC<Props> = ({
	data,
	error,
	loading,
	reloadTable,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, selectedTheme } = usePO();
	const dispatch = useDispatch();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	useHotkeys('ctrl+alt+f', () => handleKeyShortcutFilter());

	const panelControlList = useSelector((state: any) => state.panelControl);

	const handlePanelControlState = () => {
		panelControlList.panelControl === 'notifiedPanelControl'
			? dispatch(changePanelState(''))
			: dispatch(changePanelState('notifiedPanelControl'));
	};

	const [tableData, setTableData] = useState<any[]>([]);

	const colsData = [
		{
			columnTitle: t('Notificações.Tabs.Removidas.Anúncio'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 300,
		},
		{
			columnTitle: t('Notificações.Tabs.Removidas.Denunciante'),
			fieldName: 'denouncerName',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 150,
		},
		{
			columnTitle: t('Notificações.Tabs.Removidas.Motivo da Denúncia'),
			fieldName: 'classificationType',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Notificações.Tabs.Removidas.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
			dataType: 'date',
		},
	];

	const ShortCutsData = [
		{
			description: t(
				'Notificações.Tabs.Removidas.Abrir/fechar barra de filtros avançados'
			),
			shortcut: ['Ctrl', 'Alt', 'F'],
		},
	];

	useEffect(() => {
		dispatch(changePanelState(''));
	}, [dispatch]);

	useEffect(() => {
		const buildTableData = () => {
			setTableData([]);
			data.map((el) => {
				if (el.ISprocessing === false || !el.ISprocessing) {
					const formatTableData = {
						title: el.offer.title,
						denouncerName: `${el.denouncer.denouncerName} (${el.denouncer.denouncerEmail})`,
						classificationType: t(
							'ClassificationType.' + el.classificationType.classificationTypeID
						),
						created: el.created,
						link: el.offer.permalink,
					};
					setTableData((dataINtable) => [...dataINtable, formatTableData]);
				}
				return null;
			});
		};
		buildTableData();
	}, [data]);

	const handleKeyShortcutFilter = () => {
		if (panelControlList.panelControl === 'notifiedPanelControl') {
			handlePanelControlState();
		} else {
			handlePanelControlState();
		}
	};

	const rightFooterComponent = () => (
		<Line style={{ justifyContent: 'flex-end' }}>
			<Typography
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
				}}
			>
				{t('Notificações.Tabs.Removidas.Cliente')}:
			</Typography>
			<div style={{ width: 250, marginBottom: -5 }}>
				<SelectClient />
			</div>
		</Line>
	);

	const leftFooterComponent = () => (
		<>
			<OfferToolTip
				title={t('Notificações.Atualizar dados da tabela')}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={reloadTable}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<SyncRounded
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							fontSize: 20,
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<OfferToolTip
				title={
					panelControlList.panelControl === 'classifiedPanelControl'
						? t('Ocultar barra de filtros')
						: t('Mostrar barra de filtros')
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handlePanelControlState}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<PlaylistAddRounded
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</>
	);

	const renderMessages = () =>
		// eslint-disable-next-line no-nested-ternary
		error
			? t('Notificações.Tabs.Removidas.Erro ao receber dados')
			: loading
				? t('Carregando...')
				: t('Notificações.Tabs.Removidas.Sem denúncias para este cliente');

	return (
		<>
			<OfferTable
				colsData={colsData}
				tableData={tableData}
				tableActions
				tableFooter
				customNoDataMessage={
					selectedClient
						? renderMessages()
						: t('Notificações.Tabs.Removidas.Selecione um cliente')
				}
				customLeftFooterComponent={leftFooterComponent()}
				customRightFooterComponent={rightFooterComponent()}
				customInfoButton={
					<ShortCutModal
						headerTitle={t('Notificações.Tabs.Removidas.Aba de Removidas')}
						data={ShortCutsData}
					/>
				}
				ActionComponent1={OpenExternalLink}
			/>
			<CheckProcessingItems tableData={data} />
		</>
	);
};

export default RemovedDenouncementTable;
