import React, { createContext, ReactNode, useContext, useState } from 'react';

type OperationTypes = 'add' | 'remove' | 'clear' | 'select-all';

interface CardSelectorData {
	selectedCards: any;
	handleSelectCard: (card: any | null, operation: OperationTypes) => void;
}

export const CardSelectorContext = createContext<CardSelectorData>(
	{} as CardSelectorData
);
interface Props {
	children: ReactNode;
}

export const CardSelector: React.FC<Props> = ({ children }: Props) => {
	const [selectedCards, setSelectedCards] = useState<any[]>([]);

	const handleSelectCard = (card: any | null, operation: OperationTypes) => {
		if (card) {
			switch (operation) {
				case 'add':
					setSelectedCards((existentCards) => [...existentCards, card]);
					break;
				case 'select-all':
					setSelectedCards(card);
					break;
				case 'remove':
					setSelectedCards((existentCards) =>
						existentCards.filter(
							(existentCard) => existentCard.offerID !== card.offerID
						)
					);
					break;
				case 'clear':
					setSelectedCards([]);
					break;
				default:
					break;
			}
		}
		if (card === null && operation === 'clear') {
			setSelectedCards([]);
		}
	};

	return (
		<CardSelectorContext.Provider
			value={{
				selectedCards,
				handleSelectCard,
			}}
		>
			{children}
		</CardSelectorContext.Provider>
	);
};

export function useCardSelector(): CardSelectorData {
	return useContext(CardSelectorContext);
}
